// * lb-check -> carrot-check 로 변경중

.carrot-check-container {
  display: flex;
  flex-wrap: wrap;
}

.carrot-check-item {
  margin-right: 16px;
  margin-bottom: 10px;
  color: #fff;
  text-align: center;
  border-radius: 5px;
}

.check-item-balance {
  margin: 0;
  padding: 28px 25px 28px 60px;

  > label {
    > i.icon {
      position: absolute;
      left: -35px;
    }

    > i.icon::before {
      position: absolute !important;
      top: -1px !important;
      left: -5px !important;
    }

    span.text {
      width: 100%;
      max-width: 100% !important; // for cascading
      display: flex;
      justify-content: space-between;

      b {
        font-weight: 700;
        color: var(--carrot-font-color3, #555);
        word-break: normal;
        text-align: left;
      }

      color: var(--carrot-font-color, #000) !important;
    }

    hr {
      width: 100%;
      border-top: 1px solid var(--carrot-line-color1, #c4c4c4);
      margin-top: 14px;
      margin-bottom: 23px;
    }

    display: flex;
    flex-direction: column;
  }
}

.carrot-checkbox {
  height: 0;
  width: 0;
}

.carrot-checkbox + label {
  position: relative;
  display: flex;
  font-size: 20px;
  line-height: 20px;
  margin: 0;
  transition: color 250ms cubic-bezier(0.4, 0, 0.23, 1);
  align-items: flex-start;
}

.carrot-fieldset {
  label {
    margin: 12px 20px 5px 0px;
    display: inline-flex;
  }
}

.carrot-checkbox + label > .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  width: 1em;
  height: 1em;
  min-width: 1em;
  border-radius: 50%;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  cursor: pointer;
  transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
}

.carrot-checkbox + label > .text {
  vertical-align: center;
  color: #777;
  font-size: 14px;
  line-height: 20px;
}

.carrot-checkbox + label > .text-black {
  color: #000000 !important;
}

.carrot-checkbox + label:hover > .icon,
.carrot-checkbox:focus + label > .icon {
  background: #fff;
}

.carrot-checkbox + label > .icon.disabled {
  opacity: 0.3;
}

.carrot-checkbox:checked + label > .icon {
  border: 0.5em solid #f05014;
  animation: shrink-bounce 200ms cubic-bezier(0.4, 0, 0.23, 1);
}

.carrot-checkbox:checked + label > .icon:before {
  content: "";
  position: absolute;
  top: 0.45em;
  left: 0.25em;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  transform: rotate(45deg);
  transform-origin: 0% 100%;
  animation: checkbox-check 55ms 250ms cubic-bezier(0.4, 0, 0.23, 1) forwards;
}

@keyframes shrink-bounce {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes checkbox-check {
  0% {
    width: 0;
    height: 0;
    border-color: #fff;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  50% {
    width: 0.25em;
    height: 0;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  100% {
    width: 0.25em;
    height: 0.375em;
    border-color: #fff;
    transform: translate3d(0, -0.375em, 0) rotate(45deg);
  }
}
