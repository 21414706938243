// 벤더프리픽스
@mixin vendorPrefix($property, $value) {
  @each $prefix in -webkit-, -moz-, -o-, "" {
    #{$prefix}#{$property}: $value;
  }
}

// 말줄임
@mixin ellipse-one($wid: 100%) {
  width: $wid;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// hide 대체텍스트
@mixin txt-hide() {
  position: absolute !important;
  width: 0 !important;
  height: 0 !important;
  z-index: -1 !important;
  color: transparent !important;
  overflow: hidden !important;
  font-size: xx-small !important;
  line-height: 0 !important;
  min-height: 0 !important;
  max-height: 0 !important;
}

// txt 사이 bar
@mixin bar-border($height: 12px, $color: #555) {
  content: "";
  display: block;
  position: absolute;
  width: 1px;
  height: $height;
  background: $color;
}
