body,
button,
dd,
dl,
dt,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
input,
legend,
li,
ol,
p,
select,
table,
td,
textarea,
th,
ul {
  margin: 0;
  padding: 0;
}

body,
button,
h1,
h2,
h3,
h4,
h5,
h6,
input,
select,
table,
textarea {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', 'Apple SD Gothic Neo', 'Malgun Gothic', '맑은 고딕', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: -1px;
}

body {
  position: relative;
  user-select: none;
  word-break: keep-all;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  background-color: #fff;
}

*{
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong{
  font-weight: normal;
}

fieldset,
iframe,
button {
  border: 0;
}

img {
  vertical-align: top;
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
}

em,
cite,
i,
abbr {
  font-style: normal;
  text-decoration: none;
}

a {
  outline-offset: 1px;
  text-decoration: none;
}

a, button{
  cursor: pointer;
}

select {
  appearance: none;
  color: #000;

  &:focus{
    outline: none;
  }
}

button,
input {
  border-radius: 0;
  border: 0;
  background: 0;
}

input[type="radio"],
input[type="checkbox"] {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}

input::-webkit-search-cancel-button {
  display: none;
}

button,
label {
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

caption {
  position: relative;
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}

.blind{
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}