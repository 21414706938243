.ui-d,
.ui-m {
  .circle-symbol-container {
    display: flex;
    height: 20px;
    align-items: center;
    .circle-symbol {
      display: flex;
      justify-content: center;
      align-items: center;
      background: none;
      height: 16px;
      width: 16px;
      min-width: 16px;
      min-height: 16px;
      font-weight: 700;
      color: var(--carrot-font-color4, #777);
      border: 1px solid var(--carrot-font-color4, #777);
      border-radius: 50%;
      box-sizing: border-box;
      .circle-symbol-character {
        margin: 0 !important;
        padding: 0 !important;
        font-size: 12px;
        position: relative;
        color: var(--carrot-font-color4, #777);
        // line-height: 16px;
      }
    }
  }
}
