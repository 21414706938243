.ui-d {
  .react-custom-selectbox {
    position: relative;
    .bottom {
      bottom: 50px;
    }
    .box-wrap {
      &.open {
        display: block;
      }
      &.close {
        display: none;
      }
    }
    .box-scroller {
      width: 100%;
      border: 1px solid #ededed;
      max-height: 350px;
      overflow-x: hidden;
      overflow-y: auto;
      background: #fff;

      ::-webkit-scrollbar {
        width: 10px;
        height: 20px;
      }
      ::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.1);
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: #fff;
        border: 2px solid #ecebea;
      }
      ::-webkit-scrollbar-button {
        width: 20px;
        height: 10px;
      }
    }
  }
}
