@import "mixin.m";

.ui-m .btn-base-full {
  width: 100% !important;
}
.ui-m.fixed-bottom {
    padding-bottom: 90px;
}

.ui-m.fixed-bottom-b {
    padding-bottom: 185px;
}

.ui-m.step-finish {
    height: calc(100vh - 60px);
    padding-bottom: 0;
}

.ui-m.step-finish {
    .base-body {
        min-height: auto;
        height: 100%;
    }

    .base-main {
        height: 100%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-box-pack: center;
    }

    .result-tit {
        padding-top: 0;
    }
}

.ui-m {

  /************ 공통화를 위해 추가하고 있는 내용 추가 시작 ************/
  .carrot-checkbox:checked + label > .icon{
    background: var(--carrot-primary-color, #f05014);
    border-color: var(--carrot-primary-color, #f05014);
  }
  .carrot-radio:checked + label > .icon{
    background: var(--carrot-primary-color, #f05014);
    border-color: var(--carrot-primary-color, #f05014);
  }

  .carrot-checkbox + label > .text{
    color: var(--carrot-font-color4, #777);
    word-break: break-all;
    max-width:90%;
  }

  .carrot-tab-container li.active {
    background: var(--carrot-primary-color, #f05014);
    border: 1px solid var(--carrot-primary-color, #f05014);
    button{
      background: var(--carrot-primary-color, #f05014);
      border: 1px solid var(--carrot-primary-color, #f05014);
    }
  }

  /************ 공통화를 위해 추가하고 있는 내용 추가 끄읏 ************/

    body, #__next{
        color: var(--carrot-font-color, #000);
        background: var(--carrot-body-background, #fff);
    }

    .box-dialog{
        border-radius: 8px;
        background: var(--carrot-body-background, #fff);
    }
    .box-header{
        background: var(--carrot-body-background, #fff);
    }
    .ui-modal-wrap{
        background: var(--carrot-body-background, #fff);
    }
    .ui-modal-header{
        background: var(--carrot-body-background, #fff);
    }
    .ui-modal-cont{
        background: var(--carrot-body-background, #fff);
    }
    .ui-modal-footer{
        background: var(--carrot-body-background, #fff);
    }

    .hue-p *, .hue-p {
        color: var(--carrot-primary-color, #f05014) !important;
    }
    .hue-s *, .hue-s {
        color: var(--carrot-font-color3, #555) !important;
    }

    .logo-main {
        position: absolute;
        left: 16px;
        top: 16px;
        height: 24px;
        line-height: 18px;
        fill: var(--carrot-primary-color, #f05014);
    }

    .txt-small {
        font-size: 12px;
    }
    .tel-password {
        -webkit-text-security: disc;
    }

    .error-txt dt {
        font-weight: 600;
        font-size: 16px;
        display: block;
        margin-bottom: 5px;
        color: #111;
    }

    .error-txt dd {
        font-size: 14px;
        display: block;
    }

    .error-txt dl+dl {
        margin-top: 30px;
    }

    .keypad-img img {
        width: 100%;
        margin: 0;
        display: block;
    }

    .type-security {
        display: inline-block;
        position: relative;
    }


    /* 보안 키패드 버튼 */
    .btn-security {
        position: absolute;
        top: 5px;
        right: 0;
        width: 30px;
        height: 30px;
    }

    .btn-security:before {
        content: "";
        display: inline-block;
        width: 30px;
        height: 30px;
        background: url('/static/images/common/btn_mouse_m.png') no-repeat 0 0;
        background-size: 30px;
    }

    .type-security .inp-base {
        width: 100%;
    }

    .type-security .ui-btn-cancel {
        margin-left: -65px;
    }


    /* 카메라 버튼 */
    .btn-camera {
        position: absolute;
        top: 5px;
        right: 0;
        width: 30px;
        height: 30px;
    }

    .btn-camera::before {
        content: '';
        display: inline-block;
        width: 30px;
        height: 30px;
        background: url('/static/images/common/ico_camera.png') center no-repeat;
        background-size: 20px;
    }

    .type-security.type-camera .btn-security {
        right: 30px;
    }

    .type-security.type-camera [class*="inp-"].ui-inpcancel {
        padding-right: 56px;
    }


    .keypad-on .btn-security:before,
    .inp-base:focus+.btn-security:before {
        background-position: 0 -30px;
    }

    .keypad-on .ui-security .inp-base {
        border-color: var(--carrot-primary-color, #f05014);
        border-width: 2px;
        padding-top: 8px;
    }

    .agree-info {
        font-size: 16px;
        word-break: break-word;
    }

    .lb-radio,
    .lb-check {
        position: relative;
        display: inline-flex;
        vertical-align: top;
        align-items: flex-start;
        min-height: 20px;
        padding: 12px 0 0;
        margin: 0;
        cursor: pointer;
        line-height: 1.5;
    }

    .fx-item .lb-radio,
    .fx-item .lb-check {
        min-height: 40px;
    }
    .fx-item  .fx-item__notice-step5 {
        letter-spacing: -1px;
        text-indent: -10px;
        padding-left: 10px;
    }
    .lb-radio:before,
    .lb-check:before {
        content: "";
        display: block;
        flex: 0;
        min-width: 20px;
        min-height: 20px;
        margin-right: 7px;
        background-color: #fff;
        box-sizing: border-box;
        border: 1px solid #d3d3d3;
        transition: box-shadow 0.5s, border 0.3s;
    }

    .lb-radio:before {
        border-radius: 50%;
    }

    .lb-check:before {
        border-radius: 50%;
        margin-top: 1px;
    }

    fieldset .lb-radio,
    fieldset .lb-check {
        margin-right: 20px;
    }

    fieldset .lb-radio:last-child,
    fieldset .lb-check:last-child {
        margin-right: 0;
    }

    input:checked+.lb-radio:before,
    input:checked+.lb-check:before,
    .lb-radio.checked:before,
    .lb-check.checked:before {
        border: 1px solid var(--carrot-primary-color, #f05014);
        background: var(--carrot-primary-color, #f05014) url('/static/images/common/ico_form@2x.png') no-repeat -3px -3px;
        background-size: 150px;
    }

    .lb-radio.activated:before,
    .lb-check.activated:before,
    .lb-radio:hover:before,
    .lb-check:hover:before,
    .lb-radio:focus:before,
    .lb-check:focus:before {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }

    .lb-radio.checked.activated:before,
    .lb-check.checked.activated:before,
    .lb-radio.checked:hover:before,
    .lb-check.checked:hover:before,
    .lb-radio.checked:focus:before,
    .lb-check.checked:focus:before {
        box-shadow: 0 0 5px rgba(253, 71, 0, 0.7);
    }

    .lb-radio.checked,
    .lb-check.checked {
        color: var(--carrot-font-color, #000);
    }

    input:disabled+.lb-radio,
    input:disabled+.lb-check,
    .lb-radio.disabled,
    .lb-check.disabled {
        cursor: not-allowed;
        color: #6e6e6e;
        opacity: 1;
    }

    input:disabled+.lb-radio:before,
    input:disabled+.lb-check:before,
    .lb-radio.disabled:before,
    .lb-check.disabled:before {
        opacity: 0.3;
    }

    .lb-radio.disabled.checked,
    .lb-check.disabled.checked {
        opacity: 1;
    }

    .lb-radio span,
    .lb-check span {
        display: inline-block;
        vertical-align: middle;
        height: 100%;
        font-size: 14px;
        line-height: 21px;
        border-bottom: 1px solid transparent;
    }

    .lb-radio span.txt-small,
    .lb-check span.txt-small {
        font-size:14px;
    }

    .lb-check.btn-txt:before {
        display: none;
    }

    .lb-tit {
        display: inline-block;
        height: 40px;
        margin-right: 10px;
        padding-top: 10px;
        vertical-align: top;
        cursor: pointer;
    }

    .lb-radio.type-toggle,
    .lb-check.type-toggle {
        position: relative;
        display: inline-flex;
        vertical-align: top;
        align-items: center;
        min-width: 40px;
        min-height: 22px;
        font-size: 0;
        padding: 0;
        margin: 0;
        cursor: pointer;
        line-height: 1;
        background: #ccc;
        border: 0;
        border-radius: 11px;
        transition: background-color 0.1s ease-in;
    }

    .lb-radio.type-toggle:before,
    .lb-check.type-toggle:before {
        content: "";
        display: block;
        position: absolute;
        top: 2px;
        left: 2px;
        min-width: 18px;
        min-height: 18px;
        border-radius: 50%;
        margin: 0;
        box-sizing: border-box;
        background: #fff;
        border: 0;
        transition: left 0.1s ease-in;
    }

    input:checked+.lb-radio.type-toggle:before,
    input:checked+.lb-check.type-toggle:before,
    .lb-radio.type-toggle.selected:before,
    .lb-check.type-toggle.selected:before,
    .lb-radio.type-toggle.checked:before,
    .lb-check.type-toggle.checked:before {
        left: calc(100% - 20px);
        background-color: #fff;
        background-image: none;
        border: 0;
    }

    input:checked+.lb-radio.type-toggle,
    input:checked+.lb-check.type-toggle,
    .lb-radio.type-toggle.selected,
    .lb-check.type-toggle.selected,
    .lb-radio.type-toggle.checked,
    .lb-check.type-toggle.checked {
        background: var(--carrot-primary-color, #f05014);
        border: 0;
    }

    .lb-radio.type-toggle.activated,
    .lb-check.type-toggle.activated,
    .lb-radio.type-toggle:hover,
    .lb-check.type-toggle:hover,
    .lb-radio.type-toggle:focus,
    .lb-check.type-toggle:focus {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }

    .lb-radio.type-toggle.checked.activated,
    .lb-check.type-toggle.checked.activated,
    .lb-radio.type-toggle.checked:hover,
    .lb-check.type-toggle.checked:hover,
    .lb-radio.type-toggle.checked:focus,
    .lb-check.type-toggle.checked:focus {
        box-shadow: 0 0 5px rgba(253, 71, 0, 0.7);
    }

    input:checked:disabled+.lb-radio.type-toggle,
    input:checked:disabled+.lb-check.type-toggle,
    .lb-radio.checked.disabled.type-toggle,
    .lb-check.checked.disabled.type-toggle {
        background-color: #efa683;
    }

    input:checked:disabled+.lb-radio.type-toggle:before,
    input:checked:disabled+.lb-check.type-toggle:before,
    .lb-radio.checked.disabled.type-toggle:before,
    .lb-check.checked.disabled.type-toggle:before {
        background-color: #f7d3c1;
        opacity: 1;
    }

    input:disabled+.lb-radio.type-toggle,
    input:disabled+.lb-check.type-toggle,
    .lb-radio.disabled.type-toggle,
    .lb-check.disabled.type-toggle {
        background-color: #f7f7f7;
    }

    input:disabled+.lb-radio.type-toggle:before,
    input:disabled+.lb-check.type-toggle:before,
    .lb-radio.disabled.type-toggle:before,
    .lb-check.disabled.type-toggle:before {
        background-color: #fff;
        opacity: 1;
    }

    .lb-radio.type-toggle span,
    .lb-check.type-toggle span {
        font-size: 0;
        line-height: 0;
    }

    .btn-toggle {
        position: relative;
        display: inline-block;
        margin-right: 10px;
    }

    .btn-toggle.type-tip .lb-check {
        width: 100%;
    }

    .btn-toggle .lb-check:before {
        display: none;
    }

    .btn-toggle .lb-check {
        background: var(--carrot-content-background2, #eee);
        color: var(--carrot-font-color1, #262626);
        height: 50px;
        border-radius: 25px;
        border: 0;
        padding: 14px 18px 0;
        font-size: 15px;
        letter-spacing: -1px;
    }

    .btn-toggle :checked+.lb-check,
    .btn-toggle .lb-check.checked {
        background: var(--carrot-primary-color, #f05014);
        color: #fff;
    }

    .btn-toggle .btn-tip {
        position: absolute !important;
        top: 50%;
        right: 15px;
        margin-top: -8px;
    }

    button.btn-toggle,
    a.btn-toggle {
        background: rgba(68, 68, 68, 0.99);
        height: 50px;
        border-radius: 25px;
        border: 0;
        padding: 0 18px;
        font-size: 15px;
        letter-spacing: -1px;
        color: #fff;
    }

    a.btn-toggle {
        padding: 14px 18px 0;
        text-decoration: none;
        color: #fff;
    }

    .field-dl {
        position: relative;
        font-size: 15px;
        width: 100%;
    }

    .field-dl:after {
        content: "";
        display: block;
        clear: both;
    }

    .field-dt {
        float: left;
        font-weight: 700;
        color: #222;
    }

    .field-dt-tit {
        min-height: 0;
        display: inline-block;
        padding-top: 3px;
    }

    .field-dd {
        float: right;
    }

    /* common */
    hr {
        border: 0;
        border-top: 1px dashed rgba(255, 255, 255, 0.3);
        margin: 10px 0;
    }

    .slider-wrap.type-main .slider-item {
        padding: 0 15px;
    }

    .h1 {
        font-size: 24px !important;
        color: var(--carrot-font-color, #000);
        line-height: 30px;
        font-weight: bold;
        letter-spacing: -1px;
        margin: 0;
        word-break: keep-all;
    }

    .h2 {
        font-size: 22px !important;
        color: var(--carrot-font-color, #000);
        line-height: 32px;
        font-weight: bold;
        letter-spacing: -1px;
        margin: 0;
        word-break: keep-all;
    }

    .h3 {
        font-size: 20px !important;
        color: var(--carrot-font-color, #000);
        line-height: 28px;
        font-weight: bold;
        letter-spacing: -1px;
        margin: 0;
        word-break: keep-all;
        margin-top: 30px;
    }

    .h4 {
        font-size: 18px !important;
        color: var(--carrot-font-color, #000);
        line-height: 26px;
        font-weight: bold;
        letter-spacing: -1px;
        margin: 0;
        word-break: keep-all;
        margin-top: 10px;
    }

    .h5 {
        font-size: 16px !important;
        color: var(--carrot-font-color, #000);
        line-height: 26px;
        font-weight: bold;
        letter-spacing: -1px;
        margin: 0;
        word-break: keep-all;
    }

    .h6 {
        font-size: 15px !important;
        color: var(--carrot-font-color, #000);
        line-height: 26px;
        font-weight: bold;
        letter-spacing: -1px;
        margin: 0;
        word-break: keep-all;
    }

    .h7 {
        font-size: 14px !important;
        color: var(--carrot-font-color, #000);
        line-height: 20px;
        font-weight: bold;
        letter-spacing: -1px;
        margin: 0;
        word-break: keep-all;
    }

    .h8 {
        font-size: 13px !important;
        color: var(--carrot-font-color, #000);
        line-height: 18px;
        font-weight: bold;
        letter-spacing: -1px;
        margin: 0;
        word-break: keep-all;
    }

    .h1 .fx-item {
        width: calc(60% - 10px);
        margin-top: -3px;
    }

    .h1 .fx-item+span {
        width: calc(40% + 5px);
        margin-left: 5px;
    }

    .h1 .ui-datepicker .inp-base {
        font-size: 24px;
        font-weight: 400;
    }

    .h1 .fx-wrap+p {
        padding-top: 5px;
    }

    .h4.sub-tit {
        font-size: 16px;
        color: var(--carrot-font-color3, #555);
    }

    .h4 .btn-tip {
        margin-top: -3px;
    }

    .h3 .btn-tip,
    h3 .btn-tip {
        margin-top: -3px;
    }

    .nobrd-btm {
        border-bottom: 0;
    }

    hr.brd {
        margin:0px;
        height:8px;
        background: var(--carrot-line-color3, #eee);
        border:none;
    }

    .txt-s1 {
        font-size: 24px !important;
        line-height: 32px;
        color: var(--carrot-font-color, #000);
        font-weight: 400;
    }

    .txt-s2 {
        font-size: 22px !important;
        line-height: 28px;
        color: var(--carrot-font-color, #000);
        font-weight: 400;
    }

    .txt-s3 {
        font-size: 20px !important;
        line-height: 28px;
        color: var(--carrot-font-color, #000);
        font-weight: 400;
    }

    .txt-s4 {
        font-size: 18px !important;
        line-height: 26px;
        color: var(--carrot-font-color, #000);
        font-weight: 400;
    }

    .txt-s5 {
        font-size: 16px !important;
        line-height: 24px;
        color: var(--carrot-font-color, #000);
        font-weight: 400;
    }

    .txt-s6 {
        font-size: 15px !important;
        line-height: 22px;
        color: var(--carrot-font-color, #000);
        font-weight: 400;
    }

    .txt-s7 {
        font-size: 14px !important;
        line-height: 20px;
        color: var(--carrot-font-color, #000);
        font-weight: 400;
    }

    .txt-s8 {
        font-size: 13px !important;
        line-height: 18px;
        color: var(--carrot-font-color, #000);
        font-weight: 400;
    }

    .txt-s9 {
        font-size: 13px !important;
        line-height: 18px;
        color: #999;
        font-weight: 400;
    }

    .txt-s9 th,
    .txt-s9 td {
        font-size: 12px !important;
        line-height: 18px;
        color: var(--carrot-font-color, #000);
        font-weight: 400;
    }
    .txt-attended-matter {
        font-size: 13px !important;
        line-height: 1.2;
        color: #999;
        font-weight: 400;
    }

    .tit-sub {
        margin-top: 8px;
        font-size: 14px;
        color: var(--carrot-font-color3, #555);
        font-weight: normal;
        line-height: 22px;
        letter-spacing: -1px;
    }

    .tit-sub b {
        color: var(--carrot-font-color, #000);
        font-weight: 700;
    }

    .ui-modal-cont .tit-sub {
        margin-top: 8px;
        font-size: 15px;
        color: var(--carrot-font-color3, #555);
        font-weight: 400;
    }

    .txt-helper {
        opacity: 1;
    }

    .activated .txt-helper {
        opacity: 1;
    }

    .txt-price {
        display: inline-flex;
        font-size: 16px;
        color: var(--carrot-font-color, #000);
        line-height: normal;
        letter-spacing: -0.05em;
        align-items: center;
    }

    .txt-price b {
        margin-right: 5px;
        color: var(--carrot-primary-color, #f05014) !important;
        font-size: 23px;
        font-weight: 700;
    }

    .txt-price .txt-s7 {
        display: block;
    }

    .txt-tag {
        display: inline-block;
        margin: 0 3px;
        padding: 2px 7px 3px;
        font-size: 12px;
        font-weight: 400;
        line-height: 1;
        color: #fff;
        background: #6e6e6e;
        border-radius: 8px;
    }

    .txt-tag.align-middle {
        vertical-align: middle;
        margin-bottom: 3px;
    }

    .txt-deco {
        text-decoration: underline;
    }

    .txt-check {
        font-size: 15px;
        color: var(--carrot-primary-color7, #e2470f);
    }

    .txt-check > span {
      margin-left: 25px;
    }

    .txt-check:before {
      content: "";
      position: absolute;
      display:block;
      margin-top: 0px;
      width: 12px;
      height: 8px;
      border-top: 2px solid var(--carrot-primary-color, #f05014);
      border-right: 2px solid var(--carrot-primary-color, #f05014);
      transform: rotate(135deg) translateY(-50%);
      vertical-align: middle;
      border-left: 0;
    }

    .ui-select.type-telcom {
        width: 120px;
        margin-right: 24px;
    }

    .ui-fixed-top .box-srch {
        padding: 10px 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }

    .box-srch {
        padding: 10px 0;
        background: #fff;
    }

    /* 청약별 가입 단계 */
    .step-wrap {
        width: 100%;
        position: fixed;
        top: 60px;
        left: 0;
        height: 4px;
        background: var(--carrot-line-color2, #d9d9d9);
        z-index: 3;
    }

    .step-bar {
        background: var(--carrot-primary-color, #f05014);
        height: 4px;
        width: 0;
    }

    .step-wrap.n2 .n1 {
        width: 50%;
    }

    .step-wrap.n2 .n2 {
        width: 100%;
    }

    .step-wrap.n3 .n1 {
        width: 33.33%;
    }

    .step-wrap.n3 .n2 {
        width: 66.66%;
    }

    .step-wrap.n3 .n3 {
        width: 100%;
    }

    .step-wrap.n4 .n1 {
        width: 25%;
    }

    .step-wrap.n4 .n2 {
        width: 50%;
    }

    .step-wrap.n4 .n3 {
        width: 75%;
    }

    .step-wrap.n4 .n4 {
        width: 100%;
    }

    .step-wrap.n5 .n1 {
        width: 20%;
    }

    .step-wrap.n5 .n2 {
        width: 40%;
    }

    .step-wrap.n5 .n3 {
        width: 60%;
    }

    .step-wrap.n5 .n4 {
        width: 80%;
    }

    .step-wrap.n5 .n5 {
        width: 100%;
    }

    .step-wrap.n6 .n1 {
        width: 16.66%;
    }

    .step-wrap.n6 .n2 {
        width: 33.32%;
    }

    .step-wrap.n6 .n3 {
        width: 50%;
    }

    .step-wrap.n6 .n4 {
        width: 66.66%;
    }

    .step-wrap.n6 .n5 {
        width: 83.32%;
    }

    .step-wrap.n6 .n6 {
        width: 100%;
    }

    .step-wrap.n7 .n1 {
        width: 14.2%;
    }

    .step-wrap.n7 .n2 {
        width: 28.5%;
    }

    .step-wrap.n7 .n3 {
        width: 42.7%;
    }

    .step-wrap.n7 .n4 {
        width: 57%;
    }

    .step-wrap.n7 .n5 {
        width: 71.2%;
    }

    .step-wrap.n7 .n6 {
        width: 85.5%;
    }

    .step-wrap.n7 .n7 {
        width: 100%;
    }

    .step-wrap.n8 .n1 {
        width: 12.5%;
    }

    .step-wrap.n8 .n2 {
        width: 25%;
    }

    .step-wrap.n8 .n3 {
        width: 37.5%;
    }

    .step-wrap.n8 .n4 {
        width: 50%;
    }

    .step-wrap.n8 .n5 {
        width: 62.5%;
    }

    .step-wrap.n8 .n6 {
        width: 75%;
    }

    .step-wrap.n8 .n7 {
        width: 87.5%;
    }

    .step-wrap.n8 .n8 {
        width: 100%;
    }

    .step-wrap.n9 .n1 {
        width: 11.1%;
    }

    .step-wrap.n9 .n2 {
        width: 22.2%;
    }

    .step-wrap.n9 .n3 {
        width: 33.3%;
    }

    .step-wrap.n9 .n4 {
        width: 44.4%;
    }

    .step-wrap.n9 .n5 {
        width: 55.5%;
    }

    .step-wrap.n9 .n6 {
        width: 66.6%;
    }

    .step-wrap.n9 .n7 {
        width: 77.7%;
    }

    .step-wrap.n9 .n8 {
        width: 88.8%;
    }

    .step-wrap.n9 .n9 {
        width: 100%;
    }

    .step-wrap.n10 .n1 {
        width: 10%;
    }

    .step-wrap.n10 .n2 {
        width: 20%;
    }

    .step-wrap.n10 .n3 {
        width: 30%;
    }

    .step-wrap.n10 .n4 {
        width: 40%;
    }

    .step-wrap.n10 .n5 {
        width: 50%;
    }

    .step-wrap.n10 .n6 {
        width: 60%;
    }

    .step-wrap.n10 .n7 {
        width: 70%;
    }

    .step-wrap.n10 .n8 {
        width: 80%;
    }

    .step-wrap.n10 .n9 {
        width: 90%;
    }

    .step-wrap.n10 .n10 {
        width: 100%;
    }

    .btn-wrap+.bul-care {
        margin-top: 23px;
    }

    .list-terms,
    .list-terms button {
        font-size: 12px !important;
        color: #222;
        font-weight: 400;
        letter-spacing: 0;
        margin-top: 5px;
    }

    .list-terms-end {
        color: var(--carrot-primary-color, #f05014);
    }

    /* base form:select */
    .ui-select .btn-select {
        width: 100%;
        text-align: left;
        min-height: 38px;
        padding: 7px 20px 7px 0;
        border: 0;
        border-bottom: 1px solid var(--carrot-border-color4, #777);
        font-size: 16px;
        background: transparent;
        @include vendorPrefix(appearance, none);
        -webkit-border-radius: 0;
        cursor: pointer;
    }

    .ui-select .btn-select span {
        color: #ddd;
    }

    .ui-select.type-plan .btn-select {
        padding-top: 3px;
    }

    .ui-select.type-plan .btn-select span {
        color: #111;
    }

    .ui-select:before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        margin-top: -3px;
        right: 12px;
        width: 0px;
        height: 0px;
        border: 4px solid transparent;
        border-top: 5px solid #333;
        z-index: 0;
    }

    .base-select {
        display: inline-block;
        position: relative;
        vertical-align: top;
        width: auto;
        height: 38px;
        padding: 0 32px 0 0;
        border: 0;
        border-bottom: 1px solid var(--carrot-border-color4, #777);
        font-size: 14px;
        color: var(--carrot-font-color, #000);
        background: transparent;
        -webkit-border-radius: 0;
        cursor: pointer;
    }

    .base-select:hover,
    .base-select:focus {
        border-color: var(--carrot-primary-color, #f05014);
        border-width: 2px;
        outline: none;
    }

    .base-select:invalid {
        color: #ddd;
    }

    .field-inlabel.type-large select option[value=""][disabled] {
        display: none;
    }

    .base-select option {
        color: var(--carrot-font-color3, #555);
    }

    .base-select[disabled] {
        color: #ddd;
        opacity: 1;
        -webkit-text-fill-color: #ddd;
        border-color: #ddd;
    }

    /* in label style */
    [class*="inp-"] {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        border: 0;
        font-size: 14px;
        color: var(--carrot-font-color, #000);
        border-bottom: 1px solid var(--carrot-border-color4, #777);
        height: 38px;
        padding: 7px 4px;
        outline: 0;
        background: transparent;
    }

    .focus,
    [class*="inp-"]:focus {
        border-color: var(--carrot-primary-color, #f05014);
        border-width: 2px;
        padding-top: 8px;
    }

    [class*="inp-"].init-height {
        height: initial;
        min-height: 38px;
        border-color: var(--carrot-line-color1, #c4c4c4);
    }

    .box-base .focus,
    .box-base [class*="inp-"] {
        border-color: var(--carrot-font-color4, #777);
    }

    .field-inlabel [class*="inp-"].ui-inpcancel {
        padding: 7px 26px 7px 4px;
    }

    .field-inlabel input.ui-inpcancel:focus,
    .field-inlabel [class*="inp-"]:focus {
        border-color: var(--carrot-primary-color, #f05014) !important;
        border-width: 2px !important;
        padding-top: 8px;
    }

    .field-inlabel [class*="inp-"]:disabled,
    .field-inlabel [class*="inp-"][disabled] {
        //border-color: var(--carrot-line-color1, #c4c4c4) !important;
        color: #6e6e6e !important;
        opacity: 1;
        -webkit-text-fill-color: #6e6e6e;
        &::placeholder{
          -webkit-text-fill-color: var(--carrot-font-color7, #d9d9d9);
        }
    }

    .field-inlabel [class*="inp-"][readonly] {
        color: #6e6e6e !important;
        border-color: var(--carrot-line-color1, #c4c4c4) !important;
    }

    .field-inlabel [class*="inp-"][readonly]:focus {
        border-color: var(--carrot-line-color1, #c4c4c4) !important;
        border-width: 1px !important;
        padding-top: 7px;
    }

    .field-inlabel .inp-datepicker [class*="inp-"] {
        color: var(--carrot-font-color, #000) !important;
        -webkit-text-fill-color: initial !important;
    }

    .box-base .field-inlabel [class*="inp-"]:disabled,
    .box-base .field-inlabel [class*="inp-"][disabled] {
        border-color: var(--carrot-line-color1, #c4c4c4) !important;
        color: #6e6e6e !important;
        opacity: 1;
        -webkit-text-fill-color: #6e6e6e;
    }

    input[class*="inp-"]::placeholder {
        color: var(--carrot-placeholder-color, #ddd);
    }

    input[class*="inp-"]:focus::placeholder {
        color: var(--carrot-placeholder-color, #ddd);
    }

    .box-base .field-inlabel [class*="inp-"]:disabled::placeholder {
        color: var(--carrot-placeholder-color, #ddd) !important;
        -webkit-text-fill-color: var(--carrot-placeholder-color, #ddd) !important;
    }

    .field-inlabel.type-large {
        position: relative;
        padding: 20px 0 30px;
        display: inline-block;
        vertical-align: top;
        width: 100%;
    }

    .field-inlabel.type-large .field-inlabel-label {
        position: absolute;
        opacity: 1;
        top: 0;
        left: 10px;
        transform: translate(0, 29px);
        font-size: 24px;
        color: var(--carrot-font-color6, #c4c4c4);
        cursor: text;
        pointer-events: none;
        -webkit-transition: -webkit-transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1), font-size 150ms, opacity 0.2s ease-in;
        transition: -webkit-transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1), font-size 150ms, opacity 0.2s ease-in;
        -o-transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1), font-size 150ms, opacity 0.2s ease-in;
        transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1), font-size 150ms, opacity 0.2s ease-in;
    }

    .field-inlabel.type-large [class*="inp-"] {
        height: 52px;
        padding: 10px 8px;
        font-size: 24px;
    }

    .field-inlabel.type-large [class*="inp-"].ui-inpcancel {
        padding: 10px 30px 10px 8px;
    }

    .field-inlabel.type-large input.ui-inpcancel:focus,
    .field-inlabel.type-large input:focus {
        padding-top: 11px;
    }

    .field-inlabel.type-large .field-inlabel-label i {
        font-size: 13px;
        color: var(--carrot-line-color1, #c4c4c4);
    }

    .field-inlabel.type-large.activated input+.ui-btn-cancel+.field-inlabel-label,
    .field-inlabel.type-large.activated input+.field-inlabel-label,
    .field-inlabel.type-large input:focus+.ui-btn-cancel+.field-inlabel-label,
    .field-inlabel.type-large.activated .field-inlabel-label,
    .field-inlabel.type-large input:focus+.field-inlabel-label {
        @include vendorPrefix(transform, translate(0, 2px));
        color: var(--carrot-font-color3, #555);
        font-size: 13px;
        left: 0;
    }

    .field-inlabel.type-large.activated input+.field-inlabel-label:after,
    .field-inlabel.type-large input:focus+.field-inlabel-label:after {
        padding: 0 5px;
        top: 8px;
        left: -5px;
        z-index: -1;
        border-top: 2px solid #fff;
        transition-delay: 150ms;
    }

    .field-inlabel.type-large input::placeholder {
        color: transparent;
    }

    .field-inlabel.type-large input:focus::placeholder {
        color: var(--carrot-line-color1, #c4c4c4);
    }

    .field-inlabel.type-large.activated input::placeholder {
        color: var(--carrot-line-color1, #c4c4c4);
        font-size: 24px;
    }

    .field-inlabel {
        position: relative;
        padding: 18px 0 19px;
        display: inline-block;
        vertical-align: top;
        width: 100%;
    }

    .field-inlabel .field-inlabel-tit,
    .field-inlabel .field-inlabel-label {
        position: absolute;
        opacity: 1;
        top: 0;
        left: 0;
        @include vendorPrefix(transform, translate(0, 2px));
        color: var(--carrot-font-color3, #555);
        font-size: 12px;
        left: 0;
        white-space: nowrap;
    }

    .field-inlabel .field-inlabel-label.type-full {
        width: 100%;
    }

    .field-inlabel .field-inlabel-label.type-full .txt-helper {
        position: absolute;
        right: 0;
    }

    .field-inlabel .placeholder {
        position: absolute;
        left: 0;
        padding: 0;
        top: 65px;
        line-height: 1.2;
        margin: 0;
    }

    .field-inlabel .ui-select .ui-error-msg {
        top: 42px;
    }

    .field-inlabel.ui-error-true .placeholder {
        display: none;
    }

    .field-inlabel.type-large .ui-select select {
        color: transparent;
    }

    .field-inlabel.type-large.activated .ui-select select {
        font-size: 16px;
        color: var(--carrot-font-color, #000);
    }

    .field-inlabel.type-large.activated .ui-select select:required:invalid {
        color: var(--carrot-line-color1, #c4c4c4);
    }

    .merger-tit .field-inlabel {
        position: static;
    }

    .field-inlabel.no-label {
        padding-top: 0;
        margin-top: -2px;
    }

    .field-inlabel .inlabel-check {
        min-height: auto;
        padding: 0;
    }

    .field-data {
        font-size: 16px;
        margin-top: 10px;
    }

    textarea.inp-base {
        height: 140px;
        margin-top: 0 !important;
        border: 1px #777 solid;
        margin-top: 15px !important;
    }

    textarea[class*="inp-"]::placeholder {
        color: #ddd;
    }

    /* ui inputclear */
    .ui-btn-cancel {
        display: inline-block;
        position: absolute;
        top: 23px;
        margin-left: -27px;
        width: 24px;
        height: 24px;
        background: var(--carrot-line-color1, #c4c4c4);
        border-radius: 12px;
    }

    .field-inlabel.type-large .ui-btn-cancel {
        top: 34px;
        bottom: initial;
    }

    .ui-btn-cancel span {
        position: relative;
        display: block;
        left: 6px;
        width: 12px;
        height: 12px;
        background: transparent;
        color: transparent;
        overflow: hidden;
        font-size: xx-small;
    }

    .ui-btn-cancel span:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -1px;
        display: block;
        width: 100%;
        height: 2px;
        background: #fff;
        border-radius: 1px;
        @include vendorPrefix(transform, rotate(45deg));
    }

    .ui-btn-cancel span:before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -1px;
        display: block;
        width: 2px;
        height: 100%;
        background: #fff;
        border-radius: 1px;
        @include vendorPrefix(transform, rotate(45deg));
    }

    .pdt-n .ui-btn-cancel,
    .no-label .ui-btn-cancel {
        top: 6px;
    }

    .pdt-ml .ui-btn-cancel {
        top: 41px;
    }

    textarea+.ui-btn-cancel {
        top: initial;
        bottom: 27px;
    }

    .pdb-xxs>textarea+.ui-btn-cancel {
        bottom: 20px;
    }

    /* error message */
    .ui-error-msg {
        position: relative;
        width: 100%;
        display: none;
        font-size: 10px !important;
        color: var(--carrot-error-color, #e51c23);
        padding: 6px 10px 4px 20px;
        word-break: break-all;
    }

    .ui-error-msg::before {
        content: '';
        position: absolute;
        display: block;
        top: 5px;
        left: -2px;
        width: 17px;
        height: 17px;
        background: url('/static/images/common/ico_form@2x.png') no-repeat -24px -25px;
        background-size: 131px;
    }

    .ui-error-true .ui-error-msg {
        display: block;
    }

    .ui-error-true .ui-datepicker+.ui-error-msg {
        position: absolute;
        white-space: nowrap;
    }

    .ui-error-true .ui-error-item,
    .ui-error-true .ui-error-select .ui-select-btn {
        border-color: var(--carrot-error-color, #e51c23) !important;
    }

    .ui-error-wrap {
        position: absolute;
        bottom: 19px;
        left: 0;
        width: 100%;
    }

    /* confirmed message */
    .field-inlabel.type-large .ui-confirmed-msg {
        padding: 5px 10px 4px 24px;
    }

    .ui-confirmed-msg {
        width: 100%;
        display: block;
        position: relative;
        font-size: 13px !important;
        color: #048901;
        padding: 5px 4px 4px 24px;
    }

    .ui-confirmed-msg:before {
        content: "";
        display: block;
        position: absolute;
        top: 2px;
        left: 0;
        width: 25px;
        height: 25px;
        background: url('/static/images/common/ico_form@2x.png') no-repeat -125px 0;
        background-size: 150px;
    }

    /* input type */
    .type-term .fx-item {
        width: 100px;
    }

    .type-term .fx-item:first-child {
        width: calc(100% - 130px);
    }

    .type-term .fx-item.short {
        width: 30px;
        text-align: center;
    }

    .type-term .fx-item:last-child [class*="inp-"].ui-inpcancel {
        padding: 7px 4px;
    }

    .type-term-half .fx-item {
        width: calc(50% - 20px);
    }

    .type-term-half .fx-item.short {
        width: 40px;
        text-align: center;
    }

    .type-term-half .fx-item:last-child [class*="inp-"].ui-inpcancel {
        padding: 7px 4px;
    }

    .type-term-half.type-count .fx-item {
        width: calc(50% - 40px);
    }

    .type-term-half.type-count .fx-item.short {
        width: 40px;
        text-align: center;
    }

    .type-cardnum .inp-base {
        width: calc((100% - 69px) / 4);
    }

    .type-cardnum .in-item {
        display: inline-block;
        width: 23px;
        text-align: center;
        padding-top: 10px;
    }

    .type-expdate .inp-base,
    .type-expdate .ui-select {
        width: calc(50% - 13px);
    }

    .type-expdate .in-item {
        display: inline-block;
        width: 26px;
        text-align: center;
        padding-top: 10px;
    }

    .type-resreg .type-security,
    .type-resreg .inp-base {
        width: calc(50% - 13px);
    }

    .type-resreg .type-security .inp-base {
        width: 100%;
    }

    .type-resreg .in-item {
        display: inline-block;
        width: 26px;
        text-align: center;
        padding-top: 10px;
    }

    .type-daytime .fx-item:first-child {
        text-align: left;
    }

    .type-daytime .fx-item {
        text-align: right;
        width: calc(50% - 11px);
        flex-grow: 0;
    }

    .type-owner .inp-base {
        width: calc(30% - 13px);
    }

    .type-owner .inp-base:last-child {
        width: calc(40% - 26px);
    }

    .type-owner .in-item {
        display: inline-block;
        width: 26px;
        text-align: center;
        padding-top: 10px;
    }

    .type-datetime .fx-item {
        width: calc(33.3% - 10px);
    }

    .type-datetime .fx-item+.fx-item {
        margin-left: 10px;
    }

    .type-datetime .ui-select {
        padding: 17px 0 19px;
    }

    .type-srh .inp-base::-webkit-calendar-picker-indicator {
        display: none;
    }

    .type-srh .inp-base {
        padding-right: 40px !important;
    }

    .type-srh .inp-base.ui-inpcancel {
        padding-right: 65px !important;
    }

    .field-inlabel.type-srh .ui-btn-cancel {
        margin-left: -60px;
    }

    .type-srh .btn-srh {
        margin-left: -38px;
    }

    .type-srh.type-large .ui-btn-cancel {
        margin-left: -65px;
    }

    .type-srh.type-large .btn-srh {
        width: 30px;
        height: 30px;
        margin-top: 11px;
        background-position: -260px -35px;
    }

    .field-inlabel.txt-2 [class*="inp-"] {
        padding-right: 34px;
    }

    .field-inlabel.txt-2 [class*="inp-"].ui-inpcancel {
        padding-right: 60px;
    }

    .field-inlabel.txt-2 .ui-btn-cancel {
        margin-left: -55px;
    }

    .txt-in {
        display: inline-block;
        border: 0;
        padding: 8px 0 0;
        font-size: 16px;
        text-align: right;
    }

    .field-inlabel.txt-2 .txt-in {
        width: 34px;
        margin-left: -34px;
    }

    .field-inlabel.txt-1 [class*="inp-"] {
        padding-right: 15px;
    }

    .field-inlabel.txt-1 .txt-in {
        width: 15px;
        margin-left: -15px;
    }

    .field-inlabel.txt-time [class*="inp-"] {
        padding-right: 40px;
    }

    .field-inlabel.txt-time [class*="inp-"].ui-inpcancel {
        padding-right: 65px;
    }

    .field-inlabel.txt-time .ui-btn-cancel {
        margin-left: -71px;
    }

    .field-inlabel.txt-time .txt-in {
        width: 40px;
        margin-left: -40px;
    }

    .type-price .inp-intxt {
        font-size: 16px;
        width: 50px;
    }

    .type-price [class*="inp-"]:focus+.inp-intxt {
        border-color: var(--carrot-font-color3, #555);
    }

    .box-base .type-price [class*="inp-"]:focus+.inp-intxt {
        border-color: #dcdcdc;
    }

    .type-btn2 .inp-base {
        width: calc(100% - 90px);
    }

    .type-btn2 .btn-base {
        width: 70px;
        margin-left: 20px;
    }

    .type-btn3 .inp-base {
        width: calc(100% - 103px);
    }

    .type-btn3 .btn-base {
        width: 83px;
        margin-left: 20px;
    }

    .type-btn4 .inp-base {
        width: calc(100% - 115px);
    }

    .type-btn4 .btn-base {
        width: 95px;
        margin-left: 20px;
    }

    .carnum-wrap {
        padding: 20px 25px 20px !important;
    }

    .carnum-wrap strong {
        font-size: 16px;
        font-weight: 700;
        color: var(--carrot-font-color, #000);
    }

    .type-namephone .fx-item {
        min-width: calc(35% - 12px);
        max-width: calc(35% - 12px);
    }

    .type-namephone .fx-item+.fx-item {
        min-width: calc(65% - 12px);
        max-width: calc(65% - 12px);
        margin-left: 23px;
    }

    /* button */
    .scrolled-b .btn-wrap-fixed {
        box-shadow: none;
    }

    .btn-wrap-fixed {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 20;
        width: 100%;
        padding: 0;
        transition: bottom 0.2s ease-out;
    }

    .ui-acco.type-fix {
        z-index: 1;
    }

    .btn-wrap-fixed.col-2 {
        overflow: hidden;
    }

    .btn-wrap-fixed .btn-base-l.type-b {
        background-color: rgba(119, 119, 119, 0.85);
    }

    .btn-wrap-fixed .btn-base-l:disabled,
    .btn-wrap-fixed .btn-base-l.disabled,
    .btn-wrap-fixed .btn-base-l[disabled] {
        background-color: #c4c4c4;
    }

    .btn-wrap-fixed .btn-base-l {
        background-color: var(--carrot-primary-color, #f05014);
        border-radius: 0;
        height: 60px;
    }

    .btn-wrap-fixed.col-2 .btn-base-l {
        width: 50%;
        float: left;
    }

    .btn-wrap-fixed .ui-acco.acco-open:after {
        display: block;
        opacity: 1;
    }

    .btn-wrap-fixed .ui-acco:after {
        content: "";
        display: none;
        opacity: 0;
        transition: opacity 0.2s ease-in;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100% - 60px);
        background: rgba(0, 0, 0, 0.7);
        z-index: 0;
    }

    .btn-wrap-fixed .ui-acco .ui-acco-wrap {
        z-index: 1;
    }

    .btn-wrap-fixed.type-half .btn-base-l {
        width: 50%;
    }

    .btn-wrap,
    .btn-wrap.type-normal {
        position: relative;
        margin: 10px 0;
        width: 100%;
        text-align: center;
        font-size: 0;
    }

    .btn-wrap.txt-l [class*="btn-base"]+[class*="btn-base"],
    .btn-wrap [class*="btn-base"]+[class*="btn-base"] {
        margin-right: 0px;
        margin-left: 19px;
    }

    .btn-wrap.txt-r [class*="btn-base"]+[class*="btn-base"] {
        margin-right: 0;
        margin-left: 10px;
    }

    .btn-wrap.type-full button,
    .btn-wrap.type-full a {
        width: 100%;
        margin: 0;
    }

    .btn-wrap.type-half button,
    .btn-wrap.type-half a {
        width: calc(50% - 9.5px);
        margin: 0;
        padding: 0;
    }

    .btn-wrap.type-part3 [class*="btn-base"]+[class*="btn-base"] {
        margin-right: 0px;
        margin-left: 10px;
    }

    .btn-wrap.type-part3 button,
    .btn-wrap.type-part3 a {
        width: calc(32.5% - 5px);
        margin: 0;
        padding: 0;
    }

    .btn-wrap.type-part3 a.btn-base-b {
        padding-top: 9px !important;
    }

    .btn-wrap.type-normal {
        padding: 0 !important;
    }
    .btn-wrap.type-normal .btn-pure,
    .btn-pure,
    .btn-wrap.type-normal [class*="btn-base"],
    [class*="btn-base"] {
        display: inline-block;
        padding: 0 18px;
        min-height: 38px;
        border: 1px solid var(--carrot-font-color4, #777);
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        background: var(--carrot-body-background, #fff);
        vertical-align: top;
        color: var(--carrot-font-color3, #555);
        text-decoration: none;
        line-height: 1;
        border-radius: 8px;
        transition: background-color 0.2s ease-in;
        letter-spacing: -0.03em;
    }
    a.btn-pure {
        line-height: 38px;
    }
    .btn-pure.btn-pure-full {
        width: 100%;
        margin-top: 10px;
    }
    div > .btn-pure-full:first-child {
        margin-top:0;
    }
    .bul-etc>li .btn-pure-full:last-child{
        margin-bottom:15px;
    }
    .btn-app {
        width: 116px;
        padding: 0 14px;
    }
    .txt-r .btn-app + .btn-app {
        margin-left: 6px;
        margin-right:0;
    }
    div[class*="btn-base"],
    span[class*="btn-base"],
    a[class*="btn-base"] {
        padding: 9px 18px 8px;
    }

    .btn-wrap.type-normal [class*="btn-base"] {
        position: relative;
        top: initial;
        left: initial;
        width: auto;
        height: auto;
        min-width: 96px;
        padding: 0 10px;
    }

    .btn-wrap.type-normal [class*="btn-base"]+.btn-base {
        margin-left: 15px;
    }

    [class*="btn-base"]:disabled,
    [class*="btn-base"][disabled],
    [class*="btn-base"].disabled {
        opacity: 1;
        border: 1px solid var(--carrot-line-color1, #c4c4c4);
        color: var(--carrot-font-color4, #777);
        background: var(--carrot-body-background, #fff);
        box-shadow: none;
        cursor: default;
    }

    .btn-base.type-check:hover {
        border-color: var(--carrot-font-color4, #777);
        color: var(--carrot-font-color3, #555);
    }
    .btn-base.type-check:hover .ico-check::before {
        background-position:-81px -8px;
    }
    .btn-base.type-check.checked .ico-check::before {
        background-position:-31px -8px;
    }

    .btn-pure.focused,
    .btn-base.focused,
    .btn-base.checked,
    .btn-base-m.checked,
    .btn-base.type-check.checked,
    .btn-base:hover,
    .btn-base:focus {
        border-color: var(--carrot-primary-color, #f05014);
        //background: var(--carrot-body-background, #fff);
        //color: var(--carrot-font-color, #000);
    }

    .btn-base.type-primary {
        background: var(--carrot-primary-color, #f05014);
        color: #fff;
    }

    .btn-wrap.type-normal .btn-base:disabled,
    .btn-wrap.type-normal .btn-base[disabled],
    .btn-wrap.type-normal .btn-base.disabled,
    .btn-base:disabled,
    .btn-base[disabled],
    .btn-base.disabled {
        opacity: 1;
        border: 1px solid var(--carrot-line-color, #9d9d9d);
        color: var(--carrot-font-color6, #c4c4c4);
        background: var(--carrot-body-background, #fff);
        box-shadow: none;
        cursor: default;
        pointer-events: none;
    }

    .btn-base.disabled:hover,
    .btn-base.disabled:focus {
        border-color: var(--carrot-line-color1, #c4c4c4);
        color: var(--carrot-line-color1, #c4c4c4);
        background: inherit;
    }

    .chk-account .btn-base:hover,
    .chk-account .btn-base:focus {
        border-color: var(--carrot-font-color4, #777);
        background: inherit;
        color: var(--carrot-font-color3, #555);
    }

    .chk-account .btn-base.focused:hover,
    .chk-account .btn-base.focused:focus {
        border-color: var(--carrot-primary-color, #f05014);
        background: inherit;
        color: var(--carrot-primary-color7, #e2470f);
    }

    .btn-base-s {
        min-height: 30px;
    }

    a.btn-base-s {
        min-height: 30px;
        padding: 8px 20px 3px;
    }

    .btn-base-r {
        min-height: 30px;
        border-radius: 15px;
        border-color: #ddd;
    }

    a.btn-base-r {
        min-height: 30px;
        border-radius: 15px;
        padding: 7px 20px 3px;
    }

    .btn-base-r.dark {
        background: #f1f1f1;
        color: #111;
        border-color: #f1f1f1;
    }

    .btn-base-r.dark:hover {
        background: #999;
    }

    .btn-base-r.dark-type2,
    .btn-base-r.dark-type2:hover,
    .btn-base-r.dark-type2.focused,
    .btn-base-r.dark-type2:focus {
        min-height: 18px;
        padding: 0 5px;
        background: #d0d0d0;
        color: #fff;
        border-color: #d0d0d0;
        font-size: 10px;
    }

    .btn-base-r:hover {
        border-color: var(--carrot-font-color3, #555);
        color: var(--carrot-font-color, #000);
        background-color: #fcfcfc;
    }

    .btn-base-r.dark.focused,
    .btn-base-r.dark:focus {
        border-color: #777;
        background: #777;
        color: #fff;
    }

    .btn-base-r.focused,
    .btn-base-r:focus {
        border-color: var(--carrot-primary-color, #f05014);
        background: inherit;
        color: var(--carrot-primary-color, #f05014);
    }

    .btn-base-r.dark:disabled,
    .btn-base-r.dark[disabled],
    .btn-base-r.dark.disabled {
        opacity: 0.5;
        border: 1px solid #f1f1f1;
        color: #111;
        background: #f1f1f1;
        box-shadow: none;
        cursor: default;
    }

    .btn-base-r:disabled,
    .btn-base-r[disabled],
    .btn-base-r.disabled {
        opacity: 1;
        border: 1px solid var(--carrot-line-color, #9d9d9d);
        color: var(--carrot-font-color4, #777);
        background: #fff;
        box-shadow: none;
        cursor: default;
    }

    .btn-base-r.disabled:hover,
    .btn-base-r.disabled:focus {
        border-color: var(--carrot-line-color1, #c4c4c4);
        color: var(--carrot-line-color1, #c4c4c4);
        background: inherit;
    }

    /* sub button */
    .btn-base,
    .btn-base-m {
        min-height: 38px;
        padding: 0 18px;
    }

    a.btn-base,
    a.btn-base-m {
        min-height: 38px;
        padding: 11px 18px 0;
    }

    .btn-base-b {
        height: 55px;
        line-height: 1.3;
        padding: 0;
    }

    a.btn-base-b {
        padding: 9px 0 0;
    }

    .btn-base-b.focused,
    .btn-base-b:hover,
    .btn-base-b:focus {
        border-color: var(--carrot-primary-color, #f05014);
        color: #d65417;
    }

    .btn-base-b.hide,
    .btn-base-b.hidden {
        display: none;
    }

    /* bottom button */
    .btn-base-l {
        width: 100%;
        background: var(--carrot-primary-color, #f05014);
        color: #fff;
        min-height: 58px;
        font-size: 18px;
        border: 0;
        border-radius: 8px;
        margin: 0;
        padding: 0 18px;
    }

    a.btn-base-l {
        padding: 20px 18px 15px;
        max-height: 58px;
    }

    .btn-base-l:disabled,
    .btn-base-l.disabled,
    .btn-base-l[disabled] {
        background: var(--carrot-content-background4, #c4c4c4);
        border: 0;
        color: #fff;
    }

    .btn-base-l.type-b {
        background: #777;
        border: 0;
    }

    /* Answer button : secondary */
    .btn-base-a {
        min-height: 48px;
        border-radius: 24px;
        border-color: #d9d9d9;
        padding: 0 20px;
        font-size: 16px;
        color: var(--carrot-font-color, #000);
        font-weight: 400;
    }

    div.btn-base-a,
    span.btn-base-a,
    a.btn-base-a {
        padding: 14px 20px 8px;
    }

    .btn-base-a.type-primary {
        background: var(--carrot-primary-color7, #e2470f);
        border-color: var(--carrot-primary-color, #f05014);
        color: #fff;
    }

    .btn-base-a.type-emoji {
        padding-left: 18px;
        padding-right: 18px;
    }

    .btn-base-a.checked {
        border-color: var(--carrot-primary-color, #f05014);
        color: var(--carrot-primary-color, #f05014);
    }

    /* chips button */
    [class*="btn-chips"] {
        display: inline-block;
        padding: 0 18px;
        min-height: 44px;
        border-radius: 22px;
        border: 0;
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        background: #f7f7f7;
        vertical-align: top;
        color: #999;
        text-decoration: none;
        line-height: 1;
        border-radius: 22px;
        transition: background-color 0.2s ease-in;
        letter-spacing: -0.03em;
        margin: 0 10px 10px 0;
    }

    div[class*="btn-chips"] .lb-check,
    div[class*="btn-chips"] .lb-radio,
    span[class*="btn-chips"] {
        padding: 11px 20px 7px;
        min-height: 44px;
    }

    div[class*="btn-chips"] {
        padding: 0;
    }

    [class*="btn-chips"] span {
        vertical-align: middle;
        display: inline-block;
        line-height: 1.4;
        margin-top: 0;
    }

    div[class*="btn-chips"] .lb-check:before,
    div[class*="btn-chips"] .lb-radio:before {
        display: none;
    }

    [class*="btn-chips"].chips-sm {
        min-height: 30px;
    }

    [class*="btn-chips"].chips-sm .lb-radio {
        min-height: 30px;
        width: 100%;
        padding: 5px 0 2px;
        font-size: 14px;
        justify-content: center;
    }

    [class*="btn-chips"].chips-sm span {
        font-size: 14px;
    }

    [class*="btn-chips"] [checked]+.lb-check,
    [class*="btn-chips"] :checked+.lb-check,
    [class*="btn-chips"] [checked]+.lb-radio,
    [class*="btn-chips"] :checked+.lb-radio,
    [class*="btn-chips"].selected {
        color: #fff;
        background-color: var(--carrot-primary-color7, #e2470f);
        border-radius: 22px;
    }

    [class*="btn-chips"] [disabled]+.lb-check,
    [class*="btn-chips"] :disabled+.lb-check,
    [class*="btn-chips"] [disabled]+.lb-radio,
    [class*="btn-chips"] :disabled+.lb-radio,
    [class*="btn-chips"].disabled {
        color: var(--carrot-line-color1, #c4c4c4);
        background-color: #fcfcfc;
        opacity: 1;
        border-radius: 22px;
    }

    .btn-chips-step {
        min-height: 38px;
        border-radius: 4px;
        font-size: 14px;
    }

    a.btn-chips-step {
        padding: 11px 16px 6px;
    }

    .btn-chips-step [checked]+.lb-check,
    .btn-chips-step :checked+.lb-check,
    .btn-chips-step.selected {
        border-radius: 4px;
    }

    .btn-tag .lb-check {
        padding: 4px 10px 3px;
        min-height: 28px;
        font-size: 12px;
        color: #999;
        font-weight: 700;
        border: 1px solid #d9d9d9;
        border-radius: 14px;
    }

    .btn-tag [checked]+.lb-check,
    .btn-tag :checked+.lb-check,
    .btn-tag.selected {
        color: var(--carrot-primary-color7, #e2470f);
        border-color: var(--carrot-primary-color, #f05014);
        border-radius: 14px;
    }

    .btn-tag .lb-check:before {
        display: none;
    }

    .btn-tag .lb-check:after {
        content: "OFF";
        display: inline-block;
        vertical-align: middle;
        border-bottom: 1px solid transparent;
        margin-left: 3px;
    }

    .btn-tag [checked]+.lb-check:after,
    .btn-tag :checked+ :after {
        content: "ON";
        display: inline-block;
        vertical-align: middle;
        border-bottom: 1px solid transparent;
    }

    .btn-del {
        position: relative;
        width: 20px;
        height: 20px;
        border: 1px solid transparent;
        overflow: hidden;
        color: transparent;
        font-size: xx-small;
        box-sizing: content-box;
    }

    .btn-del:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -1px;
        display: block;
        width: 100%;
        height: 2px;
        background: var(--carrot-font-color3, #555);
        border-radius: 1px;
        @include vendorPrefix(transform, rotate(45deg));
    }

    .btn-del:before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -1px;
        display: block;
        width: 2px;
        height: 100%;
        background: var(--carrot-font-color3, #555);
        border-radius: 1px;
        @include vendorPrefix(transform, rotate(45deg));
    }

    .btn-del:before,
    .btn-del:after {
        background: #222;
    }

    .btn-close,
    .ui-modal-header .n2.btn-close {
        width: 20px;
        height: 20px;
        border: 0;
        background: url('/static/images/common/ico_small@2x.png') -102px -27px no-repeat;
        background-size: 300px;
        text-indent: -9999px;
        outline: none;
    }

    .btn-link {
        color: initial;
        text-decoration: underline;
    }

    .page-insuinfo .btn-close {
        background-position: -152px -27px;
    }

    .btn-close.type-b {
        width: 21px;
        height: 21px;
        background-position: -4px -75px;
    }

    .btn-del-circle {
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px;
        box-sizing: content-box;
        vertical-align: bottom;
        margin-left: 5px;
    }

    .btn-del-circle span {
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        width: 14px;
        height: 14px;
        text-indent: -9999px;
        background: #bbb;
        border-radius: 14px;
        @include vendorPrefix(transform, translate(-50%, -50%) rotate(45deg));
    }

    .btn-del-circle span:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 8px;
        height: 1px;
        background: #fff;
        border-radius: 1px;
        @include vendorPrefix(transform, translate(-50%, -50%));
        z-index: 1;
    }

    .btn-del-circle span:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 1px;
        height: 8px;
        background: #fff;
        border-radius: 1px;
        @include vendorPrefix(transform, translate(-50%, -50%));
        z-index: 1;
    }

    .btn-back {
        position: absolute;
        top: 10px;
        left: 6px;
        width: 20px;
        height: 20px;
        overflow: hidden;
        color: transparent;
        font-size: xx-small;
        box-sizing: content-box;
        padding: 10px;
    }

    .btn-back::before {
        content: '';
        position: relative;
        display: block;
        width: 20px;
        height: 20px;
        background: url('/static/images/common/ico_small@2x.png') no-repeat -180px -52px;
        background-size: 300px;
    }

    .sos::before {
        background: url('/static/images/common/ico_small@2x.png') no-repeat -126px -52px;
        background-size: 300px;
    }

    .sub-main .btn-back {
        background-position: -130px -52px;
    }

    .sub-main .base-header-wrap .logo {
        color: #fff;
    }

    .btn-more {
        position: relative;
        width: 40px;
        height: 40px;
        border: 0;
        background-color: #abadb0;
        border-radius: 50%;
        overflow: hidden;
        color: transparent;
        font-size: xx-small;
        box-sizing: content-box;
    }

    .btn-more:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 20%;
        margin-top: -1px;
        display: block;
        width: 60%;
        height: 2px;
        background: #fff;
        border-radius: 1px;
    }

    .btn-more:before {
        content: "";
        position: absolute;
        top: 20%;
        left: 50%;
        margin-left: -1px;
        display: block;
        width: 2px;
        height: 60%;
        background: #fff;
        border-radius: 1px;
    }

    .btn-white-close {
        position: absolute;
        top: 10px;
        right: 6px;
        width: 38px;
        height: 32px;
        z-index: 2;
        padding: 10px;
        background: url('/static/images/common/ico_small@2x.png') no-repeat -140px -18px;
        background-size: 290px;
    }

    .btn-menu {
        position: absolute;
        top: 10px;
        right: 6px;
        width: 38px;
        height: 38px;
        z-index: 2;
        padding: 10px;
    }

    .btn-menu:before,
    .btn-menu:after {
        content: "";
        position: absolute;
        left: 10px;
        display: block;
        width: 18px;
        height: 2px;
        background: #222;
        border-radius: 2px;
    }

    .btn-menu:before {
        top: 11px;
    }

    .btn-menu:after {
        bottom: 11px;
        width: 9px;
    }

    .btn-menu .hide {
        width: 18px !important;
        height: 2px !important;
        background: #222 !important;
        z-index: 0 !important;
        display: block;
        max-height: 2px !important;
        top: 50%;
        left: 10px;
        margin-top: -1px;
        border-radius: 2px;
    }

    .btn-menu-sos {
        @extend .btn-menu;
    }

    .btn-menu-sos:before,
    .btn-menu-sos:after {
        background: white;
    }

    .btn-menu-sos .hide {
        @extend .hide;
        background: white !important;
    }

    .btn-step {
      position: absolute;
      top: 16px;
      right: 16px;
      width: 25px;
      height: 25px;
      z-index: 2;
      background: url("/static/images/common/ico_small@2x.png")
      no-repeat -75px -25px;
      background-size: 300px;
    }

    .btn-home {
        position: absolute;
        top: 16px;
        left: 16px;
        width: 25px;
        height: 25px;
        background: url('/static/images/common/ico_small@2x.png') no-repeat -150px -50px;
        background-size: 300px;
    }

    .btn-back+.btn-home {
        left: 72px;
    }

    .ico-arrow,
    .btn-arrow {
        display: inline-block;
        position: relative;
        top: 0;
        width: 18px;
        height: 18px;
        overflow: hidden;
        color: transparent;
        font-size: xx-small;
        box-sizing: content-box;
        margin-left: 0;
        vertical-align: top;
        background: url('/static/images/common/ico_small@2x.png') no-repeat -73px -3px;
        background-size: 300px;
    }

    .btn-arrow span,
    .btn-arrow3 span {
        @include txt-hide();
    }

    .ico-arrow2,
    .btn-arrow2 {
        display: inline-block;
        position: relative;
        top: 0;
        width: 18px;
        height: 18px;
        overflow: hidden;
        color: transparent;
        font-size: xx-small;
        box-sizing: content-box;
        margin-left: 0;
        vertical-align: top;
        background: url('/static/images/common/ico_small@2x.png') no-repeat -278px -3px;
        background-size: 300px;
    }

    .btn-arrow3,
    .ico-arrow3 {
        display: inline-block;
        position: relative;
        top: 3px;
        margin-left: 5px;
        width: 0px;
        height: 0px;
        border: 5px solid transparent;
        border-top: 7px solid #333;
    }

    .btn-arrow3.reverse,
    .ico-arrow3.reverse {
        top: -2px;
        border-top: 5px solid transparent;
        border-bottom: 7px solid #333;
    }

    .btn-tip {
        position: relative;
        display: inline-block;
        width: 17px;
        height: 17px;
        background: transparent url('/static/images/common/ico_small@2x.png') no-repeat -55px -4px;
        background-size: 300px;
        border-radius: 50%;
        vertical-align: middle;
        margin: 0 0 0 5px;
        border: 1px solid #bbb;
    }

    .btn-tip-page {
        position: relative;
        display: inline-block;
        width: 21px;
        height: 21px;
        background: #bbb url('/static/images/common/ico_small@2x.png') no-repeat -52px -28px;
        background-size: 300px;
        border-radius: 50%;
        vertical-align: middle;
        margin: 0 0 0 5px;
    }

    .field-inlabel-label .btn-tip {
        top: -1px;
    }

    .h2 .btn-tip {
        vertical-align: baseline;
    }

    .tit .btn-tip,
    .h5 .btn-tip {
        margin-top: -3px;
    }

    .txt-s5 .btn-tip {
        margin-top: -5px;
    }

    .btn-arrow {
        top: 1px;
    }

    .lb-check+.btn-tip {
        margin-top: 16px;
    }

    .bul-etc>li .btn-tip {
        margin-top: -3px;
    }

    .btn-toggle .lb-check+.btn-tip {
        margin-top: -6px;
    }

    .btn-txt {
        display: inline-block;
        font-size: 15px;
        color: var(--carrot-secondary-color, #00358e);
        vertical-align: baseline;
        letter-spacing: -0.035em;
        text-decoration: none;
        border-bottom: 1px solid var(--carrot-secondary-color, #00358e);
        line-height: 1;
    }

    .btn-txt span {
        display: inline-block;
        line-height: 1.3;
    }

    a.btn-txt span {
        margin-top: 4px;
    }

    .btn-txt .ico-arrow,
    .btn-txt .btn-arrow {
        margin-left: 2px;
        top: 3px;
        background-position: -130px -28px;
    }

    .btn-txt-arrow {
        position: relative;
        display: inline-block;
        font-size: 15px;
        color: var(--carrot-secondary-color, #00358e);
        vertical-align: baseline;
        letter-spacing: -0.035em;
        text-decoration: none;
        padding-right: 20px;
    }

    .btn-txt-arrow span {
        display: inline-block;
        line-height: 1.5;
    }

    .btn-txt-arrow:before {
      content: "";
      position: absolute;
      top: 50%;
      right: 10px;
      width: 6px;
      height: 6px;
      border-top: 1px solid var(--carrot-secondary-color, #00358e);
      border-right: 1px solid var(--carrot-secondary-color, #00358e);
      -moz-transform: rotate(45deg) translateY(-50%);
      -webkit-transform: rotate(45deg) translateY(-50%);
      transform: rotate(45deg) translateY(-50%);
      vertical-align: middle;
      margin-left: 12px;
      border-left: 0;
    }

    .btn-txt-arrow.type-strong {
        color: var(--carrot-primary-color, #f05014);
    }

    .btn-txt-arrow.type-strong::after {
        background-position: -130px -79px;
    }

    .btn-txt .ico-arrow,
    .btn-txt .btn-arrow {
        margin-left: 2px;
        top: 3px;
        background-position: -130px -28px;
    }

    .btn-tel {
        display: block;
        margin-top: 5px;
        font-size: 15px;
        font-weight: 700;
        color: var(--carrot-font-color, #000);
        text-decoration: none;
    }

    /* 모두동의하기 */
    .lb-check.btn-txt {
        font-size: 14px;
        color: var(--carrot-primary-color7, #e2470f);
        border-bottom: 0;
    }

    .lb-check.btn-txt:before {
        content: '';
        display: inline-block;
        margin-right: 0;
        min-height: 11px;
        background: url('/static/images/common/ico_form@2x.png') -31px -8px no-repeat;
        background-size: 150px;
        border: 0;
        border-radius: 0;
    }

    .lb-check.btn-txt.checked:before,
    input:checked+.lb-check.btn-txt:before {
        background: url('/static/images/common/ico_form@2x.png') -31px -8px no-repeat;
        background-size: 150px;
        border: 0;
    }

    .lb-check.btn-txt.activated:before,
    .lb-check.btn-txt:hover:before,
    .lb-check.btn-txt:focus:before {
        box-shadow: none;
    }

    .all-check {
        margin-top: 30px;
    }

    .all-check .lb-check {
        display: inline-block;
        width: 100%;
        padding: 11px 18px;
        border: 1px solid var(--carrot-font-color4, #777);
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        background: var(--carrot-body-background, #fff);
        color: var(--carrot-font-color3, #555);
        line-height: 1;
        border-radius: 8px;
    }

    .all-check .lb-check:before {
        display: none;
    }

    .btn-check {
        font-size: 14px;
        color: var(--carrot-primary-color7, #e2470f);
        font-weight: 500;
    }

    .btn-check > span {
        margin-left: 20px;
    }

    .btn-check:disabled,
    .btn-check[disabled],
    .btn-check.disabled,
    .btn-check.type-b {
        color: #999;
    }

    .btn-check:before {
      content: "";
      position: absolute;
      width: 8px;
      height: 5px;
      border-top: 2px solid var(--carrot-primary-color, #f05014);
      border-right: 2px solid var(--carrot-primary-color, #f05014);
      -moz-transform: rotate(135deg) translateY(-50%);
      -webkit-transform: rotate(135deg) translateY(-50%);
      -ms-transform: rotate(135deg) translateY(-50%);
      transform: rotate(135deg) translateY(-50%);
      vertical-align: 0;
      border-left: 0;
    }

    .btn-check:disabled:before,
    .btn-check[disabled]:before,
    .btn-check.disabled:before,
    .btn-check.type-b:before {
        background-position: -56px -8px;
        opacity: 0.46;
    }

    .btn-srh {
        display: inline-block;
        width: 38px;
        height: 38px;
        background: url('/static/images/common/ico_small@2x.png') -219px 6px;
        background-size: 300px;
    }

    .btn-srh span {
        @include txt-hide();
    }

    .btn-top {
        position: fixed;
        right: 16px;
        border-radius: 50%;
        width: 41px;
        height: 41px;
        border: 1px solid #666;
        background: #fff;
        bottom: -50px;
        z-index: 9;
        opacity: 0;
        transition: bottom 0.4s ease-out, opacity 0.5s ease-in;
        font-size: 13px;
        font-weight: 700;
        color: var(--carrot-font-color, #000);
        letter-spacing: -0.050em;
    }

    .div-pnl {
        display: none;
    }

    /* 다운로드 버튼 */
    .btn-pure>.ico-download,
    .btn-base>.ico-download {
        width: auto;
        height: auto;
        padding-top: 4px;
    }
    .btn-pure>.ico-download::before,
    .btn-base>.ico-download::before {
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px;
        vertical-align: middle;
        background-position: -206px -4px;
    }

    .btn-base>.ico-email {
        display: inline-block;
        padding-top: 4px;
    }

    .btn-base>.ico-email::before {
        content: '';
        position: relative;
        display: inline-block;
        width: 25px;
        height: 21px;
        vertical-align: middle;
        margin-right: 6px;
        background: url('/static/images/common/ico_small@2x.png') no-repeat -100px -103px;
        background-size: 300px;
    }

    .type-part3 .btn-base>.ico-email {
        letter-spacing: -1.5px;
    }

    .type-part3 .btn-base>.ico-email::before {
        margin-right: 3px;
    }

    .btn-base:hover>.ico-email::before,
    .btn-base.checked>.ico-email::before {
        background-position: -125px -103px;
    }

    /* 세로로 균등 분할된 버튼 */
    .fx-wrap .btn-all {
        position: relative;
        display: block;
        height: 190px;
        border-top: 1px var(--carrot-line-color1, #c4c4c4) solid;
        padding: 0 32px;
        font-size: 13px;
        color: var(--carrot-font-color4, #777);
        text-align: initial;
    }

    .fx-wrap .btn-all:first-child {
        border-top: 0;
    }

    .fx-wrap .btn-all .h3 {
        font-size: 22px !important;
        font-weight: 700;
        margin-top: 0;
        letter-spacing: -1.2px;
    }

    .fx-wrap .btn-all.selected .h3 {
        font-size: 24px !important;
        color: var(--carrot-primary-color, #f05014);
    }

    .fx-wrap .btn-all.selected .h3::after {
        content: '';
        position: relative;
        display: inline-block;
        width: 25px;
        height: 25px;
        background: url('/static/images/common/ico_form@2x.png') -62.5px -12.5px no-repeat;
        vertical-align: top;
        background-size: 300px;
        margin-left: 8px;
    }

    .fx-wrap .btn-all.selected p {
        color: var(--carrot-font-color, #000);
    }

    .fx-wrap .btn-all-cont {
        position: relative;
        display: block;
        height: 100%;
        font-size: 14px;
        padding-top: 40px;
    }

    .fx-wrap.off {
        display: none;
    }

    .fx-wrap.type-search .input-name {
        width: 100px;
        margin-right: 20px;
    }

    .fx-wrap.type-search .input-mobile {
        width: calc(100% - 120px);
    }

    /* table */
    .tbl-base th,
    .tbl-base td {
        padding: 5px 10px;
        font-size: 13px;
        color: var(--carrot-font-color3, #555);
        line-height: 24px;

        &.new {
            padding: 12px;
        }
    }

    .h-scroll {
        position: relative;
        width: 100%;
        overflow: scroll;
    }

    .tbl-noline {
        width: 100%;
        margin-top: 17px;
    }

    .tbl-noline table {
        table-layout: fixed;
        width: 100%;
    }

    .tbl-noline th {
        font-weight: 400;
        font-size: 13px;
        color: var(--carrot-font-color3, #555);
        vertical-align: top;
        text-align: left;
        padding: 12px 0;
        word-break: keep-all;
    }

    .tbl-noline td {
        font-weight: 400;
        font-size: 15px;
        color: var(--carrot-font-color, #000);
        vertical-align: top;
        text-align: right;
        padding: 12px 0;
        word-break: break-all;
    }

    .tbl-noline .txt-sub {
        font-size: 14px;
        color: var(--carrot-font-color4, #777);
    }

    .tbl-noline .break-word {
        word-break: break-word;
    }

    .tbl-noline .btn-tip {
        margin-top: -2px;
    }

    .tbl-base.type-accident>tfoot>tr>th,
    .tbl-base.type-accident>tfoot>tr>td {
        font-size: 13px;
        color: var(--carrot-font-color3, #555);
        font-weight: 700;
        line-height: 24px;
        padding: 5px 10px;
        border-top: 1px var(--carrot-line-color1, #c4c4c4) solid;
    }

    .tbl-base.type-accident>tfoot>tr>th {
        text-align: left;
        background: #f4f4f4;
    }

    /* box */
    .box-base {
        position: relative;
        width: 100%;
        margin: 0;
        padding: 36px 25px;
        background: var(--carrot-content-background, #f8f8f8);
        border-radius: 8px;
        margin-top: 10px;
        word-break: break-all;
    }

    .box-base:first-child {
        margin-top: 0;
    }

    .box-base .box-label {
        position: absolute;
        display: -webkit-box;
        top: 0;
        right: 0;
        width: 80px;
        height: 75px;
        background: url('/static/images/content/box_label_bg.png') no-repeat;
        background-size: 100%;
        -webkit-box-align: center;
        -webkit-box-pack: center;
        z-index: 1;
    }

    .box-base .box-label span {
        position: relative;
        display: block;
        font-size: 12px;
        color: #fff;
        text-align: center;
        transform: rotateZ(41deg) translate(2px, -21px);
    }

    .box-base .box-base {
        position: relative;
        width: 100%;
        margin: 0;
        padding: 28px 20px;
        background: var(--carrot-body-background, #fff);
        border-radius: 0;
    }

    .box-list-wrap .box-base {
        padding: 25px;
    }

    .box-base tfoot .tit {
        display: block;
        padding-top: 5px;
        font-size: 15px;
        color: var(--carrot-font-color3, #555);
        font-weight: 700;
    }

    .box-base .tit {
        font-size: 16px;
        line-height: 16px;
        color: var(--carrot-font-color, #000);
        font-weight: 700;
    }

    .box-base .tit b {
        font-weight: 700;
    }

    .box-base .tit+.fx-wrap {
        margin-top: 15px;
    }

    .box-base .txt {
        margin-top: 10px;
        font-size: 15px;
        line-height: 22px;
        color: var(--carrot-font-color3, #555);
        word-break: keep-all;
    }

    .box-base hr {
        border-color: var(--carrot-line-color1, #c4c4c4);
        border-style: solid;
    }

    .box-base .item-label {
        font-size: 13px;
        color: var(--carrot-font-color3, #555);
    }

    .box-base .btn-wrap {
        margin: 0;
    }

    .box-base .btn-wrap.type-part3 .btn-base-m {
        margin-left: 9px;
        width: calc(33.3% - 6px);
    }

    .box-base .btn-wrap.type-part3 .btn-base-m:first-child {
        margin-left: 0;
    }

    .box-base .btn-tit-arrow {
        position: relative;
        display: block;
        width: 100%;
        text-align: left;
        padding-right: 25px;
        font-size: 18px;
        font-weight: 700;
        color: var(--carrot-font-color, #000);
        letter-spacing: -0.075em;
        line-height: 1.4;
    }

    .box-base .btn-tit-arrow:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        width: 18px;
        height: 18px;
        background: url('/static/images/common/ico_small@2x.png') no-repeat -278px -3px;
        background-size: 300px;
        transform: translateY(-50%);
    }

    .box-link,
    .box-base .box-link {
        display: block;
        color: var(--carrot-font-color, #000);
        text-decoration: none;
    }

    .box-base.type-line {
        background: var(--carrot-body-background, #fff);
        border: 1px solid rgba(68, 68, 68, 0.99);
        overflow: hidden;
    }
    .box-base.type-line.selected {
      border: 1px solid var(--carrot-primary-color, #f05014);
    }
    .box-base.type-line.disabled {
      border: 1px solid rgba(68, 68, 68, 0.1);
    }

    .box-inner+.box-inner {
        padding-top: 25px;
        margin-top: 25px;
        border-top: 1px solid var(--carrot-line-color1, #c4c4c4);
    }

    .box-base.active .active {
        color: var(--carrot-primary-color7, #e2470f);
    }

    .box-base .box-head {
        margin-bottom: 20px;
    }

    .box-base .box-inner pre {
        font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', 'Apple SD Gothic Neo', 'Malgun Gothic', '맑은 고딕', sans-serif;
        white-space: pre-line;
        margin: 0;
    }

    .box-head {
        position: relative;
        overflow: hidden;
    }

    .box-head:after {
        content: '';
        display: block;
        clear: both;
    }

    .box-head .tit {
        float: left;
        margin-bottom: 10px;
    }

    .box-head .btn-txt {
        float: right;
        margin-left: 16px;
        margin-bottom: 14px;
        font-size: 14px;
        word-break: keep-all;
        line-height: 1.5;
    }

    .box-head .btn-wrap {
        float: right;
        width: auto;
    }

    .box-head .btn-wrap .btn-txt {
        float: initial;
    }

    .box-head .price {
        float: right;
        margin-bottom: 10px;
        line-height: 1.2;
    }

    .box-head .price strong {
        font-size: 18px;
        font-weight: 700;
    }

    .box-head .head-pos-r {
        position: absolute;
        right: 0;
        top: 26px;
    }

    .box-head.type-b {
        padding: 0 0 26px;
        margin-bottom: 26px;
        border-bottom: 1px solid var(--carrot-border-color5, #9d9d9d);
    }

    .box-head.type-b:after {
        content: '';
        display: block;
        clear: both;
    }

    .box-head.type-b .tit {
        float: none;
        margin-bottom: 0;
    }

    .box-head.type-b .txt-s7 {
        margin-top: 3px;
    }

    .box-head.type-b .txt-s8 {
        margin-top: 5px;
        color: var(--carrot-font-color3, #555);
    }

    .box-head.type-b .btn-txt-arrow {
        margin-top: 13px;
    }

    .box-head.type-b.no-line {
        border-bottom: 0;
        margin-bottom: 30px;
        padding-bottom: 0;
    }

    .box-head.type-b .txt-tag.type-b {
        margin: 5px 0 0;
        padding: 0 13px;
        background: var(--carrot-primary-color7, #e2470f);
        color: #fff;
        line-height: 16px;
    }

    .box-base .tbl-noline {
        margin-top: 0;
    }

    .box-base .tbl-noline th,
    .box-base .tbl-noline td {
        padding: 10px 0;
        word-break: break-word;
    }

    .box-base .tbl-noline tfoot th,
    .box-base .tbl-noline tfoot td {
        padding: 28px 0 0;
        vertical-align: middle;
    }

    .box-base .tbl-noline tfoot tr.v-top th,
    .box-base .tbl-noline tfoot tr.v-top td {
        vertical-align: top;
    }

    .box-base .tbl-noline tbody tr:first-child th,
    .box-base .tbl-noline tbody tr:first-child td {
        padding-top: 0;
    }

    .box-base .tbl-noline tr:last-child th,
    .box-base .tbl-noline tr:last-child td,
    .box-base .tbl-noline .tbl-body:last-child tr:last-child th,
    .box-base .tbl-noline .tbl-body:last-child tr:last-child td {
        padding-bottom: 0;
    }

    .box-base .tbl-noline .tbl-body {
        border-top: 1px solid var(--carrot-line-color1, #c4c4c4);
    }

    .box-base .tbl-noline .tbl-body tr:first-child th,
    .box-base .tbl-noline .tbl-body tr:first-child td {
        padding-top: 27px;
    }

    .box-base .tbl-noline .tbl-body tr:last-child th,
    .box-base .tbl-noline .tbl-body tr:last-child td {
        padding-bottom: 27px;
    }

    .box-base .tbl-noline .tbl-body-first tr:last-child th,
    .box-base .tbl-noline .tbl-body-first tr:last-child td {
        padding-bottom: 25px;
    }

    .box-base .tbl-noline .tbl-body .txt-s6 {
        display: block;
        margin-bottom: 5px;
        font-weight: 700;
        line-height: 1;
    }

    .box-base .tbl-noline .tbl-body .txt-sub,
    .box-base .tbl-noline .tbl-body .tbl-bul {
        font-size: 13px;
    }

    .box-base .tbl-noline .tbl-body .tbl-bul li {
        position: relative;
        padding-left: 12px;
        line-height: 24px;
    }

    .box-base .tbl-noline .tbl-body .tbl-bul li:before {
        content: "-";
        position: absolute;
        left: 0;
        top: -1px;
    }

    .box-base .tbl-noline .tbl-body .tbl-bul .fl-item-l {
        color: var(--carrot-font-color3, #555);
    }

    .box-base .tbl-noline .tbl-item {
        margin-top: 20px;
    }

    .box-base .tbl-noline .tbl-item:first-child {
        margin-top: 0;
    }

    .box-base.type-cont {
        padding: 20px 15px 20px 25px;
    }

    .box-base.type-cont button {
        font-size: 15px;
        color: var(--carrot-font-color, #000);
    }

    .box-base.type-cont button.btn-txt-arrow {
        color: #00358e;
    }

    .box-base.type-cont .tbl-noline tr:last-child th,
    .box-base.type-cont .tbl-noline tr:last-child td,
    .box-base.type-cont .tbl-noline .tbl-body:last-child tr:last-child th,
    .box-base.type-cont .tbl-noline .tbl-body:last-child tr:last-child td {
        padding-bottom: 12px;
    }

    .box-base.type-cont .tbl-noline.brd-t {
        border-top: 1px solid var(--carrot-line-color1, #c4c4c4);
        padding-top: 20px;
    }

    .slider-item>.box-base.type-cont {
        padding: 26px 25px 36px;
    }

    .box-base .bul-hyphen,
    .box-base .bul-dot,
    .box-base .bul-care,
    .box-base .bul-star,
    .box-base .bul-etc {
        margin: 10px 0 0;
    }
    .bul-hyphen-li:after {
       content: '';
       clear: both;
       display: block;
    }
    .bul-hyphen-li .bul-hyphen-li__right {
        float: right;
        letter-spacing: -1px;
    }
    .box-base .bul-hyphen li .btn-tip {
        margin-top: -3px;
    }

    .box-base.type-bottom {
        padding-bottom: 30px;
    }

    .box-base.type-bottom2 {
        padding-bottom: 20px;
    }

    .box-base.type-pd {
        padding: 20px 25px 7px;
    }

    .box-base.type-pd .box-head {
        margin-bottom: 0;
    }

    .box-base.type-result {
        padding: 10px 25px 16px;
    }
    #PermileResult {
        display:none; //퍼마일자동차 스텝3번 하단 보험료결과
    }
    .box-base.type-result .fl-wrap {
        display: table;
        width: 100%;
    }

    .box-base.type-result .tit {
        display: table-cell;
        float: none;
        width: 40%;
        vertical-align: top;
        word-break: keep-all;
        vertical-align: middle;
    }

    .box-base.type-result .txt-price {
        text-align: right;
        vertical-align: top;
        word-break: keep-all;
    }

    .box-base.type-result .txt-price b {
        line-height: normal;
        margin: 0 5px;
    }

    .box-base.type-result .txt-price .txt-small {
        font-size: 14px;
        color: var(--carrot-font-color4, #777);
    }

    .box-base .doc-item-pic {
        background: #fff;
    }

    .box-base.type-general {
        display: none;
    }

    .ui-slide.load .ui-slide-item .box-base:after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 80%);
        transition: background-image 0.2s ease-in;
    }

    .ui-slide.load .ui-slide-item.on .box-base:after {
        display: none;
    }

    /* 현재가격 box */
    .box-price {
        position: relative;
        width: 100%;
        padding: 15px 20px;
        background: #fff;
    }

    .box-price .txt-price {
        font-size: 14px;
        color: var(--carrot-font-color3, #555);
        line-height: 18px;
    }

    .box-price .txt-price b {
        font-size: 18px;
        color: var(--carrot-font-color, #000) !important;
        line-height: normal;
    }

    .fx-wrap+.box-price {
        margin-top: -3px;
    }

    .ui-acco.type-b .ui-acco.type-b .box-base {
        padding: 25px 20px 20px;
    }

    .ui-acco.type-b .ui-acco.type-b .box-price {
        padding: 10px 0;
    }

    .box-base.type-cont .tbl-noline {
        margin-top: 0;
    }

    .box-base.type-cont .tbl-noline th,
    .box-base.type-cont .tbl-noline td {
        padding: 12px 0;
        vertical-align: middle;
    }

    .box-base.type-cont .tbl-noline tr:first-child th,
    .box-base.type-cont .tbl-noline tr:first-child td {
        padding-top: 12px;
    }

    .box-base.type-cont .tbl-noline td {
        position: relative;
        text-align: right;
    }

    .box-base.type-cont .tbl-noline td span {
        display: inline-block;
        text-align: right;
        padding-right: 25px;
        line-height: 1.3;
    }

    .box-base.type-cont .tbl-noline td button>span {
        word-break: break-word;
    }

    .box-base.type-cont .tbl-noline .btn-arrow2 {
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -9px;
    }

    .box-base.type-cont .tbl-noline .item-tit {
        font-size: 15px;
        letter-spacing: -0.075em;
        color: var(--carrot-font-color3, #555);
        font-weight: 700;
        margin: 0;
        word-break: break-word;
    }

    .box-base.type-cont .tbl-noline .btn-del {
        margin-left: 10px;
        top: -1px;
    }

    .box-base.type-cont .tbl-noline .item-price {
        font-size: 14px;
    }

    .box-base.type-car {
        padding: 18px 25px 20px;
    }

    .box-base.type-car .field-inlabel,
    .box-base.type-car .lb-radio {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .box-base.type-car .lb-radio {
        z-index: 1;
    }

    .box-base.type-car .lb-radio::before {
        position: absolute;
        top: 30px;
        left: 25px;
    }

    .box-base.type-car .type-car-cont {
        padding-bottom: 30px;
    }

    .box-base.type-car .type-car-cont .ico-car {
        display: inline-block;
        font-size: 16px;
        padding-left: 30px;
        color: var(--carrot-font-color, #000);
    }

    .box-base.type-car .type-car-cont .ico-car::before {
        content: '';
        position: relative;
        display: inline-block;
        width: 48px;
        height: 48px;
        background: url('/static/images/content/ico_car@2x.png') no-repeat;
        background-size: 96px;
        vertical-align: middle;
        margin-right: 6px;
        margin-top: -2px;
    }

    .box-base.type-car .type-car-cont .multi::before {
        background-position: -48px 0;
    }

    .box-base.type-car .type-car-cont+.type-car-cont {
        border-top: 1px var(--carrot-line-color1, #c4c4c4) solid;
        margin-top: 20px;
        padding-top: 15px;
    }

    .box-base.type-car .graph-bar {
        position: relative;
        display: block;
        height: 10px;
        border-radius: 10px;
        margin: 10px 32px 0;
    }

    .box-base.type-car .graph-bar::after {
        content: '';
        display: block;
        clear: both;
    }

    .box-base.type-car .graph-progress {
        float: right;
        position: relative;
        display: block;
        width: 100%;
        height: 10px;
        border-radius: 10px;
        background: var(--carrot-line-color1, #c4c4c4);
    }

    .box-base.type-car .graph-progress>span {
        position: relative;
        display: block;
        width: 20px;
        height: 20px;
        margin-top: -5px;
    }

    .box-base.type-car .graph-progress>span::before {
        content: '';
        position: absolute;
        display: block;
        width: 20px;
        height: 20px;
        background: #959595;
        border-radius: 20px;
    }

    .box-base.type-car .graph-progress>span.graph-start {
        float: left;
        margin-left: -10px;
    }

    .box-base.type-car .graph-progress>span.graph-end {
        float: right;
        margin-right: -10px;
    }

    .box-base.type-car .graph-progress>span.graph-end::before {
        right: 0;
    }

    .box-base.type-car .graph-progress>span>em {
        position: absolute;
        display: block;
        top: 25px;
        left: 50%;
        width: 80px;
        color: var(--carrot-font-color4, #777);
        text-align: center;
        transform: translateX(-50%);
    }

    .box-base .type-car-cont .additional {
        position: relative;
        font-size: 16px;
        text-align: center;
        margin-top: 10px;
    }

    .box-base .type-car-cont .additional+.graph-bar {
        margin-top: 10px;
    }

    .box-base .type-car-cont .additional .badge {
        display: inline-block;
        font-size: 12px;
        color: #fff;
        line-height: 17px;
        padding: 0 10px;
        background: var(--carrot-font-color3, #555);
        border-radius: 20px;
        margin-right: 8px;
    }

    .box-base .type-car-cont .additional em {
        color: var(--carrot-primary-color10, #f26421);
    }

    /* list */
    .list-dlsel .btn-wrap {
        margin-top: 43px;
    }

    .box-base.type-cont.list-dlsel .box-head {
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .list-dlsel .box-base-tit,
    .list-dlsel .box-head {
        position: relative;
        width: 100%;
        margin-bottom: 20px;
        margin-top: 4px;
    }

    .list-dlsel .box-base-tit .price,
    .list-dlsel .box-head .price {
        float: right;
        font-size: 16px;
        line-height: initial;
        color: var(--carrot-font-color, #000);
    }

    .list-dlsel .box-base-tit .price b,
    .list-dlsel .box-head .price b {
        font-weight: 700;
        font-size: 23px;
        color: var(--carrot-primary-color, #f05014) !important;
    }

    .list-dlsel .box-base-tit .tit,
    .list-dlsel .box-head .tit {
        display: inline-block;
        font-size: 15px;
        color: var(--carrot-font-color3, #555);
        font-weight: 700;
        line-height: 1.2;
    }

    .list-dlsel .box-head .tit {
        font-size: 16px;
        color: var(--carrot-font-color, #000);
        padding-top: 12px;
    }

    .box-base .list-dlsel-wrap .btn-arrow:after,
    .box-base .list-dlsel-wrap .btn-arrow:before {
        background: #000;
    }

    .list-dlsel .btn-arrow {
        display: block;
        position: absolute;
        right: 0;
    }

    .list-dlsel .btn-arrow2 {
        display: block;
        position: absolute;
        right: 0;
    }

    .list-dlsel-wrap.brd-t {
        border-top: 1px solid var(--carrot-line-color1, #c4c4c4);
        padding-top: 30px;
    }

    .list-dlsel-wrap {
        width: 100%;
    }

    .list-dlsel-wrap:after {
        content: "";
        display: block;
        clear: both;
    }

    .list-dlsel .list-dlsel-item:first-child {
        margin-top: 0;
    }

    .list-dlsel .list-dlsel-item {
        display: block;
        float: left;
        width: 100%;
        margin-top: 28px;
    }

    .list-dlsel .list-dlsel-item .lb-check {
        padding-top: 0;
    }

    /*.list-dlsel-dl { position:relative; padding-right:27px; display:inline-block; font-size:15px; font-weight:700; line-height:1.2; color:var(--carrot-font-color, #000); word-break:break-all; text-align:left;}*/
    .list-dlsel .list-dlsel-item dl {
        position: relative;
        width: 100%;
        padding-right: 60px;
    }

    .list-dlsel .list-dlsel-item.no-toggle dl {
        padding-right: 0;
    }

    .list-dlsel .list-dlsel-item dt {
        width: 100%;
        display: inline-block;
        position: relative;
        overflow: hidden;
    }

    .list-dlsel .list-dlsel-item dt .tit {
        display: block;
        font-size: 15px;
        font-weight: 700;
        line-height: 1.2;
        color: var(--carrot-font-color3, #555);
        word-break: break-all;
        text-align: left;
        margin-right: 27px;
    }

    .list-dlsel .list-dlsel-item .list-dlsel-bt {
        position: relative;
        width: 100%;
        padding-right: 20px;
        text-align: left;
    }

    .list-dlsel .list-dlsel-item .list-dlsel-bt .tit {
        font-size: 15px;
        margin-top: 0;
    }

    .list-dlsel .list-dlsel-item .list-dlsel-bt span {
        display: block;
        margin-top: 5px;
        font-size: 14px;
        color: var(--carrot-font-color3, #555);
    }

    .list-dlsel .list-dlsel-item .list-dlsel-bt .ico-arrow2 {
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -10px;
    }

    .list-dlsel .list-dlsel-item dd {
        width: 100%;
        display: block;
        font-size: 14px;
        /* margin-top:5px; */
        ;
    }

    .list-dlsel .list-dlsel-item .list-dlsel-toggle {
        position: absolute;
        width: auto;
        top: 50%;
        right: 0;
        margin-top: -15px;
    }

    .list-dlsel .list-dlsel-item fieldset .lb-radio,
    .list-dlsel .list-dlsel-item fieldset .lb-check {
        margin: 0 20px 5px 0;
        padding-top: 12px;
    }

    .list-dlsel .list-dlsel-item .btn-txt {
        display: block;
    }

    .list-dlsel .ui-slide-item {
        padding: 35px 25px;
    }

    .ui-modal-cont .list-dlsel {
        padding: 0 0 20px;
    }

    .box-base .list-dlsel .list-dlsel-item {
        margin-top: 15px;
    }

    /* System Modal */
    .type-system.box-dialog {
      border-radius: 8px;
      .box-content{
        border-radius: 8px;
      }
      .box-footer{
        padding: 13px 23px 28px;
        border-radius: 8px;
      }
      .ui-modal-cont{
        padding: 0;
        .wrap-inner{
          padding: 40px 25px 20px;
        }
        .box-system-inner{
          padding: 40px 25px 20px;
          .btn-close{
            display: none;
          }
        }
      }
    }

    .radio-list-wrap {
        position: relative;
        width: 100%;
        padding: 14px 32px 40px;
        background: var(--carrot-body-background, #fff);
    }

    .radio-list-wrap .radio-list-item {
        background: var(--carrot-content-background, #f8f8f8);
        margin-top: 10px;
    }

    .radio-list-wrap .lb-radio {
        display: block;
        width: 100%;
        padding: 28px 25px 25px 60px;
    }

    .radio-list-wrap .lb-radio:before {
        position: absolute;
        top: 30px;
        left: 25px;
    }

    .radio-list-wrap .lb-radio .item {
        display: block;
        width: 100%;
    }

    .radio-list-wrap .lb-radio .item:after {
        content: '';
        display: block;
        clear: both;
    }

    .radio-list-wrap .lb-radio .item b {
        float: left;
        max-width: calc(100% - 70px);
        font-weight: 700;
        color: var(--carrot-font-color3, #555);
    }

    .radio-list-wrap .lb-radio .item>span {
        float: right;
        text-align: right;
        color: var(--carrot-font-color, #000);
    }

    .radio-list-wrap .lb-radio .item>span>span {
        vertical-align: initial;
    }

    .radio-list-wrap .lb-radio .item em {
        font-size: 13px;
    }

    .radio-list-wrap .lb-radio>strong {
        display: block;
        margin-top: 14px;
        padding-top: 13px;
        border-top: 1px solid var(--carrot-line-color1, #c4c4c4);
    }

    .radio-list-wrap .lb-radio>strong .item,
    .radio-list-wrap .lb-radio>strong .item b,
    .radio-list-wrap .lb-radio>strong .item span,
    .radio-list-wrap .lb-radio>strong .item em {
        line-height: 18px;
        vertical-align: top;
    }

    .radio-list-wrap .lb-radio>strong .item {
        margin-top: 10px;
    }

    .check-list-wrap {
        position: relative;
        width: 100%;
        padding: 14px 32px 40px;
        background: var(--carrot-body-background, #fff);
    }

    .check-list-wrap .check-list-item {
        background: var(--carrot-content-background, #f8f8f8);
        margin-top: 10px;
    }

    .check-list-item>.lb-check {
        display: block;
        width: 100%;
        padding: 28px 25px 25px 60px;
    }

    .check-list-item>.lb-check:before {
        position: absolute;
        top: 28px;
        left: 25px;
    }

    .check-list-item>.lb-check .item {
        display: block;
        width: 100%;
    }

    .check-list-item>.lb-check .item:after {
        content: '';
        display: block;
        clear: both;
    }

    .check-list-item>.lb-check .item b {
        float: left;
        max-width: calc(100% - 90px);
        font-weight: 700;
        color: var(--carrot-font-color3, #555);
        word-break: keep-all;
    }

    .check-list-item>.lb-check .item span {
        float: right;
        text-align: right;
        color: var(--carrot-font-color, #000);
    }

    .check-list-item>.lb-check .item em {
        font-size: 13px;
    }

    .check-list-item>.lb-check>strong {
        display: block;
        margin-top: 14px;
        padding-top: 13px;
        border-top: 1px solid var(--carrot-line-color1, #c4c4c4);
    }

    .check-list-item>.lb-check>strong .item,
    .check-list-item>.lb-check>strong .item b,
    .check-list-item>.lb-check>strong .item span,
    .check-list-item>.lb-check>strong .item em {
        line-height: 18px;
        vertical-align: top;
    }

    .check-list-item>.lb-check>strong .item {
        margin-top: 10px;
    }

    .item-detail {
        display: none;
        padding: 0 25px 20px;
        margin-top: -12px;
    }

    .item-detail .lb-check {
        width: 100%;
    }

    .item-detail .lb-check::after {
        content: '';
        display: block;
        clear: both;
    }

    .item-detail .lb-check>.item {
        width: 100%;
    }

    .item-detail .lb-check>.item>b {
        float: left;
        max-width: calc(100% - 90px);
    }

    .item-detail .item>span {
        float: right;
        text-align: right;
    }

    .check-list-item.selected .item-detail,
    .radio-list-item.selected .item-detail {
        display: block;
    }

    .list-base.type-border {
        border-top: 1px var(--carrot-line-color2, #d9d9d9) solid;
    }

    .list-base.type-border .list-base-item {
        border-bottom: 1px var(--carrot-border-color7, #d9d9d9) solid;
        padding: 25px 0 27px;
    }

    .list-base.type-border .list-base-item.nobrd {
        border-bottom: 0;
    }

    .list-base.type-border .list-base-head {
        position: relative;
        padding-right: 80px;
    }

    .list-base.type-border .list-base-head.width-full {
      padding-right: 0;
    }

    .list-base.type-border .list-base-head .tit {
        display: inline;
        line-height: 24px;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -1px;
    }

    .list-base.type-border .list-base-head .btn-tip {
        margin-top: -5px;
    }

    .list-base.type-border .list-base-head .btn-wrap {
        position: absolute;
        top: -2px;
        right: 0;
        width: 80px;
        margin: 0;
        text-align: right;
    }

    .list-base.type-border .list-base-head .btn-base {
        min-height: 30px;
    }

    .list-base.type-border .txt-check {
        padding: 10px 0 0;
    }

    .list-base.type-border .list-base-cont {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .list-base.type-border .list-base-cont .ico-special {
        position: relative;
        width: 33.3%;
        text-align: center;
        font-size: 14px;
        color: var(--carrot-font-color, #000);
        padding-top: 60px;
    }

    .list-base.type-border .list-base-cont .ico-special::before {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 50%;
        width: 50px;
        height: 50px;
        background-image: url('/static/images/content/ico_special@2x.png');
        background-size: 150px 200px;
        background-repeat: no-repeat;
        margin-left: -25px;
    }

    .list-base.type-border .list-base-cont .ico-special.ico1::before {
        background-position: 0 0;
    }

    .list-base.type-border .list-base-cont .ico-special.ico2::before {
        background-position: -50px 0;
    }

    .list-base.type-border .list-base-cont .ico-special.ico3::before {
        background-position: -100px 0;
    }

    .list-base.type-border .list-base-cont .ico-special.ico4::before {
        background-position: 0 -50px;
    }

    .list-base.type-border .list-base-cont .ico-special.ico5::before {
        background-position: -50px -50px;
    }

    .list-base.type-border .list-base-cont .ico-special.ico6::before {
        background-position: -100px -50px;
    }

    .list-base.type-border .list-base-cont .ico-special.ico1.on::before {
        background-position: 0 -100px;
    }

    .list-base.type-border .list-base-cont .ico-special.ico2.on::before {
        background-position: -50px -100px;
    }

    .list-base.type-border .list-base-cont .ico-special.ico3.on::before {
        background-position: -100px -100px;
    }

    .list-base.type-border .list-base-cont .ico-special.ico4.on::before {
        background-position: 0 -150px;
    }

    .list-base.type-border .list-base-cont .ico-special.ico5.on::before {
        background-position: -50px -150px;
    }

    .list-base.type-border .list-base-cont .ico-special.ico6.on::before {
        background-position: -100px -150px;
    }

    .list-base.type-border .list-base-cont .ico-special.on:after {
        content: '';
        position: absolute;
        top: 8px;
        left: 8%;
        display: block;
        width: 13px;
        height: 10px;
        background: url('/static/images/common/ico_form@2x.png') -6px -33px no-repeat;
        background-size: 150px;
    }

    .list-base.type-border .list-base-cont .ico-special .sales {
        color: #6e6e6e;
        margin-top: 5px;
    }

    .list-base.type-border .list-base-txt {
        font-size: 14px;
        letter-spacing: -1px;
        margin-top: 10px;
    }

    .type-goodsinfo {
        padding: 30px 30px 140px !important;
        min-height: calc(100vh - 200px);
    }

    .type-goodsinfo .intro-title {
        position: relative;
        min-height: 61px;
        font-size: 18px;
        font-weight: 700;
        color: var(--carrot-font-color, #000);
        padding-left: 35px;
        padding-bottom: 10px;
        margin-top: 60px;
        border-bottom: 1px var(--carrot-line-color1, #c4c4c4) solid;
    }

    .type-goodsinfo .intro-title .num {
        position: absolute;
        display: block;
        top: -3px;
        left: 0;
        width: 30px;
        font-size: 50px;
        font-weight: 100;
        color: var(--carrot-primary-color, #f05014);
        line-height: 1.2;
    }

    .type-goodsinfo .txt-small {
        font-size: 12px;
        color: #999;
    }

    .type-goodsinfo [class*="btn-base"] {
        border-radius: 0;
    }

    .type-goodsinfo .btn-wrap [class*="btn-base"] {
        width: calc(50% - 5px) !important;
    }

    .type-goodsinfo .btn-wrap [class*="btn-base"]+[class*="btn-base"] {
        margin-left: 9px;
    }

    /* file/gallery list */
    .doc-wrap {
        position: relative;
        margin-top: 20px;
    }

    .doc-wrap .doc-item {
        position: relative;
        display: block;
        width: 100%;
        margin-bottom: 20px;
    }

    .doc-wrap .doc-item>span {
        font-size: 16px;
    }

    .doc-wrap-pic {
        position: relative;
        display: block;
    }

    .doc-item-pic {
        position: relative;
        display: inline-block;
        width: calc(50% - 9.5px);
        height: 100px;
        margin-bottom: 10px;
        /*background:var(--carrot-line-color2, #d9d9d9);*/
        overflow: hidden;
    }

    .doc-item-pic:nth-child(2n) {
        margin-left: 19px;
    }

    .doc-wrap-pic.type-full .doc-item-pic {
        display: block;
        width: 100%;
        height: auto;
        overflow: unset;
    }

    .doc-item-pic>.btn-del-circle {
        position: absolute;
        display: block;
        top: 5px;
        right: 5px;
        margin: 0;
        z-index: 1;
    }

    .doc-item-pic img {
        width: 100%;
    }

    /* layout */
    .base-header {
        position: fixed;
        width: 100%;
        z-index: 11;
        height: 60px;
        opacity: 1;
        background: var(--carrot-body-background, #fff);
        color: var(--carrot-font-color, #000);
        top: 0;
        left: 0;
    }

    .sos {
        background: #e91515 !important;
        color: white !important;
    }

    .base-header .logo {
        display: block;
        width: 100%;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        text-decoration: none;
        padding: 15px 50px 0;
        line-height: 30px;
    }

    .base-header .logo a {
        color: var(--carrot-font-color, #000);
        text-decoration: none;
    }

    .base-header-wrap {
        position: relative;
    }

    .base-body {
        position: relative;
        width: 100%;
        z-index: auto;
        min-height: 100%;
    }

    .base-footer {
        position: relative;
        width: 100%;
        padding: 28px 20px 160px;
        font-size: 11px;
        color: #4d4d4d;
        letter-spacing: 0;
        background: #efefef;
    }

    .base-footer::after {
        content: '';
        display: block;
        clear: both;
    }

    .base-footer .footer-logo {
        float: left;
        width: 90px;
        font-size: 11px;
        font-weight: 700;
    }

    .base-footer .footer-cont {
        float: right;
        width: calc(100% - 90px);
    }

    .base-footer .address {
        margin-top: 0;
    }

    .base-footer .address>b {
        font-weight: 700;
    }

    .base-footer .copyright {
        font-size: 8px;
        color: #999;
        margin-top: 10px;
    }

    .base-footer .btn-wrap {
        margin: 0;
        margin-top: 20px;
    }

    .base-footer .footer-link {
        position: relative;
        display: block;
        font-size: 11px;
        margin-bottom: 5px;
        border-bottom: 1px #4d4d4d solid;
    }

    .base-main {
        position: relative;
        width: 100%;
    }

    .base-main-fixed {
      position: fixed;
      width: 100vw;
    }

    .wrap-content-fixed {
      position: fixed;
      width: 100vw;
    }

    .wrap-inner {
      padding: 0 16px;
    }

    .ui-modal-cont .modal-pd {
        padding: 0 26px;
    }

    .wrap-inner-bg {
        padding: 0 32px;
        background: var(--carrot-body-background, #fff);
    }

    .wrap-inner-line {
        border-top: 10px solid var(--carrot-line-color2, #d9d9d9);
    }

    .wrap-inner.brd-t {
        border-top: 1px var(--carrot-line-color2, #d9d9d9) solid;
    }

    .dropdownOpened .base-body {
        z-index: auto;
    }

    .dropdownOpened .btn-wrap-fixed {
        bottom: -60px;
    }

    .slide-page>.ui-slide-wrap {
        overflow: visible;
    }

    .slide-page>.ui-slide-dotwrap {
        top: 0;
    }

    .slide-page>.ui-slide-dotwrap .ui-slide-dot {
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background: #000;
        margin: 0 2px;
        opacity: 0.4;
    }

    .slide-page>.ui-slide-dotwrap .ui-slide-dot.on {
        opacity: 0.8;
        transition: opacity 0.2s ease-in, width 0.2s ease-in;
        width: 30px;
    }

    .slide-page>.ui-slide-wrap>.ui-slide-itemwrap>.ui-slide-item.on {
        position: absolute;
        opacity: 1;
    }

    .slide-page>.ui-slide-wrap>.ui-slide-itemwrap>.ui-slide-item {
        position: fixed;
        opacity: 0;
        transition: opacity 0.5s ease-out;
    }

    .type-careplan {
        padding-bottom: 60px;
    }

    .type-careplan .ui-slide-item {
        padding: 0 32px;
    }

    .type-careplan .ui-slide-item .box-base {
        margin-top: 23px;
    }

    .type-careplan .ui-slide-dotwrap {
        top: 0px;
        padding: 0 32px;
        position: absolute;
    }

    .type-careplan .ui-slide-dotdiv {
        width: 100%;
        height: 5px;
        display: block;
        text-align: center;
    }

    .type-careplan .ui-slide-dotwrap .ui-slide-dot {
        display: inline-block;
        width: 30px;
        height: 3px;
        background-color: #f1f1f1;
        margin: 0 2px;
        vertical-align: top;
    }

    .type-careplan .ui-slide-dotwrap .ui-slide-dot.on {
        transition: background-color 0.2s ease-in;
        background-color: var(--carrot-secondary-color, #00358e);
    }

    .type-careplan .ui-slide-navwrap {
        position: absolute;
        width: 100%;
        z-index: 1;
        top: 50%;
        margin-top: -44px;
    }

    .type-careplan .ui-slide-navwrap button {
        position: absolute;
        top: 50%;
        width: 25px;
        height: 48px;
        border: 1px solid #e0e0e0;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 2px;
    }

    .type-careplan .ui-slide-navwrap button:after {
        content: "";
        display: block;
        width: 100%;
        height: 25px;
        background: rgba(255, 255, 255, 0.5) url('/static/images/common/ico_small@2x.png') no-repeat -125px 0;
        background-size: 300px;
    }

    .type-careplan .ui-slide-navwrap .ui-slide-prev {
        top: 0;
        left: 15px;
    }

    .type-careplan .ui-slide-navwrap .ui-slide-next {
        top: 50%;
        right: 15px;
    }

    .type-careplan .ui-slide-navwrap .ui-slide-prev:after {
        background-position: -100px 0;
    }

    .type-careplan .ui-slide-item.on {
        height: auto;
    }

    .type-careplan .ui-slide-item {
        height: 0;
    }

    .btn-wrap-fixed .ui-slide-dotwrap {
        bottom: 110px;
        padding: 0 32px;
        position: absolute;
    }

    .btn-wrap-fixed .ui-slide-dotdiv {
        width: 100%;
        height: 5px;
        display: flex;
        justify-content: space-between;
    }

    .btn-wrap-fixed .ui-slide-dotwrap .ui-slide-dot {
        flex: 1;
        width: auto;
        height: 5px;
        background-color: #f1f1f1;
        margin: 0 0;
    }

    .btn-wrap-fixed .ui-slide-dotwrap .ui-slide-dot.on {
        transition: background-color 0.2s ease-in;
        background-color: var(--carrot-secondary-color, #00358e);
    }

    .btn-wrap-fixed .ui-acco-wrap {
        padding: 0;
        background: var(--carrot-body-background, #fff);
    }

    .btn-wrap-fixed .box-base {
        margin-top: 0;
        background: rgba(255, 255, 255, 0.85);
        border-top: 1px var(--carrot-line-color2, #d9d9d9) solid;
    }

    /* header  */
    .stepcar-wrap {
        position: fixed;
        top: 0;
        right: -100%;
        z-index: 500;
        display: none;
        overflow: hidden;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        background: var(--carrot-body-background, #fff);
        transition: right 0.2s ease-in;
        overflow-y:auto;
    }

    .stepcar-on .stepcar-wrap {
        display: block;
    }

    .stepcar-wrap.on {
        right: 0;
    }

    .stepcar-wrap .btn-home {
        left: 16px;
    }

    .stepcar-wrap .btn-close {
        position: absolute;
        top: 16px;
        right: 16px;
        background-position: -203px -52px;
    }

    .stepcar-wrap h1 {
        width: 100%;
        text-align: center;
        height: 16px;
        font-size: 16px;
        color: var(--carrot-font-color, #000);
        font-weight: 700;
        padding-top: 22px;
        line-height: 16px;
    }

    .stepcar-list {
        position: relative;
        width: 100%;
        padding-top: 60px;
        height: calc(100% - 60px);
    }

    .stepcar-list li dl:after,
    .stepcar-list:after {
        content: "";
        display: block;
        clear: both;
    }

    .stepcar-list li {
        float: left;
        width: 100%;
        padding: 0 32px 15px;
        position: relative;
        z-index: 1;
        border-left: 4px solid var(--carrot-line-color2, #d9d9d9);
    }

    .stepcar-list li.on {
        border-left: 4px solid var(--carrot-primary-color, #f05014);
    }

    .stepcar-list li dl {
        float: left;
        width: 100%;
        padding-bottom: 20px;
        color: var(--carrot-font-color, #000);
    }

    .stepcar-list li dl+dl {
        border-top: 1px solid var(--carrot-line-color2, #d9d9d9);
        padding-top: 20px;
    }

    .stepcar-list li dl dl {
        margin-top: 24px;
        padding: 24px 0 0;
        border-top: 1px solid var(--carrot-line-color2, #d9d9d9);
        position: relative;
    }

    .stepcar-list li dl dl dt {
        float: left;
        width: 100%;
        margin-bottom: 7px;
        font-size: 15px;
        font-weight: 700;
    }

    .stepcar-list li dl dl dd {
        padding: 0;
    }

    .stepcar-list li dd dd .btn-base-s {
        right: 0;
        top: 18px;
    }

    .stepcar-list li dt {
        float: left;
        width: 100%;
        margin-bottom: 14px;
    }

    .stepcar-list li dt b {
        display: block;
        float: left;
        width: 60px;
        color: var(--carrot-primary-color7, #e2470f);
        font-weight: 700;
        font-size: 13px;
        margin-top: 3px;
    }

    .stepcar-list li dt strong {
        display: block;
        float: right;
        width: calc(100% - 67px);
        font-size: 16px;
        font-weight: 700;
    }

    .stepcar-list li dd {
        float: left;
        width: 100%;
        padding-left: 67px;
    }

    .stepcar-list li dd .btn-base-s {
        position: absolute;
        top: -4px;
        right: 32px;
        min-width: 58px;
        max-width: 58px;
        padding: 0;
    }

    body.stepcar-on,
    body.menu-on {
        overflow: hidden;
    }

    .stepcar-on .base-header,
    .menu-on .base-header {
        z-index: 300;
    }

    .stepcar-on .menu-wrap,
    .menu-on .menu-wrap {
        display: block;
    }

    .menu-wrap.on {
        right: 0;
    }

    .menu-wrap .btn-close {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 2;
    }

    .menu-wrap {
        position: fixed;
        top: 0;
        right: -100%;
        z-index: 3;
        display: none;
        width: 100vw;
        height: 100%;
        margin: 0 auto;
        background: #f5f5f5;
        transition: right 0.15s ease-in;
    }

    .menu-wrap.nomotion {
        transition: none;
    }

    .menu-wrap-head {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 60px;
        border-bottom: 1px solid var(--carrot-line-color1, #c4c4c4);
        text-align: right;
        padding: 20px 25px;
        background: var(--carrot-body-background, #fff);
    }

    .menu-wrap-logo {
        width: 100%;
        overflow: hidden;
    }

    .menu-wrap-logo .carrot {
        width: 70px;
        height: 20px;
        float: left;
    }

    .menu-wrap-logo a {
        display: block;
        float: left;
        font-size: 15px;
        font-weight: 500
    }

    .menu-wrap-logo button {
        float: left;
        margin-left: 26px;
        font-size: 15px;
        height: 22px;
        line-height: 22px;
    }

    .menu-wrap-logo button.selected {
        font-weight: 600;
        color: var(--carrot-primary-color7, #e2470f);
    }

    .menu-wrap-logout {
        display: none;
        overflow: hidden;
        padding-top: 20px
    }

    .menu-wrap-login {
        width: 100%;
        padding: 20px 0 0;
        text-align: center;
    }

    .menu-wrap-head .name {
        float: left;
        font-size: 13px;
        padding: 0;
        text-align: left;
    }

    .menu-wrap-head .name b {
        display: block;
        color: var(--carrot-font-color, #000);
        font-size: 18px;
        font-weight: 600;
    }

    .menu-wrap-head .btn-txt {
        float: right;
        font-weight: 400;
        font-size: 14px;
        color: var(--carrot-font-color3, #555);
        border-bottom: 1px solid var(--carrot-font-color3, #555);
        margin: 3px 0 0 0;
    }

    .menu-wrap-head .menu-wrap-login .txt-mark {
        margin: 0 15px;
    }

    .menu-wrap-head .menu-wrap-login .btn-txt {
        margin-right: 0;
        float: none;
        display: inline-block;
    }

    .menu-wrap-body {
        position: relative;
        margin-top: 60px;
        width: 100%;
    }

    .menu-wrap-logo .btn-login {
        color: var(--carrot-primary-color, #f05014)
    }

    .menu-wrap-logo .btn-logout {
        display: none
    }

    .state-login .btn-logout {
        display: block
    }

    .state-login .menu-wrap-head {
        height: 120px
    }

    .state-login .menu-wrap-body {
        margin-top: 120px;
    }

    .state-login .menu-wrap-logout {
        display: block
    }

    .gn-wrap:after,
    .dep-2-wrap:after {
        content: "";
        display: block;
        height: 15vh;
        width: 100%;
    }

    .dep-1-wrap {
        background-color: #f5f5f5;
    }

    .dep-1.line-dot {
        border-bottom: 1px dashed var(--carrot-primary-color, #f05014);
    }

    .menu-wrap .dep-2-wrap {
        border-left: 1px solid #e7e7e7;
    }

    .dep-1:after {
        content: "";
        display: block;
        clear: both;
    }

    .state-login .menu-wrap .dep-2-wrap {
        top: 120px
    }

    .dep-2-wrap {
        display: none;
        position: absolute;
        top: 0;
        left: 90px;
        width: calc(100% - 90px);
        z-index: 1;
        height: auto;
        text-align: left;
        padding: 25px 25px 80px;
        overflow-y: scroll;
        background-color: #fff;
        -webkit-overflow-scrolling: touch;
        opacity: 1;
    }

    .dep-1.selected .dep-2-wrap,
    .menu-wrap .selected .dep-2-wrap {
        display: block;
    }

    .menu-wrap .dep-2 {
        display: inline-block;
        width: 100%;
    }

    .menu-wrap .dep-3 {
        display: block;
        width: 100%;
        margin-top: 14px;
    }

    .menu-wrap .ui-acco-btn,
    .menu-wrap .dep-2-btn {
        display: inline-block;
        width: 100%;
        font-size: 14px;
        font-weight: 400;
        color: var(--carrot-font-color, #000);
        text-align: left;
        margin: 0;
        padding: 10px 0;
        letter-spacing: 0;
        text-decoration: none;
    }

    //main menu 안드로이드에서 깨짐 현상
    .menu-wrap .type-dep-list .dep-2 {
        height: 38px;
    }

    .menu-wrap .type-dep-list .dep-2-btn {
        padding: 0;
    }

    .menu-wrap .type-dep-list .dep-2-btn span {
        display: block;
        height: 38px;
        line-height: 36px;
    }

    .menu-wrap .dep-3-btn {
        display: inline-block;
        width: 100%;
        font-size: 14px;
        font-weight: 400;
        color: var(--carrot-font-color, #000);
        text-align: left;
        margin: 0;
        letter-spacing: 0;
        text-decoration: none;
    }

    .menu-wrap .ui-acco-btn.selected {
        padding-bottom: 13px;
    }

    .menu-dim {
        display: none;
        opacity: 0;
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
    }

    .stepcar-on .menu-dim,
    .menu-on .menu-dim {
        display: block;
    }

    .menu-dim.on {
        opacity: 0.6;
    }

    .menu-wrap .dep-3.selected .dep-3-btn {
        font-weight: 700;
        color: var(--carrot-primary-color7, #e2470f);
    }

    .selected .dep-1-btn {
        font-weight: 600;
        color: var(--carrot-primary-color, #f05014);
        background: #fff;
        width: 91px;
        z-index: 10
    }

    .dep-1-btn {
        position: relative;
        width: 90px;
        height: 75px;
        float: left;
        padding: 0;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: var(--carrot-font-color, #000);
        border-bottom: 1px solid #e7e7e7;
    }

    .dep-2-wrap h3 {
        font-size: 16px;
        font-weight: 600;
        color: #111;
        margin-bottom: 10px
    }

    .dep-2-wrap ul+h3 {
        margin-top: 30px
    }

    .menu-wrap .ui-acco-wrap {
        padding: 0;
        border: 0
    }

    .menu-wrap .ui-acco {
        border: 0
    }

    .menu-wrap .ui-acco-pnl {
        background: #f5f5f5;
        padding: 17px 20px;
        margin: 0;
        border-radius: 4px;
        margin-bottom: 14px;
    }

    .menu-wrap-footer {
        position: absolute;
        bottom: 0;
        z-index: 11;
        left: 0;
        width: 100%;
        text-align: center;
        height: 60px;
        padding-top: 12px;
        border-top: 1px solid #e7e7e7;
        background: #fff;
    }

    .menu-wrap-footer a {
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
        color: #111;
        margin: 0 10px;
        vertical-align: middle;
        text-decoration: none;
    }

    .menu-wrap-footer span {
        display: inline-block;
        font-size: 11px;
        font-weight: 500;
        color: #8a8a8a;
        vertical-align: middle;
    }

    .menu-wrap-footer .btn-urgent {
        position: relative;
        height: 36px;
        color: #111;
        padding: 0 35px 0 10px;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 500;
        vertical-align: middle;
    }

    .menu-wrap-footer .btn-urgent:after {
        content: "";
        display: block;
        position: absolute;
        right: 5px;
        top: 5px;
        background: url('/static/images/common/ico_small@2x.png') no-repeat -175px -75px;
        background-size: 300px;
        width: 25px;
        height: 25px;
    }


    .tit-wrap {
        position: relative;
        width: 100%;
        padding-top: 34px;
    }

    .tit-wrap:after {
        content: "";
        display: block;
        clear: both;
    }

    .tit-wrap .h1 {
        word-break: break-all;
    }

    .tit-wrap .h1 strong {
        color: var(--carrot-primary-color, #f05014);
        font-weight: 700;
    }

    /* pdf iframe layer */
    #iframeLayer {
        position: fixed;
        top: 100%;
        background: #fff;
        transition: all 0.3s ease-out;
        z-index: 10000;
    }

    #iframeLayer.pdfIframe {
        top: 0;
        width: 100%;
        height: 100%;
    }

    /* ui modal dialog */
    .dim,
    .modal-backdrop {
        display: none;
        opacity: 0;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 200%;
        background-color: rgba(0, 0, 0, 0.8);
    }

    .modal-open {
        overflow: hidden;
        padding: 0;
    }

    .dim-dropdown .dim {
        display: block;
        opacity: 1;
    }

    .dim-dropdown .base-body {
        z-index: auto;
    }

    .ui-modal.bug-fix[aria-hidden="true"] {
        position: fixed;
    }

    .ui-modal {
        display: none;
        opacity: 0;
        position: fixed;
        z-index: 1;
        width: auto;
        height: auto;
        padding: 0 32px;
    }

    .ui-modal.type-full {
        width: 100%;
        height: 100%;
        padding: 0;
    }

    .ui-modal.type-select .lb-radio,
    .ui-modal.type-select .lb-check {
        margin: 0;
        border-bottom: 1px solid #ddd;
        padding: 20px;
        width: 100%;
    }

    .ui-modal.ps-page {
        max-height: 100%;
    }

    .ui-modal.ps-bottom {
        padding: 0;
        background: #fff;
        width: 100%;
    }

    .ui-modal.ps-bottom .ui-modal-cont.type-half {
        max-height: 400px !important;
    }

    .ui-modal.ps-bottom .ui-modal-cont {
        max-height: 500px;
        overflow-y: auto;
        z-index: 2;
    }

    .ui-modal.ps-bottom.type-select .ui-modal-cont {
        max-height: 500px;
        overflow-y: auto;
        z-index: 2;
    }

    .ui-modal.ps-bottom .h2 {
        font-size: 22px;
    }

    .ui-modal.ps-bottom .h2:first-child {
        margin-top: 20px;
    }

    .ui-modal.ps-page .ui-modal-cont {
        /*z-index:2; */
        overflow: hidden;
    }

    .ui-modal.ps-center {
        min-width: 100%;
    }

    .ui-modal.ps-center .wrap-inner {
        padding-left: 25px;
        padding-right: 25px;
        font-size: 15px;
    }

    .ui-modal.show {
        display: block;
        left: 50%;
    }

    .ui-modal .btn-close {
        position: absolute;
        top: 18px;
        right: 18px;
        z-index: 101;
    }

    .ui-modal-wrap {
        width: 100%;
        height: 100%;
        position: relative;
        top: 0;
        left: 0;
        background-color: var(--carrot-body-background, #fff);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
    }

    .ui-modal-header {
        width: 100%;
        position: relative;
        border: 0;
        top: 0;
        left: 0;
        z-index: 1;
        padding: 35px 25px 16px;
        max-height: 100%;
        background-color: var(--carrot-body-background, #fff);
        border-radius: 8px;
    }

    .ui-modal-header .ui-modal-tit {
        font-size: 20px;
        text-align: left;
        font-weight: 700;
        background-color: var(--carrot-body-background, #fff);
        width: 100%;
        height: 100%;
        color: var(--carrot-font-color, #000);
        display: block;
    }

    .ui-modal-progress {
        top: 60px;
        width: 100%;
        height: 38px;
        background: rgba(153, 153, 153, 0.85);
    }

    .ui-modal-progress .tit-progress {
        display: block;
        font-size: 14px;
        color: #fff;
        text-align: center;
        line-height: 38px;
        letter-spacing: 0;
    }

    .ui-modal-progress .btn-prev-white {
        position: absolute;
        left: 23px;
        top: 50%;
        width: 25px;
        height: 25px;
        background: url('/static/images/common/ico_small@2x.png') -125px -50px no-repeat;
        background-size: 300px;
        transform: translateY(-50%);
    }

    .ui-modal-header .n2 {
        position: absolute;
        top: 20px;
        right: 32px;
        display: block;
        line-height: 22px;
        padding: 0;
    }

    .ui-modal-header .n1 {
        position: absolute;
        top: 22px;
        left: 32px;
        display: block;
        line-height: 22px;
        padding: 0;
    }

    .ui-modal-header .ui-modal-close.btn-txt {
        font-size: 14px;
        color: #999;
        font-weight: 400;
        border: 0;
        outline: 0;
    }

    .iframe-header {
        height: 55px;
        background: #323639;
        z-index: 3;
    }

    .iframe-header+iframe {
        background: #525659;
    }

    .ui-modal-footer.iframe-footer {
        height: 80px;
        padding: 0;
        border-radius: unset;
        background: #ededf0;
        @media (prefers-color-scheme: dark) {
          background: #2a2a2e;
        }
        .btn-base-l{
          min-height: 50px;
        }
    }

    .ui-modal-cont {
        position: relative;
        top: 0;
        left: 0;
        height: auto;
        overflow: auto;
        width: 100%;
        margin: 0;
        padding: 0;
        background-color: var(--carrot-body-background, #fff);
    }

    .ui-modal-cont.modal-fixed-pdb {
        padding-bottom: 90px !important;
    }

    .ui-modal-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 3;
        text-align: right;
        margin: 0;
        padding: 34px 23px 38px;
        background: var(--carrot-body-background, #fff);
        border-radius: 8px;
    }

    .ui-modal-footer .btn-txt {
        font-weight: 400;
        font-size: 14px;
        color: #999;
        border-bottom: 0;
        margin-right: 30px;
    }

    .ui-modal-cont .h2 {
        margin-top: 0;
        font-size: 20px;
        font-weight: 700;
        line-height: 1.2;
    }

    .ui-modal-footer .btn-wrap-fixed {
        position: relative;
    }

    .ui-modal.ps-bottom .ui-modal-footer {
        position: relative;
    }

    .ui-modal.type-system .ui-modal-footer {
        padding-top: 13px;
        padding-bottom: 28px;
    }

    .base-layer {
        transition: background-color 0.3s ease-in;
        top: 0;
        left: 0;
    }

    .ui-modal.ps-page .ui-modal-header {
        padding: 21px 70px 0;
        min-height: 60px;
        z-index: 3;
        background: #fff;
    }

    .ui-modal.ps-page .ui-modal-header.row-2 {
        padding: 14px 70px;
    }

    .ui-modal.ps-page .ui-modal-header .ui-modal-tit {
        text-align: center;
        font-size: 15px;
    }

    .ui-modal.ps-page .ui-modal-header .btn-txt {
        position: absolute;
        top: 20px;
        color: #999;
        border-bottom: 0;
    }

    .ui-modal.ps-page .ui-modal-header .btn-txt.n1 {
        left: 32px;
    }

    .ui-modal.ps-page .ui-modal-header .btn-txt.n2 {
        right: 18px;
    }

    .ui-modal.ps-page .ui-modal-header .n2 {
        right: 20px;
    }

    .page-insuinfo .ui-modal-cont,
    .ui-modal.page-insuinfo .ui-modal-cont {
        height: 100%;
        background: var(--carrot-content-background, #f8f8f8) !important;
        overflow: auto;
    }

    .page-insuinfo-tit .ui-modal-tit {
        background: var(--carrot-primary-color, #f05014);
        // padding: 20px 0;
    }

    .page-insuinfo-tit .ui-fixed-top .ui-modal-tit {
        padding: 20px 32px;
    }

    .page-insuinfo-tit,
    .ui-modal.page-insuinfo .ui-modal-wrap .modal-header {
        background-color: var(--carrot-primary-color, #f05014);
        color: #fff;
    }

    .ui-modal.page-insuinfo .ui-modal-wrap .modal-header {
        z-index: 3;
        width: 100%;
        height: 0;
    }

    .ui-modal.page-insuinfo .ui-modal-wrap .modal-header .btn-txt {
        color: transparent;
        position: absolute;
        top: 15px;
        right: 32px;
    }

    .ui-modal.page-insuinfo .ui-modal-wrap .modal-header .btn-txt:before,
    .ui-modal.page-insuinfo .ui-modal-wrap .modal-header .btn-txt:after {
        background: #fff;
    }

    .page-insuinfo-tit {
        padding: 67px 32px 20px;
        // min-height: 230px;
        width: 100%;
    }

    .page-insuinfo-tit .tit {
        font-size: 25px;
        font-weight: 700;
        word-break: break-word;
    }

    .page-insuinfo-tit .state {
        display: inline-block;
        background-color: var(--carrot-body-background, #fff);
        height: 17px;
        font-size: 12px;
        color: var(--carrot-font-color, #000);
        padding: 0 10px;
        text-align: center;
        line-height: 17px;
        border-radius: 8px;
        vertical-align: middle;
        font-weight: 400;
    }

    .page-insuinfo-tit .stocknum {
        font-size: 15px;
        margin-top: 18px;
    }

    .page-insuinfo-tit .btn-wrap {
        text-align: left;
        margin-top: 50px;
    }

    .page-insuinfo-tit .btn-base {
        border-color: #779bd8;
        background-color: transparent;
        color: #fff;
        margin: 0;
    }

    .page-insuinfo-tit .premium {
        // color: #ff9d03;
        overflow: hidden;
        margin-top: 15px;
    }

    .page-insuinfo-tit .premium span {
        display: block;
        font-size: 16px;
        float: left;
    }

    .page-insuinfo-tit .premium strong {
        display: block;
        float: right;
        font-size: 30px;
        font-weight: 700;
    }

    .page-insuinfo-tit .premium strong em {
        vertical-align: middle;
    }

    .page-insuinfo-tit .premium b {
        font-size: 16px;
        vertical-align: middle;
        margin-left: 5px;
    }

    .page-insuinfo-tit .premium p {
        font-size: 16px;
        font-weight: 400;
        margin-top: 10px;
        text-align: right;
    }

    .page-insuinfo-tit .premium-detail::after {
        content: '';
        display: block;
        clear: both;
    }

    .page-insuinfo-tit .premium-detail+p,
    .page-insuinfo-tit .premium-detail+p+p {
        font-size: 13px;
        margin-top: 5px;
    }

    .page-insuinfo .date,
    .ui-modal.page-insuinfo .date {
        display: block;
        top: 21px;
        font-weight: 400;
        color: #fff;
        font-size: 13px;
        position: absolute;
        left: 32px;
    }

    .ui-modal.page-insuinfo .btn-close:after,
    .ui-modal.page-insuinfo .btn-close:before {
        background: #fff;
    }

    .ui-floating-wrap {
        transform: translateZ(0);
    }

    .ui-tab .ui-tab-btns {
        margin: 0;
        padding: 0 32px;
        border-bottom: 1px solid var(--carrot-line-color1, #c4c4c4);
    }

    .ui-tab.type-base .ui-tab-btns {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-lines: single;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        margin: 10px 0 0;
    }

    .ui-tab.type-base .ui-tab-btn {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex-grow: 1;
    }

    .w-n2 .ui-tab-btn {
        width: 50% !important;
    }

    .w-n3 .ui-tab-btn {
        width: 33.33% !important;
    }

    .w-n3 .ui-tab-btn:nth-child(2) {
        width: 33.34% !important;
    }

    .modal-system .ui-modal-cont {
        text-align: center;
        padding: 20px;
        font-size: 16px;
    }

    .modal-system .btn-wrap {
        margin-top: 20px;
    }

    .modal-system .ui-modal-cont:before {
        content: "";
        display: inline-block;
        width: 50px;
        height: 50px;
        background: url(/netiveUI/resources/img/common/ico_modal.png) no-repeat -25px -25px;
        margin-bottom: 20px;
    }

    .modal-system .system-type-a:before {
        background-position: -25px -25px;
    }

    .modal-system .system-type-b:before {
        background-position: -125px -25px;
    }

    .modal-system .system-type-c:before {
        background-position: -225px -25px;
    }

    /* tmap */
    .tmap-wrap {
        position: relative;
        height: calc(100vh - 297px);
        background: #f9f9f9;
        z-index: 1;
    }

    /* 약관 */
    #ModalTerms .ui-modal-header {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding-top: 0;
        align-items: center;
        -webkit-box-align: center;
    }

    .terms-wrap {
        position: relative;
        width: 100%;
        font-size: 15px;
        color: var(--carrot-font-color3, #555);
        letter-spacing: -0.075em;
        line-height: 1.6;
    }

    .terms-wrap h1 {
        font-size: 20px;
        font-weight: 700;
        color: var(--carrot-font-color, #000);
        margin-bottom: 40px;
        display: none;
    }

    .terms-wrap h2 {
        font-size: 17px;
        font-weight: 700;
        color: var(--carrot-font-color, #000);
        margin: 30px 0 15px;
    }

    .terms-wrap h3 {
        font-size: 15px;
        font-weight: 700;
        color: var(--carrot-font-color3, #555);
        margin: 30px 0 15px;
    }

    .terms-wrap h4 {
        font-size: 13px;
        font-weight: 700;
        color: var(--carrot-font-color3, #555);
    }

    .terms-wrap p {
        margin: 15px 0;
    }

    .terms-wrap li {
        margin-bottom: 10px;
    }

    .terms-wrap li ul {
        margin-top: 17px;
    }

    .terms-wrap strong {
        color: var(--carrot-primary-color7, #e2470f);
    }

    .terms-wrap strong p {
        color: var(--carrot-primary-color7, #e2470f);
    }

    .terms-wrap em {
        font-weight: bold;
    }

    .terms-wrap .tbl-base .line-l {
        border-left: 1px solid #ddd;
    }

    .terms-wrap h2:first-child,
    .terms-wrap h3:first-child,
    .terms-wrap .h1+h2 {
        margin-top: 0;
    }

    /* 동의 */
    .list-agree>li {
        position: relative;
        margin-top: 30px;
    }

    .list-agree>li:first-child {
        margin-top: 0;
    }

    .list-agree>li>[class*="tit"] {
        position: relative;
        display: block;
        color: var(--carrot-font-color, #000);
        font-size: 16px;
        line-height: 24px;
        word-break: keep-all;
        letter-spacing: -1px;
    }

    .list-agree>li>[class*="tit"].type-num {
        position: relative;
        padding-left: 15px;
    }

    .list-agree>li>[class*="tit"].type-num .bul-num {
        margin-left: -15px;
        margin-right: 3px;
    }

    .list-agree>li>[class*="tit"] b {
        font-weight: 700;
    }

    .list-agree>li>[class*="tit"] em {
        color: #fd0000;
    }

    .list-agree .tit-ico,
    .type-agree .tit-ico {
        position: relative;
        width: 100%;
        text-align: left;
        padding-right: 25px;
    }

    .list-agree .tit-ico .ico-arrow,
    .type-agree .tit-ico .ico-arrow {
        position: absolute;
        top: 2px;
        right: 0;
    }

    .list-agree>li>.txt-sub {
        margin-left: 15px;
        margin-top: 12px;
        color: #999;
        font-size: 15px;
        line-height: 22px;
        word-break: keep-all;
    }

    .list-agree li .txt-sub2 {
        margin-left: 30px;
        color: #999;
        font-size: 14px;
        line-height: 24px;
        word-break: keep-all;
    }

    .list-agree .type-agree {
        padding-left: 15px;
    }

    .list-dlsel.type-agree .list-dlsel-item {
        margin-top: 18px;
        min-height: 20px;
    }

    .list-dlsel.type-agree .list-dlsel-item:first-child {
        margin-top: 18px;
    }

    .list-dlsel.type-agree .list-dlsel-item dl {
        padding-right: 82px;
    }

    .list-dlsel.type-agree .list-dlsel-item dt {
        width: 100%;
    }

    .list-dlsel.type-agree .list-dlsel-item [class*="tit"] {
        font-size: 14px;
        color: var(--carrot-font-color3, #555);
        line-height: 20px;
        font-weight: 400;
        letter-spacing: -1px;
    }

    .list-dlsel.type-agree .list-dlsel-item .tit-ico {
        width: calc(100% - 82px);
    }

    .list-dlsel.type-agree .list-dlsel-item dt .tit-ico {
        width: 100%;
    }

    .list-dlsel.type-agree .list-dlsel-item .list-dlsel-toggle {
        top: 0;
        margin-top: 0;
    }

    .list-dlsel.type-agree .list-dlsel-item.no-toggle dl {
        padding-right: 0;
    }

    .list-dlsel.type-agree .list-dlsel-item .list-dlsel-item:first-child {
        margin-top: 10px;
    }

    .list-dlsel.type-agree .list-dlsel-item .list-dlsel-item [class*="tit"] {
        color: var(--carrot-font-color3, #555);
    }

    .list-agree .list-agree-sel {
        padding-right: 82px;
    }
    .list-agree .list-agree-sel.type-full {
        padding-right: 0;
    }
    .list-agree .list-agree-sel.type-full .type-num {
        width: calc(100% - 82px);
    }
    .list-agree .list-agree-sel.type-full .list-dlsel .tit-ico {
        width: 100%;
    }

    .list-agree .list-agree-toggle {
        position: absolute;
        top: 1px;
        right: 0;
    }

    .list-agree .list-agree-toggle .lb-check {
        padding-top: 0;
    }

    /* 정보 */
    .box-dlinfo {
        padding: 35px 25px 30px;
    }

    .dlinfo-item {
        margin-top: 20px;
    }

    .dlinfo-item:first-child {
        margin-top: 35px;
    }

    .dlinfo-item dt {
        min-width: 10px;
        font-size: 13px;
        color: rgba(68, 68, 68, 0.99);
        line-height: 20px;
    }

    .dlinfo-item dd {
        font-size: 15px;
        color: #010101;
        text-align: right;
        word-break: break-all;
    }

    .dlinfo-item.dlinfo-bold {
        margin-top: 30px;
    }

    .dlinfo-item.dlinfo-bold dt,
    .dlinfo-item.dlinfo-bold dd {
        color: var(--carrot-primary-color7, #e2470f);
        font-weight: 700;
    }

    .dlinfo-item.dlinfo-bold dt {
        line-height: 22px;
    }

    .dlinfo-item.dlinfo-bold dd {
        font-size: 20px;
    }

    /*가입완료*/
    .result-tit {
        padding: 30px 0 0;
        text-align: center;
    }

    .result-wrap {
        padding: 55px 18px;
        text-align: center;
    }

    .result-wrap h2 {
        font-size: 16px;
        font-weight: 700;
        color: var(--carrot-font-color, #000);
    }

    .result-wrap p,
    .result-wrap p .txt-6 {
        margin-top: 15px;
        font-size: 16px;
        color: var(--carrot-font-color, #000);
    }

    .result-wrap .f-style {
        font-size: 23px;
        color: var(--carrot-primary-color10, #f26421);
        font-weight: 700;
    }

    .result-wrap .btn-wrap {
        margin-top: 55px;
    }

    /* 아코디언 */
    .ui-acco,
    .ui-acco-wrap,
    .ui-acco-pnl {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .ui-acco {
        position: relative;
        margin: 0;
        list-style: none;
    }

    .ui-acco-wrap {
        position: relative;
        width: 100%;
        padding: 0 16px;
        margin: 0;
        list-style: none;
        border-bottom: 1px solid #eee;
    }

    .ui-acco-wrap .ui-acco {
        border-bottom: 0;
    }

    .ui-acco-wrap .ui-acco-wrap {
        padding: 0;
    }

    .ui-acco-tit {
        box-sizing: border-box;
        position: relative;
        width: 100%;
        height: 100%;
        border-bottom: 0;
        margin: 0;
        padding: 0;
    }

    .ui-acco-tit h1 {
        position: relative;
        z-index: 0;
    }

    .ui-acco-btn {
        cursor: pointer;
        position: relative;
        top: 0;
        right: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        letter-spacing: 0;
        box-sizing: border-box;
        text-align: left;
        color: var(--carrot-font-color, #000);
        padding: 24px 35px 24px 16px;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.3;
        letter-spacing: -0.03em;
    }

    .ui-acco-btn.selected {
        padding-bottom: 20px;
    }

    a.ui-acco-btn {
        display: inline-block;
        text-decoration: none;
    }

    a.ui-acco-btn .ui-acco-arrow::before {
        transform: rotate(-90deg);
    }

    .ui-acco-pnl {
        position: relative;
        box-sizing: border-box;
        border-bottom: 0;
        padding: 0 0 20px;
        margin: 0;
    }

    .ui-acco-pnl .bul-etc:first-child,
    .ui-acco-pnl .bul-etc li:first-child {
        margin-top: 0;
    }

    .ui-acco-pnl ol>li {
        margin-top: 25px;
    }

    .ui-acco-pnl ol,
    .ui-acco-pnl ul,
    .ui-acco-pnl li:first-child {
        margin-top: 0;
    }

    .ui-modal .ui-acco {
        border-bottom: 1px solid #eee;
    }

    .ui-modal .ui-acco-wrap {
        border-top: 1px solid #eee;
        padding: 0 32px;
    }

    .box-dialog .ui-acco.type-b,
    .ui-modal .ui-acco.type-b {
        border-bottom: 0;
        padding: 0;
        background: none;
    }

    /*.ui-modal .ui-acco.type-b .ui-acco-wrap ,*/
    .ui-modal .ui-acco.type-fix .ui-acco-wrap {
        padding: 0;
        border: 0;
    }

    .ui-acco-arrow {
        font-size: 0;
        line-height: 0;
    }

    .ui-acco-arrow::before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        right: 16px;
        margin-top: -7px;
        width: 15px;
        height: 15px;
        background: url('/static/images/common/ico_small@2x.png') no-repeat -30px -55px;
        background-size: 300px;
    }

    button.selected .ui-acco-arrow:before {
        background: url('/static/images/common/ico_small@2x.png') no-repeat -5px -55px;
        background-size: 300px;
        margin-top: -9px;
    }

    .ui-acco.type-b .ui-acco-btn {
        position: absolute;
        color: transparent;
        overflow: hidden;
        font-size: xx-small;
        line-height: 0;
    }

    .ui-acco.type-b,
    .ui-acco.type-b .ui-acco-wrap {
        border: 0;
    }

    .ui-acco.type-b .ui-acco-wrap {
        padding: 8px 0;
    }

    .ui-acco.type-b .ui-acco-tit {
        border: 0;
        padding: 0;
    }

    .ui-acco.type-b .ui-acco-txt {
        position: relative;
        display: inline-block;
        z-index: 2;
        text-decoration: none;
        color: var(--carrot-font-color, #000);
    }

    .ui-acco.type-b .ui-acco-tit span.tit {
        font-size: 16px;
    }

    .ui-acco.type-b .ui-acco-tit .lb-radio,
    .ui-acco.type-b .ui-acco-tit .lb-check {
        margin-right: 5px;
        z-index: 2;
        padding-top: 0 !important;
    }

    .ui-acco.type-b .ui-acco-tit .lb-radio:before,
    .ui-acco.type-b .ui-acco-tit .lb-check:before {
        margin-top: 0 !important;
    }

    .ui-acco.type-b .ui-acco-tit .lb-radio span,
    .ui-acco.type-b .ui-acco-tit .lb-check span {
        line-height: 21px;
    }

    .ui-acco.type-b .ui-acco-tit .btn-tip {
        z-index: 10;
        top: 0;
        margin-top: -2px;
    }

    .ui-acco.type-b .ui-acco-pnl {
        border: 0;
        padding: 15px 0 0;
    }

    .ui-acco.type-b .box-base .ui-acco-tit,
    .ui-acco.type-b .box-base .ui-acco-pnl {
        background: #f1f1f1;
    }

    .ui-acco.type-contract .ui-acco-pnl.list-none {
        padding: 50px 0;
        text-align: center;
        background: transparent;
        border: 0;
    }

    .ui-acco.type-contract .ui-acco-wrap {
        padding: 0;
        border: 0;
    }

    .ui-acco.type-contract .ui-acco-wrap:first-child .ui-acco-pnl {
        margin-top: 0;
    }

    .ui-acco.type-contract .ui-acco-pnl {
        margin-top: 10px;
        padding: 0;
    }

    .ui-acco.type-contract .ui-acco-sel {
        width: 100%;
        border-radius: 4px;
        padding: 33px 25px;
        outline: 0;
        border: 1px solid var(--carrot-font-color4, #777);
    }

    .ui-acco.type-contract .ui-acco-pnl.selected .ui-acco-sel,
    .ui-acco.type-contract .ui-acco-sel:focus,
    .ui-acco.type-contract .ui-acco-sel:active {
        border: 1px solid var(--carrot-font-color4, #777);
    }

    .ui-acco.type-contract .tbl-noline {
        margin-top: 0;
    }

    .ui-acco.type-contract .tbl-noline th,
    .ui-acco.type-contract .tbl-noline td {
        padding: 6px 0;
    }

    .ui-acco .type-contract-tit {
        text-align: left;
        position: relative;
        padding: 0 0 24px;
    }

    .ui-acco .type-contract-tit h3 {
        font-size: 18px;
        font-weight: 700;
        color: var(--carrot-font-color, #000);
        letter-spacing: -0.07em;
    }

    .ui-acco .type-contract-tit .ico-arrow {
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -9px;
    }

    .btn-wrap.type-contract {
        margin: 15px 0 0;
        display: none;
    }

    .btn-wrap.type-contract.on {
        display: block;
    }

    .btn-wrap.type-contract .btn-base {
        border: 0;
        background: transparent url('/static/images/common/ico_small@2x.png') no-repeat -175px 0;
        padding: 0;
        min-height: 0;
        min-width: 0;
        background-size: 300px;
        width: 25px;
        height: 25px;
    }

    .ui-acco.type-fix {
        margin-bottom: 0px;
        border-bottom: 0;
    }

    .ui-acco.type-fix .ui-acco-tit {
        border-radius: 0;
        background: #fff;
    }

    .ui-acco.type-fix .ui-acco-tit .ui-acco-btn {
        padding: 23px 32px;
    }

    .ui-acco.type-fix .ui-acco-tit .ui-acco-btn.selected {
        border-bottom: 1px solid var(--carrot-line-color2, #d9d9d9);
    }

    .ui-acco.type-fix .ui-acco-tit .btn-tip {
        position: absolute;
        top: 0;
        left: 0;
    }

    .ui-acco.type-fix .ui-acco-tit.type-pay .btn-tip {
        top: 50%;
        left: 108px;
        z-index: 2;
        margin-top: -8px;
    }

    .ui-acco.type-fix .ui-acco-tit.type-pay .txt-s5 {
        float: left;
        font-weight: 700;
        vertical-align: middle;
    }

    .ui-acco.type-fix .ui-acco-tit.type-pay .txt-price {
        float: right;
        padding-right: 25px;
    }

    .ui-acco.type-fix .ui-acco-tit.type-pay .txt-price b {
        line-height: 1;
    }

    .ui-acco.type-fix .ui-acco-pnl {
        margin-top: 0;
        padding: 0;
        max-height: 300px;
        overflow-y: auto;
    }

    .ui-acco.type-fix .ui-acco-pnl .box-base {
        padding: 25px 32px;
        border-top: 0;
    }

    .ui-acco.type-fix .ui-acco-arrow:before {
        right: 32px;
    }

    .ui-acco.type-accident .lb-radio {
        display: block;
        padding-left: 30px;
        padding-top: 0;
    }

    .ui-acco.type-accident .lb-radio::before {
        position: absolute;
        left: 0;
        width: 23px;
        height: 23px;
    }

    .ui-acco.type-accident .lb-radio>p {
        font-size: 22px;
        color: var(--carrot-font-color, #000);
        line-height: 1;
    }

    .ui-acco.type-accident .lb-radio>em {
        display: inline-block;
        font-size: 14px;
        color: var(--carrot-font-color4, #777);
        margin-top: 10px;
    }

    .ui-acco.type-accident .ui-acco-pnl .box-base {
        padding: 25px;
    }

    .ui-acco.type-accident .ui-acco-pnl .box-base .lb-radio>p {
        font-size: 16px;
        line-height: 1.3;
    }

    .ui-acco.type-accident .ui-acco-pnl .box-base .lb-radio>em {
        font-size: 13px;
        margin-top: 3px;
    }

    /* $plugins.uiSelect */
    .ui-select {
        position: relative;
        display: inline-block;
        vertical-align: top;
        width: 100%;
    }

    .ui-select select {
        width: 100%;
    }

    .ui-select select.off {
        position: absolute;
        left: -9999px;
        width: 0;
        height: 0;
        border: 0;
        opacity: 0;
        z-index: -1;
        color: transparent;
        overflow: hidden;
        font-size: xx-small;
        line-height: 0;
    }

    .ui-select-btn {
        display: inline-block;
        position: relative;
        text-align: left;
        vertical-align: top;
        width: 100%;
        min-height: 40px;
        line-height: 1.2;
        padding: 7px 20px 7px 0;
        background: transparent;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        -webkit-appearance: none;
        -webkit-border-radius: 0;
        border: 0;
        border-bottom: 2px solid var(--carrot-line-color, #9d9d9d);
        font-size: 16px;
        cursor: pointer;
        outline: 0;
        box-sizing: border-box;
        text-overflow: ellipsis;
    }

    .ui-select-btn span {
        display: inline-block;
        width: 100%;
        text-align: left;
        word-break: nowrap;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .ui-select-btn[aria-expanded="true"],
    .ui-select-btn:focus,
    .ui-select-btn:active {
        border-color: #000;
    }

    .ui-select.not-click select {
        pointer-events: none;
    }

    .box-base .ui-select-btn {
        border-color: #dcdcdc;
    }

    .ui-select-wrap.on {
        display: block;
        bottom: 0;
    }

    .ui-select-wrap {
        display: none;
        position: fixed;
        z-index: 100;
        left: 0;
        bottom: -100%;
        width: 100%;
        border: 0;
        background: #fff;
        overflow-y: auto;
        max-height: 400px;
        transition: bottom 0.3s;
        min-height: 40vh;
    }

    .ui-select-wrap.ui-scrollbar {
        position: absolute;
        min-height: 162px;
    }

    .ui-scrollbar-item {
        overflow: hidden;
    }

    .ui-select-opt {
        display: block;
        float: left;
        width: 100%;
        padding: 10px 32px;
        min-height: 40px;
        text-align: left;
        background: #fff;
        font-size: 16px;
    }

    .ui-select-opt.selected {
        font-weight: 700;
        text-decoration: underline;
        background: rgba(68, 68, 68, 0.99);
        color: #fff;
    }

    .ui-select-opts input:focus,
    .ui-select-dim,
    .ui-select-close,
    .ui-select-tit {
        display: none;
    }

    .ui-select-opts {
        position: relative;
        z-index: 1;
        min-height: 40vh;
        width: 100%;
        background: #fff;
    }

    .ui-select-opts:after {
        content: "";
        display: block;
        clear: both;
    }

    .ui-select-wrap .btn-close {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        background-position: -153px -27px;
    }

    .ui-select.type-tel {
        width: 150px;
    }

    .ui-select.type-bank {
        width: 200px;
    }

    .ui-select.type-card {
        width: 200px;
    }

    .type-plan {
        position: relative;
        width: auto;
    }

    .type-plan::before {
        right: 11px;
        border-width: 5px;
        border-top-width: 7px;
    }

    .type-plan::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 10px;
        background: var(--carrot-primary-color, #f05014);
        z-index: -1;
    }

    .type-plan .btn-select,
    .type-plan select {
        height: 36px;
        border: 0;
        font-size: 24px;
        font-weight: 700;
        padding-left: 4px;
        padding-right: 36px;
        letter-spacing: -0.03em;
    }

    .type-plan .btn-select {
        color: var(--carrot-font-color, #000);
        padding-right: 36px;
    }

    .type-plan .btn-select span {
        color: var(--carrot-font-color, #000);
    }

    .type-plan .btn-select.txt-small {
        font-size: 18px;
    }

    /* ui-tab */
    .ui-tab {
        position: relative;
        margin: 0;
    }

    .ui-tab-btns {
        position: relative;
        z-index: 1;
        background: var(--carrot-body-background, #fff);
    }

    .ui-tab-btn {
        position: relative;
        z-index: 0;
        font-size: 14px;
        color: var(--carrot-font-color5, #9d9d9d);
        min-height: 50px;
        margin-bottom: -1px;
    }

    .ui-tab-btn.selected {
        z-index: 2;
    }

    .ui-tab-btn.selected:hover,
    .ui-tab-btn.selected:focus {
        border-bottom: 2px solid var(--carrot-primary-color, #f05014);
    }

    .ui-tab-btn.selected {
        color: var(--carrot-font-color, #000);
        border-bottom: 2px solid var(--carrot-primary-color, #f05014);
        font-weight: 700;
        line-height: 1.1;
    }

    .type-small .ui-tab-btn {
        font-size: 14px;
    }

    .type-small .ui-tab-btn.selected {
        line-height: 1.4;
    }

    .ui-tab.type-linebox {
        position: relative;
        margin: 0;
    }

    .ui-tab.type-linebox .ui-floating-wrap {
        border-bottom: none;
        background: none;
    }

    .ui-tab.type-linebox .ui-tab-btns {
        position: relative;
        width: calc(100% - 10px);
        z-index: 1;
        margin-left: 1px;
        background: none;
        padding: 0 0 0 1px;
        border: 0;
    }

    .ui-tab.type-linebox .ui-tab-btn {
        position: relative;
        z-index: 0;
        font-size: 14px;
        color: var(--carrot-font-color3, #555);
        min-height: 42px;
        border-radius: 0;
        transition: box-shadow 0.1s ease-in, background-color 0.1s ease-in;
        border: 1px solid rgba(68, 68, 68, 0.99);
        background: var(--carrot-body-background, #fff);
        margin-left: -1px;
        line-height: 1.2;
    }

    .ui-tab.type-linebox .ui-tab-btn.txt-s {
        font-size: 13px;
        line-height: 1.2;
    }

    .ui-tab.type-linebox .ui-tab-btn:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
    }

    .ui-tab.type-linebox .ui-tab-btn:last-child {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    .ui-tab.type-linebox .ui-tab-btn i {
      display: inline-block;
      text-align: center;
      padding: 2px 0;
    }

    .ui-tab.type-linebox .ui-tab-btn.selected i {
        font-weight: 700;
        padding-top: 3px;
    }

    .ui-tab.type-linebox .ui-tab-btn.selected {
        z-index: 2;
    }

    .ui-tab.type-linebox .ui-tab-btn.selected {
        border-bottom: 2px solid var(--carrot-primary-color, #f05014);
        font-weight: 700;
    }

    .ui-tab.type-linebox .ui-tab-btn.selected:hover i,
    .ui-tab.type-linebox .ui-tab-btn.selected:focus i {
        font-weight: 700;
    }

    .ui-tab.type-linebox.tabs2 .ui-tab-btn {
        width: calc(50% + 1px);
    }

    .ui-tab.type-linebox.tabs3 .ui-tab-btn {
        width: calc(33.3% + 1px);
    }

    .ui-tab.type-linebox.tabs4 .ui-tab-btn {
        width: calc(25% + 1px);
        font-size: 14px;
        padding: 0;
    }

    .ui-tab.type-linebox.tabs4 .ui-tab-btn em {
        font-size: 12px;
    }

    .ui-tab.type-linebox .ui-tab-btn.selected {
        background: var(--carrot-body-background, #fff);
        color: var(--carrot-primary-color7, #e2470f);
        border-color: var(--carrot-primary-color, #f05014);
        font-weight: 700;
    }

    .ui-tab.type-linebox .ui-tab-btn.selected:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
    }

    .ui-tab.type-linebox .ui-tab-btn.selected:last-child {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    .ui-tab.type-sub {
        position: relative;
        margin: 0;
    }

    .ui-tab.type-sub .ui-floating-wrap {
        border-bottom: none;
        background: none;
    }

    .ui-tab.type-sub .ui-tab-btns {
        position: relative;
        z-index: 1;
        margin-left: 1px;
        background: none;
        padding: 0 0 0 1px;
        border: 0;
    }

    .ui-tab.type-sub .ui-tab-btn {
        position: relative;
        z-index: 0;
        flex: 0;
        font-size: 15px;
        color: #555;
        min-height: 38px;
        min-width: 104px;
        border-radius: 0;
        transition: box-shadow 0.1s ease-in, background-color 0.1s ease-in;
        border: 1px solid #ddd;
        border-radius: 0 !important;
        background: #fff;
        margin-left: -1px;
        line-height: 1.2;
    }

    .ui-tab.type-sub .ui-tab-btns:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: #ddd;
    }

    .ui-tab.type-sub .ui-tab-btn i {
        display: inline-block;
        padding: 0;
        text-align: center;
        padding: 2px 0;
    }

    .ui-tab.type-sub .ui-tab-btn.selected i {
        font-weight: 400;
        padding-top: 3px;
    }

    .ui-tab.type-sub .ui-tab-btn.selected {
        z-index: 2;
    }

    .ui-tab.type-sub .ui-tab-btn.selected {
        background: var(--carrot-primary-color, #f05014);
        color: #fff;
    }

    .ui-tab.type-sub .ui-tab-btn.selected:hover i,
    .ui-tab.type-sub .ui-tab-btn.selected:focus i {
        font-weight: 400;
    }

    .ui-tab-pnls {
        box-sizing: border-box;
        position: relative;
        width: 100%;
    }

    .ui-tab-pnl {
        box-sizing: border-box;
        top: 0;
        left: 0;
    }

    .ui-tab-pnl,
    .ui-tab-pnl[aria-hidden='true'] {
        visibility: hidden;
        position: absolute;
        opacity: 0;
        display: none;
    }

    .ui-tab-pnl.selected,
    .ui-tab-pnl[aria-hidden='false'] {
        visibility: visible;
        position: relative;
        opacity: 1;
        /*z-index:1;*/
        display: block;
    }

    .ui-tab-pnl {
        width: 100%;
    }

    .ui-tab.eff-fade .ui-tab-pnl {
        @include vendorPrefix(transition, opacity 0.3s);
    }

    .ui-floating .ui-floating-wrap .ui-tab-btns {
        padding: 0 16px;
        width: 100%;
        margin: 0;
    }

    .ui-floating .ui-floating-wrap .ui-tab-btn.badge .txt-tag {
        position: absolute;
        font-size: 10px;
        top: -3px;
        left : 50%;
        margin : 0;
        transform: translateX(-50%);
        background: #d7d7d7;
    }

    .ui-floating .ui-floating-wrap .ui-tab-btn.badge.selected .txt-tag {
        background : #686868;
    }

    .wrap-inner.pd-n .tab-scroll .ui-tab-btns {
        padding: 0 32px;
        width: 100%;
        margin: 0;
    }

    .wrap-inner.pd-n .tab-scroll .ui-tab-btns .tab-scroll-wrap:after {
        content: "";
        display: inline-block;
        width: 32px;
        flex: 0 0 32px;
    }

    .tab-scroll .ui-tab-btns {
        overflow-x: scroll;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        width: calc(100% + 19px);
        margin-left: -5px;
    }

    .tab-scroll::after {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        right: -31px;
        width: 120px;
        height: 51px;
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, var(--carrot-body-background, #fff) 80%);
        pointer-events: none;
        z-index: 1;
    }

    .ui-tab-pnls .ui-tab-btns {
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        width: auto;
        margin-left: 0;
    }

    .ui-tab-btn {
        letter-spacing: -0.05em;
    }

    .tab-scroll-wrap {
        white-space: nowrap;
        display: flex;
        flex-wrap: nowrap;
        padding: 1px 1px 0;
    }

    .tab-scroll-wrap .ui-tab-btn {
        flex: 1 0 80px;
        padding: 0 5px;
        min-width: 100px;
        white-space: initial;
        word-break: inherit;
    }

    .tab-scroll .ui-tab-pnls {
        border: 0;
        margin-top: 0;
    }

    .tab-sort {
        position: relative;
        margin-bottom: 20px;
        margin-top: 36px;
    }

    .tab-sort button {
        position: relative;
        font-size: 14px;
        color: var(--carrot-font-color3, #555);
        padding: 0 10px;
        font-weight: normal;
        border-bottom: 0;
    }

    .tab-sort button:after {
        content: '';
        position: absolute;
        display: block;
        right: 0;
        top: 50%;
        width: 1px;
        height: 12px;
        background: #d9d9d9;
        margin-top: -5px;
    }

    .tab-sort button:last-child {
        padding-right: 0;
    }

    .tab-sort button:last-child:after {
        display: none;
    }

    .tab-sort button.selected {
        font-weight: 700;
        color: var(--carrot-primary-color7, #e2470f);
    }

    .keypad-wrap {
        width: 100%;
        flex-wrap: wrap;
        border-top: 1px solid #ddd;
    }

    .keypad-wrap button {
        flex: 0 0 25%;
        font-size: 20px;
        color: var(--carrot-font-color, #000);
        height: 55px;
        border: 1px solid #eee;
        border-left: 0;
        border-top: 0;
        font-weight: 500;
    }

    .keypad-wrap button:nth-child(4n) {
        border-right: 0;
    }

    .keypad-wrap button:disabled {
        background: var(--carrot-body-background, #fff) url('https://www.carrotins.com/static/images/common/keypad_disabled.png') no-repeat 50% 50%;
        background-size: 30px;
    }

    .keypad-wrap .redistribution {
        flex: 0 0 33%;
        font-size: 15px;
        background: var(--carrot-body-background, #fff);
        color: var(--carrot-font-color3, #555);
        border: 0;
        height: 55px;
        border-right: 1px solid #eee;
    }

    .keypad-wrap .confirm {
        flex: 0 0 34%;
        font-size: 15px;
        background: var(--carrot-primary-color, #f05014);
        color: #fff;
        border: 0;
        height: 55px;
    }

    .keypad-wrap .del {
        flex: 0 0 33%;
        font-size: 0;
        color: #fff;
        border: 0;
        background-size: 35px;
        height: 55px;
        border-right: 1px solid #eee;
    }

    .keypad-wrap .del:after {
        content: "";
        display: inline-block;
        width: 40px;
        height: 40px;
        background: var(--carrot-body-background, #fff) url(/static/images/common/keypad_del_m.png) no-repeat 0 0;
        background-size: 40px 80px;
        vertical-align: middle;
    }

    .keypad-wrap .del:focus:after,
    .keypad-wrap .del:active:after {
        background-position: 0 -40px;
    }

    .keypad-txt {
        width: 100%;
        text-align: center;
        min-height: 38px;
        padding: 8px 0 0;
        background-color: var(--carrot-body-background, #fff);
        border-top: 1px solid #ddd;
    }

    .keypad-txt span {
        display: inline-block;
        text-align: center;
        font-size: 15px;
        line-height: 1;
        letter-spacing: 0.5em;
        color: var(--carrot-font-color, #000);
        padding-left: 10px;
    }

    /* ui tooltip:z-index 200 ~ 299 */
    .ui-tooltip {
        display: none;
        position: absolute;
        z-index: 200;
        font-size: 11px;
        border: 1px solid var(--carrot-font-color3, #555);
        padding: 10px;
        background: #fff;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    }

    .ui-tooltip.ps-ct .tt-arrow,
    .ui-tooltip.ps-cb .tt-arrow,
    .ui-tooltip.ps-lt .tt-arrow,
    .ui-tooltip.ps-lb .tt-arrow,
    .ui-tooltip.ps-rt .tt-arrow,
    .ui-tooltip.ps-rb .tt-arrow {
        content: "";
        position: absolute;
        display: block;
        width: 10px;
        height: 10px;
        background: #fff;
        border-top: 1px solid #222;
        border-right: 1px solid #222;
    }

    .ui-tooltip.ps-ct .tt-arrow {
        left: 50%;
        bottom: -6px;
        transform: rotate(135deg);
        margin-left: -5px;
    }

    .ui-tooltip.ps-lt .tt-arrow {
        left: 0;
        bottom: -6px;
        transform: rotate(135deg);
        margin-left: 10px;
    }

    .ui-tooltip.ps-rt .tt-arrow {
        right: 0;
        bottom: -6px;
        transform: rotate(135deg);
        margin-right: 10px;
    }

    .ui-tooltip.ps-cb .tt-arrow {
        left: 50%;
        top: -6px;
        transform: rotate(-45deg);
        margin-left: -5px;
    }

    .ui-tooltip.ps-lb .tt-arrow {
        left: 0;
        top: -6px;
        transform: rotate(-45deg);
        margin-left: 10px;
    }

    .ui-tooltip.ps-rb .tt-arrow {
        right: 0;
        top: -6px;
        transform: rotate(-45deg);
        margin-right: 10px;
    }

    .list-contract .register-photo-wrap .btn-wrap {
        padding: 0;
    }

    .list-contract>.btn-wrap {
        border-top: 10px solid var(--carrot-line-color2, #d9d9d9);
    }

    .list-contract-item {
        position: relative;
        padding: 10px 16px 28px;
    }

    .list-contract-item.list-none {
        padding: 57px 32px;
        text-align: center;
    }

    .list-contract-item .btn-base {
        position: absolute;
        top: 34px;
        right: 0;
        width: 100%;
        height: 45px;
        border: 0;
        background: transparent;
        text-align: right;
        padding: 0 30px;
    }

    .list-contract-item .ico-arrow {
        top: 50%;
        margin-top: -9px;
        vertical-align: top;
    }

    .list-contract-item .ico-arrow:before,
    .list-contract-item .ico-arrow:after {
        background-color: #000;
    }

    .list-contract-item.no-line {
        border-top: 0;
    }

    .list-contract-item.last-child {
        border-bottom: 0;
    }

    .list-contract-tit {
        text-align: left;
        position: relative;
        padding: 24px 0 26px 0;
        border-bottom: 1px solid var(--carrot-line-color2, #d9d9d9);
    }

    .list-contract-tit h3 {
        font-size: 18px;
        font-weight: 700;
        color: var(--carrot-font-color, #000);
        letter-spacing: -0.07em;
    }

    .list-contract-tit .ico-badge {
        position: relative;
        display: inline-block;
        height: 20px;
        font-size: 12px;
        color: #fff;
        font-weight: 400;
        background: #c2c2c2;
        line-height: 20px;
        border-radius: 20px;
        padding: 0 10px;
        margin-left: 6px;
        vertical-align: bottom;
        margin-bottom: 2px;
    }

    .list-contract .tbl-noline {
        margin-top: 19px;
    }

    .list-contract .tbl-noline th,
    .list-contract .tbl-noline td {
        padding: 9px 0;
    }

    .list-contract .tbl-noline dl::after {
        content: '';
        display: block;
        clear: both;
    }

    .list-contract .tbl-noline dt {
        float: left;
    }

    .list-contract .tbl-noline dd {
        float: right;
        padding-top: 12px;
        padding-right: 4px;
    }

    .list-contract .tbl-noline dd>.btn-txt-arrow>span {
        padding-right: 0;
    }

    .list-contract .btn-wrap {
        padding: 30px 0 50px;
        margin: 0;
    }

    .list-contract .list-contract-link {
        position: relative;
        display: block;
        width: 100%;
        text-align: left;
        text-decoration: none;
        color: var(--carrot-font-color3, #555);
        padding: 8px 30px 8px 0;
        font-size: 13px;
    }
    .list-contract .list-contract-link p {
        margin-top: 6px;
    }
    .list-contract .list-contract-link .ico-arrow {
        position: absolute;
        right: 0px;
    }

    .list-contract.type-acco .list-contract-item {
        padding: 35px 0 40px;
    }

    .list-contract.type-acco .list-contract-item:first-child {
        border-top: 0;
        padding-top: 10px;
    }

    .list-contract.type-acco .list-contract-item:last-child {
        padding-bottom: 50px;
    }

    .list-contract.type-acco .list-contract-tit {
        padding: 0 32px;
        border: 0;
    }

    .list-contract.type-acco .list-contract-tit h3 {
        margin-top: 0;
    }

    .list-content-item:first-child {
        margin-top: 0;
    }

    .list-content-item {
        position: relative;
        margin-top: 10px;
        padding: 0;
        background-color: var(--carrot-body-background, #fff);
        border-radius: 6px;
    }

    // .list-content-item .btn-base { position: absolute; top:34px; right:0; width:100%; height:45px; border:0; background:transparent; text-align: right; padding:0 30px;}
    .list-content-item .ico-arrow {
        top: 50%;
        margin-top: -9px;
        vertical-align: top;
    }

    .list-content-item .list-agree-sel .ico-arrow {
        margin-top: 0;
    }

    .list-content-item .ico-arrow:before,
    .list-content-item .ico-arrow:after {
        background-color: #000;
    }

    .list-content-tit {
        text-align: left;
        position: relative;
        padding: 14px 25px 12px;
        border-bottom: 1px solid var(--carrot-line-color2, #d9d9d9);
    }

    .list-content-tit:after {
        content: "";
        display: block;
        clear: both;
    }

    .list-content-tit h3 {
        float: left;
        font-size: 15px;
        font-weight: 700;
        color: var(--carrot-font-color, #000);
        line-height: 1.3;
        letter-spacing: -0.07em;
    }

    .list-content-tit h3+.btn-base-s {
        position: absolute;
        right: 0;
        top: -8px;
    }

    .wrap-inner-bg .list-content .box-base {
        background: var(--carrot-content-background, #f8f8f8);
    }

    .wrap-inner-bg .list-content .box-base.type-acco {
        padding: 25px;
    }

    .wrap-inner-bg .list-content .box-base.type-acco .ui-acco-wrap,
    .wrap-inner-bg .list-content .box-base.type-acco .ui-acco-btn {
        padding: 0;
    }

    .wrap-inner-bg .list-content .box-base.type-acco .ui-acco-tit,
    .wrap-inner-bg .list-content .box-base.type-acco .ui-acco-tit h3 {
        margin-bottom: 0;
    }

    .wrap-inner-bg .list-content .box-base.type-acco .ui-acco-tit .total {
        float: right;
        font-size: 15px;
        margin-right: 38px;
        margin-top: 1px;
    }

    .wrap-inner-bg .list-content .box-base.type-acco .tbl-noline {
        border-top: 1px var(--carrot-line-color2, #d9d9d9) solid;
        padding-top: 26px;
        margin-top: 26px;
    }

    .ui-fixed-top+.wrap-inner-bg {
        padding-top: 81px !important;
    }

    .list-content-tit .btn-txt {
        margin-top: 4px;
    }

    .list-content-tit .btn-txt {
        margin-top: 4px;
    }

    .list-content .btn-wrap {
        padding: 30px 0 50px;
        margin: 0;
    }

    /* list-content type-info */
    .list-content.type-info>.btn-wrap {
        border-top: 10px solid var(--carrot-line-color2, #d9d9d9);
    }

    .list-content.type-info .list-content-item {
        margin-top: 0;
        padding: 35px 31px 40px;
        border-top: 10px solid var(--carrot-line-color2, #d9d9d9);
        border-radius: 0;
    }

    .list-content.type-info .list-content-tit {
        padding: 0 0 11px;
        color: var(--carrot-font-color, #000);
        border: 0 none;
    }

    .list-content.type-info .list-content-tit h2 {
        font-weight: 700;
        font-size: 18px;
    }

    .list-content.type-info .list-content-item .box-base {
        margin-top: 10px;
    }

    .list-content.type-info .list-content-item .box-base:first-child {
        margin-top: 0;
    }

    .list-agree .lb-radio,
    .list-agree .lb-check {
        padding: 0;
    }

    /* 증권발송 배너 */
    .bnr-wrap {
        position: relative;
        margin-top: 36px;
    }

    .bnr-wrap .bnr-tit {
        font-size: 15px;
        color: var(--carrot-font-color, #000);
        font-weight: 500;
    }

    .bnr-wrap .bnr-info {
        margin-top: 16px;
    }

    /* 라디오선택 유형 */
    .type-lb-case .lb-radio,
    .type-lb-case .lb-check {
        width: 100%;
        margin-bottom: 20px;
        padding-right: 60px;
        line-height: 24px;
        /*align-items: flex-start; -webkit-box-align:start; -ms-flex-align:start; */
        ;
    }

    .type-lb-case .lb-radio:before,
    .type-lb-case .lb-check:before {
        margin-right: 12px;
    }

    .type-lb-case .lb-radio em {
        display: block;
        font-size: 22px;
        line-height: 28px;
        margin-top: -3px;
        color: var(--carrot-font-color, #000);
    }

    .type-lb-case .lb-radio em.inline {
        display: inline;
        line-height: 1;
    }

    .type-lb-case .lb-radio em.inline+.btn-tip {
        vertical-align: baseline;
    }

    .type-lb-case .lb-radio i {
        display: block;
        margin-top: 5px;
        font-size: 15px;
    }

    .type-lb-case .lb-radio b {
        position: absolute;
        top: 10px;
        right: 0;
        width: 50px;
        font-size: 22px;
        color: var(--carrot-font-color, #000);
        text-align: right;
    }

    .type-lb-case .lb-radio b:before {
        @include bar-border(16px, var(--carrot-line-color1, #c4c4c4));
        top: 50%;
        margin-top: -8px;
    }

    .lb-group {
        position: relative;
    }

    .lb-group .lb-radio,
    .lb-group .lb-check {
        padding-top: 0;
    }

    .lb-group .btn-txt {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
    }

    .type-lb-cont {
        padding-left: 40px;
    }

    /* ui slider */
    .ui-slider {
        position: relative;
        width: 100%;
        padding: 50px 50px 0;
    }

    .ui-slider-wrap {
        position: relative;
        width: 100%;
        padding: 5px 0;
        z-index: 1;
    }

    .ui-slider-bg {
        position: relative;
        width: 100%;
        background-color: #ddd;
        height: 10px;
        border-radius: 5px;
    }

    .ui-slider-bar {
        position: absolute;
        width: 0;
        height: 100%;
        top: 0;
        left: 50%;
        background: var(--carrot-slider-color, #fecbba);
        border-radius: 5px;
        z-index: 2;
        transition: all 0.2s;
    }

    [class^="ui-slider-btn-"] {
        position: absolute;
        top: 0;
        background: var(--carrot-primary-color, #f05014);
        width: 20px;
        height: 20px;
        text-align: left;
        text-indent: -9999px;
        border-radius: 10px;
        z-index: 3;
        transition: left 0.2s;
        margin-left: -10px;
    }

    [class^="ui-slider-btn-"]:focus,
    [class^="ui-slider-btn-"].on {
        z-index: 4;
    }

    [class^="ui-slider-btn-"] span {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .ui-slider-divs {
        position: relative;
        z-index: 0;
        width: 100%;
        height: 30px;
    }

    .ui-slider-div {
        position: absolute;
        bottom: 0;
        padding: 0;
        z-index: 1;
        display: block;
        height: 30px;
    }

    .ui-slider-div em {
        display: block;
        text-align: center;
        width: 100%;
        margin-top: 10px;
        box-sizing: border-box;
        white-space: nowrap;
        font-size: 15px;
    }

    .ui-slider-div:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: none;
        width: 1px;
        height: 10px;
        border-left: 1px solid #ccc;
    }

    .ui-slider-tooltip {
        position: absolute;
        top: -30px;
        overflow: hidden;
        text-align: center;
        color: var(--carrot-primary-color7, #e2470f);
        padding: 5px 0;
        z-index: 1;
        white-space: nowrap;
        transition: all 0.2s;
        font-size: 15px;
    }

    .mileage-slider {
        padding: 20px 0 30px;
    }
    .mileage-slider--car {
        padding-bottom:0;
    }
    .mileage-slider--car [class^="ui-slider-btn-"] {
        width: 28px;
        height: 16px;
        background: url(/static/images/common/bg_arrow_ui-slider.png) 0 0 no-repeat;
        background-size: 28px;
        top: 2px;
    }
    .mileage-slider--car .ui-slider-btns {
        display: none;
    }
    .mileage-slider .ui-slider-down {
        top: 0;
        right: 50%;
        margin-right: 12px;
    }

    .mileage-slider .ui-slider-up {
        top: 0;
        left: 50%;
        margin-left: 12px;
    }

    .mileage-slider .ui-slider-div em {
        width: 70px;
        color: var(--carrot-font-color4, #777);
        font-size: 14px;
        margin-left: -35px;
        text-align: center;
    }

    .mileage-slider .ui-slider-div:first-child em {
        margin-left: 0;
        text-align: left;
    }

    .mileage-slider .ui-slider-div:last-child em {
        margin-left: -70px;
        text-align: right;
    }

    .ui-slidepage {
        position: relative;
        width: 100%;
        height: 100%;
        top: 0;
    }

    .ui-slidepage-wrap.ontran {
        transition: left .2s cubic-bezier(.87, .06, .82, .58);
    }

    .ui-slidepage-wrap {
        position: relative;
        width: 100%;
        left: 0;
    }

    .ui-slidepage-item {
        position: absolute;
        display: none;
        width: 100%;
        top: 0;
    }

    .ui-slidepage-item.on {
        display: block;
    }

    /* 보상처리절차안내 */
    .step-process-img {
        position: relative;
        width: 100%;
        margin: 35px 0 30px;
    }

    .step-process-img img {
        width: 100%;
    }

    .step-process-img .bul-etc>li {
        line-height: 1.2;
        padding-left: 15px;
    }

    .step-process-img .bul-etc>li .bul-ico {
        margin: 0 0 0 -15px;
        width: 15px;
    }

    .step-process-img .n1 {
        position: absolute;
        bottom: 94%;
        left: 5%;
    }

    .step-process-img .n2 {
        position: absolute;
        bottom: 94%;
        left: 45%;
    }

    .step-process-img .n3 {
        position: absolute;
        bottom: 65%;
        left: 30%;
    }

    .step-process-img .n4 {
        position: absolute;
        bottom: 37%;
        left: 19%;
    }

    .step-process-img .n5 {
        position: absolute;
        bottom: 7%;
        right: 10%;
        width: 30%;
        min-width: 85px;
    }

    .step-process-img .n6 {
        position: absolute;
        bottom: 7%;
        left: 10%;
        width: 30%;
        min-width: 96px;
    }

    .slider-wrap.type-main {
        padding: 0;
        position: relative;
    }

    .main-hashtag {
        width: 100%;
        min-width: 280px;
        padding: 0;
        margin-bottom: 0;
    }

    .main-hashtag a {
        display: inline-block;
        background: #fff;
        border: 1px solid #eee;
        color: #bbb;
        text-align: center;
        font-size: 14px;
        height: 24px;
        padding: 2px 4px 0;
        margin: 4px 3px;
        border-radius: 4px;
        text-decoration: none;
    }

    .main-hashtag a:hover,
    .main-hashtag a:focus {
        border-color: var(--carrot-primary-color, #f05014);
        color: var(--carrot-primary-color, #f05014);
        font-weight: 600;
    }

    .slider-wrap.type-main .slider-item>div {
        min-width: 100%;
        padding: 0;
        text-align: center;
    }

    .slider-item {
        outline: 0;
    }

    .slider-item .btn-wrap {
        padding: 0 17px;
    }

    .main-ani-tit {
        width: 100%;
        display: inline-block;
        /*padding:0 30px;*/
        position: relative;
        margin-top: 0;
        text-align: center;
    }

    .main-ani-tit p {
        margin: 0 0 10px;
        min-width: 300px;
        font-size: 16px;
        letter-spacing: -0.075em;
        display: block;
        border-bottom: 1px solid #dedede;
        padding: 0 0 10px;
        color: var(--carrot-font-color, #000);
    }

    .main-ani-tit strong {
        text-align: center;
        display: inline-block;
        font-size: 37px;
        font-weight: 400;
        color: var(--carrot-font-color, #000);
        letter-spacing: -0.075em;
        line-height: 1.1;
        margin-bottom: 5vh;
        text-align: left;
    }

    .main-ani-tit strong span {
        display: inline-block;
        width: 100%;
        text-align: center;
    }

    .main-ani-tit strong b {
        font-weight: 700;
    }

    .main-ani-wrap {
        flex: 0 100%;
    }

    .main-ani-img {
        width: 100%;
        height: 32vh;
        text-align: center;
    }

    .main-ani-img img {
        height: 100%;
        display: block;
        margin: 0 auto;
    }

    .main-ani-wrap.type-pet {
        width: 100%;
        height: auto;
        margin: 0 auto 0;
    }

    .type-pet .main-ani-img {
        width: 100%;
        height: 43vh;
        margin: 0 auto 0;
        background: url(/static/images/main/pet_1.png) no-repeat 50% 100%;
        background-size: contain;
    }

    .type-main.slider-wrap .slick-slider {
        height: 100%;
    }

    .type-main.slider-wrap .slick-slide {
        height: calc(100% - 120px);
    }

    .list-base.type-a .item-wrap:before {
        height: 1.5vh;
    }

    /* 이전글/다음글 */
    .view-wrap+.view-paging {
        border-top: 0;
    }

    .view-paging {
        border-top: 1px solid var(--carrot-line-color2, #d9d9d9);
        margin-bottom: 20px;
    }

    .view-paging dl {
        padding: 18px 0;
        border-bottom: 1px solid var(--carrot-line-color2, #d9d9d9);
    }

    .view-paging dl:after {
        content: '';
        display: block;
        clear: both;
    }

    .view-paging dt {
        float: left;
        width: 90px;
        font-weight: 700;
        padding-left: 30px;
    }

    .view-paging dd {
        float: left;
        @include ellipse-one($wid:calc(100% - 120px));
        padding-right: 10px;
    }

    .view-paging dd a {
        color: var(--carrot-font-color3, #555);
        text-decoration: none;
    }

    .view-paging+.btn-wrap-fixed {
        margin-top: 80px;
    }

    .type-accident .inp-base {
        width: 33.3%;
    }

    .type-accident .inp-base:last-child {
        width: calc(33.3% - 10px);
        margin-left: 10px;
    }

    .type-accident .ui-select {
        width: calc(33.3% - 10px);
        margin-left: 10px;
    }

    /* 운전자 정보 */
    .select-driver {
        margin-top: 24px;
    }

    .driver-info {
        position: relative;
        border-top: 1px #ddd solid;
    }

    .driver-info .info-txt {
        font-size: 16px;
    }

    .driver-info .txt-w6 {
        color: var(--carrot-font-color, #000);
    }

    .driver-info .bul-care {
        font-size: 14px;
        color: var(--carrot-primary-color7, #e2470f);
    }

    .driver-info .bul-care:before {
        top: 4px;
        background-position: -5px -30px;
    }

    .driver-value {
        font-size: 18px;
        font-weight: 700;
        color: var(--carrot-primary-color, #f05014);
        margin-left: 5px;
        padding-top: 1px;
        line-height: 1;
    }

    /* 운전자 정보 - 보험료 확인 */
    .plan-info {
        position: relative;
        padding: 26px 0;
        border-top: 1px var(--carrot-line-color2, #d9d9d9) solid;
    }

    .plan-info.nobrd {
        border-top: 0;
    }

    .plan-info h3 {
        font-size: 16px;
        color: var(--carrot-font-color4, #777);
        font-weight: 700;
    }

    .plan-info p {
        font-size: 15px;
        color: var(--carrot-font-color4, #777);
        margin-top: 10px;
        word-break: break-word;
    }

    .plan-info .btn-wrap {
        width: auto;
        margin: 0;
        padding-left: 40px;
    }

    .plan-info .btn-base {
        min-height: 30px;
    }

    .plan-info+.bul-dot .txt-r {
        color: var(--carrot-font-color, #000);
    }

    /* 자동차보험 E-Call 안심서비스 */
    .ecall-wrap {
        position: relative;
        display: table;
        width: 100%;
        margin-top: 35px;
    }

    .ecall-wrap .ecall-ico {
        position: relative;
        display: table-cell;
        width: 80px;
        height: 80px;
    }

    .ecall-wrap .ecall-ico span {
        position: relative;
        display: block;
        width: 80px;
        height: 80px;
        background: url('/static/images/content/ico_ecall@2x.png') no-repeat;
        background-size: 160px 80px;
    }

    .ecall-wrap .ecall-ico .ico-car {
        background-position: 0 0;
    }

    .ecall-wrap .ecall-ico .ico-symbol {
        background-position: -80px 0;
    }

    .ecall-wrap .ecall-cont {
        position: relative;
        display: table-cell;
        width: calc(100% - 160px);
        height: 80px;
        vertical-align: middle;
    }

    .ecall-wrap .ecall-cont>p {
        position: relative;
        display: block;
        width: 100%;
        text-align: center;
        color: var(--carrot-font-color, #000);
    }

    .ecall-wrap .ecall-cont>p.txt {
        padding-bottom: 16px;
        margin-bottom: 12px;
    }

    .ecall-wrap .ecall-cont>p.txt::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 10px;
        width: calc(100% - 20px);
        height: 2px;
        background: var(--carrot-line-color1, #c4c4c4);
    }

    .ecall-wrap .ecall-cont>p.txt::after {
        content: '';
        position: absolute;
        bottom: -5px;
        right: 3px;
        width: 0;
        height: 0;
        border: 6px transparent solid;
        border-left: 6px var(--carrot-line-color1, #c4c4c4) solid;
    }

    .ecall-wrap .ecall-cont>p.txt2 {
        padding-top: 16px;
        margin-top: 12px;
    }

    .ecall-wrap .ecall-cont>p.txt2::before {
        content: '';
        position: absolute;
        top: 0;
        left: 10px;
        width: calc(100% - 20px);
        height: 2px;
        background: var(--carrot-line-color1, #c4c4c4);
    }

    .ecall-wrap .ecall-cont>p.txt2::after {
        content: '';
        position: absolute;
        top: -5px;
        left: 3px;
        width: 0;
        height: 0;
        border: 6px transparent solid;
        border-right: 6px var(--carrot-line-color1, #c4c4c4) solid;
    }

    .ecall-wrap .ecall-cont .ico-phone {
        position: relative;
        display: inline-block;
        width: 11.5px;
        height: 16px;
        background: url('/static/images/content/ico_phone.png') no-repeat;
        background-size: 100%;
        vertical-align: middle;
        margin-top: -2px;
        margin-right: 7px;
    }

    /* 주소검색 */
    .srch-result.type-address .result-item {
        border-bottom: 1px var(--carrot-line-color1, #c4c4c4) solid;
        padding: 0 8px;
    }

    .srch-result.type-address .result-item:hover {
        background: #f6f6f6;
    }

    .srch-result.type-address .result-item-tit {
        position: relative;
        font-size: 16px;
        color: var(--carrot-font-color, #000);
        padding-top: 18px;
    }

    .srch-result.type-address .result-item-cont {
        position: relative;
        margin: 15px 0 22px;
    }

    .srch-result.type-address .result-item-cont>dl {
        position: relative;
        display: table;
        width: 100%;
    }

    .srch-result.type-address .result-item-cont>dl+dl {
        margin-top: 10px;
    }

    .srch-result.type-address .result-item-cont dt {
        position: relative;
        display: table-cell;
        width: 60px;
        font-size: 13px;
    }

    .srch-result.type-address .result-item-cont dd {
        position: relative;
        display: table-cell;
        width: calc(100% - 60px);
        font-size: 16px;
        color: var(--carrot-font-color, #000);
    }

    /* 보상서비스 - 청구내역조회 */
    .type-situation {
        position: relative;
        width: auto !important;
    }

    .h1>span+.type-situation {
        vertical-align: text-bottom;
        margin-top: -5px;
    }

    .h1>span {
        display: inline-block;
    }

    .h1>span.hue-p {
        color: var(--carrot-primary-color, #f05014) !important;
    }

    /* 보상서비스 - 사고처리결과 process */
    .badge-process {
        position: relative;
        display: inline-block;
        font-size: 12px;
        color: #fff;
        font-weight: normal;
        line-height: 17px;
        background: var(--carrot-primary-color7, #e2470f);
        padding: 0 15px;
        border-radius: 34px;
        margin-left: 8px;
        margin-top: 3px;
    }

    .badge-process.comp {
        background: var(--carrot-font-color3, #555);
    }

    .car-process-wrap {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        border-top: 1px var(--carrot-line-color1, #c4c4c4) solid;
        padding-top: 30px;
    }

    .car-process-wrap .car-process-item {
        position: relative;
        box-sizing: content-box;
    }

    .car-process-wrap .car-process-item span {
        display: block;
        text-align: center;
        font-size: 13px;
        margin-top: 13px;
    }

    .car-process-wrap .car-process-item .item-circle {
        position: relative;
        display: block;
        width: 40px;
        height: 40px;
        font-size: 14px;
        font-weight: 700;
        text-align: center;
        line-height: 38px;
        background: #fff;
        border-radius: 40px;
        border: 1px var(--carrot-line-color1, #c4c4c4) solid;
        margin: 0 auto;
        z-index: 1;
    }

    .car-process-wrap .car-process-item.now {
        color: var(--carrot-primary-color, #f05014);
        border-color: var(--carrot-primary-color, #f05014);
    }

    .car-process-wrap .car-process-item.now .item-circle {
        color: #fff;
        background: var(--carrot-primary-color, #f05014);
        border-color: transparent;
    }

    .car-process-wrap .car-process-item::after {
        content: '';
        position: absolute;
        display: block;
        top: 18px;
        height: 4px;
        background: url('/static/images/content/pointline.png') repeat-x;
        background-size: 7.5px;
    }

    .car-process-wrap .car-process-item:last-child::after {
        display: none;
    }

    .car-process-wrap.type-step4 .car-process-item {
        width: 25%;
    }

    .car-process-wrap.type-step3 .car-process-item {
        width: 33.3%;
    }

    .car-process-wrap.type-step4 .car-process-item::after {
        right: -9.5px;
        width: 19px;
    }

    .car-process-wrap.type-step3 .car-process-item::after {
        right: -21px;
        width: 42px;
    }

    .car-process-wrap.no-line {
        border-top: 0;
        padding-top: 5px;
    }

    /* 보상서비스 - 사고유형 */
    .fx-wrap.type-accident .field-inlabel {
        padding: 0;
    }

    .fx-wrap.type-accident .lb-radio {
        display: block;
        padding-top: 0;
    }

    .fx-wrap.type-accident .lb-radio::before {
        position: absolute;
        top: 0;
        left: 0;
    }

    .fx-wrap.type-accident .lb-radio>span {
        margin-left: 30px;
    }

    .fx-wrap.type-accident .lb-radio>i {
        position: relative;
        display: block;
        width: 100%;
        height: 100px;
        border-radius: 3px;
        border: 1px #777 solid;
        margin-top: 10px;
        text-align: center;
        line-height: 100px;
    }

    .fx-wrap.type-accident .lb-radio>i::after {
        content: '';
        display: inline-block;
        width: 100px;
        height: 50px;
        background-image: url('/static/images/content/ico_accident@2x.png');
        background-repeat: no-repeat;
        background-size: 200px 100px;
        vertical-align: middle;
    }

    .fx-wrap.type-accident .lb-radio>.vs-bicycle::after {
        background-position-x: -100px;
    }

    .fx-wrap.type-accident .lb-radio>.vs-human::after {
        background-position: 0 -50px;
    }

    .fx-wrap.type-accident .lb-radio>.vs-etc::after {
        background-position: -100px -50px;
    }

    .fx-wrap.type-accident input:checked+.lb-radio>i {
        border-color: var(--carrot-primary-color, #f05014);
    }

    /* 계약취소(개시 전) */
    .box-base.list-dlsel.type-result .tit,
    .box-base.list-dlsel.type-result .price b.hue-p {
        letter-spacing: -1.5px;
    }

    /* 증명서 발급 */
    .box-base.list-base {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .box-base.list-base li {
        border-top: 1px solid var(--carrot-line-color1, #c4c4c4);
    }

    .box-base.list-base li:first-child {
        border-top: 0;
    }

    .box-base.list-base .btn-link-full {
        position: relative;
        width: 100%;
        font-size: 16px;
        color: var(--carrot-font-color, #000);
        text-align: left;
        padding: 16px 0;
    }

    .box-base.list-base .btn-link-full::after {
      content: '';
      position: absolute;
      width: 7.5px;
      height: 7.5px;
      -webkit-transform: rotate(45deg) translateY(-50%);
      -ms-transform: rotate(45deg) translateY(-50%);
      transform: rotate(45deg) translateY(-50%);
      border-top: 1px solid var(--carrot-font-color3, #555);
      border-right: 1px solid var(--carrot-font-color3, #555);
      border-left: 0px;
      right: 10px;
      margin-top: 10px;
    }

    /* 고객센터-이벤트 */
    .type-notice .tab-sort {
        margin-top: 44px;
    }

    .list-base.type-event li {
        position: relative;
        border-top: 1px var(--carrot-line-color2, #d9d9d9) solid;
    }

    .list-base.type-list01 {
        padding-top: 6px;
        border-top: 1px var(--carrot-line-color2, #d9d9d9) solid;
    }

    .list-base.type-list01 li {
        position: relative;
    }

    .list-base.type-event li:last-child {
        border-bottom: 1px var(--carrot-line-color2, #d9d9d9) solid;
    }

    .list-base.type-event li.nothing,
    .list-base.type-list01 li.nothing {
        color: #999;
        text-align: center;
        padding: 50px 0;
    }

    .list-base.type-event a {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        min-height: 160px;
        padding: 24px 32px;
        border: 0;
        text-decoration: none;
        align-items: center;
    }

    .list-base.type-list01 a {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        padding: 20px 28px;
        border: 0;
        text-decoration: none;
        align-items: flex-start;
    }

    .list-base.type-event .thumbnail {
        position: relative;
        width: 98px;
        height: 98px;
        overflow: hidden;
        margin-right: 20px;
        background: #f1f1f1;
    }
    .list-base.type-list01 .thumbnail {
        position: relative;
        width: 112px;
        height: 79px;
        overflow: hidden;
        margin-right: 12px;
        background: #fff;
        border: 1px solid #EEEEEE;
        box-sizing: border-box;
    }

    .list-base.type-event .thumbnail img,
    .list-base.type-list01 .thumbnail img {
        width: 100%;
    }

    .list-base.type-event .cont {
        position: relative;
        width: calc(100% - 118px);
    }

    .list-base.type-list01 .cont {
        position: relative;
        width: calc(100% - 124px);
    }

    .list-base.type-event .cont .badge  {
        position: relative;
        display: inline-block;
        min-width: 60px;
        height: 17px;
        font-size: 12px;
        color: #fff;
        text-align: center;
        line-height: 17px;
        padding: 0 14px;
        border-radius: 17px;
    }

    .list-base.type-list01 .cont .badge {
        position: relative;
        display: inline-block;
        min-width: 48px;
        height: 22px;
        font-size: 10px;
        line-height: 22px;
        color: #C5C5C5;
        text-align: center;
        border-radius: 3px;
        border: 1px solid #6e6e6e;
        color: #6e6e6e;
    }

    .list-base.type-event .cont .badge.ing {
        background: var(--carrot-primary-color, #f05014);
    }

    .list-base.type-list01 .cont .badge.ing {
        color: var(--carrot-primary-color, #f05014);
        border-color: var(--carrot-primary-color, #f05014);
        background: #fff;
    }

    .list-base.type-event .cont .badge.result  {
        background: var(--carrot-secondary-color, #00358e);
    }

    .list-base.type-list01 .cont .badge.result {
        border-color: var(--carrot-secondary-color, #00358e);
        color: var(--carrot-secondary-color, #00358e);
        background: #fff;
        padding: 0 3px;
    }

    .list-base.type-event .cont .badge.end {
        background: #6e6e6e;
    }

    .list-base.type-list01 .cont .badge.end {
        background: var(--carrot-body-background, #fff);
        border-color: #eee;
        color: #c5c5c5;
    }

    .list-base.type-event h2,
    .list-base.type-list01 h2 {
        font-size: 18px;
        color: var(--carrot-font-color, #000);
        font-weight: 700;
        margin-top: 10px;
        letter-spacing: -0.83px;
    }

    .list-base.type-list01 h2 {
        font-size: 16px;
    }

    .list-base.type-event .cont p{
        font-size: 13px;
        color: #999;
        margin-top: 10px;
    }

    .list-base.type-list01 .cont p {
        font-size: 14px;
        color: #999;
        margin-top: 4px;
    }

    .list-base.type-event .cont .btn-txt-arrow,
    .list-base.type-list01 .cont .btn-txt-arrow {
        font-size: 15px;
        color: var(--carrot-secondary-color, #00358e);
    }

    .list-base.type-event .cont .btn-txt-arrow:after,
    .list-base.type-list01 .cont .btn-txt-arrow:after {
        top: 10px;
    }

    .list-base.type-event .btn-wrap-more,
    .list-base.type-list01 .btn-wrap-more {
        text-align: center;
        padding: 30px 0 50px;
    }

    .btn-sns-wrap {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-top: 30px;
        -webkit-box-pack: end;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
    }

    .btn-sns-wrap .btn-sns {
        position: relative;
        width: 24px;
        height: 24px;
        background: url(/static/images/content/ico_sns.png) no-repeat;
        text-indent: -9999px;
        margin-left: 6px;
    }

    .btn-sns-wrap .btn-sns:first-child {
        margin-left: 0;
    }

    .btn-sns-wrap .btn-sns.kakaotalk {
        background-position: 0 0;
    }

    .btn-sns-wrap .btn-sns.facebook {
        background-position: -34px 0;
    }

    .btn-sns-wrap .btn-sns.instagram {
        background-position: -68px 0;
    }

    .btn-share {
        width: 60px;
        height: 60px;
        &.kakao {
            background: url('https://developers.kakao.com/assets/img/about/logos/kakaolink/kakaolink_btn_medium.png') no-repeat 0 0;
            background-size: 100%;
        }
        &.link-share {
            background: url('/static/images/b2b2c/share.png') no-repeat 0 0;
            background-size: 100%;
        }
    }

    /* 고객센터-상담게시판 */
    .list-base.type-notice .list-base-item .tit .ico-security {
        position: relative;
        display: inline-block;
        height: 18px;
        font-size: 12px;
        color: #fff;
        font-weight: 400;
        text-align: center;
        line-height: 18px;
        padding: 0 10px;
        border-radius: 18px;
        background: #999;
        margin: 0 6px 0 0;
    }

    .line-inner+.line-inner {
        border-top: 0;
    }

    /* 가격 공시실 */
    .list-base.type-price li {
        border-bottom: 1px var(--carrot-line-color2, #d9d9d9) solid;
        padding: 27px 32px;
    }

    .list-base.type-price .h4 {
        margin-top: 0;
    }

    .list-agree .question {
        padding-left: 25px;
    }

    .list-agree .tit.question .bul-ico {
        position: absolute;
        font-size: 16px;
        color: var(--carrot-font-color, #000);
        padding: 0;
        margin: 0;
        margin-left: -25px;
    }

    .list-agree .tit.question .bul-ico::before {
        display: none;
    }

    .result-event {
        text-align: center;
    }

    .result-event h2 {
        font-size: 20px;
        color: var(--carrot-font-color, #000);
        font-weight: 700;
        letter-spacing: -0.83px;
    }

    .result-event p {
        font-size: 16px;
        margin-top: 8px;
    }

    .abs-r {
        position: absolute;
        top: -2px;
        right: 0;
    }

    .abs-r label {
        min-height: initial;
    }

    .abs-r label span {
        font-size: 15px;
    }

    /* modal 안내 */
    .modal-info {
        font-size: 15px;
    }
    .modal-info--notice {
        position: relative;
        font-size: 13px;
        padding-left: 10px;
        line-height: 22px;
        margin-right: -10px;
    }
    .modal-info--notice:after {
        content: '';
        position: absolute;
        top: 8px;
        left: 0;
        width: 6px;
        height: 6px;
        background: #000;
    }
    .bottom-line {
        position: relative;
    }
    .bottom-line:after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 1px;
        background: #000;
    }
    .center-phone {
        font-size: 28px;
        color: var(--carrot-font-color, #000);
        font-weight: 700;
    }

    .center-time {
        font-size: 13px;
        color: #999;
    }

    .txt-bold {
        font-weight: 700;
    }

    .only-s {
        display: none;
    }

    /* sub main */
    .sub-main .base-header {
        background: var(--carrot-primary-color, #f05014);
    }

    .sub-main .base-header .btn-back:after,
    .sub-main .base-header .btn-menu:before,
    .sub-main .base-header .btn-menu:after,
    .sub-main .base-header .btn-menu .hide {
        background: #fff !important;
    }

    .sub-main .base-header .logo a {
        color: #fff;
    }

    .sub-main .base-header .btn-back::before {
        background-position: -128px -52px;
    }

    .sub-main .sub-main-intro {
        background: var(--carrot-primary-color, #f05014);
        color: #fff;
    }

    .sub-main-intro {
        padding: 15px 32px 30px;
    }

    .sub-main-intro .tit-wrap {
        padding: 0;
    }

    .sub-main-intro .h1 {
        font-size: 25px;
        color: #fff;
    }

    .sub-main-intro .txt-s7 {
        margin-top: 10px;
        font-size: 15px;
        color: #f1f1f1;
    }

    .sub-main-intro .btn-txt-arrow {
        margin-top: 20px;
        color: #fff;
    }

    .sub-main-intro .btn-txt-arrow:after {
        background-position: -105px -52px;
    }

    /* 찾기 */
    .srh-step {
        padding-bottom: 30px;
    }

    .srh-step .step {
        display: block;
        margin-bottom: 10px;
        font-size: 13px;
        color: var(--carrot-font-color, #000);
    }

    .srh-step .field-inlabel-label .btn-tip {
        top: -1;
    }

    // .srh-step .type-srh .btn-srh {margin-top:-5px;}
    .srh-tag {
        margin: 15px 0 20px;
    }

    .srh-tag .btn-chips-step {
        max-width: 100%;
        @include ellipse-one(auto);
    }

    .srh-result.no-result {
        padding: 55px 0 30px;
        font-size: 16px;
        text-align: center;
    }

    .srh-result.no-result strong {
        display: block;
        margin-bottom: 10px;
        font-weight: 700;
    }

    .result-list li {
        border-bottom: 1px solid var(--carrot-line-color2, #d9d9d9);
    }

    .result-list li button {
        width: 100%;
        padding: 16px 15px 14px;
        font-size: 16px;
        color: var(--carrot-font-color, #000);
        line-height: 22px;
        text-align: left;
    }

    .result-list li button b {
        font-weight: 700;
    }

    .result-list li button:focus,
    .result-list li.on button {
        background: #f6f6f6;
    }

    .like-list {
        padding: 25px 8px;
        font-size: 13px;
    }

    .like-list>strong {
        color: var(--carrot-font-color3, #555);
        font-weight: 700;
    }

    .like-list>div {
        margin-top: 10px;
        line-height: 18px;
        color: #6e6e6e;
    }
    /* 2020-01-21 검색으로 찾기 신규 */
    .srh-result .result-color {
        color: #4275b7 !important;
    }
    .srh-result .result-color02 {
        color: var(--carrot-primary-color, #f05014) !important;
    }
    .ui-m .result-list li .result-list__cont {
        font-size: 14px;
        padding: 16px 0 14px;
        word-break: break-all;
    }
    .result-list__cont>span {
        padding-right:4px;
    }
    /* 이미지 박스 */
    .imgs-wrap span {
        font-size: 14px;
        font-weight: 700;
    }

    .imgs-wrap .img-box {
        margin-top: 12px;
        background: #eee;
        width: 100%;
        height: 150px;
    }

    .imgs-wrap .img-box img {
        width: 100%;
    }

    .imgs-wrap+.imgs-wrap {
        margin-top: 25px;
    }

    /* 서명 영역 */
    .signature-wrap {
        width: 100%;
        height: 200px;
        background: #f7f7f7
    }

    /*슬라이드*/
    .slider-wrap {
        position: relative;
        width: 100%;
        padding: 45px 0 68px;
    }

    .slider-wrap.type-a .slick-slide {
        padding-right: 8px;
    }

    .slider-wrap.type-a .slider-item {
        padding: 0 !important;
    }

    .slider-wrap .slider-item {
        padding: 0 15px;
    }

    .slider-wrap .slick-active .slider-item {
        padding: 0;
    }

    .slider-wrap .slick-prev,
    .slider-wrap .slick-next {
        top: auto;
        bottom: -48px;
        left: 50%;
        z-index: 1;
        width: 24px;
        height: 24px;
        border: 1px solid #ddd;
        border-radius: 100%;
        @include vendorPrefix(transform, inherit);
    }

    .slider-wrap .slick-prev:before,
    .slider-wrap .slick-next:before {
        display: none;
    }

    .slider-wrap .slick-prev,
    .slider-wrap .slick-prev:hover,
    .slider-wrap .slick-prev:focus {
        margin-left: -29px;
        background: url('/static/images/common/ico_small@2x.png') no-repeat -177px -27px;
        background-size: 300px;
    }

    .slider-wrap .slick-next,
    .slider-wrap .slick-next:hover,
    .slider-wrap .slick-next:focus {
        margin-left: 5px;
        background: url('/static/images/common/ico_small@2x.png') no-repeat -201px -27px;
        background-size: 300px;
    }

    .slider-wrap .slick-prev.slick-disabled:before,
    .slider-wrap .slick-next.slick-disabled:before {
        opacity: 1;
    }

    /* text simple banner */
    [class*="txt-bn"] .tit {
        display: block;
        font-size: 15px;
        font-weight: 500;
        color: var(--carrot-font-color, #000);
        line-height: 22px;
    }

    [class*="txt-bn"] .txt-s6 {
        display: block;
        color: var(--carrot-font-color3, #555);
    }

    .txt-bn-link {
        display: block;
        position: relative;
        padding: 30px 0;
        border-top: 1px solid var(--carrot-line-color2, #d9d9d9);
    }

    .txt-bn-link a {
        display: block;
        text-decoration: none;
        padding-right: 25px;
    }

    .txt-bn-link .btn-arrow2 {
        position: absolute;
        top: 30px;
        right: 0;
    }

    /* 금액선택 임시 */
    .type-lb-case2 .lb-radio {
        width: 100%;
    }

    .type-lb-case2 .lb-radio>span {
        width: 100%;
    }

    .no-data {
        padding: 30px 0;
        text-align: center;
    }

    /* 자주하는질문 acco */
    .ui-acco.type-faq {
        border-bottom: 1px solid var(--carrot-line-color2, #d9d9d9)
    }

    .ui-acco.type-faq .total {
        padding: 30px 32px 10px;
        font-size: 14px;
        color: var(--carrot-font-color3, #555);
        text-align: right;
    }

  .ui-acco.type-faq .ui-acco-wrap {
    padding: 0;
  }

  .ui-acco.type-faq .ui-acco-btn {
    padding: 26px 57px 26px 32px;
  }

  .ui-acco.type-faq .ui-acco-pnl {
    padding: 25px 32px 45px;
    border-top: 1px solid var(--carrot-line-color2, #d9d9d9);
    font-size: 16px;
    color: var(--carrot-font-color3, #555);
    line-height: 24px;
    background: var(--carrot-content-background, #f8f8f8);
    word-break: break-all;
  }

  .ui-acco.type-faq .ui-acco-arrow:before {
    top: 50%;
    margin-top: -7px;
    right: 32px;
    opacity: 0.7;
  }

    /* 선택 아코디언 full */
    .wrap-sel-acco>.ui-acco.type-b>.ui-acco-wrap {
        padding: 25px 32px 25px;
        border-bottom: 1px solid var(--carrot-line-color2, #d9d9d9);
    }

    .wrap-sel-acco>.ui-acco.type-b>.ui-acco-wrap:first-child {
        border-top: 1px solid var(--carrot-line-color2, #d9d9d9);
    }

    .wrap-sel-acco>.ui-acco.type-b>.ui-acco-pnl {
        padding: 23px 0 5px;
    }

    /* 일련번호 */
    .num-item {
        position: relative;
        margin-top: 15px;
        padding-right: 32px;
        font-size: 16px;
    }

    .num-item:first-child {
        margin-top: 0;
    }

    .num-item i {
        display: inline-block;
        width: 25px;
        text-align: center;
    }

    .num-item .btn-del-circle {
        position: absolute;
        top: 2px;
        right: 0;
    }

    .state-wrap {
        padding: 22px 0;
    }

    .state-wrap .h4 {
        margin-top: 5px;
    }

    .state-wrap+.info-wrap {
        padding-top: 5px;
        border-top: 1px solid var(--carrot-line-color2, #d9d9d9);
    }

    .info-wrap .tbl-noline th {
        font-size: 15px;
        font-weight: 700;
        vertical-align: middle;
    }

    .info-wrap .tbl-noline td {
        font-size: 15px;
        color: var(--carrot-font-color, #000);
        vertical-align: middle;
    }

    .info-wrap .tbl-noline td .btn-arrow2 {
        top: 1px;
        margin-left: 8px;
    }

    .indent-entity {
        text-indent: -8px;
    }

    /* 나의계약 */
    .list-content.type-info .list-content-item.service {
        padding: 24px 32px;
        border-top: 0;
        background: #f7f7f7;
    }

    .list-content-item.service+.list-content-item {
        border-top: 0;
    }

    .list-content-item.service .box-base {
        background: #fff;
    }

    .list-content-item.service .box-base+.box-base {
        margin-top: 36px;
    }

    .list-content-item.service .box-base+.box-base:before {
        content: '';
        position: absolute;
        top: -23px;
        left: 50%;
        margin-left: -8px;
        display: block;
        width: 16px;
        height: 10px;
        background: url('/static/images/common/ico_small@2x.png') no-repeat -30px -83px;
        background-size: 300px;
    }

    .cycle-img {
        position: relative;
        top: 0;
        left: 0;
        margin-top: 25px;
    }

    .cycle-img img {
        width: 100%;
    }

    .cycle-img .cycle span {
        position: absolute;
        font-size: 14px;
        color: var(--carrot-font-color, #000);
    }

    .cycle-img .cycle .n1 {
        left: 50%;
        top: 0;
        @include vendorPrefix(transform, translate(-50%));
    }

    .cycle-img .cycle .n2 {
        right: 15%;
        bottom: 18px;
    }

    .cycle-img .cycle .n3 {
        left: 15%;
        bottom: 18px;
    }

    .cycle-info>li {
        margin-top: 30px;
    }

    .cycle-info dt span {
        position: absolute;
        top: 0;
        left: 0;
        line-height: 22px;
        font-size: 13px;
        color: var(--carrot-primary-color7, #e2470f);
        font-weight: 700;
    }

    .cycle-info dt {
        position: relative;
        padding-left: 68px;
        font-size: 16px;
        color: var(--carrot-font-color, #000);
        font-weight: 700;
    }

    .cycle-info dt .arrow {
        display: inline-block;
        width: 6px;
        height: 10px;
        margin: 0 7px 0 9px;
        background: url('/static/images/common/ico_small@2x.png') no-repeat -60px -83px;
        background-size: 300px;
    }

    .cycle-info dd {
        padding-left: 68px;
        margin-top: 10px;
        font-size: 15px;
        color: var(--carrot-font-color, #000);
        line-height: 22px;
    }

    .list-info li {
        margin-top: 20px;
        font-size: 15px;
        color: var(--carrot-font-color, #000);
    }

    .list-info li:first-child {
        margin-top: 0;
    }

    /*moon*/
    /* 나의계약 예)MP01010100 */
    .type-situation {
        width: 69px;
        margin-right: 10px;
    }

    .type-situation+span {
        display: inline-block;
    }

    .type-situation::before {
        right: 11px;
        border-width: 5px;
        border-top-width: 7px;
    }

    .type-situation .base-select {
        font-size: 24px;
        font-weight: 400;
        border-width: 2px;
        border-color: var(--carrot-font-color, #000);
        padding-right: 36px;
    }

    .list-content-item.pdt-s .list-content-tit {
        border: 0 none;
    }

    /* 나의계약 > 스마트on > 펫 선택 20200427*/
    .box-base.swich-pet {
        overflow: hidden;
        padding: 12px 20px;
    }
    .swich-pet .photo-wrap {
        float: left;
    }
    .swich-pet .pet-photo {
        position :relative;
        width: 97px;
        height: 97px;
        border-radius: 50%;
        background :#fff;
        border: 3px solid #fff;
    }
    .swich-pet .pet-photo img{
        width: 100%;
        border-radius: 50%;
    }
    .swich-pet .pet-photo .btn-register{
        position: absolute;
        display: block;
        font-size: 0;
        width: 25px;
        height: 25px;
        left: -3px;
        bottom: -3px;
        border-radius: 50%;
        background:  #fff url(/static/images/content/ico_pet_photo_register.png) no-repeat 50% 50%;
        background-size: 11px auto;
        border: 1px solid #e5e5e5;
    }
    .swich-pet .pet-select-wrap {
        position: relative;
        float: left;
        width: calc(100% - 97px);
        padding-right:30px;
    }
    .swich-pet .lb-check {
        width: 100%;
        padding: 0;
    }
    .swich-pet .lb-check:before {
        position :absolute;
        border-radius: 50%;
        background: #dcdcdc url('/static/images/common/ico_form@2x.png') no-repeat -3px -3px;
        background-size: 150px;
        right: -30px;
        top :50%;
        transform : translate(0, -50%);
        margin:0;
    }
    .swich-pet .pet-info {
        display: block;
        padding-left: 20px;
    }
    .swich-pet .pet-info span {
        position: relative;
        width: 100%;
        display: block;
        font-size: 12px;
        line-height: 1.5;
        padding-left: 30px;
    }
    .swich-pet .pet-info span em {
        position: absolute;
        left: 0;
        top: 0;
        font-weight: bold;
        display: inline-block;
        width: 30px;
    }
    .swich-pet .pet-name {
        display: block;
        width : 100%;
        font-size:13px !important;
        font-weight: bold;
        color: #111;
        padding: 6px 0px 8px 0px !important;
        line-height: 1.5;
    }

    /* 자동차번호 등록.변경 */
    .wrap-inner-select {
        padding: 24px 16px;
        background: var(--carrot-content-background, #f8f8f8);
    }

    .wrap-inner-select+.list-content.type-info .list-content-item:first-child {
        border-top: 0 !important
    }

    /* 상품약관 아이콘 */
    .ico-download {
        display: inline-block;
        position: relative;
        height: 20px;
    }

    i.ico-download {
        width: 20px;
    }

    .ico-download::before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 2px;
        width: 20px;
        height: 20px;
        background: url('/static/images/common/ico_small@2x.png') no-repeat -206px 0;
        background-size: 300px;
    }

    .btn-type-txt {
        text-align: left;
        padding: 7px 32px;
    }

    .btn-type-txt .btn-txt {
        border-bottom: 0;
    }

    .btn-type-txt .btn-txt span {
        color: var(--carrot-font-color3, #555);
        font-size: 14px;
    }

    .page-insuinfo-tit .racestreet {
        display: block;
        color: #ff9d03;
        margin-top: 10px;
        text-align: right;
    }

    /* intro main */

    .address {
        margin-top: 10px;
        font-style: normal;
    }

    .error-txt {
        margin-top: 27px;
    }

    .tit-wrap .btn-tip-page {
        position: absolute;
        right: -3px;
        top: 35px;
    }

    .btn-wrap-fixed .box-base {
        background: var(--carrot-body-background, #fff);
        border-radius: 0;
        border-top: 1px solid var(--carrot-line-color2, #d9d9d9);
    }

    .btn-wrap-fixed .box-base-tit h2 {
        font-weight: bold;
    }

    /* list base */
    .list-base,
    .list-base.type-notice .list-base-item .tit h3,
    .list-base.type-notice a,
    .list-base.type-notice button.link {
        width: 100%;
        color: var(--carrot-font-color, #000);
        text-decoration: none;
        text-align: left;
    }

    .list-base.type-notice .list-base-item .tit h3 {
        text-overflow: ellipsis;
        white-space: nowrap;
        word-wrap: normal;
        width: calc(100% - 30px);
        overflow: hidden;
    }

    .list-base .tit h3 {
        font-size: 16px;
        font-weight: 700;
    }

    .list-base .tit span {
        margin-top: 2px;
        font-size: 15px;
    }

    .list-base .tit h3,
    .list-base-item .tbl-noline th,
    .list-base.type-notice .btn-wrap span {
        color: var(--carrot-font-color3, #555);
    }

    .list-base-tit {
        padding: 35px 33px 20px;
    }

    .list-base-item .tbl-noline {
        margin-top: 43px;
    }

    .list-base .txt-bn,
    .list-base-item li {
        border-top: 1px solid var(--carrot-line-color2, #d9d9d9);
        padding: 34px 33px 22px;
    }

    .list-base-item li:last-child {
        border-bottom: 1px solid var(--carrot-line-color2, #d9d9d9);
    }

    /* 공지/뉴스*/
    .list-base.type-notice .tit h3,
    .wrap-inner.type-notice>.tit h2 {
        padding-bottom: 7px;
        word-break: break-all;
    }

    .list-base.type-notice li {
        position: relative;
    }

    .list-base.type-notice li .btn-arrow2 {
        position: absolute;
        top: 30px;
        right: 0;
        padding-right: 28px;
    }

    .list-base.type-notice .list-base-item li {
        padding: 17px 33px 16px;
    }

    .tit .tit-date-wrap span {
        position: relative;
        font-size: 13px;
        line-height: 16px;
        color: #999;
        padding: 0 10px;
    }

    .tit .tit-date-wrap span+span::before {
        left: 0;
        top: 3px;
        @include bar-border(10px, #d9d9d9);
    }

    /* 공지-상단 고정노출 기능 */
    .list-base.type-notice .list-base-item .fix .tit h3 {
        color: var(--carrot-primary-color7, #e2470f);
    }

    /* 공지 상세화면 */
    .wrap-inner.type-notice {
        padding: 0;
    }

    .wrap-inner.type-notice>.tit {
        font-size: 13px;
        color: #999;
        padding: 35px 33px 28px;
    }

    .wrap-inner.type-notice .line-inner .btn-wrap {
        padding: 13px 33px 20px 0;
    }

    .line-inner {
        border-top: 1px solid var(--carrot-line-color2, #d9d9d9);
        border-bottom: 1px solid var(--carrot-line-color2, #d9d9d9);
        padding: 0 33px 32px;
        word-break: break-all;
        user-select: text;
    }

    .line-inner pre {
        font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', 'Apple SD Gothic Neo', 'Malgun Gothic', '맑은 고딕', sans-serif;
        white-space: pre-wrap;
    }

    /* edit 영역. */
    .edit-wrap {
        margin-top: 30px;
    }

    .edit-wrap img {
        max-width: 100%;
    }

    .edit-wrap .ql-align-center {
        text-align: center;
    }

    /* list-content type-tbl-line ex)UM02000101-일별상세조회*/
    .list-content.type-tbl-line {
        padding: 7px 25px;
    }

    .list-content.type-tbl-line .tbl-noline {
        border-top: 1px solid var(--carrot-line-color2, #d9d9d9);
        margin: 0;
        padding: 14px 0;
    }

    .list-content.type-tbl-line .tbl-noline td,
    .list-content.type-tbl-line .tbl-noline th {
        padding: 8px 0;
    }

    .list-content.type-tbl-line .btn-wrap {
        padding: 12px 0;
    }

    .round-num {
        display: inline-block;
        margin: 16px 0 12px;
        text-align: center;
        color: #fff;
        background: var(--carrot-font-color4, #777);
        width: 36px;
        height: 17px;
        border-radius: 12px;
        font-size: 12px;
    }

    /* tbl-noline type-tbody-a ex)PO06005300-보험료 확인 */
    .box-base .tbl-noline.type-tbody-a td,
    .box-base .tbl-noline.type-tbody-a th {
        text-align: left;
    }

    .box-base .tbl-noline.type-tbody-a td:last-child {
        text-align: right
    }

    .box-base .tbl-noline.type-tbody-a tbody tr:first-child td,
    .box-base .tbl-noline.type-tbody-a tbody tr:first-child th {
        padding-top: 18px;
    }

    .box-base .tbl-noline.type-tbody-a td {
        padding: 0;
    }

    .box-base .tbl-noline.type-tbody-a tbody:nth-child(3) td,
    .box-base .tbl-noline.type-tbody-a tbody:nth-child(3) th {
        padding: 0 !important;
    }

    // list-contract type-a MP04080100 긴급출동 이용확인서
    .list-contract.type-a .list-contract-tit {
        overflow: hidden;
    }

    .list-contract.type-a .list-contract-tit .contract-tit-wrap {
        float: left;
    }

    .list-contract.type-a .list-contract-tit .field-inlable {
        float: right;
    }

    // list-contract type-b 행사보험 PO06005301
    .list-contract.type-b .list-contract-item {
        padding: 0;
    }

    .list-contract.type-b .list-contract-item .item-wrap {
        padding: 36px 32px 38px;
        border-bottom: 1px solid var(--carrot-line-color2, #d9d9d9);
    }

    .list-contract.type-b .list-contract-item .item-wrap:last-child {
        border: 0;
    }

    .list-contract.type-b .list-contract-item .list-contract-tit {
        padding: 25px 32px 20px;
    }

    .list-contract.type-b .field-inlabel {
        padding: 22px 0 16px
    }

    // list-base type-a 스위치 해외여행보험 PO02005310
    .list-base.type-a .list-base-item li {
        padding: 20px 0;
        font-size: 13px;
        color: var(--carrot-font-color3, #555);
    }

    .list-base.type-a .list-base-item li p {
        margin-bottom: 17px;
    }

    .list-base.type-a .list-base-item li .item-wrap span {
        display: inline-block;
        text-align: center;
        padding-top: 5px;
        color: var(--carrot-secondary-color, #00358e);
    }

    .list-base.type-a .list-base-item li .item-wrap.n1 span,
    .list-base.type-a .list-base-item li .item-wrap.n4 span {
        width: calc(50% - 5px);
        margin-left: 5px;
    }

    .list-base.type-a .list-base-item li .item-wrap.n2 span {
        width: calc(62% - 5px);
        margin-left: 10px;
    }

    .list-base.type-a .list-base-item li .item-wrap.n2 span+span {
        width: calc(38% - 5px);
        margin-left: 0;
    }

    // .list-base.type-a .item-wrap:before { content: ''; display: block; width: 100%; height: 8px; }
    .list-base.type-a .item-wrap.n1::before {
        background: url(/static/images/content/insgrap_1_m.png) no-repeat;
        background-size: 100%;
    }

    .list-base.type-a .item-wrap.n2::before {
        background: url(/static/images/content/insgrap_2_m.png) no-repeat;
        background-size: 100%;
    }

    .list-base.type-a .item-wrap.n3 {
        text-align: center;
    }

    .list-base.type-a .item-wrap.n3::before {
        background: url(/static/images/content/insgrap_3_m.png) no-repeat;
        background-size: 100%;
    }

    .list-base.type-a .item-wrap.n4::before {
        background: url(/static/images/content/insgrap_4_m.png) no-repeat;
        background-size: 100%;
    }

    .list-base.type-a .item-wrap .progress {
        position: relative;
        display: block;
        height: 2px;
        margin: 0 6px;
    }

    .list-base.type-a .item-wrap .progress::after {
        content: '';
        display: block;
        clear: both;
    }

    .list-base.type-a .item-wrap .progress>div {
        position: relative;
        height: 2px;
    }

    .list-base.type-a .item-wrap .progress>div::before,
    .list-base.type-a .item-wrap .progress>div::after {
        content: '';
        position: absolute;
        display: block;
        top: -5px;
        width: 12px;
        height: 12px;
        border: 2px #fff solid;
        border-radius: 12px;
        box-sizing: border-box;
        z-index: 1;
    }

    .list-base.type-a .item-wrap .progress>div::before {
        left: -6px;
    }

    .list-base.type-a .item-wrap .progress>div::after {
        right: -6px;
    }

    .list-base.type-a .item-wrap .progress .daily {
        float: left;
        background: var(--carrot-secondary-color, #00358e);
    }

    .list-base.type-a .item-wrap .progress .daily::before,
    .list-base.type-a .item-wrap .progress .daily::after {
        background: var(--carrot-secondary-color, #00358e);
    }

    .list-base.type-a .item-wrap .progress .normal {
        float: right;
        background: var(--carrot-line-color2, #d9d9d9);
    }

    .list-base.type-a .item-wrap .progress .normal::before {
        display: none;
    }

    .list-base.type-a .item-wrap .progress .normal::after {
        background: var(--carrot-line-color2, #d9d9d9);
    }

    .list-base.type-a .item-wrap .progress .added {
        float: right;
        background: var(--carrot-primary-color, #f05014);
    }

    .list-base.type-a .item-wrap .progress .added::before,
    .list-base.type-a .item-wrap .progress .added::after {
        background: var(--carrot-primary-color, #f05014);
    }

    .list-base.type-a .item-wrap.n1 .daily,
    .list-base.type-a .item-wrap.n1 .normal {
        width: 50%;
    }

    .list-base.type-a .item-wrap.n2 .daily {
        width: 66.7%;
    }

    .list-base.type-a .item-wrap.n2 .added {
        width: 33.3%;
    }

    .list-base.type-a .item-wrap.n3 .added {
        width: 100%;
    }

    .list-base.type-a .item-wrap.n4 .daily,
    .list-base.type-a .item-wrap.n4 .added {
        width: 50%;
    }

    .list-base.type-a .item-wrap.n2 .added::before,
    .list-base.type-a .item-wrap.n4 .added::before {
        display: none;
    }

    // 우리말서비스
    .btn-base.btn-ico-tel {
        min-width: 140px;
        padding: 11px 8px 0;
        span {
            vertical-align: middle;
            &:before {
                content: '';
                display: inline-block;
                width:20px;
                height: 20px;
                margin-right: 6px;
                margin-top: -2px;
                background: url('/static/images/common/ico_tel.png') 0 0 no-repeat;
                background-size: 100%;
                vertical-align: middle;
            }
        }
    }
    .tel-service-topText {
        font-size: 14px;
        line-height: 1.85 !important;
        color: var(--carrot-font-color, #000);
    }

    .tel-service {
        .service-text {
            padding-left: 8px;
            font-size: 12px;
            font-weight: bold;
            &.bul-dot.hue-p:before {
                background: var(--carrot-primary-color, #f05014);
            }
        }
        .btn-base.btn-ico-tel {
            width: 100%;
            max-width: 200px;
            border-color: #000;
            color: var(--carrot-font-color, #000);
        }
        @media all and (max-width:320px) {
            .btn-base.btn-ico-tel {
                width: 130px;
                padding-left: 8px;
            }
        }
    }
    // btn-wrap col3 행사보험 PO06005301
    .btn-wrap.col3 {
        padding: 0 0 17px;
        font-size: 0;
        text-align: left;
    }

    .btn-wrap.col3 .btn-chips-step {
        width: calc(33.3% - 6px);
        margin: 0 9px 10px 0;
        padding: 0;
    }

    .btn-wrap.col3 .btn-chips-step:nth-child(3n) {
        margin-right: 0;
    }

    .btn-wrap.col3 .btn-base-r {
        width: calc(33.3% - 6px);
        margin: 0 9px 0 0;
        padding: 0;
    }

    .btn-wrap.col3 .btn-base-r:nth-child(3) {
        margin-right: 0;
        width: calc(33.4% - 6px);
    }

    .btn-wrap.col3 a.btn-base-r {
        padding-top: 7px;
    }

    // btn-wrap col2 스위치보험 PO02005600
    .btn-wrap.col2 a,
    .btn-wrap.col2 button {
        width: calc(50% - 10px);
    }

    // tfoot td, tr 간격 스위치보험 PO02005600
    .box-base .tbl-noline tfoot tr+tr th,
    .box-base .tbl-noline tfoot tr+tr td {
        padding-top: 10px;
    }

    .no-line {
        border: 0 !important;
    }

    .type-lb-insu label>span {
        font-size: 15px;
    }

    .type-lb-insu label>span em {
        display: block;
        font-size: 22px;
        color: var(--carrot-font-color, #000);
        margin-bottom: 10px;
    }

    .type-lb-insu label {
        display: flex;
    }

    .dl-tbl dl {
        margin-top: 24px;
        overflow: hidden;
    }

    .dl-tbl dl:first-child {
        margin-top: 19px;
    }

    .dl-tbl dl dt {
        font-weight: 700;
        font-size: 15px;
    }

    .dl-tbl dl dd {
        position: relative;
        margin-top: 5px;
    }

    .dl-tbl .list-dlsel-toggle {
        position: absolute;
        top: -15px;
        right: 0;
    }

    .dl-tbl .txt-price {
        margin-top: -11px;
    }

    // tbl-base type-a 행사보험 담보가입내역 PO06005502
    .tbl-base.type-a td:last-child {
        text-align: right
    }

    // list-content-item MP01010100
    .list-content.type-contract .box-base {
        padding-top: 0;
    }

    .list-content.type-contract .list-content-item.list-none .box-base {
        padding-top: 36px;
    }

    .list-content.type-contract .box-head.type-b {
        padding: 24px 0 26px 0;
    }

    .list-content.type-contract .box-head.type-b .fl-wrap .fl-item-l {
        font-size: 13px;
        width: calc(100% - 60px);
    }

    .list-content.type-contract .box-head.type-b .fl-wrap .fl-item-r {
        padding-top: 15px;
    }

    .list-content.type-contract .box-head.type-b .fl-wrap .fl-item-l strong {
        display: block;
        color: var(--carrot-font-color3, #555);
        font-weight: 700;
        font-size: 15px;
    }

    .list-content.type-contract .box-head.type-b .fl-wrap .fl-item-l span {
        display: block;
        word-break: keep-all;
    }

    .list-content .list-content-link {
        position: relative;
        display: block;
        text-decoration: none;
        color: var(--carrot-font-color, #000);
    }

    .list-content .list-content-link .ico-arrow {
        position: absolute;
        right: 0px;
    }

    .btn-wrap.btn-type-txt.fl-wrap {
        border-top: 1px solid var(--carrot-line-color1, #c4c4c4);
        padding: 16px 0 0;
    }

    // 나의계약 납입내역조회 type-acco-bg MP03040600
    .type-acco-bg .ui-acco {
        border-bottom: 0;
    }

    .type-acco-bg .ui-acco-wrap,
    .type-acco-bg .ui-acco-tit,
    .type-acco-bg .list-content-item {
        background-color: var(--carrot-content-background, #f8f8f8);
        border-radius: 0;
    }

    .type-acco-bg .ui-acco-wrap {
        border-radius: 6px;
    }

    .type-acco-bg .list-content.type-tbl-line .tbl-noline {
        border-top: 1px solid var(--carrot-line-color1, #c4c4c4);
    }

    .list-dlsel .box-base-tit .price b.txt-s6 {
        color: var(--carrot-font-color, #000) !important;
        font-weight: 700;
    }

    .txt-of {
        display: inline-block;
        max-width: calc(100% - 82px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-wrap: normal;
        vertical-align: middle;
        margin-top: -3px;
    }

    .wrap-pet-w li .fl-item-l {
        width: calc(100% - 90px);
        padding-right: 20px;
    }

    .wrap-pet-w li .fl-item-r {
        width: 90px;
        justify-content: flex-end;
    }

    .list-user-info {
        margin: 30px 0
    }

    .list-user-info li {
        font-size: 16px;
        color: var(--carrot-font-color, #000)
    }

    .graph-area {
        background: #f9f9f9;
        min-height: 256px;
    }

    .paging-area {
        text-align: center;
        margin-top: 24px
    }

    .paging-area>* {
        display: inline-block;
        vertical-align: middle;
    }

    .paging-area .link-pg {
        width: 24px;
        height: 24px;
        line-height: 100;
        overflow: hidden;
        border: 1px solid #ddd;
        border-radius: 50%;
        position: relative;
        background: #fff url('/static/images/common/ico_small@2x.png') no-repeat -178px -27px / 300px
    }

    .paging-area .link-pg.first,
    .paging-area .link-pg.last {
        background-position-x: -228px
    }

    .paging-area .link-pg.prev {
        margin-left: 16px
    }

    .paging-area .link-pg.next {
        margin-right: 16px;
    }

    .paging-area .link-pg.next,
    .paging-area .link-pg.last {
        transform: rotate(180deg)
    }

    .paging-area .paging-num {
        width: 90px
    }

    .list-contract-item.bor-bot {
        border-bottom: 10px solid var(--carrot-content-background, #f8f8f8);
    }

    // UBI - 운행정보
    .page-insuinfo-tit.type-case .ui-modal-tit {
        height: 115px;
    }

    .page-insuinfo-tit.type-case .ui-floating.ui-fixed-top .ui-modal-tit {
        height: 135px;
    }

    .page-insuinfo-tit.type-case .state {
        margin-bottom: 10px
    }

    .page-insuinfo-tit.type-case .premium {
        margin-top: 0
    }

    .page-insuinfo-tit.type-case .ui-floating+.premium {
        margin-top: 0px
    }

    .page-insuinfo-tit.type-case .premium em {
        line-height: 1
    }

    .page-insuinfo-tit.type-case .racestreet {
        font-size: 13px;
        letter-spacing: -0.05em;
        margin-top: 10px
    }

    .page-insuinfo-tit.type-case .date {
        position: relative;
        top: 0;
        left: 0 !important;
        text-align: right;
        margin-top: 20px
    }

    .hue-s3 {
        color: #999
    }

    .no-data.car {
        padding: 15px 0 20px
    }

    // UBI - A/S
    .list-contract-item .tit-area::after {
        content: "";
        display: block;
        clear: both
    }

    .list-contract-item .tit-area .h4 {
        float: left;
        margin: 0;
    }

    .list-contract-item .tit-area .btn-base {
        float: right;
        width: auto;
        height: 30px;
        padding: 0 18px;
        border: 1px solid #777;
        position: static;
    }

    .list-contract-item .btn-base.focused,
    .list-contract-item .btn-base:hover,
    .list-contract-item .btn-base:focus {
        border-color: var(--carrot-primary-color, #f05014)
    }

    .list-txt-info li {
        margin-top: 24px
    }

    .list-txt-info li:first-child {
        margin-top: 0
    }

    .list-txt-info li>span {
        display: block;
        margin-bottom: 10px
    }

    // UBI - 주행내역 상세
    .box-map {
        background: #f9f9f9;
        min-height: 256px;
    }

    .wrap-inner.map .box-map {
        height: 400px
    }

    .type-location .fx-item {
        width: calc(50% - 27px);
    }

    .type-location .fx-item:first-child {
        margin-right: 24px
    }

    .type-location .fx-item.btn {
        width: 20px;
        margin-left: 10px
    }

    // UBI - 마이페이지 서브메인
    .type-b .tbl-noline tr {
        border-bottom: 1px solid var(--carrot-line-color2, #d9d9d9)
    }

    .type-b .tbl-noline th,
    .type-b .tbl-noline td {
        height: 8vh;
        vertical-align: middle;
    }

    .type-b .tbl-noline th {
        padding-left: 46px;
        position: relative;
    }

    .type-b .tbl-noline th b {
        font-size: 13px;
        font-weight: 700;
        display: block;
        color: var(--carrot-font-color, #000)
    }

    .type-b .tbl-noline th .txt-w4 {
        font-weight: 400;
    }

    .type-b .tbl-noline th .sub-tit {
        font-size: 14px;
        font-weight: 700;
        text-decoration: underline;
        color: #84aae9
    }

    .type-b .tbl-noline .txt-price {
        font-size: 15px;
        border-bottom: 1px solid var(--carrot-primary-color, #f05014);
    }

    .type-b .tbl-noline .txt-price b {
        font-size: 20px;
    }

    .type-b .tbl-noline .txt-price .minus {
        color: #e91515 !important;
    }

    .ui-acco.type-b .tbl-noline th {
        padding-left: 0;
        height: auto;
    }

    .ui-acco.type-b .tbl-noline th+td {
        height: auto;
    }

    .btn-wrap.col2.ico-type {
        margin: 0;
        padding: 0;
    }

    .btn-wrap.col2.ico-type button {
        height: 40px;
        color: var(--carrot-font-color, #000)
    }

    .btn-wrap.col2.ico-type button::before {
        content: "";
        display: inline-block;
        width: 25px;
        height: 25px;
        background: url('/static/images/content/ico_ubi@2x.png') no-repeat -50px -50px;
        background-size: 300px;
        vertical-align: middle;
        margin-top: -3px;
        margin-right: 8px;
    }

    .btn-wrap.col2.ico-type button.btn2:before {
        background-position-x: -100px;
    }

    // 운행현황
    .ui-modal-cont .type-b {
        padding: 60px 30px 20px;
        background: #fff
    }

    .ui-modal-cont .type-b .today-ubi {
        margin-bottom: 10px;
        font-size: 15px
    }

    .ui-modal-cont .type-b .in {
        border: 1px solid var(--carrot-line-color1, #c4c4c4);
        border-radius: 6px;
        padding: 10px 10px 0
    }

    .ui-modal-cont .type-b .in .tit-wrap {
        padding: 0;
        text-align: center
    }

    .ui-modal-cont .type-b .sub-tit {
        font-size: 16px;
        margin-top: 10px
    }

    .ui-modal-cont .type-b .tbl-noline {
        margin-top: 0
    }

    .ui-modal-cont .type-b .tbl-noline .sub-tit,
    .ui-modal-cont .type-b .tbl-noline .txt-price,
    .ui-modal-cont .type-b .tbl-noline tr {
        border-bottom: none;
        text-decoration: none;
    }

    .ui-modal-cont .box-rdo-ubi {
        padding: 0;
        margin-bottom: 15px
    }

    .ui-modal-cont .box-rdo-ubi .lb-radio {
        padding-top: 0;
    }

    .ui-modal-cont .list-ubi li+li {
        margin-top: 5px
    }

    .page-insuinfo .inp-datepicker [class*="inp-"] {
        background: #f1f1f1 !important
    }

    // 해외여행보험 수정
    .check-r {
        position: absolute;
        top: 0;
        right: 0
    }

    .check-r div .lb-check {
        padding: 0;
        min-height: 25px
    }

    // 우수정비업체
    .list-service-ico {
        margin: 20px 0 30px
    }

    .list-service-ico li {
        width: 25%
    }

    .list-service-ico li span {
        display: inline-block;
        width: 40px;
        text-align: center;
        color: var(--carrot-line-color, #9d9d9d);
    }

    .list-service-ico li span::before {
        display: inline-block;
        width: 40px;
        height: 40px;
        background: url(/static/images/content/ico_ubi_service@2x.png) no-repeat 0 0 / 200px 80px;
        content: ''
    }

    .list-service-ico .item3 {
        text-align: center;
    }

    .list-service-ico .item4,
    .list-service-ico .item5 {
        text-align: right;
    }

    .list-service-ico .item2 span {
        width: 50px;
    }

    .list-service-ico .item1 span::before {
        background-position: 0 -40px;
    }

    .list-service-ico .item2 span::before {
        background-position: -40px -40px;
    }

    .list-service-ico .item3 span::before {
        background-position: -80px -40px;
    }

    .list-service-ico .item4 span::before {
        background-position: -120px -40px;
    }

    .list-service-ico .item5 span::before {
        background-position: -160px -40px;
    }

    .list-service-ico li.active span {
        color: var(--carrot-font-color, #000)
    }

    .list-service-ico .item1.active span::before {
        background-position: 0 0;
    }

    .list-service-ico .item2.active span::before {
        background-position: -40px 0;
    }

    .list-service-ico .item3.active span::before {
        background-position: -80px 0;
    }

    .list-service-ico .item4.active span::before {
        background-position: -120px 0;
    }

    .list-service-ico .item5.active span::before {
        background-position: -160px 0;
    }

    .ico-tel::before {
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-right: 13px;
        background: url(/static/images/content/ico_ubi@2x.png) no-repeat -255px -55px / 300px;
        content: '';
        vertical-align: middle;
    }

    // 간편인증
    .certify-info {
        position: relative;
        font-size: 13px;
        color: #6e6e6e;
        margin-top: 10px;
    }

    .certify-num {
        position: relative;
        display: block;
        height: 26px;
        margin-top: 25px;
        text-align: center;
        background: url('/static/images/content/certify_dot.png') no-repeat;
        background-size: 216px 212px;
    }

    .certify-num.dot0 {
        background-position: 50% 0;
    }

    .certify-num.dot1 {
        background-position: 50% -31px;
    }

    .certify-num.dot2 {
        background-position: 50% -62px;
    }

    .certify-num.dot3 {
        background-position: 50% -93px;
    }

    .certify-num.dot4 {
        background-position: 50% -124px;
    }

    .certify-num.dot5 {
        background-position: 50% -155px;
    }

    .certify-num.dot6 {
        background-position: 50% -186px;
    }

    .certi-img-wrap {
        position: relative;
        text-align: center;
        margin-top: 30px;
    }

    .certi-img-wrap>img {
        width: 100%;
    }

    .certi-step {
        position: relative;
        margin-top: 30px;
    }

    .certi-step ol>li {
        position: relative;
        padding-bottom: 30px;
    }

    .certi-step ol>li::before {
        content: '';
        position: absolute;
        left: 20px;
        top: 10px;
        width: 1px;
        height: 100%;
        background: var(--carrot-line-color2, #d9d9d9);
    }

    .certi-step ol>li:last-child {
        padding-bottom: 0;
    }

    .certi-step ol>li:last-child::before {
        display: none;
    }

    .certi-step dl {
        position: relative;
        width: 100%;
    }

    .certi-step dl::after {
        content: '';
        display: block;
        clear: both;
    }

    .certi-step dt {
        float: left;
        position: relative;
        display: block;
        width: 40px;
        height: 40px;
        font-size: 13px;
        color: #fff;
        font-weight: bold;
        text-align: center;
        line-height: 40px;
        border-radius: 40px;
        background: var(--carrot-primary-color, #f05014);
    }

    .certi-step dd {
        float: right;
        width: calc(100% - 40px);
        color: var(--carrot-font-color, #000);
        padding-top: 10px;
        padding-left: 10px;
    }

    .certi-step b {
        font-weight: 700;
    }

    .certi-step .certi-img-wrap {
        text-align: left;
        margin-top: 20px;
    }

    .certi-step.type-small dt {
        width: 20px;
        height: 20px;
        font-size: 11px;
        color: #fff;
        font-weight: 400;
        line-height: 20px;
        background: #bbb;
    }

    .certi-step.type-small dd {
        width: calc(100% - 20px);
        padding: 0 0 13px 7px;
    }

    /* 바이오 인증 */
    .btn-bio {
        position: relative;
        width: 98px;
        height: 98px;
        text-align: left;
        text-indent: -9999px;
        background-repeat: no-repeat;
        background-size: 100%;
    }

    .btn-bio.type-finger {
        background-image: url('/static/images/common/ico_finger.png');
    }

    .btn-bio.type-face {
        background-image: url('/static/images/common/ico_face.png');
    }

    // 보상서비스
    .pnls-acco .list-contract-tit {
        padding: 0 32px;
        border-bottom: none
    }

    .pnls-acco .list-contract-tit h3 {
        margin-top: 0
    }

    .pnls-acco .list-contract-item {
        padding: 35px 0 40px
    }

    /* 청약상품별 인트로 */
    .main-product .base-header {
        box-shadow: 0px 10px 20px rgba(238, 238, 238, 0.3);
    }

    .main-product .type-goodsinfo {
        padding: 50px 16px 100px !important
    }

    .main-product .type-goodsinfo .introduce-tit {
        font-size: 26px;
        color: var(--carrot-font-color, #000);
        font-weight: 600;
        letter-spacing: -1px;
        line-height: 1.2;
    }

    .main-product .type-goodsinfo .box.bg-img {
        background: url(/static/images/main/bg_txt_main.png) no-repeat right top 10px/65px auto
    }

    .main-product .type-goodsinfo .box+.box {
        margin-top: 45px
    }

    .main-product .type-goodsinfo .h5 {
        margin-bottom: 12px
    }

    .main-product .type-goodsinfo .box.bg-img .txt {
        padding-right: 55px
    }

    .main-product .type-goodsinfo .txt {
        font-size: 13px;
        line-height: 1.6;
        margin-bottom: 15px;
    }

    .main-product .type-goodsinfo ul.txt>li+li {
        margin-top: 8px
    }

    .main-product .type-goodsinfo .txt-label {
        font-size: 13px;
        display: inline-block;
        background: var(--carrot-secondary-color, #00358e);
        font-weight: 700;
        color: #fff;
        padding: 0 3px
    }

    .main-product .type-goodsinfo .step-square>li {
        display: inline-block;
        border: 1px solid var(--carrot-secondary-color, #00358e);
        padding: 10px 0;
        width: calc(25% - 8px);
        font-size: 11px;
        font-weight: 700;
        color: var(--carrot-secondary-color, #00358e);
        text-align: center;
        letter-spacing: -1px;
        position: relative;
        vertical-align: top;
    }

    .main-product .type-goodsinfo .step-square>li+li {
        margin-left: 10px
    }

    .main-product .type-goodsinfo .step-square>li+li::before {
        content: "";
        width: 8px;
        height: 1px;
        border-bottom: 1px dotted var(--carrot-secondary-color, #00358e);
        position: absolute;
        top: 0;
        bottom: 0;
        left: -10px;
        margin: auto 0;
    }

    .main-product .btn-wrap-fixed {
        padding: 0 23px;
    }

    .main-product .btn-wrap-fixed .btn-base-l {
        opacity: 1;
        margin-bottom: 10px;
        width: 100%;
        margin: 0 0 10px !important;
        height: 55px;
        font-size: 18px;
        padding: 0;
        letter-spacing: -0.075em;
        background-color: var(--carrot-primary-color, #f05014);
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
        border-radius: 0;
    }

    // 스위치해외 floating button
    .insu-floating {
        position: fixed;
        left: 35px;
        right: 35px;
        bottom: 178px;
        height: 50px;
        opacity: 0;
        pointer-events: none;
        transition: all 0.3s ease-out;
        z-index: 2;
    }

    .insu-floating .insu-floating-btn {
        position: relative;
        display: block;
        width: 100%;
    }

    .insu-floating .insu-floating-btn>.btn-ico {
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        width: 60px;
        height: 60px;
        margin-top: -30px;
        background: url('/static/images/content/ico_floating.png') center no-repeat;
        background-size: 100%;
        border-radius: 60px;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
        z-index: 2;
    }

    .insu-floating .insu-floating-btn>.btn-txt {
        display: block;
        width : 0;
        min-width : 0;
        height: 32px;
        margin: 4px 0 0 40px;
        padding: 7px 25px 0 0;
        color: transparent;
        font-size: 15px;
        font-weight: 300;
        line-height: 1.4;
        text-align: left;
        background: #e8edf5;
        border-radius: 0 16px 16px 0;
        overflow: hidden;
        transition: all 0.2s ease-out 0.4s;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
        border-bottom: none;
        white-space: nowrap;
        z-index: 1;
    }

    .insu-floating.show {
        opacity: 1;
        pointer-events: all;
    }

    .insu-floating.show .btn-close {
        position: absolute;
        top: 13px;
        right: 3px;
        width: 20px;
        height: 20px;
        background: url('/static/images/common/ico_small.png') no-repeat -47px -24px;
        background-size: 150px;
        z-index: 1;
    }

    .insu-floating.show .insu-floating-btn>.btn-txt {
        width: calc(100% - 40px);
        padding-left: 26px;
        color: var(--carrot-secondary-color, #00358e);
    }

    .insu-floating.show .insu-floating-btn>.btn-txt b {
        font-weight: 700;
    }

    // 상품안내 이미지
    .product-info-btn-wrap-fixed {
        z-index: 2;
    }

    // 스위치해외 상품안내
    .content-wrap+.content-wrap {
        margin-top: 30px;
    }

    .content-wrap .content-tit:after {
        display: block;
        clear: both;
        content: '';
    }

    .content-wrap p>b {
        font-weight: 700;
    }

    .content-wrap .content-tit span {
        display: block;
        float: right;
        font-weight: normal;
        font-size: 14px;
    }

    .content-wrap .content-tit b {
        font-size: 15px;
        vertical-align: top;
        margin-right: 2px;
    }

    .content-wrap .premium-box-tit {
        position: relative;
        padding: 6px 15px;
        color: #fff;
        background: #4d4d4d;
    }
    .premium-box-tit .font-bold {
        font-weight: 500;
    }
    .content-wrap .premium-box-tit::after {
        content: '';
        display: block;
        clear: both;
    }

    .content-wrap .premium-box-cont {
        position: relative;
        padding: 20px;
    }

    .pricing-process-box {
        padding: 10px;
        margin-top: 10px;
        background: var(--carrot-content-background2, #eee);
    }

    .pricing-process-box .label,
    .content-wrap .premium-box-cont .label {
        color: var(--carrot-primary-color, #f05014);
        font-weight: 700;
    }

    .pricing-process-box .value,
    .content-wrap .premium-box-cont .value {
        font-size: 17px;
        margin-top: 5px;
    }

    .pricing-process-box .value+.label,
    .content-wrap .premium-box-cont .value+.label {
        margin-top: 15px;
    }

    .pricing-process-box .result,
    .content-wrap .premium-box-cont .result {
        position: relative;
        font-weight: 700;
        border-top: 1px var(--carrot-line-color1, #c4c4c4) solid;
        padding-top: 20px;
        margin-top: 20px;
    }

    .pricing-process-box .result::after,
    .content-wrap .premium-box-cont .result::after {
        content: '';
        display: block;
        clear: both;
    }

    .content-wrap .premium-box-n1,
    .content-wrap .premium-box-n2 {
        display: none;
    }

    .content-wrap .bul-dot>li:before {
        width: 3px;
        height: 3px;
        top: 6px;
    }

    .content-wrap .bul-dot li {
        font-size: 15px;
        color: var(--carrot-font-color3, #555);
    }

    .content-wrap .btn-wrap {
        margin: 20px 0 0;
    }

    .content-wrap .btn-wrap [class*="btn-base"] {
        padding: 0 28px;
        border-radius: 3px;
    }

    .content-wrap .btn-wrap [class*="btn-base"]+[class*="btn-base"] {
        margin-left: 6px;
    }

    // 스위치해외 상품안내 - modal tab
    .ui-modal.ps-page.main-sub.type-full,
    .box-dialog.main-sub {
        position: fixed;
        width: 100%;
        padding: 0 24px;
    }

    .box-dialog.main-sub {
        height: 100%;
        background: none;
    }

    .ui-modal.ps-page.main-sub.type-full .ui-modal-wrap,
    .box-dialog.main-sub .ui-modal-wrap {
        box-shadow: none;
    }

    .ui-modal.main-sub+.modal-backdrop {
        display: none !important;
    }

    .box-dialog.main-sub+.background {
        top: 60px;
        background: var(--carrot-body-background, #fff);
        opacity: 1;
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.05) inset;
    }

    .ui-modal.ps-page.main-sub .ui-modal-header,
    .box-dialog.main-sub .box-header {
        width: 100%;
        position: relative;
        background-color: var(--carrot-primary-color, #f05014);
        min-height: 38px;
        padding: 8px 16px;
        margin-top: 110px;
    }

    .ui-modal.ps-page.main-sub .ui-modal-header .ui-modal-tit,
    .box-dialog.main-sub .box-header .box-title {
        font-size: 15px;
        color: #fff;
        background-color: var(--carrot-primary-color, #f05014);
        text-align: left;
    }

    .ui-modal.ps-page.main-sub .ui-modal-cont,
    .box-dialog.main-sub .box-content {
        padding: 0;
        min-height: auto !important;
        height: calc(100% - 234px);
        overflow-y: auto;
        padding-bottom: 20px;
    }

    .ui-modal.main-sub .ui-modal-header .sub-modal-close,
    .box-dialog.main-sub .box-header .sub-modal-close {
        position: absolute;
        right: 0;
        top: -16px;
        width: 31px;
        height: 31px;
        background: url(/static/images/main/sub_modal_close.png) no-repeat;
        background-size: contain;
        text-indent: -9999px;
    }

    .box-dialog.main-sub .box-footer {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 0 20px 20px;
        background: var(--carrot-body-background, #fff);
    }

    .box-dialog.main-sub .h6.bul-dot {
        line-height: 1.4;
    }

    .box-dialog.main-sub .ui-acco-arrow::before {
        opacity: 0.3
    }

    .box-dialog.main-sub li,
    .box-dialog.main-sub th,
    .box-dialog.main-sub td,
    .box-dialog.main-sub p {
        line-height: normal;
        word-break: break-all;
    }

    .box-dialog.main-sub .tbl-base.type-s tbody th+th {
        border-left: 1px var(--carrot-line-color1, #c4c4c4) solid;
    }

    .box-dialog.main-sub .type-function li>.bul-ico {
        position: absolute;
    }

    .box-dialog.main-sub .type-function .type-term-half>.type-box,
    .box-dialog.main-sub .type-function .box-share {
        position: relative;
        text-align: center;
        border: 1px var(--carrot-line-color1, #c4c4c4) solid;
        padding: 10px;
    }

    .box-dialog.main-sub .type-function .type-term-half>.type-box {
        width: calc(50% - 10px)
    }

    .box-dialog.main-sub .type-function .type-term-half>.short {
        width: 20px;
    }

    .box-dialog.main-sub .type-function .box-share>p {
        padding: 10px 0;
        margin: 0 10px;
    }

    .box-dialog.main-sub .type-function .box-share>p+p {
        border-top: 1px var(--carrot-line-color, #9d9d9d) solid;
    }

    .ui-modal.main-sub .ui-tab.type-sub .ui-tab-btn,
    .box-dialog.main-sub .ui-tab.type-sub .ui-tab-btn {
        width: 50%;
        color: #999;
        border: 0 none;
        border-bottom: 1px solid var(--carrot-line-color1, #c4c4c4);
    }

    .ui-modal.main-sub .ui-tab.type-sub .ui-tab-btn+.ui-tab-btn,
    .box-dialog.main-sub .ui-tab.type-sub .ui-tab-btn+.ui-tab-btn {
        border-left: 1px solid var(--carrot-line-color1, #c4c4c4);
    }

    .ui-modal.main-sub .ui-tab.type-sub .ui-tab-btn.selected,
    .box-dialog.main-sub .ui-tab.type-sub .ui-tab-btn.selected {
        background: none;
        color: var(--carrot-primary-color, #f05014);
        border-bottom: 0 none;
    }

    .ui-modal.main-sub .ui-tab-btn.selected i,
    .ui-modal.main-sub .ui-tab-btn.selected:hover i,
    .ui-modal.main-sub .ui-tab .ui-tab-btn.selected:focus i,
    .box-dialog.main-sub .ui-tab-btn.selected i,
    .box-dialog.main-sub .ui-tab-btn.selected:hover i,
    .box-dialog.main-sub .ui-tab .ui-tab-btn.selected:focus i {
        font-weight: 700;
    }

    .ui-modal.main-sub .ui-tab .ui-tab-btns:after,
    .box-dialog.main-sub .ui-tab .ui-tab-btns:after {
        background: none;
    }

    .ui-modal.main-sub .point-txt,
    .box-dialog.main-sub .point-txt {
        display: block;
        text-align: right;
        font-size: 12px;
        color: var(--carrot-primary-color, #f05014);
        margin-top: 10px;
    }

    .ui-modal.main-sub .ui-acco-wrap,
    .box-dialog.main-sub .ui-acco-wrap {
        padding: 0;
    }

    .ui-modal.main-sub .ui-acco-btn,
    .box-dialog.main-sub .ui-acco-btn {
        padding: 14px 35px 14px 16px;
    }

    .ui-modal.main-sub .ui-acco-pnl,
    .box-dialog.main-sub .ui-acco-pnl {
        padding: 0 20px 16px;
    }

    .box-dialog.main-sub .ui-acco-arrow::after {
        opacity: 0.3;
    }

    .list-bul-dot {
        padding-top: 30px;
    }

    .list-bul-dot h2 {
        color: var(--carrot-secondary-color, #00358e);
        font-size: 14px;
        font-weight: 700;
    }

    .list-bul-dot h3 {
        color: #333;
        font-size: 14px;
        font-weight: 700;
        margin: 20px 0 14px;
    }

    .list-bul-dot .list-dot-wrap {
        position: relative;
        border: 1px solid var(--carrot-line-color1, #c4c4c4);
        border-right: 0;
        border-left: 0;
        margin-top: 20px;
        padding-bottom: 20px;
    }

    .round-ico {
        display: inline-block;
        text-align: center;
        width: 16px;
        height: 16px;
        padding-right: 1px;
        border-radius: 50%;
        background-color: var(--carrot-secondary-color, #00358e);
        color: #fff;
        font-size: 12px;
        font-weight: normal;
    }

    .list-bul-dot .tbl-base {
        padding: 10px 0;
        border-top: 1px solid var(--carrot-line-color1, #c4c4c4);
    }

    .list-bul-dot .tbl-base th {
        padding: 0 0 0 10px;
        text-align: left;
        vertical-align: top;
        color: #333;
    }

    .list-bul-dot .tbl-base td {
        padding: 0 0 4px;
        vertical-align: top;
    }

    .list-bul-dot th {
        position: relative;
    }

    .list-bul-dot th::before,
    .list-bul-dot .list-dot-wrap li::before {
        content: "";
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background: #555;
        position: absolute;
        left: 0;
        top: 9px;
    }

    .list-bul-dot .bul-dot li {
        font-size: 13px;
        padding-left: 10px;
    }

    .list-bul-etc {
        padding: 20px 0;
    }

    .tbl-base.type-s+.list-bul-etc {
        padding: 10px 0 30px;
    }

    .list-bul-etc h2 {
        color: var(--carrot-font-color, #000);
        font-size: 14px;
        font-weight: 700;
        margin-top: 20px;
    }

    .list-bul-etc h2+ul {
        margin-top: 10px;
    }

    .list-bul-etc p {
        margin: 10px 0;
    }

    .list-bul-etc .tbl-base th {
        vertical-align: top;
    }

    .tbl-sub-cont {
        width: 100%;
        overflow-x: auto;
        font-size: 13px;
        margin-top: 16px;
    }

    .tbl-sub-cont table {
        width: 400px;
        font-size: 13px;
    }

    .tbl-sub-cont thead {
        border: 1px solid var(--carrot-primary-color, #f05014);
        border-left: 0 none;
        border-right: 0 none;
    }

    .tbl-sub-cont th {
        padding: 6px 17px;
    }

    .tbl-sub-cont thead tr>th {
        border-right: 1px solid var(--carrot-primary-color, #f05014);
        color: var(--carrot-primary-color, #f05014);
    }

    .tbl-sub-cont thead tr>th:last-child,
    .tbl-sub-cont tbody td:last-child,
    .tbl-sub-cont tfoot tr th:last-child {
        border-right: 0 none;
    }

    .tbl-sub-cont thead tr>th+th {
        border-bottom: 1px solid var(--carrot-primary-color, #f05014);
    }

    .tbl-sub-cont tbody tr,
    .tbl-sub-cont tfoot tr {
        border-bottom: 1px solid var(--carrot-line-color2, #d9d9d9);
    }

    .tbl-sub-cont tbody th,
    .tbl-sub-cont tfoot th {
        text-align: left;
        font-weight: normal;
        padding: 6px;
        border-right: 1px solid var(--carrot-line-color2, #d9d9d9);
        vertical-align: top;
    }

    .tbl-sub-cont tbody td,
    .tbl-sub-cont tfoot td {
        text-align: right;
        padding: 6px;
        border-right: 1px solid var(--carrot-line-color2, #d9d9d9);
    }

    .tbl-sub-cont tfoot {
        font-weight: 700;
        color: var(--carrot-font-color, #000);
        background-color: #f7f7f7;
    }

    .tbl-sub-cont tfoot th {
        font-weight: 700;
    }

    //랜딩페이지
    .tbl-base.type-landing {
        width: auto;
    }

    .tbl-base.type-landing>tbody>tr:first-child>th:first-child {
        border-right: 1px solid var(--carrot-line-color1, #c4c4c4);
    }

    .of-scroll {
        overflow-x: auto;
        width: 100%;
    }

    .step-process-img.type-landing {
        margin: 75px 0 45px;
    }

    .step-process-img.type-landing .n1 {
        left: 30px;
        bottom: 280px;
    }

    .step-process-img.type-landing .n2 {
        left: 180px;
        bottom: 280px;
    }

    .step-process-img.type-landing .n3 {
        left: 100px;
        bottom: 195px;
    }

    .step-process-img.type-landing .n4 {
        left: 100px;
        bottom: 115px;
    }

    .step-process-img.type-landing .n5 {
        left: 200px;
        bottom: 30px;
    }

    .step-process-img.type-landing .n6 {
        left: 35px;
        bottom: 30px;
    }

    .step-process-img.type-landing.n2 .n1 {
        bottom: 295px;
    }

    .step-process-img.type-landing.n2 .n3,
    .step-process-img.type-landing.n2 .n4 {
        left: 75px;
    }

    .step-process-img.type-landing.n2 .n5 {
        width: 55%;
        left: 95px;
    }

    .list-base.type-top-line .list-base-tit {
        border-top: 10px solid var(--carrot-line-color2, #d9d9d9);
    }

    .type-radio-align .lb-radio {
        min-width: 104px;
        width: auto;
        margin: 0 20px 10px 0;
    }

    .type-radio-align .lb-radio:nth-child(7) {
        margin-right: 0;
    }

    .fixed-bottom-landing {
        padding-bottom: 180px;
    }

    .fixed-bottom-landing .base-body {
        padding-bottom: 20px;
    }

    .fixed-bottom-landing .wrap-inner.small-h {
        min-height: calc(100vh - 246px);
    }

    .landing-footer {
        position: absolute;
        bottom: -157px;
        left: 0;
        padding-bottom: 90px;
        text-align: center;
    }
     //tip-box
    .tip-box {
        font-size: 14px;
    }
    .tip-box__tit {
        font-weight: bold;
        color :var(--carrot-primary-color, #f05014);
    }

    //퍼마일 무이자할부 팝업
    .tbl-base.type-b th{
        font-size: 12px;
    }
    .tbl-base.type-b td{
        font-size: 11px;
        line-height: 16px;
    }
    .ui-tab-btn .badge {
        width: 26px;
        height: 16px;
        vertical-align: baseline;
        margin-left: 10px;
        margin-bottom: -2px;
    }
    .tit-ico.type-a {
        line-height: 18px;
        color: var(--carrot-primary-color, #f05014);
    }
    .tit-ico.type-a .ico-arrow {
        background-position: -125px -78px;
    }
    .ui-floating .ui-floating-wrap .ui-tab-btn.tag .txt-tag {
        vertical-align: middle;
        margin-left: 8px;
        margin-top: -4px;
        font-size: 10px;
        background: #9c9795;
        height: 20px;
        line-height: 20px;
        border-radius: 10px;
        padding: 0 4px;
    }

    .ui-floating .ui-floating-wrap .ui-tab-btn.tag.selected .txt-tag {
        background : var(--carrot-primary-color, #f05014);
    }

  .mobile-swiper-button-next, .mobile-swiper-button-prev{
    display:none;
  }
}

/* 퍼마일 특약정보 모달 */
.ui-m {
    th.bd-r,
    td.bd-r {
        border-right: 1px solid #ddd;
    }
    .box-base.permile-slider-box {
        padding-bottom: 10px;
    }
    .box-base.material-permile-slider-box {
        padding: 0 25px 10px;
    }
    .mileage-slider__price {
        padding-top:22px;
        text-align: right;
        font-size: 20px;
        line-height: 20px;
        font-weight: bold;
    }
    .mileage-slider__price span {
        color : var(--carrot-primary-color, #f05014);
    }
    .mileage-slider__tit {
        color: var(--carrot-font-color4, #777);
        position: relative;
        margin-top: 10px;
        padding-bottom: 6px;
        text-align: right;
        font-size: 14px;
        line-height: 21px;
    }
    .box-base.type-expect .mileage-slider__tit .btn-tip-page {
        position: static;
        width: 17px;
        height: 17px;
        background-size: 288px;
        top: auto;
        right: auto;
        vertical-align: top;
    }
    .mileage-slider__desc {
        color: var(--carrot-font-color4, #777);
        text-align: right;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 16px;
        margin-left: -10px;
    }

    .mileage-slider__desc span {
        font-weight: bold;
    }
    .mileage-slider__text {
        padding-top: 22px;
        font-size: 16px;
    }
    .mileage-slider__text b {
        font-weight: bold;
    }
    .mileage-slider__text strong {
        color : var(--carrot-primary-color, #f05014);
        font-weight: 700;
    }
    .ui-m .box-base.type-result .txt-price i {
        font-size: 12px;
    }

    .box-content__text {
        font-size: 15px;
        color:#555;
    }
    .box-content__text>p {
        margin-top: 20px;
    }
    .box-content__text + .box-content__img  {
        margin-top: 32px;
    }
    .box-content__img img{
        width: 100%;
    }
    .permile-month>li {
        margin-top: 22px;
    }
    .h1 .h1__fw-normal {
        font-weight: normal;
    }
    .permile-month__img {
        display: inline-block;
        width: 23%;
        padding:0 5px;
        text-align: center;
        vertical-align: middle;
    }
    .permile-month__img img {
        width: 100%;
    }
    .permile-month__tex {
        display: inline-block;
        width: 76%;
        padding-left: 10px;
        vertical-align: middle;
        font-size: 14px;
        color: var(--carrot-font-color, #000);
    }
    .permile-month__tex strong {
        font-weight: 700;
    }
    .permile-month__tex.type-a {
        color: #777;
    }
    .img-permile_premium {
        width: 100%;
    }
    .table-right-desc {
        margin-top:10px;
        padding-bottom:6px;
        text-align: right;
        font-size: 13px;
    }
    .table-right-desc + .tbl-base {
        margin-top: 0;
    }
    .tbl-base--car-premium {
        overflow-x: auto;
    }
    .tbl-base--car-premium table {
        width: 100%;
    }
    .tbl-base--car-premium .col-width-14 {
        width: 14%;
    }
    .tbl-base--car-premium th,
    .tbl-base--car-premium td {
        padding-top: 10px;
        padding-bottom: 10px;
        border-color: #ddd;
    }
    .tbl-base--car-premium th {
        border-bottom: 1px solid #eee;
        background: #f4f4f473;
    }
    .tbl-base--car-premium td{
        border-bottom: 1px solid #eee;
        border-left: 1px solid #eee;
        font-size: 11px;
        letter-spacing: -1px;
    }
    td.td-bg-gray {
        background: #f4f4f473;
      }
    .tit.tit--pos-r {
        position: relative;
    }
    .tit.tit--pos-r .btn-tip {
        position: absolute;
        bottom: 3px;
        right: -25px;
    }
    .txt-price .txt-price__result {
        width: 80px;
        font-size: 12px;
        padding-right: 2px;
    }
}

/* 월 납입 예상 보험료 보기 모달 */
.ui-m {
    .tit-wrap-type2 {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .tit-wrap-type2 .ico-tit {
        overflow: hidden;
        position: relative;
        display: inline-block;
        width: 50px;
        height: 50px;
        background: var(--carrot-primary-color7, #e2470f);
        border-radius: 100%;

    }
    .tit-wrap-type2 .ico-tit > span {
        position: absolute;
        top: 50%;
        left: 0%;
        transform: translateY(-50%);
        color :#fff;
        font-size: 11px;
        font-weight: bold;
        padding: 0 8px;
        width: 100%;
        text-align: center;
        word-break: break-all;
    }
    .tit-wrap-type2 .h1-type2 {
        display: inline-block;
        font-size: 14px;
        color :var(--carrot-font-color, #000);
    }
    .tit-wrap-type2 .ico-tit + .h1-type2 {
        width: calc(100% - 56px);
        padding-top: 14px;
    }
    .tit-wrap-type2 .h1-type2>strong {
        display: block;
        font-weight: bold;
    }
    .h1-type2 .h1-type__price {
        font-size: 16px;
    }
    .h1-type2 .h1-type__price i {
        font-weight: bold;
        font-size: 23px;
        color: var(--carrot-primary-color7, #e2470f);
    }
    .ui-m .tit-wrap-type2 + .box-base {
        margin-top: 22px;
    }
    .box-base + .tit-wrap-type2 {
        margin-top: 32px;
    }
    .box-base + p.bul-care {
        margin-top: 14px;
    }
    .info-lst  {
        font-size: 15px;
    }
    .info-lst > li {
        margin-top: 28px;
    }
    .info-lst > li:first-child {
        margin-top: 0;
    }
    .box-base .info-lst {
        color : var(--carrot-font-color3, #555);
    }
    .info-lst__num,
    .info-lst__tit {
        font-size: 16px;
        font-weight: bold;
        color: var(--carrot-font-color, #000);
    }
    .info-lst__tit strong {
        color: var(--carrot-primary-color7, #e2470f);
    }
    .info-lst__tit span {
        display: inline-block;
        vertical-align: top;
    }
    .info-lst__tit span em {
        color : #555;
        font-size: 15px;
        font-weight: normal;
    }
    .info-lst__tit + p {
        margin-top: 9px;
    }
    .info-lst__num,
    .info-lst__tit.display-ib {
        display: inline-block;
        vertical-align: top;
    }
    .info-lst__num {
        padding-right: 3px;
    }
    .info-lst__tit .fw-normal {
        font-weight: normal;
    }
    .tit-wrap-type3 {
        margin-top: 50px;
        padding: 22px 0 20px;
        border-top: 1px solid #eee;
    }
    .tit-wrap-type3 + .box-base {
        margin-top: 0;
    }
    .tit-wrap-type3 .h1-type3 {
        font-size: 18px;
        font-weight: bold;
        color : var(--carrot-font-color, #000);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .h1-type3 .h1-type__price {
        font-size: 16px;
    }
    .h1-type3> strong {
        padding-top: 2px;
    }
    .h1-type3 .h1-type__price i {
        font-size: 23px;
        color :var(--carrot-primary-color7, #e2470f);
    }
    .permile-main__img img{
        max-width: 100%;
    }
    .type-productmain .slide-item .box-desc>span {
        font-size: 15px;
    }
    .intro-desc {
        margin-top: 15px;
        padding: 16px 0 8px;
        font-weight: normal;
    }
    .intro-desc > li {
        font-size: 16px;
        margin-top: 8px;
    }
    .intro-desc > li > strong {
        display: inline-block;
        font-weight: 700;
        padding-right: 2px;
        vertical-align: top;
        width: 80px;
    }
    .intro-desc > li > span {
        display: inline-block;
        width: calc(100% - 90px);
        vertical-align: top;
    }
    .intro-desc > li:first-child {
        margin-top: 0;
    }

    .intro-title .intro-title__desc-lst strong {
        font-weight: 700;
    }

    .reminder-box {
        margin-top: 30px;
        border: 1px solid #c9c9c9;
        padding: 20px 20px;
    }
    .reminder-box:after {
        content: '';
        display: block;
        clear: both;
    }
    .reminder-box__txt {
        font-size: 14px;
    }
    .reminder-box__tit {
        font-size: 15px;
        display: block;
        margin-bottom: 4px;
    }
    .reminder-box__img {
        float: right;
        width: 45%;
        max-width:150px;
        padding-left: 5px;
    }
    .reminder-box__img img {
        width: 100%;
    }
    .reminder-box__txt strong {
        font-weight: 700;
        color: var(--carrot-font-color, #000);
    }
    .img_premium_refer {
        margin-top: 20px;
    }
    .img_premium_refer img{
        width: 100%;
    }
    .table-detail-box {
        border-top: 1px dashed #ddd;
        margin-top: 10px;
        padding-top: 8px;
        margin-bottom: 8px;
    }
    .table-detail-box > p {
    margin-top: 4px;
    display: flex;
    flex-wrap: wrap;
    }

    .table-detail-box__tit {
    display: inline-block;
    padding-right: 2px;
    font-weight: 500;
    color: #111;
    max-width: 88px;
    min-width: 34px;
    }
    .table-detail-box__txt{
        padding-left: 3px;
        flex: 1 1 0;
    }

    //퍼마일자동차 상품안내 아코디언
    .main-product .ui-acco--car-prod .type-goodsinfo {
        padding-top: 24px!important;
    }
    .ui-acco.ui-acco--car-prod .ui-acco-wrap {
        padding: 0;
    }
    .ui-acco.ui-acco--car-prod .ui-acco-btn {
        padding: 26px 57px 26px 32px;
    }
    .ui-acco.ui-acco--car-prod .ui-acco-arrow:before {
        top: 50%;
        margin-top: -7px;
        right: 32px;
        opacity: 0.7;
    }
    //퍼마일 스텝3페이지 테이블 내 버튼
    .btn-step3-calcu-table {
        letter-spacing: -1px;
    }
    .box-base.type-cont .tbl-noline td .btn-step3-calcu-table span {
        padding-right: 20px;
    }
    //퍼마일 자동차 상품안내 5번
    .recommend-lst {
        border-top: 1px solid #d8d8d8;
        padding-top: 20px;
    }
    .recommend-lst > li {
        margin-top: 15px;
    }
    .recommend-lst__left, .recommend-lst__right {
        display: inline-block;
        vertical-align: middle;
        position: relative;
    }
    .recommend-lst__left {
     width: 100px;
    }
    .recommend-lst__left:after {
        position: absolute;
        top: 50%;
        left: 60px;
        transform: translateY(-50%);
        width: 36px;
        height: 1px;
        border-bottom: 1px dashed var(--carrot-primary-color, #f05014);
        content: '';
    }
    .recommend-lst__right {
        width: calc(100% - 105px);
        padding-left: 6px;
        color : var(--carrot-font-color, #000);
        font-size:16px;
    }
    .recommend-lst__tit {
        position: relative;
        overflow: hidden;
        display: flex;
        width: 60px;
        height: 60px;
        border-radius: 100%;
        background: var(--carrot-primary-color, #f05014);
        flex-wrap: wrap;
        align-items : center;
        text-align: center;
    }

    .recommend-lst__tit span {
        font-size: 16px;
        color:#fff;
        width: 100%;
    }
    .car-into-law{
        letter-spacing: -1px;
    }
    strong.hue-p {
        font-weight: 700;
    }
    a.a-tel {
       padding-right:3px;
    }

    /* 퍼마일 상품안내 블루링크 컨텐츠 추가 (박스 스텝) */
    .step-linebox li{
        position: relative;
        display: inline-block;
        padding:10px 20px 10px 0;
    }
    .step-linebox li:last-child {
        padding: 0 ;
    }
    .step-linebox li:last-child::before {
        display: none;
    }
    .step-linebox li:last-child::after {
        display: none;
    }
    .step-linebox li::before {
        content: "";
        position:absolute;
        top: 50%;
        right: 0;
        width:20px;
        height:1px;
        transform : translateY(-50%);
        border-bottom : 1px dashed var(--carrot-primary-color, #f05014);
    }
    .step-linebox li::after {
        content: "";
        position:absolute;
        top: 50%;
        right: -6px;
        transform : translateY(-50%);
        border-left: 6px solid var(--carrot-primary-color, #f05014);
        border-right: 6px solid transparent;
        border-top:5px solid transparent;
        border-bottom:5px solid transparent;
    }
    .step-linebox li span {
        display: block;
        height: 30px;
        min-width: 88px;
        text-align: center;
        line-height: 30px;
        font-size: 12px;
        padding: 0 15px;
        background: #fff;
        border: 1px solid var(--carrot-primary-color, #f05014);
    }
    @media screen and (max-width: 320px) {
        .step-linebox li span {
            min-width: 78px;
            padding: 0 10px;
        }
    }
    @media screen and (min-width: 375px) {
        .step-linebox li{
            padding-right:40px;
        }
        .step-linebox li::before {
            width:40px;
        }
    }

}

/* 디지털 키 관련(공통 사용 가능) */
.ui-m {
    .flex-row {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-wrap: wrap;
        text-align: left;
        align-items: baseline;
      }
      .flex-row + .flex-row {
        margin-top: 14px;
      }
      .flex-col-12 {
        width: 100%;
      }
      .flex-col-11 {
        width: calc(100% / 12 * 11);
      }
      .flex-col-10 {
        width: calc(100% / 12 * 10);
      }
      .flex-col-9 {
        width: calc(100% / 12 * 9);
      }
      .flex-col-8 {
        width: calc(100% / 12 * 8);
      }
      .flex-col-7 {
        width: calc(100% / 12 * 7);
      }
      .flex-col-6 {
        width: calc(100% / 12 * 6);
      }
      .flex-col-5 {
        width: calc(100% / 12 * 5);
      }
      .flex-col-4 {
        width: calc(100% / 12 * 4);
      }
      .flex-col-3 {
        width: calc(100% / 12 * 3);
      }
      .flex-col-2 {
        width: calc(100% / 12 * 2);
      }

      .flex-col-1 {
        width: calc(100% / 12);
      }
      .flex-row .lb-check {
        padding-top: 0;
      }

    /*** 디지털키 관련 ***/
    /* 현대 디지털키 컬러 시작 */
    .dig-key-hyundai .base-main{
        padding-bottom: 100px;
    }
    .btn-base-l.btn-type-hyundai:disabled,
    .btn-base-l.btn-type-hyundai.disabled,
    .btn-base-l.btn-type-hyundai[disabled],
    .main-product .btn-wrap-fixed .btn-base-l.btn-type-hyundai:disabled,
    .main-product .btn-wrap-fixed .btn-base-l.btn-type-hyundai.disabled,
    .main-product .btn-wrap-fixed .btn-base-l.btn-type-hyundai[disabled],
    .dig-key-hyundai .btn-wrap-fixed .btn-base-l:disabled,
    .dig-key-hyundai .btn-wrap-fixed .btn-base-l.disabled,
    .dig-key-hyundai .btn-wrap-fixed .btn-base-l[disabled]  {
        background-color: rgba(221, 221, 221, 1.0);
    }
    .main-product .btn-wrap-fixed .btn-base-l.btn-type-hyundai,
    .btn-base-l.btn-type-hyundai,
    .dig-key-hyundai .btn-wrap-fixed .btn-base-l{
        background-color: #002c5f;
    }
    .sub-main.dig-key-hyundai .base-header {
        background-color: #002c5f;
    }
     /* 현대 디지털키 컬러 끝 */
     .list-agree__btn {
        font-size: 15px;
        display: block;
        color: #111;
        line-height: 24px;
        word-break: keep-all;
     }
     .list-agree__btn .ico-arrow {
        top: 3px;
     }
     .list-agree.top-line{
        margin-top: 24px;
        padding-top: 20px;
        border-top: 1px solid #eee;
     }
     .term-txt {
         padding-top: 10px;
         font-size: 14px;
     }
     .term-txt p {
         margin-bottom: 3px;
     }
     .box-base--digkey p{
         font-size: 15px;
     }
    .lb-check.lb-check--mg-left {
        margin-left: 10px;
    }
    .tit .samll-num {
        display: block;
        font-size: 12px;
        margin-top: 14px;
        font-weight: 400;
    }
    .list-base.type-border .list-base-head .btn-tip.list-base-head__absolute-btn {
        margin-top: 0;
    }
    .list-base-head__absolute-btn{
        position: absolute;
        top: 2px;
        right: 0;
    }
    .box-base.box-base--guarantee {
        padding: 20px 14px;
    }
    .btn-tip.btn-tip--multiline {
        margin-top: -20px;
    }
    .tbl-type2 table {
        border-top: 1px solid #eee;
        border-right: 1px solid #eee;

    }
    .tbl-type2 th, .tbl-type2 td{
        border-left: 1px solid #eee;
        border-bottom: 1px solid #eee;
        padding: 12px 8px;
        vertical-align: middle;
    }
    .guarantee-table table td {

        color:#111;
    }
    td.tdRadioBg {
        background :#fdfaf6;
    }
    .tbl-type2 td.txt-c {
        padding: 12px 4px;
    }
    .tbl-type2 th {
        background: #f4f4f473;
    }
    .tbl-type2 td {
        font-size: 13px;
    }
    .tbl-type2 tbody tr th:first-child {
        padding-right: 0;
    }
    .tbl-type2.sos-table {
        box-shadow: 5px 7px 28px -21px rgba(0, 0, 0, 0.38);
    }
    .sos-table {
        overflow-x: auto;
    }
    .sos-table table {
        min-width: 600px;
        width: 100%;
    }
    .tbl-type2.sos-table tbody th,
    .tbl-type2.sos-table tbody td {
        font-size: 12px;
        overflow-wrap: break-word;
    }
    .tbl-type2.sos-table tbody th {
        font-size: 13px;
    }
    .tbl-type2.sos-table tbody td {
        color :#555;
    }

    .lb-radio.lb-radio--block {
        display: block;
        padding-top: 0;
    }
    .lb-radio.lb-radio--block:before {
        width: 18px;
        height: 18px;
        min-width: 0;
        min-height: 0;
        margin: 0 auto 2px;
    }
    input:checked + .lb-radio.lb-radio--block:before {
        background-size: 120px;
    }
    .list-dlsel__flex {
        align-items: center;
    }
    .list-dlsel__flex.flex-row + .flex-row {
        margin-top: 16px;
    }
    .list-dlsel__flex  .lb-radio {
        padding-top:0;
        font-size: 12px;
    }
    .list-dlsel__flex .bul-care {
        margin-top: 0;
        color : var(--carrot-font-color, #000);
        font-size: 14px;
    }
    .list-dlsel__flex .bul-care.bul-care--font16 {
        font-size: 16px;
    }
    .list-dlsel__flex .lb-radio:before {
        min-width: 18px;
        min-height: 18px;
        margin-right: 3px;
    }
    .list-dlsel__flex input:checked + .lb-radio:before {
        background-size: 120px;
    }
    .right-radio {
        text-align: right;
    }
    .lb-radio--margin-left {
        margin-left: 8px;
    }
    .lts-minus {
        letter-spacing: -1px;
    }
    .notice-mark {
        font-size: 13px;
        text-indent: -13px;
        padding-left: 13px;
    }
    // 보험계약 해지안내
    .cancel-guide-cont {
        .cancel-graph-box {
            background: #f7f7f7;
            text-align: center;
            img {
                max-width: 100%;
            }
        }
        .txt-nowrap {
            white-space: nowrap;
        }
    }
    //자동차보험 보상가이드
    .bg-accident-research {
        background: url('/static/images/content/bg_accident_research.png') 87% center no-repeat;
        background-size: 21%;
    }
    .img-trafficAccident {
        vertical-align: bottom;
        margin-left: 14px;
        width: 40px;
    }
    .car-guide-cont__bottom-img {
        margin-top: 25px;
        text-align: center;
    }
    .car-guide-cont__bottom-img  img {
        max-width: 67%;
    }
    // 제휴 이벤트 페이지
    .page--affiliate img{
        max-width: 100%;
        vertical-align: top;
    }
    .leisure_premiumExample_img {
        margin-top:20px;
    }
    .leisure_premiumExample_img img {
        width: 100%;
        vertical-align: top;
    }

}

// ui.content.m end
