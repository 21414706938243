@import "mixin.m";

/* desktop scss */
.ui-d {
  :focus {
    outline: none;
  }

  .box-dialog {
    background: #fff;
  }

  /* common */
  body {
    font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', 'Apple SD Gothic Neo', 'Malgun Gothic', '맑은 고딕', sans-serif;
    font-size: 16px;
    color: #333;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: -0.03em;
    overflow-x: auto;
    overflow-y: scroll; /** pc는 세로 스크롤 고정 가로 왓다리갓다리 방지  **/
  }

  textarea {
    max-height: 186px;
    overflow: hidden;
    margin-top: 0 !important;
  }

  textarea.is-scroll {
    overflow: auto;
  }

  div[class*="btn-base"],
  span[class*="btn-base"],
  a[class*="btn-base"] {
    padding: 9px 18px 8px;
  }

  [class*="btn-base"]:disabled,
  [class*="btn-base"][disabled],
  [class*="btn-base"].disabled {
    opacity: 1;
    border: 1px solid #ddd;
    background: #fff;
    box-shadow: none;
    cursor: default;
  }

  [class*="btn-base-a"]:disabled {
    color: #ddd;
  }

  [class*="btn-base"]:disabled .ico-arrow,
  [class*="btn-base"][disabled] .ico-arrow,
  [class*="btn-base"].disabled .ico-arrow {
    opacity: 0.3;
  }

  /* base form:radio & checkbox */
  input[type="radio"],
  input[type="checkbox"] {
    opacity: 0;
    filter: alpha(opacity=0);
    display: inline-block;
    position: absolute;
    z-index: -1;
    vertical-align: top;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    margin: 0;
  }

  .lb-radio,
  .lb-check {
    position: relative;
    display: inline-block;
    vertical-align: top;
    min-height: 20px;
    padding: 7px 0 0 30px;
    margin: 0;
    cursor: pointer;
    line-height: 1.5;
  }

  .fx-item .lb-radio,
  .fx-item .lb-check {
    min-height: 40px;
  }
  .intro-right .type-col.fx-wrap--first-row-car .label {
    width: 167px;
  }
  .intro-right .type-col.fx-wrap--first-row-car .label + .fx-item {
    width: 230px;
  }
  .lb-radio:before,
  .lb-check:before {
    content: "";
    display: inline-block;
    position: absolute;
    min-width: 22px;
    left: 0;
    top: 9px;
    min-height: 22px;
    width: 22px;
    height: 22px;
    margin-right: 7px;
    background-color: #fff;
    box-sizing: border-box;
    border: 1px solid #999;
    transition: box-shadow 0.5s, border 0.3s;
  }

  .lb-radio:before {
    border-radius: 50%;
  }

  .lb-check:before {
    top: 10px;
    min-width: 20px;
    min-height: 20px;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    margin-top: 0;
  }

  fieldset .lb-radio,
  fieldset .lb-check {
    margin-right: 80px;
  }

  fieldset .lb-radio:last-child,
  fieldset .lb-check:last-child {
    margin-right: 0;
  }

  input:checked + .lb-radio:before,
  input:checked + .lb-check:before,
  .lb-radio.checked:before,
  .lb-check.checked:before {
    border: 1px solid #f05014;
    background: #f05014
    url("/static/images/common/ico_form.png")
    no-repeat -3px -3px;
  }

  input:checked + .lb-check:before,
  .lb-check.checked:before {
    background-position: -4px -4px;
  }

  .lb-radio.activated:before,
  .lb-check.activated:before,
  .lb-radio:hover:before,
  .lb-check:hover:before {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }

  input:focus + .lb-radio > span,
  input:focus + .lb-check > span,
  input:focus + .lb-check + .tit-label {
    text-decoration: underline;
  }

  .lb-radio.checked.activated:before,
  .lb-check.checked.activated:before,
  .lb-radio.checked:hover:before,
  .lb-check.checked:hover:before {
    box-shadow: 0 0 5px rgba(253, 71, 0, 0.7);
  }

  input:focus + .lb-radio.checked > span,
  input:focus + .lb-check.checked > span {
    text-decoration: underline;
  }

  .lb-radio.checked,
  .lb-check.checked {
    color: #000;
  }

  .ui-acco-pnl .lb-check:before,
  .ui-acco-pnl .lb-radio:before {
    background-color: transparent;
  }

  input:disabled + .lb-radio,
  input:disabled + .lb-check,
  .lb-radio.disabled,
  .lb-check.disabled {
    opacity: 1;
    cursor: not-allowed;
  }

  input:disabled + .lb-check::before,
  .lb-check.disabled::before,
  input:disabled + .lb-radio::before,
  .lb-radio.disabled::before {
    border: none;
    background-color: rgba(0, 0, 0, 0.4);
  }

  input:checked:disabled + .lb-check::before,
  .lb-check.checked.disabled::before {
    border-color: #777;
    background-color: #777;
  }

  input:checked:disabled + .lb-check:before,
  .lb-check.checked:before {
    background-position: -79px -4px;
  }

  .lb-radio.disabled.checked,
  .lb-check.disabled.checked {
    opacity: 1;
  }

  input:disabled + .lb-check.required::before,
  .lb-check.disabled.required::before {
    border-color: #f05014;
    background-color: #f05014;
  }

  .lb-radio span,
  .lb-check span {
    display: inline-block;
    vertical-align: top;
    height: 100%;
    font-size: 16px;
    color: #111;
    line-height: 24px;
    border-bottom: 1px solid transparent;
  }

  .lb-tit {
    display: inline-block;
    height: 40px;
    margin-right: 10px;
    padding-top: 10px;
    vertical-align: top;
    cursor: pointer;
  }

  .lb-radio.type-toggle,
  .lb-check.type-toggle {
    position: relative;
    display: inline-block;
    vertical-align: top;
    align-items: center;
    min-width: 50px;
    min-height: 22px;
    font-size: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
    line-height: 1;
    background: #999;
    border: 0;
    border-radius: 11px;
    transition: background-color 0.1s ease-in;
  }

  .lb-radio.type-toggle:before,
  .lb-check.type-toggle:before {
    content: "";
    display: block;
    position: absolute;
    top: 2px;
    left: 2px;
    min-width: 18px;
    min-height: 18px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin: 0;
    box-sizing: border-box;
    background: #fff;
    border: 0;
    transition: left 0.1s ease-in;
  }

  input:checked + .lb-radio.type-toggle:before,
  input:checked + .lb-check.type-toggle:before,
  .lb-radio.type-toggle.selected:before,
  .lb-check.type-toggle.selected:before,
  .lb-radio.type-toggle.checked:before,
  .lb-check.type-toggle.checked:before {
    left: calc(100% - 20px);
    background-color: #fff;
    background-image: none;
    border: 0;
  }

  input:checked + .lb-radio.type-toggle,
  input:checked + .lb-check.type-toggle,
  .lb-radio.type-toggle.selected,
  .lb-check.type-toggle.selected,
  .lb-radio.type-toggle.checked,
  .lb-check.type-toggle.checked {
    background: #f05014;
    border: 0;
  }

  input:disabled + .lb-radio.type-toggle,
  input:disabled + .lb-check.type-toggle,
  .lb-radio.type-toggle.disabled,
  .lb-check.type-toggle.disabled,
  input:disabled + .lb-radio.type-toggle::before,
  input:disabled + .lb-check.type-toggle::before,
  .lb-radio.type-toggle.disabled::before,
  .lb-check.type-toggle.disabled::before {
    opacity: 0.5;
  }

  .lb-radio.type-toggle.activated,
  .lb-check.type-toggle.activated,
  .lb-radio.type-toggle:hover,
  .lb-check.type-toggle:hover,
  .lb-radio.type-toggle:focus,
  .lb-check.type-toggle:focus {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }

  .lb-radio.type-toggle.checked.activated,
  .lb-check.type-toggle.checked.activated,
  .lb-radio.type-toggle.checked:hover,
  .lb-check.type-toggle.checked:hover,
  .lb-radio.type-toggle.checked:focus,
  .lb-check.type-toggle.checked:focus {
    box-shadow: 0 0 5px rgba(253, 71, 0, 0.7);
  }

  .lb-radio.type-toggle span,
  .lb-check.type-toggle span {
    font-size: 0;
    line-height: 0;
  }

  .lb-check .btn-tip {
    top: -2px;
    margin: 0 5px 0 3px;
  }

  .check-ul li {
    margin-top: 8px;
  }

  .check-ul li:first-child {
    margin-top: 0;
  }

  //라디오 뱃지타입 추가
  .field-inlabel.field-inlabel--badge {
    margin-top: 5px;
  }
  .field-inlabel--badge .lb-radio--badge {
    margin-top: 7px;
    margin-bottom: 7px;
  }
  input[type="radio"] + .lb-radio--badge::before {
    display: none;
  }
  input[type="radio"] + .lb-radio--badge {
    overflow: hidden;
    display: inline-block;
    margin-right: 14px;
    padding-left: 14px;
    padding-right: 14px;
    height: 31px;
    color: #f05014;
    font-size: 13px;
    text-align: center;
    font-weight: 700;
    line-height: 29px;
    border: 1px #f05014 solid;
    border-radius: 31px;
    background: #fff;
    vertical-align: middle;
  }
  input[type="radio"] + .lb-radio--badge:hover {
    color: #fff;
    background: #f05014e0;
  }
  input[type="radio"]:checked + .lb-radio--badge,
  input[type="radio"]:checked + .lb-radio--badge:hover {
    color: #fff;
    background: #f05014;
  }

  .btn-toggle {
    position: relative;
    display: inline-block;
    margin-right: 10px;
  }

  .btn-toggle .lb-check {
    background: #f7f7f7;
    height: 44px;
    border-radius: 22px;
    border: 0;
    padding: 10px 20px 0;
    margin-right: 0;
  }

  .btn-toggle .lb-check:before {
    display: none;
  }

  .btn-toggle .lb-check span {
    font-size: 18px;
    color: #999;
    letter-spacing: -1px;
    font-weight: 700;
  }

  .btn-toggle :checked + .lb-check,
  .btn-toggle .lb-check.checked {
    background: #f05014;
  }

  .btn-toggle :checked + .lb-check span,
  .btn-toggle .lb-check.checked span {
    color: #fff;
  }

  .btn-toggle .lb-check + .btn-tip {
    margin-top: -10px;
  }

  .btn-toggle :checked + .lb-check + .btn-tip,
  .btn-toggle .lb-check.checked + .btn-tip {
    border-color: #fff;
    background-position: -54px -28px;
  }

  .btn-toggle.type-tip .lb-check {
    margin-right: 25px;
  }

  button.btn-toggle,
  a.btn-toggle {
    background: rgba(68, 68, 68, 0.99);
    height: 50px;
    border-radius: 25px;
    border: 0;
    padding: 0 18px;
    font-size: 15px;
    letter-spacing: -1px;
    color: #fff;
  }

  a.btn-toggle {
    padding: 14px 18px 0;
    text-decoration: none;
    color: #fff;
  }

  /* base form:select */
  .ui-select .btn-select {
    width: 100%;
    text-align: left;
    min-height: 51px;
    padding: 7px 20px 7px 0;
    border: 0;
    border-bottom: 1px solid #777;
    font-size: 16px;
    background: transparent;
    @include vendorPrefix(appearance, none);
    -webkit-border-radius: 0;
    cursor: pointer;
  }

  .ui-select::before,
  .ui-select-btn.arrow::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 10px;
    width: 0px;
    height: 0px;
    border: 5px solid transparent;
    border-top: 7px solid #333;
    z-index: 0;
  }

  .base-select {
    display: inline-block;
    position: relative;
    vertical-align: top;
    width: auto;
    height: 51px;
    padding: 0 20px 0 10px;
    border: 0;
    border-bottom: 1px solid #777;
    font-size: 22px;
    background: transparent;
    -webkit-border-radius: 0;
    cursor: pointer;
  }

  .ui-select select::-ms-expand {
    display: none;
  }

  .box-base select {
    border-color: #dcdcdc;
  }

  /* in label style */
  [class*="inp-"] {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    border: 0;
    font-size: 22px;
    color: #111;
    border-bottom: 1px solid #777;
    height: 51px;
    padding: 10px 10px 10px;
    outline: 0;
    background: transparent;
    font-weight: 400;
  }

  textarea[class*="inp-"] {
    padding: 15px 20px;
    height: 166px;
    overflow: auto;
    font-size: 18px;
    border: 1px #777 solid;
    margin-top: 10px !important;
  }

  textarea[class*="inp-"]::placeholder {
    font-size: 18px !important;
  }

  input[class*="inp-"]:focus {
    border-color: #f05014;
    border-width: 2px;
    padding-top: 11px !important;
  }

  [class*="inp-"]::-ms-clear {
    display: none;
  }

  textarea[class*="inp-"]:focus {
    font-size: 18px !important;
    padding-top: 15px !important;
    border-width: 1px !important;
  }

  input[class*="inp-"]::placeholder,
  textarea[class*="inp-"]::placeholder {
    color: #ccc;
  }

  input[class*="inp-"]:focus::placeholder,
  textarea[class*="inp-"]:focus::placeholder {
    color: #ccc;
  }

  .box-base .focus,
  .box-base [class*="inp-"] {
    border-color: #dcdcdc;
  }

  .field-inlabel [class*="inp-"].ui-inpcancel {
    padding-right: 50px;
  }

  .field-inlabel [class*="inp-"][readonly],
  .field-inlabel [class*="inp-"]:disabled,
  .field-inlabel [class*="inp-"][disabled] {
    border-color: #777 !important;
    color: #777 !important;
    opacity: 1;
    background-color: #f6f6f6;
    -webkit-text-fill-color: #777;
  }

  .field-inlabel [class*="inp-"][readonly]:focus {
    border-color: #777 !important;
    border-width: 1px !important;
  }

  .box-base .field-inlabel [class*="inp-"]:disabled,
  .box-base .field-inlabel [class*="inp-"][disabled] {
    border-color: #ddd !important;
    color: #555 !important;
    opacity: 1;
    -webkit-text-fill-color: #555;
  }

  .field-inlabel {
    position: relative;
    padding: 24px 0 0;
    display: inline-block;
    vertical-align: top;
    width: 100%;
  }

  .field-inlabel.no-label {
    padding-top: 20px;
  }

  .field-inlabel fieldset {
    display: inline-block;
    margin-top: 8px;
    width: 100%;
  }

  .field-inlabel + .field-inlabel.no-label {
    padding-top: 10px;
  }

  .field-inlabel .field-inlabel-tit,
  .field-inlabel .field-inlabel-label {
    position: absolute;
    opacity: 1;
    top: 0;
    left: 0;
    font-size: 14px;
    left: 0;
    white-space: nowrap;
  }

  .field-inlabel .field-inlabel-label.type-full {
    width: 100%;
  }

  .field-inlabel .field-inlabel-label.type-full .txt-helper {
    position: absolute;
    right: 0;
  }

  .field-inlabel .field-inlabel-tit.type-large {
    font-size: 16px;
  }

  .field-inlabel .placeholder {
    position: absolute;
    left: 0;
    padding: 0;
    top: 65px;
    line-height: 1.2;
    margin: 0;
  }

  .field-inlabel .ui-select .ui-error-msg {
    top: 42px;
  }

  .field-inlabel.ui-error-true .placeholder {
    display: none;
  }

  .merger-tit .field-inlabel {
    position: static;
  }

  .field-inlabel .inlabel-check {
    min-height: auto;
    padding: 0;
  }

  .field-data {
    font-size: 22px;
    margin-top: 10px;
  }

  /* input grid */
  .g6-1 {
    width: 85px !important;
  }

  .g6-2 {
    width: 190px !important;
  }

  .g6-3 {
    width: 295px !important;
  }

  .g6-4 {
    width: 400px !important;
  }

  .g6-5 {
    width: 505px !important;
  }

  [class*="g6-"] + button {
    margin-left: 20px;
    vertical-align: bottom;
  }

  [class*="g6-"] + [class*="g6-"] {
    margin-left: 20px;
  }

  /* ui inputclear */
  .ui-btn-cancel {
    display: inline-block;
    position: absolute;
    bottom: 14px;
    margin-left: -27px;
    width: 20px;
    height: 20px;
    background: #ddd;
    border-radius: 20px;
  }

  .ui-btn-cancel span {
    position: relative;
    display: block;
    left: 4px;
    width: 12px;
    height: 12px;
    background: transparent;
    color: transparent;
    overflow: hidden;
    font-size: xx-small;
  }

  .ui-btn-cancel span:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -1px;
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
    border-radius: 1px;
    @include vendorPrefix(transform, rotate(45deg));
  }

  .ui-btn-cancel span:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -1px;
    display: block;
    width: 2px;
    height: 100%;
    background: #fff;
    border-radius: 1px;
    @include vendorPrefix(transform, rotate(45deg));
  }

  /* error message */
  .ui-error-msg {
    position: relative;
    width: 100%;
    display: none;
    font-size: 13px !important;
    color: #e51c23;
    padding-left: 24px;
    margin-top: 4px;
    padding-top: 3px;
  }

  .ui-error-msg::before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 25px;
    height: 25px;
    background: url("/static/images/common/ico_form@2x.png")
    no-repeat -29px -25px;
    background-size: 150px;
  }

  .field-inlabel.type-large .ui-error-msg {
    padding: 4px 10px;
  }

  .ui-error-true .ui-error-msg {
    display: block;
  }

  .ui-error-true .ui-error-item,
  .ui-error-true .ui-error-select .ui-select-btn {
    border-color: #e51c23 !important;
  }

  /* confirmed message */
  .field-inlabel.type-large .ui-confirmed-msg {
    padding: 4px 10px 4px 24px;
  }

  .ui-confirmed-msg {
    width: 100%;
    display: block;
    position: relative;
    font-size: 13px !important;
    color: #00358e;
    padding-left: 24px;
    line-height: 25px;
    margin-top: 5px;
  }

  .ui-confirmed-msg:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 25px;
    height: 25px;
    background: url("/static/images/common/ico_form.png")
    no-repeat -130px -1px;
  }

  /* layout */
  .fx-wrap {
    position: relative;
    display: block;
    margin-top: 45px;
  }

  .fx-wrap.off {
    display: none;
  }

  .fx-wrap.type-jumin {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .fx-item {
    display: inline-block;
    width: 100%;
  }

  .fx-wrap + .btn-wrap {
    margin-top: 30px;
  }

  .col-2 .fx-item {
    min-width: calc(50% - 10px);
    max-width: calc(50% - 10px);
    vertical-align: top;
  }

  .col-2 .fx-item-txt {
    min-width: 24px;
    max-width: 24px;
    padding-top: 31px;
    text-align: center;
    color: #010101;
  }

  .col-2 .fx-item + .fx-item {
    margin-left: 20px;
  }

  .col-2 .fx-item .type-half .inp-base {
    width: 192px;
  }

  .col-4 .fx-item {
    min-width: calc(25% - 8px);
    max-width: calc(25% - 8px);
  }

  /* ico_bullet */
  .bul-tit {
    margin: 55px 0 13px;
    font-size: 16px;
    color: #111;
    font-weight: 700;
  }

  .bul-txt .bul-hyphen,
  .bul-dot,
  .bul-etc {
    font-size: 14px;
    color: #333;
    line-height: 24px;
  }

  .bul-hyphen li,
  .bul-hyphen > dd,
  p.bul-hyphen,
  p.bul-star {
    padding-left: 8px;
  }

  a.bul-dot,
  span.bul-dot {
    display: inline-block;
  }

  p.bul-dot,
  span.bul-dot,
  .bul-dot li,
  .bul-dot > dd {
    padding-left: 13px;
  }

  .bul-dot > li:before,
  .bul-dot > dd:before,
  p.bul-dot:before,
  span.bul-dot:before {
    width: 5px;
    height: 5px;
    background: #555;
  }

  a.bul-dot:before {
    width: 3px;
    height: 3px;
    top: 8px;
    left: 2px;
    background: #333;
  }

  .bul-txt.type-a,
  .bul-dot.type-a {
    color: #777;
  }

  .bul-dot.type-a > li:before,
  .bul-dot.type-a > dd:before,
  a.bul-dot.type-a:before,
  p.bul-dot.type-a:before {
    background: #999;
  }

  .bul-txt.type-b,
  .bul-dot.type-b,
  .bul-dot.type-b [class*="bul-"],
  .bul-hyphen.type-b {
    font-size: 14px;
    color: #777;
  }

  p.bul-dot.type-b,
  .bul-dot.type-b > li,
  .bul-dot.type-b > dd {
    padding-left: 10px;
    margin-top: 8px;
  }

  .bul-dot.type-b > li [class*="bul-"] li {
    margin-top: 5px;
  }

  .bul-dot.type-b > li:before,
  .bul-dot.type-b > dd:before,
  p.bul-dot.type-b:before {
    top: 9px;
    width: 3px;
    height: 3px;
    background: #999;
  }

  p.bul-dot.type-b:before {
    top: 10px;
  }

  .bul-hyphen.type-b > li:before {
    top: 1px;
  }

  .bul-dot.type-likelist {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .bul-dot.type-likelist > dt {
    position: relative;
    width: 140px;
    color: #333;
    padding-left: 13px;
  }

  .bul-dot.type-likelist > dd {
    width: calc(100% - 140px);
    padding: 0;
    margin: 0;
  }

  .bul-dot.type-likelist > dt::before {
    content: "";
    position: absolute;
    display: block;
    top: 10px;
    left: 0;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #555;
  }

  .bul-dot.type-likelist > dd::before {
    display: none;
  }

  .bul-dot em {
    color: #777;
  }

  .bul-care > li,
  div.bul-care,
  em.bul-care,
  p.bul-care {
    padding-left: 23px;
    margin-top: 7px;
    font-size: 14px;
    color: #777;
  }

  .bul-care [class*="bul-"] {
    font-size: 14px;
    color: #777;
  }

  .bul-care > li {
    margin-top: 9px;
  }

  .bul-care > li:first-child {
    margin-top: 7px;
  }

  .bul-care > li:before,
  div.bul-care:before,
  em.bul-care:before,
  p.bul-care:before {
    top: 3px;
    width: 16px;
    height: 16px;
    background: url("/static/images/common/ico_small.png")
    no-repeat -30px -4px;
  }

  .bul-care.hue-p:before {
    background: url("/static/images/common/ico_small@2x.png")
    no-repeat -105px -79px;
    background-size: 300px;
  }

  .bul-sdot,
  ul.bul-sdot > li,
  ul.bul-sdot > li > ul > li {
    position: relative;
    font-size: 14px;
    color: #777;
    padding-left: 9px;
    margin-top: 5px;
  }

  ul.bul-sdot > li > ul > li {
    padding-left: 15px;
  }

  ul.bul-sdot > li > ul > li:first-child {
    margin-top: 0;
  }

  ul.bul-sdot > li > ul > li > .bul-ico {
    width: 15px;
    margin-left: -15px;
  }

  ul.bul-sdot {
    padding-left: 0;
  }

  ul.bul-sdot::before {
    display: none;
  }

  ul.bul-sdot > li {
    margin-top: 0;
  }

  .bul-sdot::before,
  ul.bul-sdot > li::before {
    content: "";
    position: absolute;
    display: block;
    top: 9px;
    left: 0;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: #777;
  }

  .bul-sdot > li.emphasis__carrot,
  li .emphasis__carrot {
    color: var(--carrot-primary-color, #f05014);
    &:before {
      background: var(--carrot-primary-color, #f05014);
    }
  }

  .bul-sdot > .tit {
    font-size: inherit !important;
    color: #333;
    font-weight: 400 !important;
  }

  .hue-p {
    color: #f05014 !important;
  }

  .hue-s {
    color: #333 !important;
  }

  hr {
    border: 0;
    border-top: 1px dashed rgba(255, 255, 255, 0.3);
    margin: 10px 0;
  }

  .h1 {
    font-size: 32px !important;
    color: #111;
    line-height: 45px;
    font-weight: 700;
    margin: 0;
    word-break: keep-all;
  }

  .h1 i {
    font-size: 24px;
    font-weight: 400;
  }

  .h3 {
    font-size: 24px !important;
    color: #111;
    line-height: 32px;
    font-weight: 700;
    margin: 0;
    word-break: keep-all;
  }

  .h4 {
    font-size: 20px !important;
    color: #111;
    line-height: 28px;
    font-weight: 700;
    margin: 0;
    word-break: keep-all;
  }

  .h5 {
    font-size: 18px !important;
    color: #111;
    line-height: 26px;
    font-weight: 700;
    margin: 0;
    word-break: keep-all;
  }

  .h2 {
    font-size: 28px !important;
    color: #111;
    line-height: 36px;
    font-weight: 700;
    margin: 0;
    word-break: keep-all;
  }

  .h6 {
    font-size: 16px !important;
    color: #111;
    line-height: 24px;
    font-weight: 700;
    margin: 0;
    word-break: keep-all;
  }

  .txt-b {
    font-weight: 700;
  }

  .tit-sub {
    margin-top: 7px;
    font-size: 16px;
    color: #777;
    font-weight: 400;
    line-height: 24px;
  }

  .tit-sub b {
    color: #111;
    font-weight: 700;
  }

  .ui-modal-cont .tit-sub {
    margin-top: 8px;
    font-size: 18px;
    color: #333;
    font-weight: 400;
    line-height: 26px;
  }

  .txt-helper {
    opacity: 1;
  }

  .activated .txt-helper {
    opacity: 1;
  }

  .txt-price {
    font-size: 16px;
    color: #000;
    line-height: normal;
    letter-spacing: -0.05em;
  }

  .txt-price b {
    margin-right: 5px;
    font-size: 23px;
    font-weight: 700;
    vertical-align: top;
    line-height: 1;
  }

  .ui-fixed-top .box-srch {
    padding: 10px 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

  .box-srch {
    padding: 10px 0;
    background: #fff;
  }

  .base-wrap {
    min-width: 1280px;
}

  /* layout */
  .base-header {
    position: fixed;
    width: 100%;
    z-index: 3;
    height: 96px;
    opacity: 1;
    background: #fff;
    top: 0;
    left: 0;
  }

  .base-header .logo {
    display: block;
    float: left;
    width: auto;
    text-align: left;
    font-size: 18px;
    font-weight: 700;
    text-decoration: none;
    padding: 0;
  }

  .base-header .logo em {
    display: inline-block;
    color: #111;
    vertical-align: middle;
    margin-left: 20px;
    margin-bottom: 10px;
  }

  .base-header .logo a {
    display: inline-block;
    text-decoration: none;
    vertical-align: middle;
  }

  .base-header .mLogo {
    position: absolute;
    display: none;
    top: 26px;
    left: 27px;
    width: 95px;
  }
  .base-header .mLogo img {
    width: 100%;
  }
  .base-header .btn-login {
    float: right;
    position: relative;
    display: inline-block;
    width: 69px;
    min-height: 27px;
    min-width: 68px;
    margin: 2px 0 0 20px;
    border: 1px solid #343434;
    background: #fff;
    background-image: none;
    text-decoration: none;
    font-size: 14px;
    color: #343434;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    border-radius: 4px;
    transition: background-color 0.2s ease-in;
    letter-spacing: -0.03em;
    vertical-align: top;
  }
  .btn-login:hover {
    border-color: #333;
    color: #111;
    background-color: #fcfcfc;
  }
  .base-header .btn-login.log-on {
    background-color: #343434;
    border-color: #343434;
    color: #fff;
  }
  .base-header .btn-info {
    width: 73px;
    height: 30px;
    margin: 0 24px 0 32px;
    background-color: #eee;
    border-radius: 8px;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    letter-spacing: -1px;
    color: #777777;
  }

  .base-header .btn-step {
    display: inline-block;
    vertical-align: top;
    margin: 3px 0 0 -25px;
  }
  .base-header .nav-close {
    display: none;
  }

  .base-header-wrap {
    max-width: 1440px;
    width: 100%;
    min-width: 1300px;
    height: 96px;
    padding: 34px 30px 0;
    margin: 0 auto;
  }

  .base-header-wrap:after {
    content: "";
    display: block;
    clear: both;
  }

  .base-body {
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding-top: 96px;
    z-index: 1;
    min-height: 90vh;
  }

  .base-footer {
    position: relative;
    width: 100%;
    z-index: 0;
    padding: 10px 32px;
    font-size: 12px;
    margin-top: 40px;
  }

  .base-footer.main {
    margin-top: 0;
  }

  .type-floating .base-footer {
    margin-top: 200px;
  }

  .base-main {
    display: block;
    position: relative;
    width: 100%;
  }

  .wrap-inner-bg {
    padding: 0 32px;
    background: #f1f1f1;
  }

  .wrap-inner-line {
    border-top: 10px solid #eee;
  }

  .dropdownOpened .base-header {
    z-index: 2;
  }

  .dropdownOpened .btn-wrap-fixed {
    bottom: 0;
  }

  /* footer */
  .footer-address {
    width: 100%;
    max-width: 1440px;
    font-size: 14px;
    color: #333;
    margin: 0 auto;
    font-style: normal;
    text-align: center;
  }

  .footer-address strong {
    font-weight: 600;
  }

  .footer-address span {
    display: inline-block;
    position: relative;
    padding: 0 15px;
  }

  .footer-address span:first-child:before {
    display: none;
  }

  .footer-address span:before {
    content: "";
    display: block;
    position: absolute;
    top: 6px;
    left: 0;
    width: 1px;
    height: 10px;
    background: #d7d7d7;
  }
  // .footer-subpage .main-footer.main-footer--design02{
  //   position: static;
  // }

  /* footer 변경된 디자인 적용 */
  .main-footer.main-footer--design02 {
    height: auto;
    padding-top: 0;
    padding-right: 28px;
  }
  .main-footer__inner {
    width: 100%;
    max-width: 1565px;
    padding: 40px 0 30px 76px;
  }
  .main-footer__inner strong {
    font-weight: 700;
  }
  .main-footer__inner strong {
    font-weight: bold;
  }
  .main-footer__inner:after {
    content: "";
    clear: both;
    display: block;
  }
  .main-footer__left {
    float: left;
  }
  .main-footer__right {
    float: right;
  }
  .main-footer__tit {
    font-size: 27px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .main-footer__company {
    font-size: 13px;
  }
  .main-footer__company address {
    font-style: normal;
  }
  .main-footer__company address > p {
    margin-bottom: 13px;
  }
  .main-footer__copy {
    margin-top: 20px;
    color: #787878;
  }
  .footer-btns .main-footer-select {
    width: 170px;
    height: 36px;
    display: inline-block;
    float: none;
    vertical-align: middle;
    margin-top: 0;
    margin-left: 11px;
  }
  .footer-btns .main-footer-select .btn-select {
    height: 36px;
    text-align: center;
  }
  .footer-btns .main-footer-select > .btn-select::after {
    content: "";
    position: absolute;
    right: 16px;
    top: 15px;
    width: 7px;
    height: 7px;
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    vertical-align: middle;
    margin-left: 12px;
    border-left: 0;
  }
  .footer-btns .main-footer-select > .select-layer {
    width: 170px;
    padding-left: 1px;
  }
  .btn-footer {
    position: relative;
    display: inline-block;
    min-width: 170px;
    height: 36px;
    border: 1px #777 solid;
    color: #fff;
    vertical-align: top;
    margin-left: 11px;
    padding: 0 6px;
    text-align: right;
    font-weight: 300;
    text-align: center;
  }
  .btn-footer.btn-footer--bg {
    border-width: 0;
    background: #434343;
  }
  .ico-footbtn-arrow {
    display: inline-block;
    width: 7px;
    height: 7px;
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    vertical-align: 1px;
    margin-left: 6px;
  }
  .main-footer__customer {
    text-align: right;
    font-size: 13px;
    margin-top: 20px;
  }
  .main-footer__customer > p {
    margin-bottom: 10px;
  }
  .main-footer__customer .label {
    font-weight: 500;
    padding-right: 6px;
  }
  .main-footer__customer .footer-bar {
    display: inline-block;
    padding: 0 8px;
  }
  /* desktop layout */
  .wrap-content {
    position: relative;
    width: 100%;
  }
  .plan-single .wrap-inner,
  .type-floating .wrap-content > .wrap-inner {
    position: relative;
    left: 50%;
    margin-left: -305px;
    transition: left 0.2s ease-in, margin 0.2s ease-in;
    padding-bottom: 60px;
  }

  .type-floating.side-line .tooltip-side {
    right: -461px;
    width: 460px;
    border: 1px solid #ddd;
    margin-left: 0;
    padding-left: 40px;
    padding-bottom: 60px;
  }

  .wrap-inner {
    padding: 0;
    width: 610px;
    margin: 0 auto;
  }

  .base-main > .wrap-inner:last-child,
  .wrap-content > .wrap-inner:last-child {
    padding-bottom: 60px;
  }

  .wrap-padding {
    padding-top: 75px;
  }

  /* side modal */
  .tooltip-side {
    position: fixed;
    top: 0;
    right: -1000px;
    width: 420px;
    height: 100%;
    padding: 0 0 0 28px;
    background: #fff;
    border: 1px solid #eee;
    transition: right 0.2s ease-in;
  }

  .tooltip-side .btn-wrap-fixed,
  .wrap-side .btn-wrap-fixed {
    margin-top: 60px;
  }

  .tooltip-side .btn-wrap-fixed.col-2 .btn-base-l,
  .tooltip-side .btn-wrap-fixed.col-2 .btn-base-l.type-b,
  .wrap-side .btn-wrap-fixed.col-2 .btn-base-l,
  .wrap-side .btn-wrap-fixed.col-2 .btn-base-l.type-b,
  .btn-wrap-fixed.type-half .btn-base-l,
  .btn-wrap-fixed.type-half .btn-base-l.type-b {
    width: 50%;
  }

  .tooltip-side.wrap-side {
    padding: 0 0 0 20px;
  }

  .tooltip-side-close {
    position: absolute;
    top: 25px;
    right: -4px;
    z-index: 1;
  }

  .view-aside-tooltip .plan-single .wrap-inner,
  .view-aside-tooltip-car .plan-single .wrap-inner,
  .type-car.type-floating .wrap-content > .wrap-inner,
  .view-aside-tooltip-car .type-floating .wrap-content > .wrap-inner,
  .view-aside-tooltip .type-floating .wrap-content > .wrap-inner {
    position: relative;
    left: 50%;
    margin-left: -495px;
  }

  .view-aside-tooltip .tooltip-side {
    right: 0;
  }

  .view-aside-tooltip .type-floating.side-line .tooltip-side {
    right: 0;
    margin-right: 20px;
  }

  .view-aside-tooltip-car .wrap-side {
    right: 0;
  }

  .view-aside-tooltip-car .type-floating.side-line .wrap-side {
    right: 0;
    margin-right: 20px;
  }

  .tooltip-side .ui-scrollbar {
    width: 100%;
    padding-right: 32px;
  }

  .tooltip-side .ui-scrollbar-item {
    width: calc(100% - 32px);
    padding: 40px 0;
  }

  .tooltip-side .ui-scrollbar-barwrap.type-y {
    right: 6px;
    height: calc(100% - 10px);
    top: 5px;
  }

  .type-floating.side-line .tooltip-side .ui-scrollbar {
    padding-right: 40px;
  }

  .type-floating.side-line .tooltip-side .ui-scrollbar-item {
    width: calc(100% - 40px);
  }

  .wrap-side .ui-scrollbar {
    width: 100%;
    padding-right: 20px;
  }

  .wrap-side .ui-scrollbar-item {
    width: calc(100% - 20px);
    padding: 40px 0;
  }

  .wrap-side .ui-scrollbar-barwrap.type-y {
    right: 3px;
    top: 5px;
    height: calc(100% - 10px);
    border-radius: 3px;
  }

  /* 보험금청구/청구결과조회 sidemodal scrollbar */
  /* 자동차보험 담보 sidemodal scrollbar */
  .tooltip-side.carSidemodal .ui-scrollbar {
    overflow: hidden;
    padding-right: 0;
  }
  .type-sidescroll .tooltip-side .ui-scrollbar {
    overflow-y: auto;
  }

  .tooltip-side.carSidemodal .tooltip-cont::-webkit-scrollbar,
  .type-sidescroll .tooltip-side .ui-scrollbar::-webkit-scrollbar {
    width: 10px;
    height: 20px;
  }

  .tooltip-side.carSidemodal .tooltip-cont::-webkit-scrollbar-track,
  .type-sidescroll .tooltip-side .ui-scrollbar::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
  }

  .tooltip-side.carSidemodal .tooltip-cont::-webkit-scrollbar-thumb,
  .type-sidescroll .tooltip-side .ui-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #fff;
    border: 2px solid #ecebea;
  }

  .tooltip-side.carSidemodal .tooltip-cont::-webkit-scrollbar-button,
  .type-sidescroll .tooltip-side .ui-scrollbar::-webkit-scrollbar-button {
    width: 20px;
    height: 10px;
  }

  .type-sidescroll .tooltip-side-close {
    right: auto;
    left: 362px;
  }

  .tooltip-side.carSidemodal .ui-scrollbar-item {
    width: 100%;
    height: 100%;
    padding-bottom: 0;
  }

  .tooltip-side.carSidemodal .tooltip-side-close {
    right: 28px;
  }

  .tooltip-side.carSidemodal .tooltip-cont {
    height: calc(100% - 52px);
    padding-right: 32px;
    padding-bottom: 40px;
    overflow-y: auto;
  }

  /* input[type=tel] 이면서 password 처럼 보여줘야 할때 */
  .tel-password {
    -webkit-text-security: disc;
  }

  /* width 1000px layout */
  .type-wide .wrap-top {
    border-bottom: 1px #ddd solid;
    padding: 73px 0 55px;
  }
  .fixed-footer-use .footer-subpage {
    position: fixed;
    bottom: 213px;
    left: 0;
    width: 100%;
    z-index: 10;
  }
  .fixed-footer-use .footer-subpage .main-footer {
    height: 213px;
  }
  .type-wide .wrap-top > .announce-info-wrap,
  .type-wide .wrap-inner {
    width: 1000px;
    margin: 0 auto;
  }

  .type-wide .wrap-inner {
    margin-top: 80px;
  }

  .type-wide .wrap-top + .wrap-inner {
    margin-top: 60px;
  }

  .type-wide .h2 {
    font-size: 22px !important;
    line-height: 1;
  }

  .type-wide .ui-acco-btn {
    font-weight: 400;
    color: #333;
  }

  .type-wide .ui-acco-btn.selected {
    font-weight: 700;
  }

  .type-wide .nothing {
    height: 160px;
    color: #777;
    text-align: center;
    line-height: 160px;
    border-bottom: 1px #eee solid;
  }

  .type-wide .type-top {
    margin-top: 80px !important;
  }

  .type-wide .fx-wrap.type-srch {
    text-align: right;
  }

  .type-wide .fx-wrap.type-srch > .fx-item {
    width: 490px;
    text-align: left;
  }

  .type-wide .fx-wrap.type-srch .ui-error-msg {
    display: block;
  }

  .type-wide .tbl-base.type-s thead th {
    vertical-align: middle;
    border-left: 0;
  }

  .type-wide .tbl-base.type-s tbody th {
    border-bottom: 0;
    vertical-align: middle;
  }

  .type-wide .tbl-base.type-s thead th.brd-l,
  .type-wide .tbl-base.type-s tbody td.brd-l {
    border-left: 1px #eee solid;
  }

  .type-wide .tbl-base.type-s tfoot td,
  .type-wide .tbl-base.type-s tbody td {
    border-bottom: 0;
    border-left: 0;
    padding-left: 20px;
    padding-right: 20px;
    vertical-align: middle;
    height: 60px;
  }

  .type-wide .tbl-base.type-s tbody td.txt-r {
    padding-right: 10px;
  }

  .type-wide .tbl-base.type-s.brd-b {
    border-bottom: 0;
  }

  .type-wide .tbl-base.type-s.brd-b tbody th,
  .type-wide .tbl-base.type-s.brd-b tbody td {
    border-bottom: 1px #eee solid;
  }

  .type-wide .tbl-base.type-s tbody td.nothing {
    text-align: center;
    color: #777;
  }

  .type-wide .tbl-base.type-s tbody td.nothing b {
    font-weight: 700;
    color: #333;
  }

  .type-wide .tbl-base.type-s tbody.line-t,
  .type-wide .tbl-base.type-s tfoot.total {
    border-top: 1px solid #eee;
  }

  .type-wide .tbl-base.type-s tfoot.total td {
    font-weight: 700;
    color: #f05014;
  }

  .type-wide .tbl-base.type-s tfoot.total td.txt-r {
    padding-right: 10px;
  }

  .type-wide .tbl-base.type-s tfoot.total.normal td {
    font-weight: 700;
    color: #333;
  }

  .type-wide .tbl-base tbody .btn-txt span {
    max-width: 760px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 1px;
  }

  .type-wide .info-header {
    position: relative;
    font-size: 18px;
    text-align: center;
    padding: 16px 0;
    background: #f7f7f7;
  }

  .type-wide .bul-sdot li {
    margin-top: 7px;
  }

  .type-wide .bul-dot .bul-etc li {
    font-size: 14px;
    color: #777;
    padding-left: 20px;
    margin-top: 0;
  }

  .type-wide .bul-dot .bul-etc li > .bul-ico {
    margin-left: -20px;
  }

  .type-wide .srch-wrap {
    position: relative;
    text-align: right;
  }

  .type-wide .srch-item {
    display: inline-block;
  }

  .type-wide .srch-item.tit {
    width: 190px;
  }

  .type-wide .srch-item.inp {
    width: 400px;
    text-align: left;
    margin-left: 20px;
  }

  .type-wide .srch-item.inp .ui-select {
    width: 240px;
  }

  .type-wide .btn-txt {
    font-size: inherit;
  }

  .type-wide .img-wrap {
    margin-top: 20px;
  }

  .type-wide .step-row.type-line li dt {
    width: 220px;
  }

  .type-wide .step-row.type-line li dd {
    width: calc(100% - 220px);
  }

  .type-wide .step-row.type-line li dd b {
    font-weight: 700;
  }

  /* width 1000 table */
  .type-wide .list-base-item {
    padding: 30px 40px;
  }

  .type-wide .tbl-board.type-a th::before {
    display: none;
  }

  .type-wide .tbl-board thead th,
  .type-wide .tbl-board tbody td {
    padding: 14px 20px 17px;
  }

  .field-dl > .field-dt {
    font-size: 18px;
  }

  .main-inner {
    width: 100%;
    max-width: 1400px;
    min-width: 1260px;
    padding: 0 40px;
    margin: 0 auto;
  }

  .main-sec-b,
  .main-sec-c,
  .main-sec-a {
    width: 100%;
    position: relative;
    margin-bottom: 10px;
  }

  .main-sec-a > div:first-child,
  .main-sec-b > div:first-child,
  .main-sec-c > div:first-child {
    margin-left: 0;
  }

  .main-sec-a::after,
  .main-sec-b::after,
  .main-sec-c::after {
    content: "";
    display: block;
    clear: both;
  }

  /* 상품소개 layout */
  .intro-insu {
    position: relative;
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    height: calc(100vh - 96px);
    min-height: 800px;
    align-items: stretch;
  }

  .intro-insu .intro-left {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 50%;
    height: 100%;
    background: #f8f8f8;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    align-items: flex-end;
  }

  .intro-insu .intro-right {
    position: relative;
    width: 50%;
    height: 100%;
    color: #fff;
    background: #262626;
  }

  .intro-insu .intro-insu-item {
    position: relative;
    width: 720px;
    height: 100%;
    overflow: hidden;
  }

  .intro-insu .intro-insu-item .insu-cont::after {
    content: "";
    position: absolute;
    display: block;
    right: -82px;
    top: -234px;
    width: 512px;
    height: 294px;
    border-bottom: 1px #f05014 solid;
    background-repeat: no-repeat;
    z-index: 1;
    pointer-events: none;
  }

  .intro-insu .intro-left .intro-insu-item {
    padding: 80px 87px 0 87px;
  }

  .intro-insu .intro-right .intro-insu-item {
    width: 100%;
    padding: 80px 87px 0 87px;
  }

  .intro-insu .intro-right-inner {
    width: 464px;
    min-height: 443px;
  }

  /* 상품소개 왼쪽 */
  .intro-left .insu-boolean {
    position: relative;
    display: inline-block;
    font-size: 16px;
    color: #111;
    font-weight: 500;
    border-bottom: 5px #f05014 solid;
  }

  .intro-left .insu-sub-tit {
    position: relative;
    font-size: 22px;
    color: #b5b5b6;
    margin-top: 30px;
    line-height: 1;
  }

  .intro-left .insu-sub-tit strong {
    position: relative;
    display: inline-block;
    color: #111;
    font-weight: 700;
    padding: 0 5px;
  }

  .intro-left .insu-sub-tit strong::after {
    content: "";
    position: absolute;
    display: block;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 10px;
    background: #f05014;
    z-index: -1;
  }

  .intro-left .insu-tit {
    position: relative;
    font-size: 38px;
    font-weight: 100;
    color: #111;
    letter-spacing: -4px;
    line-height: 1;
    margin-top: 26px;
  }

  .intro-left .slide-wrap,
  .intro-left .slick-slider {
    height: 100%;
  }

  .intro-left .slide-wrap .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 30px;
  }
  .intro-left .slide-wrap .swiper-button-prev {
    left: 16px;
    color: #d9d9d9;
  }
  .intro-left .slide-wrap .swiper-button-next {
    right: 16px;
    color: #d9d9d9;
  }

  .intro-left .slide-wrap .swiper-pagination {
    width: 100%;
    text-align: center;
    bottom: 20px;
  }

  .intro-left .slide-wrap .swiper-pagination-bullet {
    width: 14px;
    height: 4px;
    border-radius: 2px;
    background: #c9c9c9;
    margin: 0 6px 0 0 !important;
    opacity: 1;
  }

  .intro-left .slide-wrap .swiper-pagination-bullet-active {
    background: #f05014;
  }

  .intro-left .slide-wrap .swiper-pagination-old {
    text-align: center;
  }

  .intro-left .slide-wrap .slider-item {
    padding-left: 79px;
  }

  .intro-left .slide-wrap .slick-active .slider-item {
    padding: 0;
  }

  .intro-left .insu-cont {
    position: relative;
    height: 290px;
    background: #fff;
    padding: 60px 16px 0;
    margin-top: 28px;
  }
  .img-permile_premium {
    width: 332px;
    margin-bottom: 20px;
  }
  .insu-cont .slide-wrap--car .insu-cont-exp.lts-minus .insu-cont-exp__des {
    letter-spacing: -2px;
  }
  .insu-cont .insu-cont-exp__desc {
    display: block;
    font-size: 17px;
    letter-spacing: -2px;
  }
  .insu-cont-row {
    font-size: 22px;
  }
  .insu-cont-row.type2:after {
    content: "";
    display: block;
    clear: both;
  }
  .insu-cont-row.type2 span {
    font-size: 15px;
    display: block;
    width: 100px;
    float: left;
    text-align: center;
  }
  .insu-cont-row.type2 span:first-child {
    width: 92px;
  }
  .insu-cont-row.type2 span:nth-child(2) {
    width: calc(100% - 200px);
  }
  .insu-cont-row.type2 span:nth-child(3) {
    width: 100px;
  }
  .insu-cont-row.type2 span:nth-child(2) i {
    display: inline-block;
    padding: 0 5px;
  }
  .intro-left .slick-prev,
  .intro-left .slick-next {
    top: 68px;
    width: 18px;
    height: 34px;
    transform: none;
    z-index: 1;
  }

  .intro-left .slick-prev {
    left: -70px;
  }

  .intro-left .slick-next {
    right: -70px;
  }

  .intro-left .slick-prev::before,
  .intro-left .slick-next::before {
    content: "";
    position: relative;
    display: block;
    width: 15px;
    height: 32px;
    background-repeat: no-repeat;
  }

  .intro-left .slick-prev::before {
    background-image: url("/static/images/main/insu_arrow_prev.png");
  }

  .intro-left .slick-next::before {
    background-image: url("/static/images/main/insu_arrow_next.png");
  }

  .intro-left .insu-cont .insu-cont-tit {
    position: relative;
    display: inline-block;
    height: 32px;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    line-height: 32px;
    padding: 0 15px;
    background: #434343;
    border-radius: 8px;
  }

  .intro-left .insu-cont .insu-cont-exp {
    position: relative;
    font-size: 24px;
    font-weight: normal;
    color: #111;
    line-height: 1.4;
    letter-spacing: -3px;
    margin-top: 25px;
  }

  .intro-left .insu-cont .insu-cont-exp .ico-start::after {
    content: "*";
    color: #f05014;
  }

  .intro-left .insu-cont .insu-cont-exp em {
    color: #f05014;
  }

  .intro-left .insu-cont .insu-cont-exp .en {
    letter-spacing: -1px;
  }

  .intro-left .insu-cont p {
    font-size: 13px;
    font-weight: 200;
    color: #343434;
    line-height: 1.6;
    margin-top: 10px;
    letter-spacing: -1px;
  }

  .intro-left .insu-cont p + p {
    margin-top: 0;
  }

  .intro-left .insu-cont p .label {
    display: inline-block;
    color: #00358e;
    font-weight: 400;
    margin-right: 5px;
  }

  .intro-left .insu-btns {
    position: relative;
    display: table;
    margin-top: 25px;
    margin-left: 0px !important;
    width: 241px;
    float: left;
  }

  .intro-left .insu-btn-item {
    display: table-cell;
    width: 96px;
    vertical-align: top;
  }

  .intro-left .insu-btn-item + .insu-btn-item {
    padding-left: 8px;
  }

  .intro-left .insu-btns button {
    display: block;
    width: 96px;
    height: 31px;
    min-height: auto;
    font-size: 12px;
    color: #f05014;
    font-weight: 500;
    line-height: 29px;
    border: 1px #f05014 solid;
    background: #fff;
    border-radius: 8px;
    padding: 0;
  }

  .intro-left .insu-btns button > span {
    display: inline-block;
    line-height: 1;
  }

  .intro-left .insu-btns button + button {
    margin-top: 12px;
  }

  .intro-left .insu-btns button:hover {
    color: #fff;
    background: #f05014;
  }

  .intro-left .insu-floating {
    position: absolute;
    left: 66px;
    bottom: 206px;
    height: 50px;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease-out;
  }

  .intro-left .insu-floating .btn-close {
    position: absolute;
    display: none;
    top: 31px;
    right: -306px;
    width: 10px;
    height: 10px;
    background-position: -51px -27px;
    background-size: 150px;
  }

  .intro-left .insu-floating.show {
    opacity: 1;
    pointer-events: all;
  }

  .intro-left .insu-floating-btn {
    position: relative;
    min-width: 50px;
    height: 50px;
  }

  .intro-left .insu-floating.show .btn-close {
    display: block;
  }

  .intro-left .insu-floating-btn > .btn-ico {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 78px;
    height: 78px;
    background: url("/static/images/content/ico_floating.png") center no-repeat;
    background-size: 100%;
    border-radius: 78px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .intro-left .insu-floating-btn > .btn-txt {
    position: absolute;
    display: block;
    top: 21px;
    left: 0;
    width: 0;
    height: 32px;
    font-size: 15px;
    color: #00358e;
    font-weight: 300;
    line-height: 32px;
    background: #e8edf5;
    border: 0;
    border-radius: 0 16px 16px 0;
    overflow: hidden;
    margin-left: 30px;
    padding-left: 55px;
    transition: all 0.2s ease-out 0.4s;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  }

  .intro-left .insu-floating-btn b {
    font-weight: 500;
  }

  .intro-left .insu-floating.show .insu-floating-btn > .btn-txt {
    width: 290px;
  }

  .intro-left .insu-floating.show .insu-floating-btn:hover > .btn-ico,
  .intro-left .insu-floating.show .insu-floating-btn:focus > .btn-ico {
    border-color: #555;
  }

  .intro-left .review-num {
    margin-top: 25px;
    font-size: 10px;
    text-align: right;
    letter-spacing: -1px;
    color: #9d9d9d;
    width: 200px;
    float: right;
    line-height: 2;
  }

  /* 상품소개 오른쪽 */
  .intro-right .intro-title .tit-hello {
    position: relative;
    display: inline-block;
    font-size: 34px;
    color: #fff;
    font-weight: 700;
    line-height: 1;
  }

  .intro-right .intro-title .tit-hello::after {
    content: "";
    position: absolute;
    display: block;
    bottom: 2px;
    right: -10px;
    width: 6px;
    height: 6px;
    background: #f05014;
    border-radius: 6px;
  }

  .intro-right .intro-title .tit-info {
    position: relative;
    margin-top: 14px;
  }

  .intro-right .intro-title .tit-info::after {
    content: "";
    display: block;
    clear: both;
  }

  .intro-right .intro-title .tit-info h2 {
    float: left;
    font-size: 34px;
    font-weight: 100;
    line-height: 1.3;
    letter-spacing: -2px;
  }

  .intro-right .intro-title .btn-wrap {
    float: right;
    width: auto;
    margin: 0;
    margin-top: 6px;
  }

  .intro-right .intro-title .btn-base {
    width: 97px;
    height: 31px;
    min-height: auto;
    color: #f05014;
    font-size: 13px;
    font-weight: 700;
    line-height: 29px;
    border: 1px #f05014 solid;
    border-radius: 31px;
    padding: 0;
    background: #fff;
  }

  .intro-right .intro-title .btn-base.selected {
    color: #fff;
    background: #f05014;
  }

  .intro-right .intro-title .btn-base:hover {
    color: #fff;
    background: rgba(237, 91, 22, 0.4);
  }

  .intro-right .intro-title .btn-base.selected:hover {
    background: #f05014;
  }

  .intro-right .type-col {
    margin-top: 0;
  }

  .intro-right .type-col::after {
    content: "";
    display: block;
    clear: both;
  }

  .intro-right .type-col > .fx-item {
    float: left;
  }

  .intro-right .type-col .label,
  .intro-right .type-col .text {
    position: relative;
    font-size: 22px;
    font-weight: 200;
    padding-top: 5px;
    letter-spacing: -2px;
  }

  .intro-right .type-col .label {
    width: 114px;
  }

  .intro-right .type-col .label + .fx-item,
  .intro-right .type-col .label + .type-jumin {
    width: 285px;
  }

  .intro-right .type-col .label + .fx-item.type-radio {
    width: 138px;
  }

  .intro-right .type-col .label + .type-jumin {
    float: left;
    margin-top: 0;
  }

  .intro-right .type-col .text {
    width: 65px;
  }

  .intro-right .type-col .label + .fx-item.type-radio + .text {
    padding-top: 45px;
  }

  .intro-right .type-col .label.type-line2 {
    line-height: 1;
    padding-top: 0;
  }
  .intro-right .fx-item .fx-item__block {
    display: block;
    padding-top: 8px;
  }
  .intro-right .type-col .label > em {
    font-size: 14px;
  }

  .intro-right .field-inlabel {
    padding: 0;
  }

  .intro-right .inp-base {
    height: 40px;
    font-size: 16px;
    font-weight: 200;
    color: #fff;
    letter-spacing: -1px;
    text-align: center;
    border-bottom: 1px #6a6a6a solid;
    caret-color: #fff;
    padding: 10px !important;
  }

  .intro-right .inp-base::placeholder,
  .type-multi
  .intro-right
  .type-col
  .inp-datepicker
  [class*="inp-"]::placeholder {
    font-size: 16px;
    font-weight: 200;
    letter-spacing: -1px;
    color: rgba(255, 255, 255, 0.3);
  }

  .intro-right .inp-base:-webkit-autofill {
    color: #fff !important;
    -webkit-box-shadow: 0 0 0 1000px #343434 inset;
    -webkit-text-fill-color: #fff;
  }

  .type-multi .intro-right .type-col .label,
  .type-driver .intro-right .type-col .label {
    width: 100%;
  }

  .intro-right .type-col .btn-tip {
    margin-bottom: 5px;
    border-color: #fff;
    background-position: -54px -28px;
  }

  .intro-right .type-col .label + .type-jumin .field-inlabel-label {
    display: none;
  }

  .intro-right .type-col .label + .type-jumin .inp-base {
    height: 40px;
    letter-spacing: 0.5px;
  }

  .intro-right .type-col .label + .type-jumin .inp-base[disabled],
  .intro-right .type-col .label + .type-jumin .inp-base[readonly] {
    background: transparent;
    border-color: #6a6a6a !important;
    -webkit-text-fill-color: rgba(255, 255, 255, 0.3) !important;
  }

  .intro-right .type-col .label + .type-jumin .in-item {
    padding-top: 9px;
  }

  .intro-right .type-col input[type="radio"] + .lb-radio::before {
    display: none;
  }

  .intro-right .type-col input[type="radio"] + .lb-radio {
    display: block;
    width: 134px;
    height: 31px;
    min-height: auto;
    color: #f05014;
    font-size: 13px;
    font-weight: 700;
    line-height: 29px;
    border: 1px #f05014 solid;
    border-radius: 31px;
    padding: 0;
    background: #fff;
  }

  .intro-right .type-col input[type="radio"] + .lb-radio:hover {
    color: #fff;
    background: rgba(237, 91, 22, 0.4);
  }

  .intro-right .type-col input[type="radio"]:checked + .lb-radio,
  .intro-right .type-col input[type="radio"]:checked + .lb-radio:hover {
    color: #fff;
    background: #f05014;
  }

  .intro-right .type-security .inp-base {
    padding-right: 50px !important;
  }

  .intro-right .btn-security {
    width: 100%;
    height: 32px;
    text-align: right;
  }

  .intro-right .btn-security::before {
    width: 32px;
    height: 32px;
    background-image: url("/static/images/main/btn_mouse.png");
  }
  .intro-right .btn-security:hover::before {
    background-position: 0;
  }

  .intro-right .btn-security::after {
    top: 7px;
    left: 7px;
    width: 16px;
    height: 16px;
  }

  .intro-right .btn-security[aria-expanded="true"]::before {
    background-position: 0 0;
  }

  .intro-right .btn-security:hover:after {
    display: none;
  }

  .intro-right .keypad-head h1 {
    color: #333;
  }

  .intro-right p {
    font-size: 22px;
    font-weight: 200;
    letter-spacing: -1.5px;
  }

  .intro-right p.bul-care {
    font-size: 14px;
  }

  .intro-right p > em {
    color: #f05014;
  }

  .intro-right .btn-wrap-fixed {
    margin-top: 60px !important;
  }

  .intro-right .btn-wrap-fixed::after {
    content: "";
    display: block;
    clear: both;
  }

  .intro-right .btn-wrap-fixed .item-next {
    position: relative;
    margin-left: 411px;
    border-bottom: 1px #f05014 solid;
  }

  .intro-right .btn-wrap-fixed .item-prev {
    float: left;
    position: relative;
    border-bottom: 1px #f05014 solid;
  }

  .intro-right .btn-wrap-fixed button {
    position: relative;
    background: none;
    width: auto;
    height: 44px;
    min-height: auto;
    color: #f05014;
    font-size: 25px;
    font-weight: 700;
    padding: 0;
  }

  .intro-right .btn-wrap-fixed button[disabled] {
    opacity: 0.5;
  }

  .intro-right .btn-wrap-fixed .type-prev {
    float: left;
    padding-left: 30px;
  }

  .intro-right .btn-wrap-fixed .type-prev::before {
    content: "";
    position: absolute;
    display: block;
    left: 0;
    top: 19px;
    width: 20px;
    height: 7px;
    background: url("/static/images/main/arrow_prev.png") no-repeat;
  }

  .intro-right .btn-wrap-fixed .type-next {
    padding-right: 30px;
  }

  .intro-right .btn-wrap-fixed .type-next::after {
    content: "";
    position: absolute;
    display: block;
    right: 0;
    top: 19px;
    width: 20px;
    height: 7px;
    background: url("/static/images/main/arrow_next.png") no-repeat;
  }

  .intro-right .ui-acco.type-b {
    border-top: 0;
  }

  .intro-right .ui-acco.type-b .ui-acco-wrap {
    color: #bbb;
    border-bottom: 1px #666 solid;
  }

  .intro-right .ui-acco-tit {
    background: none;
  }

  .intro-right .h2 {
    font-size: 32px !important;
    color: #fff;
    font-weight: 400;
  }

  .intro-right .h5 {
    font-size: 16px !important;
    color: #fff !important;
    font-weight: 400;
  }

  .intro-right .ui-acco.type-b .lb-check:before {
    border-color: #999;
    background: transparent;
  }

  .intro-right .ui-acco.type-b input:checked + .lb-check:before {
    border: 1px solid #f05014;
    background: #f05014
    url("/static/images/common/ico_form.png")
    no-repeat -4px -4px;
  }

  .intro-right .ui-acco.type-b input:disabled + .lb-check::before,
  .intro-right .ui-acco.type-b input:disabled + .lb-radio::before {
    border-color: #777;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .intro-right .ui-acco-arrow::before {
    background-position: -133px -85px;
  }

  .intro-right .ui-acco.type-b .ui-acco-pnl {
    border-top: 0;
    background: #545353;
    padding-right: 0;
  }

  .intro-right .terms-wrap {
    color: #bbb;
    font-size: 14px;
    line-height: 1.5;
    padding-right: 20px;
  }

  .intro-right .terms-wrap > *,
  .intro-right .ui-acco.type-b legend {
    font-size: 14px;
    color: #bbb;
  }

  .intro-right .terms-wrap .bul-dot > li:before {
    background: #bbb;
  }

  .intro-right .intro-insu-item > .ui-scrollbar {
    height: 100%;
  }

  .intro-right .intro-insu-item > .ui-scrollbar > .ui-scrollbar-item {
    width: 100%;
  }

  .intro-right .ui-scrollbar-barwrap.type-y {
    background: transparent;
  }

  .intro-right .ui-scrollbar-bar {
    background: #444;
    box-shadow: none;
  }

  .intro-right .ui-acco .field-inlabel {
    padding-top: 24px;
  }

  .intro-right .ui-acco .lb-check > span,
  .intro-right .ui-acco .lb-radio > span {
    color: #bbb;
  }

  .intro-right .ui-confirmed-msg {
    color: #0eb633;
  }

  .intro-right .btn-base.checked,
  .intro-right .btn-base.checked:hover,
  .intro-right .btn-base.focused,
  .intro-right .btn-base:focus {
    background: #fcfcfc;
  }

  .intro-right .inp-datepicker .ui-datepicker-btn {
    height: 39px;
    min-height: auto;
  }

  .intro-right .inp-datepicker .ui-datepicker-btn i {
    margin: 7px 0 0;
  }

  .intro-right .ui-datepicker .ui-datepicker-wrap {
    left: 0 !important;
  }

  /* 상품소개 validation error 문구 css */
  .intro-right .ui-error-msg {
    min-height: 35px;
    font-size: 12px;
    font-weight: 200;
    color: #dc5b2e;
    background: #292929;
    margin-top: 0;
    padding: 7px 0 7px 30px;
  }

  .intro-right .ui-error-msg::before {
    top: 5px;
    left: 2px;
    background-position: -99px -25px;
  }

  .intro-right .ui-error-true .ui-error-item {
    border-color: #dc5b2e !important;
  }

  /* 상품소개 인트로 modal content */
  .introModal {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f8f8f8;
    z-index: 10;
    transition: top 0.3s ease-out;
  }

  .introModal.on {
    top: 0;
  }

  .introModal .box-dialog {
    position: relative;
    width: 588px;
    height: calc(100% - 74px);
    margin: 0 auto;
    margin-top: 74px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 18px 18px 0px 0px;
  }

  .introModal .box-header {
    position: relative;
    display: block;
    height: 80px;
    padding: 0 40px;
    line-height: 47px;
    background: #ffffff;
    border-radius: 18px 18px 0px 0px;
  }

  .introModal .box-title {
    padding-top: 40px;
    display: inline-block;
    line-height: 1;
    font-weight: bold;
    font-size: 22px;
    letter-spacing: -2px;
    color: #000000;
  }

  .introModal .btn-close {
    position: absolute;
    right: 40px;
    top: 40px;
  }

  .introModal .box-content {
    position: relative;
    height: calc(100% - 47px);
    background: #fff;
    overflow-y: auto;
  }

  .introModal .box-content > .modal-pd {
    padding: 30px 40px;
  }

  .introModal .box-content::-webkit-scrollbar {
    width: 10px;
    height: 20px;
  }

  .introModal .box-content::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
  }

  .introModal .box-content::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #fff;
    border: 2px solid #ecebea;
  }

  .introModal .box-content::-webkit-scrollbar-button {
    width: 20px;
    height: 10px;
  }

  .introModal .intro-data {
    position: relative;
    width: 100%;
    border: 1px #999 dashed;
    margin-top: 20px;
  }

  .introModal .intro-data dl {
    position: relative;
    display: table;
    width: 100%;
    padding: 20px 20px 0;
  }

  .introModal .intro-data dl + dl {
    padding-bottom: 20px;
  }

  .introModal .intro-data dt,
  .introModal .intro-data dd {
    position: relative;
    display: table-cell;
  }

  .introModal .intro-data dt {
    width: 100px;
    color: #111;
    font-weight: 700;
  }

  .introModal .intro-data .data-item {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 30px;
    font-size: 14px;
    color: #555;
    text-align: center;
    line-height: 28px;
    border: 1px #ddd solid;
    border-radius: 2px;
    margin-right: 5px;
    margin-bottom: 3px;
  }

  .introModal .intro-data.type-b {
    padding: 20px 25px;
  }

  .introModal .intro-data.type-b em {
    color: #777;
  }

  .introModal .intro-data.type-b .sum {
    font-size: 18px;
    margin-top: 10px;
    border-top: 1px #eee solid;
    padding-top: 10px;
  }

  .introModal .intro-caution {
    font-size: 14px;
    color: #f05014;
    text-align: right;
  }

  .introModal .strongPet {
    position: relative;
    font-size: 24px;
    color: #f05014;
    text-align: center;
    font-weight: 700;
    margin-top: 30px;
  }

  .introModal p.bul-dot,
  .introModal .bul-dot > li {
    padding-left: 10px;
    word-break: break-all;
  }

  .introModal p.bul-dot::before,
  .introModal .bul-dot > li::before {
    top: 12px;
    width: 3px;
    height: 3px;
  }

  .introModal .bul-dot > li > .bul-hyphen > li {
    font-size: 14px;
  }

  .introModal .tbl-base.type-intro {
    border: 0;
    padding: 0;
    margin-bottom: 10px;
  }

  .introModal .tbl-base.type-intro thead th {
    font-size: 14px;
    color: #f05014;
    padding: 8px 0;
    border-top: 2px #f05014 solid;
    border-bottom: 2px #f05014 solid;
    border-right: 1px #f05014 solid;
    vertical-align: middle;
  }

  .introModal .tbl-base.type-intro thead th:last-child {
    border-right: 0;
  }

  .introModal .tbl-base.type-intro tbody th,
  .introModal .tbl-base.type-intro tbody td {
    font-size: 14px;
    text-align: left;
    border-left: 1px #ddd solid;
    border-bottom: 1px #ddd solid;
    padding: 8px 10px;
  }

  .introModal .tbl-base.type-intro tbody th {
    color: #111;
    border-left: 0;
  }

  .introModal .tbl-base.type-intro.type-b tbody th {
    font-weight: normal;
  }

  .introModal .tbl-base.type-intro.type-b tbody td {
    text-align: right;
  }

  .introModal .tbl-base.type-intro tfoot th,
  .introModal .tbl-base.type-intro tfoot td {
    font-size: 14px;
    color: #111;
    font-weight: 700;
    text-align: right;
    border-left: 1px #ddd solid;
    border-bottom: 1px #ddd solid;
    background: #f7f8f8;
    padding: 8px 10px;
  }

  .introModal .tbl-base.type-intro tfoot th {
    text-align: left;
    border-left: 0;
  }

  .introModal .tbl-base.type-intro.type-dl table {
    border-top: 2px #f05014 solid;
  }

  .introModal .type-num {
    color: #00358e;
  }

  .introModal .type-num .num {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    font-size: 14px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    background: #00358e;
    border-radius: 20px;
    margin-right: 8px;
  }

  .introModal .bul-dot.type-intro {
    border-top: 1px #ddd solid;
    border-bottom: 1px #ddd solid;
    padding: 15px 0;
    margin: 15px 0;
  }

  .introModal .bul-etc > li {
    font-size: 14px;
  }

  .introModal .bul-etc .bul-ico {
    font-size: 13px;
  }

  .introModal .type-box {
    position: relative;
    display: block;
    color: #777;
    text-align: center;
    padding: 10px 0;
    border: 1px #ddd solid;
    border-radius: 4px;
  }

  .introModal .fl-wrap .type-box {
    width: calc(50% - 5px);
  }

  .introModal .fx-wrap.col-3 .fx-item {
    width: 33.3%;
    vertical-align: top;
  }

  .introModal .ico-pet::before {
    content: "";
    position: relative;
    display: block;
    width: 50px;
    height: 50px;
    background: url("/static/images/main/pet_ico.png") no-repeat;
    background-size: 100%;
    margin-bottom: 5px;
  }

  .introModal .type-goodsinfo .intro-title {
    position: relative;
    min-height: 61px;
    font-size: 18px;
    font-weight: 700;
    color: #111;
    padding-left: 35px;
    padding-bottom: 10px;
    margin-top: 60px;
    border-bottom: 1px #ddd solid;
  }

  .introModal .type-goodsinfo .intro-title .num {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 30px;
    font-size: 50px;
    font-weight: 200;
    color: #f05014;
    line-height: 1;
  }

  .introModal .content-wrap .premium-box-wrap {
    position: relative;
    background: #f7f7f7;
  }

  .introModal .content-wrap .premium-box-tit {
    position: relative;
    padding: 10px 15px;
    color: #fff;
    background: #343434;
  }

  .introModal .content-wrap .premium-box-tit::after {
    content: "";
    display: block;
    clear: both;
  }

  .premium-box-tit .font-bold {
    font-weight: 700;
  }

  .introModal .content-wrap .premium-box-cont {
    position: relative;
    padding: 20px;
  }

  .pricing-process-box {
    overflow: hidden;
    position: relative;
    width: 100%;
    margin-top: 20px;
    background: #f7f7f7;
    padding: 20px;
  }

  .pricing-process-box .label,
  .introModal .content-wrap .premium-box-cont .label {
    color: #f05014;
    font-weight: 500;
  }

  .pricing-process-box .value,
  .introModal .content-wrap .premium-box-cont .value {
    margin-top: 5px;
  }

  .pricing-process-box .value + .label,
  .introModal .content-wrap .premium-box-cont .value + .label {
    margin-top: 15px;
  }

  .pricing-process-box .result,
  .introModal .content-wrap .premium-box-cont .result {
    position: relative;
    font-weight: 700;
    border-top: 1px #ddd solid;
    padding-top: 20px;
    margin-top: 20px;
  }

  .pricing-process-box .result::after,
  .introModal .content-wrap .premium-box-cont .result::after {
    content: "";
    display: block;
    clear: both;
  }

  .introModal .round-ico-wrap {
    display: block;
    color: #00358e;
    font-weight: 700;
    margin-bottom: 7px;
  }

  .introModal .round-ico {
    margin-right: 5px;
    display: inline-block;
    text-align: center;
    width: 16px;
    height: 16px;
    padding-right: 1px;
    border-radius: 50%;
    background-color: #00358e;
    color: #fff;
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
  }

  .introModal .type-function li > .bul-ico {
    position: absolute;
  }

  .introModal .type-term-half {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .introModal .type-term-half .fx-item {
    width: calc(50% - 20px);
  }

  .introModal .type-term-half .fx-item.short {
    width: 40px;
    text-align: center;
  }

  .introModal .type-term-half .fx-item:last-child [class*="inp-"].ui-inpcancel {
    padding: 7px 4px;
  }

  .introModal .type-term-half.type-count .fx-item {
    width: calc(50% - 40px);
  }

  .introModal .type-term-half.type-count .fx-item.short {
    width: 40px;
    text-align: center;
  }

  .introModal .type-function .type-term-half > .type-box,
  .introModal .type-function .box-share {
    position: relative;
    text-align: center;
    border: 1px #ddd solid;
    padding: 10px;
  }

  .introModal .type-function .type-term-half > .type-box {
    width: calc(50% - 10px);
    border-radius: 0;
  }

  .introModal .type-function .type-term-half > .short {
    width: 20px;
  }

  .introModal .type-function .box-share > p {
    padding: 10px 0;
    margin: 0 10px;
  }

  .introModal .type-function .box-share > p + p {
    border-top: 1px #ccc solid;
  }

  /* 청약 절차 단계 */
  .step-wrap {
    display: table;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 10px;
  }

  .step-wrap:after {
    content: "";
    display: block;
    clear: both;
  }

  .step-wrap.n2 .step-bar {
    width: 25%;
  }

  .step-wrap.n3 .step-bar {
    width: 33.33%;
  }

  .step-wrap.n4 .step-bar {
    width: 25%;
  }

  .step-wrap.n5 .step-bar {
    width: 20%;
  }

  .step-wrap.n6 .step-bar {
    width: 16.66%;
  }

  .step-wrap.n7 .step-bar {
    width: 14.27%;
  }

  .step-wrap.n8 .step-bar {
    width: 12.5%;
  }

  .step-wrap.n9 .step-bar {
    width: 11.11%;
  }

  .step-wrap.n10 .step-bar {
    width: 10%;
  }

  .step-bar {
    display: table-cell;
    height: 51px;
    font-size: 13px;
    color: #999;
    font-weight: 500;
    text-align: center;
    vertical-align: middle;
    border-bottom: 3px solid #f05014;
  }

  .step-bar.active {
    color: #f05014;
  }

  .step-bar.active ~ .step-bar {
    border-bottom-color: #eee;
  }

  /* step circle - 카카오인증, 토스인증, 과오납보험료, 보험가입 프로세스, 사고담보 진행상황, 보험금청구 프로세스 단계 표시 */
  .step-basic {
    border: 1px solid #ddd;
    padding: 40px 0;
  }

  .step-basic .step-item {
    display: inline-block;
    vertical-align: top;
    width: 25%;
  }

  .step-basic .item-circle {
    position: relative;
    display: block;
    width: 48px;
    height: 48px;
    font-weight: 700;
    text-align: center;
    line-height: 46px;
    background: #fff;
    border-radius: 48px;
    border: 1px #ddd solid;
    margin: 0 auto;
    z-index: 1;
  }

  .step-basic .item-circle::after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: -79px;
    width: 50px;
    height: 5px;
    margin: auto 0;
    background: url("/static/images/content/pointline_pc.png") repeat-x;
  }

  .step-basic .step-item:first-child .item-circle::after {
    display: none;
  }

  .step-basic .step-item .txt {
    text-align: center;
    margin-top: 13px;
    line-height: 21px;
  }

  .step-basic .step-item .txt-emph {
    display: block;
    font-weight: 700;
  }

  .step-basic .step-item.curr .txt {
    color: #f05014;
    border-color: #f05014;
  }

  .step-basic .step-item.curr .item-circle {
    color: #fff;
    background: #f05014;
    border-color: transparent;
  }

  .step-basic.min {
    width: 450px;
  }

  .step-basic.min .step-item {
    width: 33.333%;
  }

  .step-basic.auth {
    width: 100%;
  }

  .step-basic.auth .step-item {
    position: relative;
    width: 33.3%;
  }

  .step-basic.auth .item-circle {
    position: static;
  }

  .step-basic.step5 .step-item {
    width: 20%;
  }

  .step-basic.step5 .item-circle::after {
    left: -63px;
  }

  .step-basic.step6 .step-item {
    width: 16.666%;
  }

  .step-basic.step6 .item-circle::after {
    width: 32px;
    left: -44px;
  }

  .step-basic.auth .item-circle::after {
    top: 22px;
    left: -25px;
    margin: 0;
  }

  .step-basic.max .step-item:nth-child(5) .item-circle::after {
    display: none;
  }

  .step-basic.max .step-item:nth-child(n + 5):nth-child(-n + 12) {
    border-top: 1px solid #ddd;
    padding-top: 40px;
    margin-top: 40px;
  }

  /* step + box-base - 보상서비스 사고접수 */
  .box-base.type-step .box-head {
    margin-bottom: 17px;
  }

  .box-base.type-step .box-head .tit {
    font-weight: 400;
  }

  .box-base.type-step .box-body::after {
    content: "";
    display: block;
    clear: both;
  }

  .box-base.type-step .list-accident {
    float: left;
  }

  .box-base.type-step .list-accident li + li {
    margin-top: 10px;
  }

  .box-base.type-step .list-accident .tit-s {
    color: #777;
    display: block;
  }

  .box-base.type-step .step-basic {
    float: right;
    width: 65%;
    margin-right: -5px;
    text-align: right;
    border: none;
    padding: 0;
  }

  .box-base.type-step .step-basic .step-item {
    width: 60px;
  }

  .box-base.type-step .step-basic .step-item + .step-item {
    margin-left: 30px;
  }

  .box-base.type-step .step-basic .item-circle::after {
    width: 23px;
    left: -33px;
  }

  .box-base.type-step .step-basic.step3 .step-item + .step-item {
    margin-left: 57px;
  }

  .box-base.type-step .step-basic.step3 .item-circle::after {
    width: 50px;
    left: -59px;
  }

  /* 인증서비스 이용안내 및 민원처리절차안내 step */
  .step-row {
    border-bottom: 1px solid #ddd;
  }

  .step-row li {
    position: relative;
    border-top: 1px solid #ddd;
    padding: 25px 0;
  }

  .step-row li:before {
    content: "";
    display: block;
    position: absolute;
    top: -1px;
    left: 50%;
    margin-left: -10px;
    z-index: 1;
    width: 20px;
    height: 12px;
    background: #fff
    url("/static/images/common/ico_small.png")
    no-repeat -178px -32px;
  }

  .step-row li:first-child {
    border-top-color: #bbb;
  }

  .step-row li:first-child:before {
    display: none;
  }

  .step-row li dl:after {
    content: "";
    display: block;
    clear: both;
  }

  .step-row li dt {
    float: left;
    width: 20%;
  }

  .step-row li dt b {
    font-size: 18px;
    color: #f05014;
    font-weight: 700;
  }

  .step-row li dd {
    float: left;
    width: 80%;
    font-size: 18px;
    line-height: 26px;
    font-weight: 700;
  }

  .step-row li dd span {
    display: block;
    margin-top: 10px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #777;
  }

  .step-row.type-line {
    margin-left: 120px;
    border-color: #bbb;
  }

  .step-row.type-line li {
    padding: 22px 0 25px;
  }

  .step-row.type-line li::after,
  .step-row.type-line .tit > span::after {
    content: "";
    display: inline-block;
  }

  .step-row.type-line li:before {
    left: 47px;
  }

  .step-row.type-line li::after {
    position: absolute;
    top: 0px;
    left: -41px;
    width: 2px;
    height: 101%;
    background: #ddd;
    z-index: -1;
  }

  .step-row.type-line li:first-child::after {
    top: 32px;
  }

  .step-row.type-line li:last-child::after {
    height: 32px;
  }

  .step-row.type-line li dt {
    position: relative;
  }

  .step-row.type-line li dd {
    font-size: 16px;
    font-weight: 400;
  }

  .step-row.type-line li dd b {
    font-weight: 700;
  }

  .step-row.type-line .tit,
  .step-row.type-line .stit {
    display: block;
    font-weight: 700;
  }

  .step-row.type-line .tit {
    position: absolute;
    top: 4px;
    left: -120px;
    width: 120px;
    padding-right: 30px;
    text-align: right;
    font-size: 14px;
    color: #f05014;
    box-sizing: border-box;
  }

  .step-row.type-line .tit > span {
    display: block;
    word-break: break-all;
    padding-right: 35px;
  }

  .step-row.type-line .tit > span::after {
    position: absolute;
    top: 0;
    right: 30px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0 0 0 4px #f05014 inset;
    vertical-align: top;
    margin-left: 15px;
  }

  .list-terms,
  .list-terms button {
    font-size: 12px !important;
    color: #222;
    font-weight: 400;
    letter-spacing: 0;
    margin-top: 5px;
  }

  .list-terms-end {
    color: #f05014;
  }

  .fx-wrap.type-col2:after {
    content: "";
    display: block;
    clear: both;
  }

  .fx-wrap.type-col2 .fx-item {
    float: left;
    width: calc(50% - 10px);
    margin-left: 20px;
  }

  .fx-wrap.type-col2 .fx-item:first-child {
    margin-left: 0;
  }

  .fx-wrap.type-pin .field-inlabel [class*="inp-"] {
    width: 258px;
  }

  .fx-wrap.type-search .field-inlabel {
    padding-top: 0;
  }

  .fx-wrap.type-search .input-name {
    width: 190px;
    margin-right: 20px;
  }

  .fx-wrap.type-search .input-mobile {
    width: 284px;
    margin-right: 20px;
  }

  .box-dialog .fx-wrap.type-search .input-mobile {
    width: calc(100% - 326px);
  }

  .fx-wrap.type-search .input-btn {
    width: 96px;
    padding-top: 12px;
  }

  .fx-wrap.type-search .input-btn .btn-base {
    width: 96px;
  }

  .type-term .fx-item {
    width: calc(50% - 10px);
  }

  .type-term .fx-item.short {
    width: 20px;
    min-width: 20px;
    position: relative;
    top: 40px;
    text-align: center;
    vertical-align: top;
  }

  .type-term2 .fx-item {
    width: calc(50% - 40px);
  }

  .type-term2 .fx-item.short {
    width: 20px;
    min-width: 20px;
    position: relative;
    bottom: 12px;
    text-align: center;
    vertical-align: bottom;
  }

  .type-term2 .fx-item.txt {
    width: 50px;
    position: relative;
    bottom: 12px;
    text-align: right;
    vertical-align: bottom;
  }

  .type-cardnum .ui-security {
    width: calc(25% - 15px);
  }

  .type-cardnum .in-item {
    display: inline-block;
    width: 20px;
    text-align: center;
    padding-top: 15px;
    color: #777;
  }

  .type-expdate-wrap .fx-item {
    width: calc(50% - 10px);
    margin-left: 20px;
  }

  .type-expdate-wrap .fx-item:first-child {
    margin-left: 0;
  }

  .type-expdate .inp-base,
  .type-expdate .ui-select {
    width: calc(50% - 10px);
    margin-right: 20px;
  }

  .type-expdate .ui-select + .ui-select {
    margin-right: 0;
  }

  .type-resreg .type-security,
  .type-resreg .inp-base {
    width: calc(50% - 10px);
  }

  .type-resreg .in-item {
    display: inline-block;
    width: 20px;
    text-align: center;
    padding-top: 10px;
  }

  .type-jumin .fx-item {
    width: calc(50% - 10px);
  }

  .type-jumin .in-item {
    display: inline-block;
    width: 20px;
    text-align: center;
    padding-top: 40px;
  }

  .type-daytime .fx-item {
    min-width: calc(50% - 10px);
    max-width: calc(50% - 10px);
  }

  .type-daytime .fx-item + .fx-item {
    margin-left: 20px;
  }

  .type-telcom .fx-item {
    width: 190px;
    margin-right: 20px;
  }

  .type-telcom .fx-item + .fx-item {
    width: calc(100% - 210px);
    margin-right: 0;
  }

  .type-mail .inp-base {
    width: calc(30% - 10px);
    margin-right: 10px;
  }

  .type-mail .ui-select {
    width: calc(40% - 10px);
  }

  .type-mail .in-item {
    display: inline-block;
    width: 20px;
    text-align: center;
    margin-left: -10px;
    position: relative;
    bottom: 12px;
    vertical-align: bottom;
  }

  .type-certify:after {
    content: "";
    display: block;
    clear: both;
  }

  .type-certify .in-item {
    display: inline-block;
    width: calc(50% - 10px);
    position: relative;
  }

  .type-certify .in-item .time {
    position: absolute;
    top: 12px;
    right: 10px;
    font-size: 16px;
    color: #f05014;
  }

  .type-certify .btn-base {
    margin-left: 20px;
    vertical-align: bottom;
  }

  .type-slc .ui-select {
    width: calc(100% - 114px);
    margin-right: 20px;
  }

  .type-slc .btn-base {
    width: 94px;
    vertical-align: bottom;
  }

  /* 사업자번호 */
  .type-owner .inp-base {
    width: calc(30% - 10px);
  }

  .type-owner .inp-base:last-child {
    width: calc(40% - 20px);
  }

  .type-owner .in-item {
    display: inline-block;
    width: 20px;
    text-align: center;
    padding-top: 10px;
  }

  /* 사고일시 */
  .type-accidentdate .field-inlabel .ui-datepicker,
  .type-accidentdate .field-inlabel .ui-select {
    width: calc(35% - 10px);
    margin-right: 20px;
  }

  .type-accidentdate .field-inlabel .ui-select + .ui-select {
    width: calc(35% - 51px);
    margin-right: 0;
  }

  .type-accidentdate .field-inlabel .ui-datepicker .inp-base {
    width: 100%;
  }

  .type-accidentdate .field-inlabel .inp-base {
    width: calc(30% - 20px);
  }

  .type-accidentdate .in-item {
    display: inline-block;
    width: 20px;
    text-align: center;
    padding-top: 10px;
  }

  .type-srh .inp-base {
    padding-right: 40px !important;
  }

  .type-srh .inp-base.ui-inpcancel {
    padding-right: 65px !important;
  }

  .field-inlabel.type-srh .ui-btn-cancel {
    margin-left: -60px;
  }

  .type-srh .btn-srh {
    margin-left: -32px;
    margin-top: 14px;
  }

  .type-srh.type-large .btn-srh {
    width: 30px;
    height: 30px;
    margin-top: 11px;
    background-position: -260px -35px;
  }

  .type-security {
    display: inline-block;
    position: relative;
    width: 100%;
  }

  .btn-security {
    position: absolute;
    top: 6px;
    right: 0;
    width: 100%;
    height: 40px;
    text-align: right;
  }

  .btn-security:after {
    content: "";
    display: none;
    position: absolute;
    z-index: 0;
    top: 11px;
    left: 11px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: transparent;
    transition: transform 0.2s ease-in, background-color 0.2s ease-in;
  }

  .btn-security:hover:after {
    background-color: #eee;
    transform: scale(2, 2);
  }

  .btn-security:before {
    content: "";
    display: inline-block;
    position: relative;
    z-index: 1;
    width: 40px;
    height: 40px;
    background: url(/static/images/common/btn_mouse.png) no-repeat 0 0;
  }

  .btn-security:hover:before {
    background-position: 0 -40px;
  }

  .btn-security[aria-expanded="true"]:before {
    background-position: 0 -40px;
  }

  .type-security .inp-base {
    width: 100%;
  }

  .type-security .ui-btn-cancel {
    margin-left: -65px;
  }

  .field-inlabel.txt-2 [class*="inp-"] {
    padding-right: 34px;
  }

  .field-inlabel.txt-2 [class*="inp-"].ui-inpcancel {
    padding-right: 60px;
  }

  .field-inlabel.txt-2 .ui-btn-cancel,
  .field-inlabel.txt-time .ui-btn-cancel {
    margin-left: -80px;
  }

  .txt-in {
    display: inline-block;
    border: 0;
    padding: 14px 10px 0 0;
    text-align: right;
  }

  .field-inlabel.txt-2 .txt-in {
    width: 55px;
    margin-left: -55px;
    font-size: 22px;
    padding-top: 9px;
  }

  .field-inlabel.txt-time [class*="inp-"] {
    padding-right: 40px;
  }

  .field-inlabel.txt-time [class*="inp-"].ui-inpcancel {
    padding-right: 60px;
  }

  .field-inlabel.txt-time .txt-in {
    width: 53px;
    margin-left: -53px;
  }

  .type-price .inp-intxt {
    font-size: 16px;
    width: 50px;
  }

  .type-price [class*="inp-"]:focus + .inp-intxt {
    border-color: #333;
  }

  .box-base .type-price [class*="inp-"]:focus + .inp-intxt {
    border-color: #dcdcdc;
  }

  .type-btn2 .inp-base {
    width: calc(100% - 90px);
  }

  .type-btn2 .btn-base {
    width: 70px;
    margin-left: 20px;
    vertical-align: bottom;
  }

  .col-2 .type-btn2 .inp-base {
    width: calc(100% - 136px);
  }

  .col-2 .type-btn2 .btn-base {
    width: 116px;
  }

  .type-btn3 .inp-base {
    width: calc(100% - 103px);
  }

  .type-btn3 .btn-base {
    width: 83px;
    margin-left: 20px;
  }

  .type-btn4 .inp-base {
    width: calc(100% - 210px);
  }

  .type-btn4 p.inp-base {
    height: auto;
    min-height: 51px;
  }

  .type-btn4 .btn-base {
    width: 95px;
    margin-left: 20px;
    vertical-align: bottom;
  }

  .type-half .inp-base,
  .type-half .ui-select {
    width: calc(50% - 10px);
  }

  .type-half .btn-base {
    min-width: 70px;
    margin-left: 20px;
    vertical-align: bottom;
  }

  .carnum-wrap {
    position: relative;
    border-bottom: 1px #ddd solid;
    padding-bottom: 15px;
  }

  /* button */
  .scrolled-b .btn-wrap-fixed {
    box-shadow: none;
  }

  .btn-wrap-fixed {
    position: relative;
    width: 100%;
    margin-top: 75px;
    transition: bottom 0.2s ease-out;
  }

  .btn-wrap-fixed.col-2,
  .btn-wrap-fixed.col-3 {
    overflow: hidden;
    border-radius: 4px;
  }

  .btn-wrap-fixed .btn-base-l {
    display: inline-block;
  }

  .btn-wrap-fixed.col-2 .btn-base-l {
    width: 75%;
    float: left;
    border-radius: 0;
  }

  .btn-wrap-fixed.col-2 .btn-base-l.type-b {
    width: 25%;
  }

  .btn-wrap-fixed.col-3 .btn-base-l {
    width: 45%;
    float: left;
    border-radius: 0 4px 4px 0;
  }

  .btn-wrap-fixed.col-3 .btn-base-l.type-b {
    width: 25%;
    border-radius: 4px 0 0 4px;
  }

  .btn-wrap-fixed.col-3 .btn-base-l.type-c {
    width: 30%;
    background: #fff;
    color: #f05014;
    border: 1px #f05014 solid;
    border-radius: 0;
  }

  .box-footer.col-2 .btn-base-l {
    width: 50%;
    border-radius: 0 4px 4px 0;
  }

  .box-footer.col-2 .btn-base-l.type-c {
    background: #fff;
    color: #f05014;
    border: 1px #f05014 solid;
    border-radius: 4px 0 0 4px;
  }

  .fx-wrap + .btn-wrap-fixed,
  .box-base + .btn-wrap-fixed,
  .total-price + .btn-wrap-fixed,
  .box-list-wrap + .btn-wrap-fixed {
    margin-top: 80px;
  }

  .box-list-wrap button[class*="box-"] {
    width: 100%;
    text-align: left;
  }

  .btn-wrap,
  .btn-wrap.type-normal {
    position: relative;
    margin: 10px 0;
    width: 100%;
    text-align: center;
    font-size: 0;
  }

  .btn-wrap.txt-l [class*="btn-base"] + [class*="btn-base"],
  .btn-wrap [class*="btn-base"] + [class*="btn-base"] {
    margin-right: 0px;
    margin-left: 10px;
  }

  .btn-wrap.txt-r [class*="btn-base"] + [class*="btn-base"] {
    margin-right: 0;
    margin-left: 10px;
  }

  .btn-wrap.type-full button,
  .btn-wrap.type-full a {
    width: 100%;
    margin: 0;
  }

  .btn-wrap.type-half button,
  .btn-wrap.type-half a {
    width: calc(50% - 9.5px);
    margin: 0;
    padding: 0;
  }

  .btn-wrap.type-part3 [class*="btn-base"] + [class*="btn-base"] {
    margin-right: 0px;
    margin-left: 10px;
  }

  .btn-wrap.type-part3 button,
  .btn-wrap.type-part3 a {
    width: calc(32.8% - 5px);
    margin: 0;
    padding: 0;
  }

  .btn-wrap.type-normal {
    padding: 0 !important;
  }

  .btn-wrap.type-normal [class*="btn-base"],
  [class*="btn-base"] {
    display: inline-block;
    padding: 0 18px;
    min-height: 38px;
    min-width: 68px;
    border: 1px solid #777;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    background: #fff;
    vertical-align: top;
    color: #333;
    text-decoration: none;
    line-height: 1;
    border-radius: 4px;
    transition: background-color 0.2s ease-in;
    letter-spacing: -0.03em;
  }

  .btn-wrap.type-normal [class*="btn-base"] {
    position: relative;
    top: initial;
    left: initial;
    width: auto;
    height: auto;
  }

  .btn-wrap.top-line {
    border-top: 1px solid #eee;
    margin-top: 20px;
    padding-top: 20px;
  }

  .btn-base:hover {
    border-color: #333;
    color: #111;
    background-color: #fcfcfc;
  }

  .btn-base.checked,
  .btn-base.checked:hover,
  .btn-base.focused,
  .btn-base:focus {
    border-color: #f05014;
    background: inherit;
    color: #f05014;
  }

  .btn-base:disabled,
  .btn-base[disabled],
  .btn-base.disabled {
    opacity: 1;
    border: 1px solid #ccc;
    color: #777;
    background: #fff;
    box-shadow: none;
    cursor: default;
  }

  .btn-base.disabled:hover,
  .btn-base.disabled:focus {
    border-color: #ddd;
    color: #ddd;
    background: inherit;
  }

  .btn-base-s {
    min-height: 30px;
  }

  a.btn-base-s {
    min-height: 30px;
    padding: 7px 20px 3px;
  }

  .btn-base-s:hover {
    border-color: #333;
    color: #111;
    background-color: #fcfcfc;
  }

  .btn-base-s.focused,
  .btn-base-s:focus {
    border-color: #f05014;
    background: inherit;
    color: #f05014;
  }

  .btn-base-s:disabled,
  .btn-base-s[disabled],
  .btn-base-s.disabled {
    opacity: 1;
    border: 1px solid #ccc;
    color: #777;
    background: #fff;
    box-shadow: none;
    cursor: default;
  }

  .btn-base-s.disabled:hover,
  .btn-base-s.disabled:focus {
    border-color: #ddd;
    color: #ddd;
    background: inherit;
  }

  .btn-base-r {
    min-height: 30px;
    border-radius: 15px;
    border-color: #ddd;
  }

  a.btn-base-r {
    min-height: 30px;
    border-radius: 15px;
    padding: 7px 20px 3px;
  }

  .btn-base-r.dark {
    background: #777;
    color: #fff;
    border-color: #777;
  }

  .btn-base-r.dark:hover {
    background: #999;
  }

  .btn-base-r:hover {
    border-color: #333;
    color: #111;
    background-color: #fcfcfc;
  }

  .btn-base-r.dark.focused,
  .btn-base-r.dark:focus {
    border-color: #111;
    background: #111;
    color: #fff;
  }

  .btn-base-r.focused,
  .btn-base-r:focus {
    border-color: #f05014;
    background: inherit;
    color: #f05014;
  }

  .btn-base-r.dark:disabled,
  .btn-base-r.dark[disabled],
  .btn-base-r.dark.disabled {
    opacity: 0.5;
    border: 1px solid #777;
    color: #fff;
    background: #777;
    box-shadow: none;
    cursor: default;
  }

  .btn-base-r:disabled,
  .btn-base-r[disabled],
  .btn-base-r.disabled {
    opacity: 1;
    border: 1px solid #ccc;
    color: #777;
    background: #fff;
    box-shadow: none;
    cursor: default;
  }

  .btn-base-r.disabled:hover,
  .btn-base-r.disabled:focus {
    border-color: #ddd;
    color: #ddd;
    background: inherit;
  }

  .btn-base-r.dark-type2,
  .btn-base-r.dark-type2:hover,
  .btn-base-r.dark-type2.focused,
  .btn-base-r.dark-type2:focus {
    min-height: 22px;
    padding: 0 8px;
    background: #d0d0d0;
    color: #fff;
    border-color: #d0d0d0;
    font-size: 14px;
  }

  .chk-account .btn-base:hover,
  .chk-account .btn-base:focus {
    border-color: #777;
    background: inherit;
    color: #333;
  }

  .chk-account .btn-base.focused:hover,
  .chk-account .btn-base.focused:focus {
    border-color: #f05014;
    background: inherit;
    color: #f05014;
  }

  /* sub button */
  .btn-base,
  .btn-base-m {
    min-height: 38px;
    padding: 0 15px;
  }

  a.btn-base,
  a.btn-base-m {
    min-height: 38px;
    padding: 11px 15px 0;
  }

  .btn-base-b {
    height: 65px;
    line-height: 1.4;
  }

  /* bottom button */
  .btn-base-l {
    width: 100%;
    background: #f05014;
    color: #fff;
    min-height: 58px;
    height: 58px;
    font-size: 22px;
    border: 0;
    border-radius: 4px;
    margin: 0;
    padding: 0 18px;
  }

  a.btn-base-l {
    padding: 20px 18px 15px;
    max-height: 58px;
  }

  .btn-base-l:hover {
    background: #ff4600;
  }

  .btn-base-l:disabled,
  .btn-base-l.disabled,
  .btn-base-l[disabled] {
    background: #ddd;
    border: 0;
  }

  .btn-base-l.type-b {
    background: #777;
    border: 0;
  }

  .btn-base-l.type-b:disabled,
  .btn-base-l.type-b.disabled,
  .btn-base-l.type-b[disabled] {
    background: #eee;
  }

  /* Answer button :secondary */
  .btn-base-a {
    min-height: 48px;
    border-radius: 24px;
    border-color: #d9d9d9;
    padding: 0 20px;
    font-size: 16px;
    color: #111;
    font-weight: 400;
  }

  div.btn-base-a,
  span.btn-base-a,
  a.btn-base-a {
    padding: 14px 20px 8px;
  }

  .btn-base-a.type-primary {
    background: #f05014;
    border-color: #f05014;
    color: #fff;
  }

  .btn-base-a.type-emoji {
    padding-left: 18px;
    padding-right: 18px;
  }

  /* 약관 모두 동의 */
  .btn-base.type-agree > span {
    font-weight: 700;
  }

  /* chips button */
  [class*="btn-chips"] {
    display: inline-block;
    padding: 0 18px;
    min-height: 44px;
    border-radius: 22px;
    border: 0;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    background: #f7f7f7;
    vertical-align: top;
    color: #999;
    text-decoration: none;
    line-height: 1;
    border-radius: 22px;
    transition: background-color 0.2s ease-in;
    letter-spacing: -0.03em;
    margin: 0 10px 10px 0;
  }

  div[class*="btn-chips"] .lb-check,
  span[class*="btn-chips"] {
    padding: 11px 20px 7px;
    min-height: 44px;
  }

  div[class*="btn-chips"] {
    padding: 0;
  }

  [class*="btn-chips"] span {
    vertical-align: middle;
    display: inline-block;
    line-height: 1.4;
    margin-top: 0;
  }

  div[class*="btn-chips"] .lb-check:before {
    display: none;
  }

  [class*="btn-chips"] [checked] + .lb-check,
  [class*="btn-chips"] :checked + .lb-check,
  [class*="btn-chips"].selected {
    color: #fff;
    background-color: #f05014;
    border-radius: 22px;
  }

  [class*="btn-chips"] [disabled] + .lb-check,
  [class*="btn-chips"] :disabled + .lb-check,
  [class*="btn-chips"].disabled {
    color: #ddd;
    background-color: #fcfcfc;
    opacity: 1;
    border-radius: 22px;
  }

  .btn-chips-step {
    min-height: 44px;
    border-radius: 4px;
    font-size: 16px;
    color: #111;
    font-weight: 400;
  }

  a.btn-chips-step {
    padding: 11px 16px 6px;
  }

  .btn-chips-step [checked] + .lb-check,
  .btn-chips-step :checked + .lb-check,
  .btn-chips-step.selected {
    border-radius: 4px;
    font-weight: 700;
  }

  .btn-tag .lb-check {
    padding: 4px 10px 3px;
    min-height: 28px;
    font-size: 12px;
    color: #999;
    font-weight: 700;
    border: 1px solid #d9d9d9;
    border-radius: 14px;
  }

  .btn-tag [checked] + .lb-check,
  .btn-tag :checked + .lb-check,
  .btn-tag.selected {
    color: #f05014;
    border-color: #f05014;
    border-radius: 14px;
  }

  .btn-tag .lb-check:before {
    display: none;
  }

  .btn-tag .lb-check:after {
    content: "OFF";
    display: inline-block;
    vertical-align: middle;
    border-bottom: 1px solid transparent;
    margin-left: 3px;
  }

  .btn-tag [checked] + .lb-check:after,
  .btn-tag :checked + :after {
    content: "ON";
    display: inline-block;
    vertical-align: middle;
    border-bottom: 1px solid transparent;
  }

  .btn-del {
    position: relative;
    width: 20px;
    height: 20px;
    border: 1px solid transparent;
    overflow: hidden;
    color: transparent;
    font-size: xx-small;
    box-sizing: content-box;
  }

  .btn-del:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -1px;
    display: block;
    width: 100%;
    height: 2px;
    background: #333;
    border-radius: 1px;
    @include vendorPrefix(transform, rotate(45deg));
  }

  .btn-del:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -1px;
    display: block;
    width: 2px;
    height: 100%;
    background: #333;
    border-radius: 1px;
    @include vendorPrefix(transform, rotate(45deg));
  }

  .btn-del:before,
  .btn-del:after {
    background: #222;
  }

  .btn-close,
  .ui-modal-header .n2.btn-close {
    width: 20px;
    height: 20px;
    border: 0;
    background: url("/static/images/common/ico_small.png") -103px -28px
    no-repeat;
    text-indent: -9999px;
  }

  .btn-link {
    color: initial;
    text-decoration: underline;
  }

  .btn-close.type-b {
    background-position: -152px -27px;
  }

  .btn-del-minus {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    box-sizing: content-box;
    vertical-align: bottom;
    margin-left: 5px;
  }

  .btn-del-minus span {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    width: 14px;
    height: 14px;
    text-indent: -9999px;
    background: #fff;
    border: 1px solid #f05014;
    border-radius: 14px;
    @include vendorPrefix(transform, translate(-50%, -50%));
  }

  .btn-del-minus span:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 8px;
    height: 1px;
    background: #f05014;
    border-radius: 1px;
    @include vendorPrefix(transform, translate(-50%, -50%));
    z-index: 1;
  }

  .btn-del-minus.large span {
    width: 20px;
    height: 20px;
  }

  .btn-del-minus.large span:before {
    width: 10px;
    height: 2px;
  }

  .btn-del-circle {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    box-sizing: content-box;
    vertical-align: bottom;
    margin-left: 5px;
  }

  .btn-del-circle span {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    width: 14px;
    height: 14px;
    text-indent: -9999px;
    background: #bbb;
    border-radius: 14px;
    @include vendorPrefix(transform, translate(-50%, -50%) rotate(45deg));
  }

  .btn-del-circle span:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 8px;
    height: 1px;
    background: #fff;
    border-radius: 1px;
    @include vendorPrefix(transform, translate(-50%, -50%));
    z-index: 1;
  }

  .btn-del-circle span:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 1px;
    height: 8px;
    background: #fff;
    border-radius: 1px;
    @include vendorPrefix(transform, translate(-50%, -50%));
    z-index: 1;
  }

  .btn-del-circle.large {
    border-radius: 100%;
  }

  .btn-del-circle.large span {
    width: 22px;
    height: 22px;
  }

  .btn-del-circle.large span::before {
    width: 12px;
    height: 2px;
  }

  .btn-del-circle.large span::after {
    width: 2px;
    height: 12px;
  }

  .btn-back {
    position: absolute;
    top: 22px;
    left: 27px;
    width: 18px;
    height: 18px;
    border: 1px solid transparent;
    overflow: hidden;
    color: transparent;
    font-size: xx-small;
    box-sizing: content-box;
  }

  .btn-back:after {
    content: "";
    position: absolute;
    top: 12px;
    left: 3px;
    display: block;
    width: 66%;
    height: 2px;
    background: #333;
    border-radius: 2px;
    @include vendorPrefix(transform, rotate(45deg));
  }

  .btn-back:before {
    content: "";
    position: absolute;
    top: 4px;
    left: 3px;
    display: block;
    width: 66%;
    height: 2px;
    background: #333;
    border-radius: 2px;
    @include vendorPrefix(transform, rotate(-45deg));
  }

  .btn-more {
    position: relative;
    width: 40px;
    height: 40px;
    border: 0;
    background-color: #bbb;
    border-radius: 50%;
    overflow: hidden;
    color: transparent;
    font-size: xx-small;
    box-sizing: content-box;
  }

  .btn-more:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 20%;
    margin-top: -1px;
    display: block;
    width: 60%;
    height: 2px;
    background: #fff;
    border-radius: 1px;
  }

  .btn-more:before {
    content: "";
    position: absolute;
    top: 20%;
    left: 50%;
    margin-left: -1px;
    display: block;
    width: 2px;
    height: 60%;
    background: #fff;
    border-radius: 1px;
  }

  .btn-step {
    position: relative;
    width: 25px;
    height: 25px;
    background: url("/static/images/common/ico_small@2x.png")
    no-repeat -75px -25px;
    background-size: 300px;
    margin-top: 6px;
  }

  .btn-ico {
    position: relative;
    padding-left: 30px;
    font-size: 14px;
    color: #333;
  }

  .ico-arrow,
  .btn-arrow {
    display: inline-block;
    position: relative;
    top: 0;
    width: 18px;
    height: 20px;
    overflow: hidden;
    color: transparent;
    font-size: xx-small;
    box-sizing: content-box;
    margin-left: 0;
    vertical-align: top;
    background: url("/static/images/common/ico_small.png")
    no-repeat -105px -3px;
  }

  .btn-arrow span,
  .btn-arrow3 span {
    @include txt-hide();
  }

  .ico-arrow2,
  .btn-arrow2 {
    display: inline-block;
    position: relative;
    top: 0;
    width: 18px;
    height: 18px;
    overflow: hidden;
    color: transparent;
    font-size: xx-small;
    box-sizing: content-box;
    margin-left: 0;
    vertical-align: top;
    background: url("/static/images/common/ico_small@2x.png")
    no-repeat -278px -3px;
    background-size: 300px;
  }

  .btn-arrow3,
  .ico-arrow3 {
    display: inline-block;
    position: relative;
    top: 3px;
    margin-left: 5px;
    width: 0px;
    height: 0px;
    border: 5px solid transparent;
    border-top: 7px solid #333;
  }

  .btn-arrow3.reverse,
  .ico-arrow3.reverse {
    top: -2px;
    border-top: 5px solid transparent;
    border-bottom: 7px solid #333;
  }

  .ico-arrow-s {
    display: inline-block;
    position: relative;
    top: 4px;
    width: 4px;
    height: 8px;
    margin: 0 4px;
    overflow: hidden;
    color: transparent;
    font-size: xx-small;
    box-sizing: content-box;
    vertical-align: top;
    background: url("/static/images/common/ico_small.png")
    no-repeat -111px -59px;
  }

  .ico-reset:before {
    content: "";
    display: inline-block;
    position: relative;
    top: 1px;
    width: 14px;
    height: 14px;
    margin-right: 6px;
    background: url("/static/images/common/ico_small.png") -6px -31px
    no-repeat;
  }

  .btn-tip {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    background: transparent
    url("/static/images/common/ico_small.png")
    no-repeat -53px -3px;
    border-radius: 50%;
    vertical-align: middle;
    margin: 0 0 0 5px;
    border: 1px solid #666;
  }

  .btn-tip-page {
    position: relative;
    display: inline-block;
    width: 21px;
    height: 21px;
    background: #bbb
    url("/static/images/common/ico_small@2x.png")
    no-repeat -52px -28px;
    background-size: 300px;
    border-radius: 50%;
    vertical-align: middle;
    margin: 0 0 0 5px;
  }

  .field-inlabel-label .btn-tip {
    margin-top: -3px;
  }

  .field-inlabel-label .sample {
    color: #999;
    margin-left: 10px;
  }

  .h2 .btn-tip {
    vertical-align: baseline;
  }

  .tit .btn-tip,
  .h5 .btn-tip,
  .h6 .btn-tip {
    margin-top: -3px;
  }

  .txt-s5 .btn-tip {
    margin-top: -5px;
  }

  .btn-arrow {
    top: 1px;
  }

  .lb-check + .btn-tip {
    margin-top: 15px;
  }

  .bul-etc > li .btn-tip {
    margin-top: -4px;
  }

  .btn-txt {
    display: inline-block;
    font-size: 15px;
    color: #333;
    vertical-align: baseline;
    letter-spacing: -0.035em;
    text-decoration: none;
    border-bottom: 1px solid #111;
    line-height: 1;
  }

  .btn-txt span {
    display: inline-block;
    line-height: 1.3;
  }

  a.btn-txt span {
    margin-top: 4px;
  }

  .btn-txt:hover {
    color: #f05014;
    border-color: #f05014;
  }

  .btn-txt .ico-arrow,
  .btn-txt .btn-arrow {
    margin-left: 2px;
    top: 3px;
    background-position: -130px -28px;
  }

  .btn-txt-arrow {
    position: relative;
    display: inline-block;
    line-height: 1;
    color: #333;
    vertical-align: baseline;
    text-decoration: none;
  }

  .btn-txt-arrow:after {
    content: "";
    display: inline-block;
    position: relative;
    top: -1px;
    width: 12px;
    height: 18px;
    overflow: hidden;
    color: transparent;
    font-size: xx-small;
    box-sizing: content-box;
    margin-left: 4px;
    vertical-align: top;
    background: url("/static/images/common/ico_small.png")
    no-repeat -130px -28px;
  }

  .btn-txt-arrow:hover {
    color: #111;
    font-weight: bold;
  }

  .btn-txt-arrow > span {
    font-size: 16px;
  }

  .btn-txt-arrow[disabled] span {
    color: #ccc;
  }

  .btn-txt-arrow[disabled]::after {
    opacity: 0.3;
  }

  .btn-txt-arrow.type-strong {
    color: #f05014;
  }

  .btn-txt-arrow.type-strong::after {
    background-position: -130px -53px;
  }

  .btn-txt-arrow.type-l {
    font-size: 16px;
  }

  .btn-txt-arrow.type-l::after {
    margin-left: 8px;
  }

  .btn-txt-arrow.type-s span {
    font-size: 14px;
    color: #777777;
  }

  .btn-txt-arrow.type-s:hover {
    color: #777777;
    font-weight: 400;
  }

  .btn-txt-arrow.type-s::after {
    background: none;
    border-top: 1px solid #777777;
    border-right: 1px solid #777777;
    width: 6px;
    height: 6px;
    top: 3px;
    transform: rotate(45deg);
  }

  .btn-txt .ico-arrow,
  .btn-txt .btn-arrow {
    margin-left: 2px;
    top: 3px;
    background-position: -130px -28px;
  }

  .ico-check:before {
    content: "";
    display: inline-block;
    width: 13px;
    height: 10px;
    margin-right: 5px;
    background: url("/static/images/common/ico_form.png") -31px -8px
    no-repeat;
  }

  button.focused .ico-check:before,
  button:hover .ico-check:before,
  button:focus .ico-check:before {
    background-position: -31px -8px;
  }

  button[disabled] .ico-check:before,
  button.disabled .ico-check:before {
    background-position: -81px -8px;
  }

  .chk-account button:hover .ico-check:before,
  .chk-account button:focus .ico-check:before {
    background-position: -81px -8px;
  }

  .chk-account button.focused:hover .ico-check:before,
  .chk-account button.focused:focus .ico-check:before {
    background-position: -31px -8px;
  }

  .btn-tel {
    display: block;
    margin-top: 5px;
    font-size: 15px;
    font-weight: 700;
    color: #111;
    text-decoration: none;
  }

  .btn-check {
    font-size: 14px;
    color: #f05014;
    font-weight: 500;
  }

  .btn-check:disabled,
  .btn-check[disabled],
  .btn-check.disabled,
  .btn-check.type-b {
    color: #999;
  }

  .btn-check:before {
    content: "";
    display: inline-block;
    width: 13px;
    height: 11px;
    margin-right: 5px;
    background: url("/static/images/common/ico_form.png") -31px -8px
    no-repeat;
  }

  .btn-check:disabled:before,
  .btn-check[disabled]:before,
  .btn-check.disabled:before,
  .btn-check.type-b:before {
    background-position: -56px -8px;
    opacity: 0.46;
  }

  .btn-srh {
    display: inline-block;
    width: 25px;
    height: 25px;
    background: url("/static/images/common/ico_small.png") -225px
    0;
  }

  .btn-srh span {
    @include txt-hide();
  }

  .scroll-top .fixed-bottom .btn-top {
    bottom: 76px;
    opacity: 1;
  }

  .scroll-top .btn-top {
    bottom: 30px;
    opacity: 1;
  }

  .btn-top {
    position: fixed;
    right: 16px;
    border-radius: 50%;
    width: 41px;
    height: 41px;
    border: 1px solid #666;
    background: #fff;
    bottom: -50px;
    z-index: 9;
    opacity: 0;
    transition: bottom 0.4s ease-out, opacity 0.5s ease-in;
    font-size: 13px;
    font-weight: 700;
    color: #000;
    letter-spacing: -0.05em;
  }

  .div-pnl {
    display: none;
  }

  /* 다운로드 버튼 */
  .ico-download {
    display: inline-block;
    position: relative;
    padding-left: 28px;
    line-height: 1.3;
    height: 20px;
  }

  .ico-download::before {
    content: "";
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    background: url("/static/images/common/ico_small.png")
    no-repeat -202px -2px;
  }

  .btn-base[disabled] > .ico-download::before {
    background-position: -202px -27px;
  }

  .btn-base:focus .ico-download::before,
  .btn-base.checked .ico-download::before {
    background-position: -202px -52px;
  }

  .btn-base > .ico-email {
    position: relative;
    display: inline-block;
    padding-left: 30px;
    line-height: 25px;
    height: 25px;
  }

  .btn-base > .ico-email::before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 25px;
    height: 25px;
    background: url("/static/images/common/ico_small.png")
    no-repeat -225px -50px;
  }

  .btn-base:focus > .ico-email::before,
  .btn-base.checked > .ico-email::before {
    background-position: -250px -50px;
  }

  /* 세로로 균등 분할된 버튼 */
  .fx-wrap .btn-all {
    position: relative;
    display: block;
    width: 100%;
    height: 190px;
    border-top: 1px #ddd solid;
    padding: 0 32px;
    font-size: 13px;
    color: #777;
    text-align: initial;
  }

  .fx-wrap .btn-all .h3 {
    display: block;
    font-size: 22px;
    font-weight: 700;
    margin-top: 0;
    letter-spacing: -1.2px;
  }

  .fx-wrap .btn-all.selected .h3 {
    font-size: 24px;
    color: #f05014;
  }

  .fx-wrap .btn-all.selected .h3::after {
    content: "";
    position: relative;
    display: inline-block;
    width: 12px;
    height: 9px;
    background: url("/static/images/common/ico_form.png") -32px -9px
    no-repeat;
    vertical-align: middle;
    margin: -5px 0 0 8px;
  }

  .fx-wrap .btn-all.selected p {
    color: #111;
  }

  .fx-wrap .btn-all-cont {
    position: relative;
    display: block;
    height: 100%;
    font-size: 16px;
    padding-top: 40px;
  }

  /* box */
  .box-base {
    position: relative;
    width: 100%;
    margin: 20px 0 0;
    padding: 33px 40px;
    border: 1px solid #ddd;
    background: #fff;
    word-break: break-all;
  }

  .box-link {
    margin-top: 20px;
  }

  .box-base:first-child,
  .box-link:first-child {
    margin-top: 0;
  }

  .box-base .box-label {
    position: absolute;
    display: -webkit-box;
    top: 0;
    right: 0;
    width: 80px;
    height: 75px;
    background: url("/static/images/content/box_label_bg.png") no-repeat;
    background-size: 100%;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    z-index: 1;
  }

  .box-base .box-label span {
    position: relative;
    display: block;
    font-size: 12px;
    color: #fff;
    text-align: center;
    line-height: 1.3;
    transform: rotateZ(41deg) translate(3px, -22px);
  }

  .box-base .box-base {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 28px 20px;
    border: 0;
    background: #fff;
    border-radius: 0;
  }

  .box-base .tit {
    font-size: 18px;
    color: #111;
    font-weight: 700;
  }

  .box-base .tit b {
    font-weight: 700;
  }

  .box-base .tit + .fx-wrap {
    margin-top: 15px;
  }

  .box-base .txt {
    margin-top: 10px;
    font-size: 15px;
    line-height: 22px;
    color: #333;
  }

  .box-base .tit .num {
    /*display:inline-block;*/
    position: relative;
    margin-left: 15px;
    padding-left: 20px;
  }

  .box-base .tit .num:before {
    @include bar-border(16px, #ccc);
    top: 10px;
    left: 0;
  }

  .box-base .tit-info {
    font-size: 20px;
    color: #111;
    text-align: center;
    line-height: 28px;
  }

  .box-base hr {
    border-color: #ddd;
    border-style: solid;
  }

  .box-base .item-label {
    font-size: 13px;
    color: #333;
  }

  .box-base .btn-wrap {
    margin: 0;
  }

  .box-base .btn-wrap.type-part3 .btn-base-m {
    margin-left: 9px;
    width: calc(33.3% - 6px);
  }

  .box-base .btn-wrap.type-part3 .btn-base-m:first-child {
    margin-left: 0;
  }

  .box-base .btn-tit-arrow {
    position: relative;
    display: block;
    width: 100%;
    text-align: left;
    padding-right: 50px;
    font-size: 18px;
    font-weight: 700;
    color: #111;
    letter-spacing: -0.075em;
    line-height: 1.4;
  }

  .box-base .btn-tit-arrow:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    width: 18px;
    height: 18px;
    background: url("/static/images/common/ico_small@2x.png")
    no-repeat -278px -3px;
    background-size: 300px;
    transform: translateY(-50%);
  }

  .box-link,
  .box-base .box-link {
    position: relative;
    display: block;
    color: #111;
    text-decoration: none;
  }

  button.box-link {
    width: 100%;
    text-align: left;
  }

  button.box-link:hover .tbl-noline table {
    background: none;
  }

  .box-link:focus .box-head > .tit {
    text-decoration: underline;
  }

  .box-link::after {
    content: "";
    position: absolute;
    right: 35px;
    top: 50%;
    width: 25px;
    height: 25px;
    background: url("/static/images/common/ico_small.png") -150px -25px
    no-repeat;
    transform: translateY(-50%);
  }

  .box-link:hover > .box-base,
  .box-base .box-link:hover {
    background: #fcfcfc;
  }

  .box-inner,
  .box-inner pre {
    word-break: break-all;
    white-space: pre-wrap;
  }

  .box-inner + .box-inner {
    padding-top: 25px;
    margin-top: 25px;
    border-top: 1px solid #ddd;
  }

  .box-base.active .active {
    color: #f05014;
  }

  .box-link.only .box-base {
    padding: 32px 40px 27px;
  }

  .box-link.only {
    margin-top: 10px;
  }

  .box-base .box-head .txt-tag {
    height: 22px;
    margin-left: 20px;
    min-width: 75px;
    text-align: center;
    line-height: 16px;
    vertical-align: sub;
    border-radius: 22px;
  }

  .box-base .box-head .txt-tag.align-middle {
    vertical-align: middle;
    margin-top: -5px;
    margin-left: 10px;
  }

  .box-base .box-head .txt-tag.align-abs {
    position: absolute;
    top: 6px;
    right: 0;
    margin: 0;
  }

  .box-head .head-pos-r {
    position: absolute;
    right: 0;
    top: 4px;
  }

  .box-base.type-txt {
    padding: 37px 40px 40px;
  }

  .box-base.type-txt:first-child {
    margin-top: 0;
  }

  .box-base.type-txt .btn-tit-arrow {
    font-size: 22px;
    font-weight: 400;
    color: #111;
    letter-spacing: -0.03em;
  }

  .box-base.type-txt::after {
    background: url("/static/images/common/ico_small.png")
    no-repeat -150px -3px;
  }

  .box-base.type-txt .tit {
    float: none;
    position: relative;
    font-weight: 400;
    margin-bottom: 0;
    padding-right: 50px;
  }

  .box-base.type-txt .txt {
    font-size: 14px;
    color: #777;
  }

  .box-base.type-check {
    padding-left: 70px;
  }

  .box-base.type-check .chk-box {
    position: absolute;
    top: 44px;
    left: 40px;
  }

  .box-base.type-check .chk-box .lb-check:before,
  .box-base.type-check .chk-box .lb-radio:before {
    top: 0;
  }

  .box-base.no-data {
    display: table;
    height: 360px;
  }

  .box-base.no-data p {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    font-size: 26px;
    color: #111;
  }

  .box-base.no-data p span {
    display: block;
    margin-top: 7px;
    font-size: 16px;
    color: #777;
  }

  .box-base.no-data b {
    font-weight: 700;
  }

  .box-base .box-head {
    margin-bottom: 8px;
    padding-right: 50px;
  }

  .box-base .box-head.fl-wrap {
    padding-right: 0;
  }

  .box-base .box-head.fl-wrap .fl-item-l {
    float: left;
    padding-right: 0;
    max-width: calc(100% - 100px);
  }

  .box-base .box-head.fl-wrap .fl-item-r {
    float: right;
    padding-right: 0;
  }

  .box-head {
    position: relative;
  }

  .box-head:after {
    content: "";
    display: block;
    clear: both;
  }

  .box-head .tit {
    float: left;
    font-size: 22px;
    margin-bottom: 10px;
  }

  .box-head .fl-item-l > .tit {
    float: none;
  }

  .box-head .tit + p {
    font-size: 16px;
  }

  .box-head .btn-txt {
    float: right;
    margin-left: 16px;
    margin-bottom: 14px;
    font-size: 14px;
    word-break: keep-all;
    line-height: 1.5;
  }

  .box-head .btn-wrap {
    float: right;
    width: auto;
  }

  .box-head .btn-wrap .btn-txt {
    float: initial;
  }

  .box-head .price {
    float: right;
    margin-bottom: 10px;
    line-height: 1.2;
  }

  .box-head .price strong {
    font-size: 18px;
    font-weight: 700;
  }

  .box-head .txt-company {
    float: right;
    font-size: 16px;
  }

  .box-head.type-b {
    padding: 0 0 26px;
    margin-bottom: 26px;
    border-bottom: 1px solid #ddd;
  }

  .box-head.type-b .tit {
    float: none;
    margin-bottom: 0;
  }

  .box-head.type-b .txt-s7 {
    margin-top: 3px;
  }

  .box-head.type-b .btn-txt-arrow {
    margin-top: 13px;
  }

  .box-head .ico-badge {
    position: relative;
    display: inline-block;
    font-size: 14px;
    color: #fff;
    line-height: 21px;
    background: #f05014;
    border-radius: 21px;
    vertical-align: sub;
    margin-left: 9px;
    padding: 0 15px;
    margin-top: 6px;
  }

  .box-head .btn-txt-arrow {
    position: absolute;
    right: 0;
    top: 6px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
    letter-spacing: -0.035em;
    text-decoration: none;
    line-height: 1;
  }

  .box-head .field-inlabel {
    position: absolute;
    top: -10px;
    right: 20px;
    width: auto;
    padding: 0;
  }

  .box-head .field-inlabel .lb-radio {
    padding: 0;
  }

  .box-base .tbl-noline {
    margin-top: 0;
  }

  .box-base .tbl-noline thead th {
    padding-bottom: 10px !important;
    font-size: 16px;
    font-weight: 700;
    color: #333;
  }

  .box-base .box-head + .tbl-noline thead th {
    padding-top: 0;
  }

  .box-base .tbl-noline tfoot th,
  .box-base .tbl-noline tfoot td {
    padding: 28px 0 0;
    vertical-align: middle;
  }

  .box-base .tbl-noline tfoot tr.v-top th,
  .box-base .tbl-noline tfoot tr.v-top td {
    vertical-align: top;
  }

  .box-base .tbl-noline .tbl-body {
    border-top: 1px solid #ddd;
  }

  .box-base .tbl-noline .tbl-body tr:first-child th {
    padding-top: 27px;
  }

  .box-base .tbl-noline .tbl-body tr:first-child td {
    padding-top: 25px;
  }

  .box-base .tbl-noline .tbl-body tr:last-child th,
  .box-base .tbl-noline .tbl-body tr:last-child td,
  .box-base .tbl-noline .tbl-body-first tr:last-child th,
  .box-base .tbl-noline .tbl-body-first tr:last-child td {
    padding-bottom: 25px;
  }

  .box-base .tbl-noline .tbl-body strong {
    color: #333;
  }

  .box-base .tbl-noline .tbl-body .txt-s6 {
    display: inline-block;
    font-weight: 700;
    line-height: 1;
  }

  .box-base .tbl-noline .tbl-body .tbl-bul li {
    position: relative;
    padding-left: 12px;
    line-height: 24px;
  }

  .box-base .tbl-noline .tbl-body .tbl-bul li:before {
    content: "-";
    position: absolute;
    left: 0;
    top: -1px;
    color: #777;
  }

  .box-base .tbl-noline .tbl-body .tbl-bul .fl-item-l {
    width: calc(33.3% - 12px);
    font-size: 14px;
    color: #777;
  }

  .box-base .tbl-noline .tbl-body .tbl-bul .fl-item-r {
    width: calc(66.7% + 12px);
    text-align: left;
  }

  .box-base .tbl-noline .tbl-item {
    margin-top: 20px;
  }

  .box-base .tbl-noline .tbl-item:first-child {
    margin-top: 0;
  }

  .box-base .tbl-noline .lb-radio,
  .box-base .tbl-noline .lb-check {
    padding-top: 0;
    margin-right: 80px;
  }

  .box-base .tbl-noline .lb-radio:before,
  .box-base .tbl-noline .lb-check:before {
    top: 1px;
  }

  .box-base .bul-hyphen,
  .box-base .bul-dot,
  .box-base .bul-care,
  .box-base .bul-star,
  .box-base .bul-etc {
    margin: 10px 0 0;
  }

  .box-base.type-pd {
    padding: 20px 25px 7px;
  }

  .box-base.type-pd .box-head {
    margin-bottom: 0;
  }

  .box-base.type-result .box-base-tit {
    display: table;
    width: 100%;
  }

  .box-base.type-result .tit {
    display: table-cell;
    font-size: 28px;
    color: #f05014;
    vertical-align: middle;
  }

  .box-base.type-result .txt-price {
    display: table-cell;
    font-size: 22px;
    font-weight: 700;
    text-align: right;
    line-height: 1.2;
    vertical-align: middle;
  }

  .box-base.type-result .txt-small {
    font-size: 16px !important;
    font-weight: 400;
  }

  .box-base .doc-item-pic {
    background: #fff;
  }

  .ui-slide.load .ui-slide-item .box-base:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(
                    to bottom,
                    rgba(255, 255, 255, 0),
                    rgba(255, 255, 255, 1) 80%
    );
    transition: background-image 0.2s ease-in;
  }

  .ui-slide.load .ui-slide-item.on .box-base:after {
    display: none;
  }

  /* 현재가격 box */
  .box-price {
    position: relative;
    width: 100%;
    padding: 15px 20px;
    background: #fff;
  }

  .box-price .txt-price {
    font-size: 14px;
    color: #333;
    line-height: 18px;
  }

  .box-price .txt-price b {
    font-size: 18px;
    color: #111;
  }

  .fx-wrap + .box-price {
    margin-top: -3px;
  }

  .ui-acco.type-b .ui-acco.type-b .box-base {
    padding: 25px 20px 20px;
  }

  .ui-acco.type-b .ui-acco.type-b .box-price {
    padding: 5px 0 0;
  }

  .box-base.type-cont {
    padding: 20px 25px;
  }

  .box-base.type-cont button {
    font-size: 15px;
    color: #111;
  }

  .box-base.type-cont .tbl-noline {
    margin-top: 0;
  }

  .box-base.type-cont .tbl-noline th,
  .box-base.type-cont .tbl-noline td {
    padding: 12px 0;
    vertical-align: middle;
  }

  .box-base.type-cont .tbl-noline tr:first-child th,
  .box-base.type-cont .tbl-noline tr:first-child td {
    padding-top: 12px;
  }

  .box-base.type-cont .tbl-noline tr:last-child th,
  .box-base.type-cont .tbl-noline tr:last-child td,
  .box-base.type-cont .tbl-noline .tbl-body:last-child tr:last-child th,
  .box-base.type-cont .tbl-noline .tbl-body:last-child tr:last-child td {
    padding-bottom: 12px;
  }

  .box-base.type-cont .tbl-noline td {
    position: relative;
    text-align: right;
  }

  .box-base.type-cont .tbl-noline td span {
    display: inline-block;
    text-align: right;
    padding-right: 25px;
    line-height: 1.3;
  }

  .box-base.type-cont .tbl-noline .btn-arrow2 {
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -9px;
  }

  .box-base.type-cont .tbl-noline .item-tit {
    font-size: 15px;
    letter-spacing: -0.075em;
    color: #333;
    font-weight: 700;
    margin: 0;
    word-break: keep-all;
  }

  .box-base.type-cont .tbl-noline .btn-del {
    margin-left: 10px;
    top: -1px;
  }

  .box-base.type-cont .tbl-noline .item-price {
    font-size: 14px;
  }

  .box-base.type-bg {
    background: #f7f7f7;
    border: 0;
    border-radius: 4px;
    padding: 25px 30px 31px;
  }

  .box-base.type-bg table {
    background: none;
  }

  .list-dlsel-wrap {
    width: 100%;
  }

  .list-dlsel-wrap:after {
    content: "";
    display: block;
    clear: both;
  }

  .list-dlsel .list-dlsel-item {
    display: block;
    float: left;
    width: 100%;
    padding: 18px 20px 14px;
    border-bottom: 1px solid #eee;
  }

  .list-dlsel .list-dlsel-item:first-child {
    border-top: 1px solid #bbb;
  }

  .list-dlsel .list-dlsel-item .lb-check {
    padding-top: 0;
  }

  .list-dlsel .list-dlsel-item .lb-check::before {
    top: 3px;
  }

  .list-dlsel .list-dlsel-item .lb-check.type-toggle:before {
    top: 2px;
  }

  .list-dlsel .list-dlsel-item dl {
    position: relative;
    width: 100%;
    padding-right: 125px;
  }

  .list-dlsel .list-dlsel-item dt {
    width: 100%;
    display: inline-block;
    position: relative;
    overflow: hidden;
  }

  .list-dlsel .list-dlsel-item dt .tit {
    display: block;
    font-size: 16px;
    line-height: 24px;
    color: #111;
    word-break: break-all;
    text-align: left;
    margin-right: 27px;
  }

  .list-dlsel .list-dlsel-item dd {
    width: 100%;
    display: block;
    font-size: 14px;
  }

  .list-dlsel .list-dlsel-item .list-dlsel-bt {
    position: relative;
    width: 100%;
    padding-right: 20px;
    text-align: left;
  }

  .list-dlsel .list-dlsel-item .list-dlsel-bt .tit {
    font-size: 16px;
    margin-top: 0;
    color: #111;
  }

  .list-dlsel .list-dlsel-item .list-dlsel-bt span {
    display: block;
    font-size: 16px;
    color: #777;
  }

  .list-dlsel .list-dlsel-item .list-dlsel-bt .ico-arrow2 {
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -8px;
  }

  .list-dlsel .list-dlsel-item .list-dlsel-toggle {
    position: absolute;
    width: auto;
    top: 50%;
    right: 0;
    margin-top: -12px;
  }

  .terms-box {
    width: 100%;
    max-height: 120px;
    min-height: 120px;
  }

  /* System Modal */
  .type-system .ui-modal-cont {
    max-width: 300px;
    padding: 20px;
  }

  .type-system .ui-modal-cont .wrap-inner {
    padding: 25px 0 0;
    text-align: center;
  }

  .type-system .ui-modal-cont b {
    font-weight: 700;
  }

  .type-system .ui-modal-cont .btn-close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 16px;
    height: 16px;
    background: url("/static/images/common/btn_close.png")
    no-repeat 50% 50%;
  }

  .type-system .ui-modal-footer {
    padding: 20px;
    text-align: center;
  }

  .type-system .ui-modal-footer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 20px;
    width: calc(100% - 40px);
    height: 1px;
    background: #eee;
  }

  .type-system .ui-modal-footer button {
    min-width: 68px;
    height: 30px;
    font-weight: 400;
    color: #333;
    border: 1px #777 solid;
    border-radius: 3px;
    transition: background-color 0.2s ease-in;
  }

  .type-system .ui-modal-footer button::before {
    display: none;
  }

  .type-system .ui-modal-footer button:hover {
    border-color: #333;
    color: #111;
    background: #fcfcfc;
  }

  .type-system .ui-modal-footer button:first-child {
    margin-right: 10px;
  }

  .box-dialog.type-system .box-content {
    padding: 20px;
  }

  .box-dialog.type-system .box-system-inner {
    padding: 25px 0 0 !important;
    font-size: 16px !important;
    text-align: center !important;
  }

  .box-dialog.type-system .box-footer {
    position: relative;
    padding: 20px;
    text-align: center;
    justify-content: center;
    -webkit-box-pack: center;
  }

  .box-dialog.type-system .box-footer button {
    min-width: 68px;
    height: 30px;
    font-weight: 400;
    color: #333;
    border: 1px #777 solid;
    border-radius: 3px;
    transition: background-color 0.2s ease-in;
  }

  .box-dialog.type-system .box-footer button:first-child {
    margin-right: 10px;
  }

  .box-dialog.type-system .box-footer button::before {
    display: none;
  }

  .box-dialog.type-system .box-footer button:hover {
    border-color: #333;
    color: #111;
    background-color: #fcfcfc;
  }

  .box-dialog.type-system .box-footer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 20px;
    width: calc(100% - 40px);
    height: 1px;
    background: #eee;
  }

  .radio-list-wrap {
    position: relative;
    width: 100%;
  }

  .radio-list-wrap .lb-radio {
    display: block;
    width: 100%;
    padding: 20px 20px 20px 55px;
    margin-top: 10px;
    border: 1px #ddd solid;
  }

  .radio-list-wrap input:hover + .lb-radio,
  .radio-list-wrap .lb-radio:hover {
    background-color: #fbfbfb;
  }

  .radio-list-wrap .lb-radio:before {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
  }

  .radio-list-wrap .lb-radio > .item {
    display: block;
    width: 100%;
    border-top: 1px solid #eee;
  }

  .radio-list-wrap .lb-radio > .item:first-child {
    border-top: 0;
  }

  .radio-list-wrap .lb-radio > .item + .item {
    padding-top: 10px;
    margin-top: 10px;
  }

  .radio-list-wrap .lb-radio > .item:after {
    content: "";
    display: block;
    clear: both;
  }

  .radio-list-wrap .lb-radio > .item > em {
    display: inline-block;
    margin-left: 85px;
    color: #777;
  }

  .radio-list-wrap .lb-radio > .item b {
    float: left;
    font-weight: 700;
    max-width: 180px;
  }

  .radio-list-wrap .lb-radio > .item span {
    float: right;
  }

  .radio-list-wrap .lb-radio > .item span > span {
    float: none;
  }

  .radio-list-wrap .lb-radio > .item.no-brd {
    border-top: 0;
    padding-top: 0;
    margin-top: 5px;
  }

  .check-list-wrap {
    position: relative;
    width: 100%;
  }

  .check-list-wrap .lb-check {
    display: block;
    width: 100%;
    padding: 20px 20px 20px 55px;
    margin-top: 10px;
    border: 1px #ddd solid;
  }

  .check-list-wrap input:hover + .lb-check,
  .check-list-wrap .lb-check:hover {
    background-color: #fbfbfb;
  }

  .check-list-wrap .lb-check:before {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
  }

  .check-list-wrap input[type="checkbox"]:focus + .lb-check > .item > b,
  .check-list-wrap input[type="radio"]:focus + .lb-radio > .item > b {
    text-decoration: underline;
  }

  .check-list-wrap .lb-check > .item {
    display: block;
    width: 100%;
    border-top: 1px solid #eee;
  }

  .check-list-wrap .lb-check > .item:first-child {
    border-top: 0;
  }

  .check-list-wrap .lb-check > .item + .item {
    padding-top: 10px;
    margin-top: 10px;
  }

  .check-list-wrap .lb-check > .item:after {
    content: "";
    display: block;
    clear: both;
  }

  .check-list-wrap .lb-check > .item > em {
    display: inline-block;
    margin-left: 85px;
    color: #777;
  }

  .check-list-wrap .lb-check > .item b {
    float: left;
    font-weight: 700;
    max-width: 180px;
  }

  .check-list-wrap .lb-check > .item strong {
    font-weight: 700;
  }

  .check-list-wrap .lb-check > .item span {
    float: right;
  }

  .check-list-wrap .lb-check > .item span > span {
    float: none;
  }

  .check-list-wrap .lb-check > .item.no-brd {
    border-top: 0;
    padding-top: 0;
    margin-top: 5px;
  }

  .check-list-wrap .item-detail,
  .radio-list-wrap .item-detail {
    display: none;
    padding: 15px 0;
    border: 1px #ddd solid;
    border-top: 0;
  }

  .check-list-item.selected .item-detail,
  .radio-list-item.selected .item-detail {
    display: block;
  }

  .check-list-wrap .item-detail .lb-check {
    border: 0;
    padding: 5px 20px 5px 55px;
    margin-top: 0;
  }

  .check-list-wrap .item-detail .lb-check:hover {
    background: none;
  }

  .check-list-wrap .item-detail .lb-check b {
    font-weight: 400;
  }

  .check-list-wrap .item-detail .lb-check::before {
    top: 8px;
    transform: none;
  }

  .radio-list-wrap .agr-wrap.type-b,
  .check-list-wrap .agr-wrap.type-b {
    border: 0;
  }

  .radio-list-wrap .agr-wrap.type-b .agr-item,
  .check-list-wrap .agr-wrap.type-b .agr-item {
    padding: 0;
  }

  .radio-list-wrap .agr-wrap .lb-radio,
  .check-list-wrap .agr-wrap .lb-check {
    border: 0;
    margin-top: 0;
    padding: 0 0 0 30px;
  }

  .check-list-wrap .agr-wrap .lb-check::before,
  .check-list-wrap .agr-wrap .lb-check::before {
    top: 50%;
    left: 0;
  }

  /* file/gallery list */
  .file-wrap {
    border-top: 1px solid #bbb;
  }

  .file-wrap .file-item {
    padding: 25px 20px 25px;
    border-bottom: 1px solid #eee;
  }

  .file-wrap .h6 {
    float: left;
  }

  .file-wrap .h6 b {
    font-weight: 700;
    color: #f05014;
  }

  .file-wrap .btn-base {
    float: right;
    min-height: 30px;
  }

  .file-wrap .txt {
    margin-top: 3px;
  }

  .file-wrap.type-bg {
    border-top: 0;
    padding: 0 30px;
    background: #f7f7f7;
  }

  .file-wrap.type-bg .file-item {
    padding: 25px 0 30px;
    border-bottom: 0;
    border-top: 1px solid #eee;
  }

  .file-wrap.type-bg .file-item:first-child {
    border-top: 0;
  }

  .doc-wrap {
    position: relative;
    margin-top: 20px;
  }

  .doc-wrap.brd-top {
    border-top: 1px #eee solid;
  }

  .doc-wrap .doc-item {
    position: relative;
    display: block;
    width: 100%;
    margin-top: 10px;
  }

  .doc-wrap .doc-item + .doc-item {
    margin-bottom: 20px;
  }

  .doc-wrap .doc-item > span {
    font-size: 16px;
  }

  .doc-wrap .doc-item .btn-del-circle.large {
    margin-left: 19px;
  }

  .doc-wrap-pic {
    position: relative;
    display: block;
  }

  .doc-item-pic {
    position: relative;
    display: inline-block;
    width: calc(50% - 9.5px);
    height: 100px;
    margin-bottom: 10px;
    background: #eee;
    overflow: hidden;
    vertical-align: top;
  }

  .doc-item-pic:nth-child(2n) {
    margin-left: 19px;
  }

  .doc-item-pic > .btn-del-circle {
    position: absolute;
    display: block;
    top: 5px;
    right: 5px;
    margin: 0;
    z-index: 1;
  }

  .doc-wrap.inline-type + .doc-wrap-pic.logo-type {
    margin-top: 33px;
  }

  .doc-wrap-pic.logo-type .btn-del-circle.large {
    top: 9px;
    right: 9px;
  }

  .doc-wrap-pic.logo-type .doc-item-pic {
    height: 105px;
    background: #eee url(/static/images/content/logo_pic.png) no-repeat 50%;
  }

  .doc-wrap-pic.col-2 .doc-item-pic {
    width: calc(50% - 10px);
    height: 180px;
  }

  .doc-wrap-pic.col-2 .doc-item-pic:nth-child(2n) {
    margin-left: 20px;
  }

  .tooltip-side .doc-wrap-pic.col-2 .doc-item-pic {
    width: calc(50% - 10px);
    height: 120px;
    margin: 0 0 20px 20px;
  }

  .tooltip-side .doc-wrap-pic.col-2 .doc-item-pic:nth-child(odd) {
    margin-left: 0;
  }

  .doc-wrap-pic.col-3 .doc-item-pic {
    width: calc(33.333% - 9px);
  }

  .doc-wrap-pic.col-3 .doc-item-pic + .doc-item-pic {
    margin-left: 13px;
  }

  .doc-wrap.inline-type .doc-item {
    display: inline-block;
    width: auto;
    margin: 5px 20px 5px 0;
  }

  .doc-wrap.inline-type .doc-item > * {
    vertical-align: middle;
  }

  .doc-wrap.inline-type .btn-del-minus {
    margin-left: 19px;
  }

  .slide-page > .ui-slide-wrap {
    overflow: visible;
  }

  .slide-page > .ui-slide-dotwrap {
    top: 0;
  }

  .slide-page > .ui-slide-dotwrap .ui-slide-dot {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: #000;
    margin: 0 2px;
    opacity: 0.4;
  }

  .slide-page > .ui-slide-dotwrap .ui-slide-dot.on {
    opacity: 0.8;
    transition: opacity 0.2s ease-in, width 0.2s ease-in;
    width: 30px;
  }

  .slide-page > .ui-slide-wrap > .ui-slide-itemwrap > .ui-slide-item.on {
    position: absolute;
    opacity: 1;
  }

  .slide-page > .ui-slide-wrap > .ui-slide-itemwrap > .ui-slide-item {
    position: fixed;
    opacity: 0;
    transition: opacity 0.5s ease-out;
  }

  .type-careplan {
    padding-bottom: 60px;
  }

  .type-careplan .ui-slide-item {
    padding: 0 32px;
  }

  .type-careplan .ui-slide-item .box-base {
    margin-top: 23px;
  }

  .type-careplan .ui-slide-dotwrap {
    top: 0px;
    padding: 0 32px;
    position: absolute;
  }

  .type-careplan .ui-slide-dotdiv {
    width: 100%;
    height: 5px;
    display: block;
    text-align: center;
  }

  .type-careplan .ui-slide-dotwrap .ui-slide-dot {
    display: inline-block;
    width: 30px;
    height: 3px;
    background-color: #f1f1f1;
    margin: 0 2px;
    vertical-align: top;
  }

  .type-careplan .ui-slide-dotwrap .ui-slide-dot.on {
    transition: background-color 0.2s ease-in;
    background-color: #00358e;
  }

  .type-careplan .ui-slide-navwrap {
    position: absolute;
    width: 100%;
    z-index: 1;
    top: 50%;
    margin-top: -44px;
  }

  .type-careplan .ui-slide-navwrap button {
    position: absolute;
    top: 50%;
    width: 25px;
    height: 48px;
    border: 1px solid #e0e0e0;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 2px;
  }

  .type-careplan .ui-slide-navwrap button:after {
    content: "";
    display: block;
    width: 100%;
    height: 25px;
    background: rgba(255, 255, 255, 0.5)
    url("/static/images/common/ico_small@2x.png")
    no-repeat -125px 0;
    background-size: 300px;
  }

  .type-careplan .ui-slide-navwrap .ui-slide-prev {
    top: 0;
    left: 15px;
  }

  .type-careplan .ui-slide-navwrap .ui-slide-next {
    top: 50%;
    right: 15px;
  }

  .type-careplan .ui-slide-navwrap .ui-slide-prev:after {
    background-position: -100px 0;
  }

  .type-careplan .ui-slide-item.on {
    height: auto;
  }

  .type-careplan .ui-slide-item {
    height: 0;
  }

  .btn-wrap-fixed .ui-slide-dotwrap {
    bottom: 110px;
    padding: 0 32px;
    position: absolute;
  }

  .btn-wrap-fixed .ui-slide-dotdiv {
    width: 100%;
    height: 5px;
    display: flex;
    justify-content: space-between;
  }

  .btn-wrap-fixed .ui-slide-dotwrap .ui-slide-dot {
    flex: 1;
    width: auto;
    height: 5px;
    background-color: #f1f1f1;
    margin: 0 0;
  }

  .btn-wrap-fixed .ui-slide-dotwrap .ui-slide-dot.on {
    transition: background-color 0.2s ease-in;
    background-color: #00358e;
  }

  .btn-wrap-fixed .ui-acco-wrap {
    padding: 0;
    background: #fff;
  }

  .btn-wrap-fixed .box-base {
    background: rgba(255, 255, 255, 0.85);
    border-top: 1px #eee solid;
  }

  /* GNB */
  .menu-on {
    padding-right: 17px;
    overflow: hidden;
  }

  .menu-on .base-header {
    z-index: 100;
    padding-right: 17px;
  }

  .menu-on .menu-wrap {
    display: block;
  }

  .menu-wrap.on {
    right: 0;
  }

  .menu-wrap .btn-close {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .menu-wrap-head {
    height: 187px;
    border-bottom: 1px solid #ddd;
    text-align: right;
  }

  .menu-wrap-head .logo {
    width: 100%;
    height: 60px;
  }

  .menu-wrap-head .name {
    width: 100%;
    padding: 41px 25px 0;
    text-align: left;
  }

  .menu-wrap-head .name b {
    font-weight: 700;
    color: #111;
  }

  .menu-wrap-head .btn-txt {
    font-weight: 400;
    font-size: 14px;
    color: #333;
    border-bottom: 1px solid #333;
    margin: 11px 25px 0 0;
  }

  .menu-wrap-head .menu-wrap-login {
    text-align: left;
    padding: 0 25px;
  }

  .menu-wrap-head .menu-wrap-login .txt-mark {
    margin: 0 15px;
  }

  .menu-wrap-head .menu-wrap-login .btn-txt {
    margin-right: 0;
  }

  .menu-wrap-body {
    padding: 0 25px;
    height: calc(100% - 187px);
    overflow-y: auto;
  }

  .menu-wrap {
    position: fixed;
    top: 0;
    right: -600px;
    z-index: 3;
    display: none;
    overflow: hidden;
    width: 600px;
    height: 100%;
    margin: 0 auto;
    background: #fff;
    transition: right 0.2s ease-in;
  }

  .dep-1-wrap {
    margin-top: 33px;
  }

  .dep-1.line-dot {
    border-top: 1px dashed #f05014;
    padding-top: 20px;
  }

  .dep-1 {
    margin-top: 15px;
  }

  .dep-1:after {
    content: "";
    display: block;
    clear: both;
  }

  .menu-wrap .dep-2 {
    display: inline-block;
    width: 100%;
    margin-bottom: 15px;
  }

  .menu-wrap .dep-2-btn {
    display: inline-block;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    color: #111;
    margin: 0;
    letter-spacing: 0;
    text-decoration: none;
  }

  .menu-dim {
    display: none;
    opacity: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background: #000;
    top: 0;
    left: 0;
    transition: opacity 0.3s;
  }

  .menu-on .menu-dim {
    display: block;
  }

  .menu-dim.on {
    opacity: 0.6;
  }

  .nav-main {
    float: right;
  }

  .nav-main-1 {
    float: left;
  }

  .nav-main-wrap {
    float: left;
  }

  .nav-main a {
    text-decoration: none;
    cursor: pointer;
  }

  .nav-btn-1 {
    display: block;
    padding: 0 12px;
    font-size: 14px;
    font-weight: 400;
    color: #777;
    vertical-align: top;
    line-height: 1;
  }

  .nav-btn-1 i {
    display: block;
    padding: 2px 8px 37px;
    border-bottom: 3px solid transparent;
    transition: border 0.2s ease-in, padding 0.2s ease-in;
    line-height: 25px;
  }

  .nav-btn-1.selected i,
  .on .nav-btn-1 i,
  .nav-btn-1:focus i,
  .nav-btn-1:hover i {
    border-bottom: 4px solid #f05014;
    padding-bottom: 37px;
    color: #f05014;
    font-weight: 600;
  }

  .on .nav-sub {
    display: block;
  }

  .nav-sub {
    display: none;
    position: absolute;
    width: 100%;
    min-height: 75px;
    top: 96px;
    left: 0;
    background: #fff;
    border-bottom: 1px solid #e4e9f0;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
    border-top: 1px solid #eee;
    overflow-x: auto;
  }

  // 메뉴수정
  .nav-sub .ui-scrollbar {
    margin: 0 auto;
    width: 100%;
    min-width: 1300px;
  }

  .nav-sub .ui-scrollbar-item {
    width: 100%;
  }

  // 메뉴수정
  .nav-sub-wrap {
    max-width: 1440px;
    width: 100%;
    min-width: 1300px;
    margin: 0 auto;
    padding: 54px 0 20px;
    position: relative;
  }

  .nav-sub-wrap h2 {
    font-size: 26px;
    float: left;
    width: 320px;
    padding: 0 0 0 50px;
    font-weight: 600;
    color: #000;
  }

  .nav-sub-wrap h2:after {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-left: 20px;
  }

  .n2 .nav-sub-wrap li {
    width: 160px;
    margin-right: 0;
  }

  .nav-sub-wrap ul ul {
    width: 100%;
    margin-top: 10px;
    padding-bottom: 0;
  }

  .nav-sub-wrap ul:after {
    content: "";
    display: block;
    clear: both;
  }

  .nav-sub-wrap li {
    float: left;
  }

  .nav-sub-wrap li a {
    font-size: 16px;
    color: #333;
    font-weight: 500;
  }

  .nav-sub-wrap li li {
    float: none;
    display: block;
    margin: 0;
    padding: 0;
  }

  .nav-sub-wrap li li a {
    font-size: 14px;
    color: #666;
    font-weight: 400;
  }

  .nav-sub-wrap .bul-dot li {
    padding-left: 6px;
  }

  .nav-sub-wrap a:hover,
  .nav-sub-wrap a:focus {
    text-decoration: underline;
  }

  .nav-sub-link h3 {
    float: left;
    width: 100%;
    font-size: 18px;
    color: #111;
    font-weight: 600;
    margin-bottom: 25px;
  }

  .nav-sub-link h4 {
    float: left;
    font-size: 14px;
    width: 100%;
    color: #333;
    margin-bottom: 10px;
    margin-top: 24px;
  }
  .nav-sub-wrap .nav-sub-link .dep3 + h4 {
    margin-top: 16px;
  }
  .nav-sub-wrap .nav-sub-link button,
  .nav-sub-wrap .nav-sub-link a {
    padding-right: 8px;
    font-size: 14px;
    color: #333;
    text-align: left;
  }

  .nav-sub-wrap .nav-sub-link li {
    width: 100%;
    margin-bottom: 10px;
  }

  .nav-sub-wrap .nav-sub-link .dep3 li {
    margin-bottom: 7px;
  }

  .nav-sub-wrap .nav-sub-link .dep3 button,
  .nav-sub-wrap .nav-sub-link .dep3 a {
    font-size: 12px;
    color: #aaa;
  }

  .nav-sub-wrap .nav-sub-link button:hover,
  .nav-sub-wrap .nav-sub-link button:focus,
  .nav-sub-wrap .nav-sub-link a:hover,
  .nav-sub-wrap .nav-sub-link a:focus {
    color: #f05014 !important;
    font-weight: 500;
  }

  .nav-sub-wrap .selected {
    color: #f05014 !important;
    font-weight: 500;
  }

  .nav-sub-wrap .btn-txt-arrow {
    font-size: 16px;
  }

  .nav-main-1 .nav-sub-wrap:after {
    content: "";
    display: block;
    clear: both;
  }

  .nav-main-1 .nav-sub-tit p {
    font-size: 30px;
    line-height: 1.2;
    color: #111;
    margin-bottom: 15px;
  }

  .nav-main-1 .nav-sub-tit p strong {
    font-weight: 600;
  }

  .nav-main-1 .nav-sub-wrap:after {
    content: "";
    display: block;
    clear: both;
  }

  .nav-main-1 .nav-sub-link {
    position: relative;
    padding-bottom: 50px;
    padding-left: 140px;
    background-repeat: no-repeat;
  }

  .nav-main-1 .nav-sub-wrap ul {
    margin-top: 0;
  }

  .nav-main-1 .nav-sub-link > li {
    float: left;
    width: 205px;
  }

  .nav-main-1 .nav-sub-link .n2 {
    width: 410px;
  }

  .nav-main-1 .nav-sub-link .n2 > div {
    float: left;
    width: 205px;
  }

  .nav-main-1.n1 .nav-sub-wrap .nav-sub-link {
    padding-left: 416px;
    background: url("/static/images/common/nav_sub01_img.png?20200428")
    no-repeat 73px 5px;
  }

  .nav-main-1.n2 .nav-sub-wrap {
    padding-bottom: 0;
  }

  .nav-main-1.n2 .nav-sub-wrap .nav-sub-link {
    background-image: url("/static/images/common/nav_sub02_img.png?20200428");
    background-position: 1025px 236px;
    padding-left: 216px;
  }

  .nav-main-1.n2 .nav-sub-link .n1-n3 .dep3 + h4 {
    margin-top: 26px;
  }

  .nav-main-1.n3 .nav-sub-link {
    padding-left: 822px;
    background: url("/static/images/common/nav_sub03_img.png?20200429")
    no-repeat 417px 54px;
  }

  .nav-main-1.n4 .nav-sub-wrap {
    padding-bottom: 0;
  }

  .nav-main-1.n4 .nav-sub-link {
    background: url("/static/images/common/nav_sub04_img.png?20200428")
    no-repeat 216px 122px;
    padding-left: 550px;
  }

  .nav-main-1.n5 .nav-sub-wrap ul {
    padding-bottom: 20px;
  }

  .nav-main-1.n5 .nav-sub-wrap .nav-sub-link {
    padding-left: 622px;
    background: url("/static/images/common/nav_sub05_img.png?20200428")
    no-repeat 216px 5px;
  }

  .nav-main-1.n6 .nav-sub-wrap {
    padding-bottom: 0;
  }

  .nav-main-1.n6 .nav-sub-wrap .nav-sub-link {
    padding-left: 426px;
    background: url("/static/images/common/nav_sub06_img.png?20200526")
    no-repeat 100px 5px;
  }

  // 셀렉트박스가 컨텐츠영역 위로 나오지 못해 z-index값 0 >> 1로 변경
  .tit-wrap {
    position: relative;
    width: 100%;
    /*z-index:1;*/
    margin-top: 71px;
  }

  .tit-wrap:after {
    content: "";
    display: block;
    clear: both;
  }

  .tit-wrap .h1 {
    word-break: break-all;
    white-space: pre-wrap;
  }

  .tit-wrap .h1 strong {
    color: #f05014;
    font-weight: 700;
  }

  .step-wrap + .tit-wrap {
    margin-top: 41px;
  }

  .tit-wrap .btn-tip-page {
    position: absolute;
    top: 10px;
    right: 0;
    margin: 0;
  }

  .tit-wrap + .ui-tab.type-linebox {
    z-index: initial;
  }

  .tit-wrap .sub-title {
    font-size: 16px;
    color: #aaa;
  }

  /* table */
  /* 목록형 테이블 */
  [class*="tbl-base"] {
    margin: 20px 0;
    padding: 10px 0;
    border-top: 1px #bbb solid;
    border-bottom: 1px #eee solid;
    background: #fff;
  }

  [class*="tbl-base"] > table {
    width: 100%;
  }

  [class*="tbl-base"] > table > thead > tr > th,
  [class*="tbl-base"] > table > tbody > tr > th,
  [class*="tbl-base"] > table > tbody > tr > td {
    font-size: 16px;
    color: #333;
    background: #fff;
    border: 0;
    padding: 8px 20px;
    vertical-align: top;
  }

  [class*="tbl-base"] > table > tbody > tr > th {
    font-size: 14px;
    color: #777;
  }

  [class*="tbl-base"].type-s,
  [class*="tbl-base"].type-a {
    margin: 23px 0 0;
    padding: 0;
  }

  [class*="tbl-base"].type-s > table > thead th,
  [class*="tbl-base"].type-a > table > thead th {
    padding: 17px 10px;
    height: 60px;
    color: #333;
    border-bottom: 1px solid #eee;
    border-left: 1px solid #eee;
  }

  [class*="tbl-base"].type-s > table > tbody > tr > th,
  [class*="tbl-base"].type-s > table > tbody > tr > td,
  [class*="tbl-base"].type-a > table > tbody > tr > th,
  [class*="tbl-base"].type-a > table > tbody > tr > td,
  [class*="tbl-base"].type-s > table > tfoot > tr > th,
  [class*="tbl-base"].type-s > table > tfoot > tr > td {
    padding: 13px 10px 12px;
    height: 50px;
    text-align: left;
    color: #333;
    border-bottom: 1px solid #eee;
    border-left: 1px solid #eee;
  }

  [class*="tbl-base"].type-s > table > tbody > tr > th,
  [class*="tbl-base"].type-a > table > tbody > tr > th,
  [class*="tbl-base"].type-s > table > tfoot > tr > th {
    font-size: 16px;
    text-align: center;
    font-weight: 400;
  }

  [class*="tbl-base"].type-s > table > tfoot > tr > th,
  [class*="tbl-base"].type-s > table > tfoot > tr > td {
    font-weight: 700;
  }

  [class*="tbl-base"].type-s tr > *:first-child,
  [class*="tbl-base"].type-a tr > *:first-child {
    border-left: 0;
  }

  [class*="tbl-base"].type-a > table > tbody > tr > th {
    text-align: left;
  }

  [class*="tbl-base"].type-a > table > tbody > tr > td:last-child {
    text-align: right;
  }

  .tbl-noline {
    width: 100%;
    margin-top: 17px;
  }

  .tbl-noline table {
    table-layout: fixed;
    width: 100%;
    background: #fff;
  }

  .tbl-noline th {
    font-weight: 400;
    font-size: 14px;
    color: #777;
    vertical-align: top;
    text-align: left;
    padding: 10px 0 8px;
  }

  .tbl-noline td {
    font-weight: 400;
    font-size: 16px;
    color: #333;
    vertical-align: top;
    text-align: left;
    padding: 8px 0;
    word-break: break-all;
  }

  .tbl-noline tbody tr:first-child th {
    padding-top: 2px;
  }

  .tbl-noline tbody tr:first-child td {
    padding-top: 0;
  }

  .tbl-noline tr:last-child th,
  .tbl-noline tr:last-child td,
  .tbl-noline .tbl-body:last-child tr:last-child th,
  .tbl-noline .tbl-body:last-child tr:last-child td {
    padding-bottom: 0;
  }

  .tbl-noline .txt-sub {
    display: inline-block;
    font-size: 14px;
    color: #777;
    font-weight: 400;
  }

  .tbl-noline .txt-sub.txt-w6 {
    font-weight: 700;
  }

  .tbl-noline .txt-b {
    color: #333;
  }

  .ui-acco-pnl .tbl-noline {
    margin: 0;
    padding: 30px 20px 30px;
    background: #fff;
    border-top: 1px solid #bbb;
    border-bottom: 1px solid #eee;
  }

  .ui-acco-pnl .tbl-noline + .tbl-noline {
    margin-top: 20px;
  }

  a:hover .tbl-noline table,
  a:focus .tbl-noline table {
    background: transparent;
  }

  .tbl-noline.col-3 .col-th {
    width: 177px;
  }

  .tbl-noline.col-4 .col-th {
    width: 133px;
  }

  /* Date Picker */
  .ui-datepicker {
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: 100%;
    border: 0;
  }

  .ui-datepicker.calendar_top > .ui-datepicker-wrap {
    top: auto !important;
    bottom: 51px !important;
  }

  .ui-datepicker .inp-base {
    width: 100px;
  }

  .ui-datepicker .ui-datepicker-wrap {
    position: absolute;
    min-width: 295px;
    width: auto;
    margin-top: 0 !important;
    border: 1px solid #ddd;
  }

  .ui-datepicker .ui-datepicker-wrap.multi {
    width: 602px;
  }

  .ui-datepicker .ui-datepicker-wrap.multi:after {
    content: "";
    display: block;
    clear: both;
  }

  .ui-datepicker .ui-datepicker-wrap.multi .tbl-datepicker {
    width: 270px;
    float: left;
    margin: 0 10px;
  }

  .box-content.page-insureinfo .ui-datepicker-wrap {
    left: 0 !important;
  }

  .inp-datepicker {
    background: transparent;
    padding: 0;
    width: 100%;
    position: relative;
  }

  .inp-datepicker.disabled {
    background: #f6f6f6;
  }

  .inp-datepicker.disabled input.inp-base {
    color: #777 !important;
    border-color: #777 !important;
    -webkit-text-fill-color: #777 !important;
  }

  .inp-datepicker [class*="inp-"] {
    width: 100%;
  }

  .inp-datepicker .ui-datepicker-btn {
    position: absolute;
    top: 1px;
    right: 0;
    border-bottom: 2px solid transparent;
    margin: 0;
    width: 100%;
    min-width: 50px;
    height: 50px;
    text-align: right;
    background-color: transparent;
  }

  .inp-datepicker .ui-datepicker-btn i {
    display: inline-block;
    width: 25px;
    height: 25px;
    margin: 7px 11px 0;
    background: url("/static/images/common/ico_small.png")
    no-repeat 0 0;
  }

  .inp-datepicker .ui-datepicker-btn:focus,
  .inp-datepicker .ui-datepicker-btn:hover {
    border-bottom: 2px solid #f05014;
    outline: 0;
  }

  .inp-datepicker .ui-btn-cancel {
    margin-left: -60px;
  }

  .inp-datepicker.disabled [class*="inp-"] {
    background: #f6f6f6 !important;
    -webkit-text-fill-color: #777 !important;
  }

  .inp-datepicker [class*="inp-"] {
    background: #fff !important;
    -webkit-text-fill-color: #111 !important;
  }

  .ui-datepicker-btn:disabled,
  .ui-datepicker-btn[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }

  .ui-datepicker.multi .inp-datepicker .ui-btn-cancel {
    margin-left: -20px;
  }

  .ui-datepicker .ui-drop-pnl .btn-close {
    right: 16px;
    z-index: 1;
  }

  .tbl-datepicker td.empty {
    background: #fff;
  }

  .tbl-datepicker button:hover,
  .tbl-datepicker button.selected {
    color: #fff;
    background: #d65417;
  }

  .tbl-datepicker button.selected-end {
    color: #fff;
    background: #d65417 !important;
    width: 30px !important;
    border-radius: 50% !important;
  }

  .tbl-datepicker button.selected-start {
    color: #fff;
    background: #d65417 !important;
    width: 30px !important;
    border-radius: 50% !important;
  }

  .tbl-datepicker td.on-start:after {
    content: "";
    position: absolute;
    display: block;
    width: 50%;
    height: 30px;
    background: #fdeee7;
    left: 50%;
    top: 3px;
    z-index: 1;
  }

  .on-end-tbl.on-start-tbl tr.on-start.on-end td.on-start.on-end:after,
  .tbl-datepicker td.on-start.on-end:after {
    display: none !important;
  }

  .tbl-datepicker td.on-end:after {
    content: "";
    position: absolute;
    display: block;
    width: 50%;
    height: 30px;
    background: #fdeee7;
    left: 0;
    top: 3px;
    z-index: 1;
  }

  .tbl-datepicker button.selected-ing {
    background: rgb(227, 247, 255);
  }

  .datepicker-core .btn-wrap {
    float: left;
  }

  .tbl-datepicker button:disabled {
    background: #fff;
    cursor: default;
    color: #ddd;
  }

  .tbl-datepicker td.hover:not(.disabled) button {
    background: #fdeee7;
    width: 100%;
    border-radius: 0;
  }

  .tbl-datepicker td.hover-on:not(.disabled) button {
    background: #fdeee7;
    width: 100%;
    border-radius: 0;
  }

  .tbl-datepicker td.empty button {
    color: #ddd !important;
    background: transparent !important;
    width: 30px;
    border-radius: 0;
  }

  .tbl-datepicker td.empty button {
    color: #ddd !important;
    background: transparent !important;
    width: 30px;
    border-radius: 0;
  }

  .ui-datepicker.multi .inp-datepicker [class*="inp-"] {
    width: calc(50% - 27px);
  }

  .ui-datepicker.multi .txt-mark {
    width: 10px;
  }

  .datepicker-sec select {
    border: 0;
  }

  .datepicker-wrap:after {
    content: "";
    display: block;
    clear: both;
  }

  .datepicker-sec {
    width: 100%;
    height: 100%;
    background: #fff;
  }

  .datepicker-sec .ui-datepicker-close {
    display: none;
  }

  .datepicker-foot {
    display: block;
    width: 100%;
    padding: 20px 0;
    text-align: center;
  }

  .datepicker-sec .ui-datepicker-close.btn-base-s {
    display: inline-block;
  }

  .datepicker-head {
    position: relative;
  }

  .datepicker-head-tit {
    border-bottom: 1px solid #ccc;
    padding: 5px;
    text-align: center;
    font-weight: 700;
    display: none;
  }

  .datepicker-head-select {
    padding: 30px 10px 4px;
    text-align: center;
    display: none;
  }

  .datepicker-head-select .ui-select {
    display: inline-block;
    width: auto;
    margin: 0 13px;
    height: 30px;
    min-height: 30px;
  }

  .datepicker-head-select .ui-select select {
    font-size: 16px;
    font-weight: 700;
    padding-right: 20px;
    height: 30px;
    min-height: 30px;
    color: #000;
  }

  .datepicker-head-select .ui-select:before {
    border: 4px solid transparent;
    border-top: 5px solid #333;
    top: 18px;
  }

  .datepicker-head-date {
    text-align: center;
    font-size: 18px;
    width: 100%;
    padding: 26px 0 15px;
    font-size: 16px;
    font-weight: 600;
  }

  .datepicker-head-date:after {
    content: "";
    display: block;
    clear: both;
  }

  .datepicker-head-date strong {
    font-weight: 600;
  }

  .datepicker-head-date .year {
    display: block;
    float: left;
    margin: 0 0 0 56px;
    font-weight: 600;
  }

  .datepicker-head-date .month {
    display: block;
    float: right;
    margin: 0 70px 0 0;
    font-weight: 600;
  }

  .datepicker-multi-head {
    width: 100%;
    overflow: hidden;
  }

  .datepicker-multi-head > div {
    width: 50%;
    float: left;
  }

  .datepicker-sec .cal-wrapper {
    padding: 10px;
    min-width: 450px;
    width: auto !important;
    width: 450px;
    overflow: hidden;
  }

  .tb_cal_popup tbody tr td:first-child button {
    color: #ff0000;
  }

  .datepicker-core {
    padding: 0 0 10px;
    margin: 0 auto;
    border-bottom: 1px solid #ccc;
    width: 266px;
  }

  .datepicker-core:after {
    content: "";
    display: block;
    clear: both;
  }

  .tbl-datepicker {
    table-layout: fixed;
    margin: 0 auto;
  }

  .tbl-datepicker col.n1 {
    width: 35px;
  }

  .tbl-datepicker thead th {
    color: #222;
    font-size: 13px;
  }

  .tbl-datepicker th {
    padding: 11px 0;
    text-align: center !important;
    font-weight: 400;
    background: #fff;
  }

  .tbl-datepicker th abbr {
    display: block;
    border: 0;
    cursor: default;
    font-weight: 600;
  }

  .tbl-datepicker td {
    padding: 3px 0;
    text-align: center;
    background: #fff;
    border: 0;
    position: relative;
  }

  .tbl-datepicker td span,
  .tbl-datepicker td button {
    z-index: 2;
    position: relative;
    font-size: 14px;
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 16px;
    min-height: 30px;
    line-height: 25px;
    margin: 0 auto;
    color: #000;
    text-align: center;
  }

  .tbl-datepicker td span {
    color: #666;
    cursor: default;
    opacity: 0.5;
  }

  .tbl-datepicker td.empty {
    background: #fff;
  }

  .tbl-datepicker button:hover {
    background: #eee;
  }

  .tbl-datepicker button.selected {
    color: #fff;
    background: #f05014;
  }

  .tbl-datepicker button.selected-end {
    color: #fff;
    background: #f05014 !important;
  }

  .tbl-datepicker button.selected-start {
    color: #fff;
    background: #f05014 !important;
  }

  .tbl-datepicker button.selected-ing {
    background: rgb(227, 247, 255);
  }

  .datepicker-core .btn-wrap {
    float: left;
  }

  .tbl-datepicker button:disabled {
    background: #fff;
    cursor: default;
    color: #ddd;
  }

  .hover button.today:after,
  .hover-on button.today:after,
  .tbl-datepicker button.today:hover:after,
  .tbl-datepicker button.today.selected:after {
    display: none;
  }

  .tbl-datepicker button.today + em,
  .tbl-datepicker button.selected-start + em,
  .tbl-datepicker button.selected-end + em {
    position: absolute;
    display: block;
    bottom: -10px;
    width: 100%;
    font-size: 11px;
    color: #d65417;
    text-align: center;
    z-index: 5;
  }

  .tbl-datepicker button.today:hover + em,
  .tbl-datepicker button.today.selected + em {
    display: none;
  }

  .tbl-datepicker button.today.selected-start,
  .tbl-datepicker button.today.selected {
    color: #fff !important;
  }

  .tbl-datepicker button.today {
    color: #d65417;
  }

  .datepicker-head-btn {
    position: absolute;
    width: 100%;
    z-index: 1;
    top: 20px;
  }

  .datepicker-head-today {
    position: absolute;
    z-index: 1;
    top: 0;
    display: none;
  }

  .datepicker-head-today button {
    width: 100px;
    height: 30px;
  }

  .tbl-datepicker button.today.selected-start:after {
    color: #000;
  }

  .datepicker-head-btn button {
    position: absolute;
    top: 9px;
    background: url("/static/images/common/ico_small.png")
    no-repeat 0 0;
  }

  .datepicker-head-btn .ui-datepicker-prev {
    right: 115px;
    background-position: -53px -53px;
    width: 25px;
    height: 25px;
  }

  .datepicker-head-btn .ui-datepicker-next {
    right: 30px;
    background-position: -78px -53px;
    width: 25px;
    height: 25px;
  }

  .datepicker-head-btn .ui-datepicker-prev-y {
    left: 30px;
    background-position: -53px -53px;
  }

  .datepicker-head-btn .ui-datepicker-next-y {
    left: 115px;
    background-position: -78px -53px;
  }

  .datepicker-head-btn .btn-arrow.disabled {
    pointer-events: none;
  }

  /* ui modal dialog */
  .base-layer {
    transition: background-color 0.3s ease-in;
    top: 0;
    left: 0;
  }

  .dim,
  .modal-backdrop {
    display: none;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 200%;
    background-color: rgba(0, 0, 0, 0.8);
  }

  .modal-open {
    overflow: hidden;
    padding: 0;
  }

  .dim-dropdown .dim {
    display: block;
    opacity: 1;
  }

  .ui-modal {
    display: none;
    opacity: 0;
    position: fixed;
    z-index: 1;
    width: auto;
    height: auto;
    padding: 0 32px;
  }

  .ui-modal.type-full {
    width: 100%;
    height: 100%;
    padding: 0;
  }

  .ui-modal.type-select .lb-radio,
  .ui-modal.type-select .lb-check {
    margin: 0;
    border-bottom: 1px solid #ddd;
    padding: 20px;
    width: 100%;
  }

  .ui-modal.ps-page {
    max-height: 100%;
  }

  .ui-modal.ps-bottom {
    padding: 0;
    background: #fff;
    width: 100%;
  }

  .ui-modal.ps-bottom .ui-modal-cont.type-half {
    max-height: 400px !important;
  }

  .ui-modal.ps-bottom .ui-modal-cont {
    max-height: 300px;
    overflow-y: auto;
    z-index: 2;
  }

  .ui-modal.ps-bottom.type-select .ui-modal-cont {
    max-height: 500px;
    overflow-y: auto;
    z-index: 2;
  }

  .ui-modal.ps-bottom .h2 {
    font-size: 22px;
  }

  .ui-modal.ps-bottom .h2:first-child {
    margin-top: 20px;
  }

  .ui-modal.ps-page .ui-modal-cont {
    z-index: 2;
    overflow: hidden;
  }

  .ui-modal.ps-center {
    padding: 0;
  }

  .ui-modal .wrap-inner {
    width: auto;
    padding-top: 0;
    padding-left: 40px;
    padding-right: 40px;
  }

  .ui-modal.show {
    display: block;
    left: 50%;
  }

  .ui-modal.ps-bottom .ui-modal-footer {
    position: relative;
  }

  .ui-modal.ps-page .ui-modal-header {
    padding: 24px 70px 0;
    min-height: 70px;
    z-index: 3;
    background: #fff;
  }

  .ui-modal.ps-page .ui-modal-header.row-2 {
    padding: 14px 70px;
  }

  .ui-modal.ps-page .ui-modal-header .ui-modal-tit {
    text-align: center;
    font-size: 15px;
  }

  .ui-modal.ps-page .ui-modal-header .btn-txt {
    position: absolute;
    top: 22px;
    color: #999;
    border-bottom: 0;
  }

  .ui-modal.ps-page .ui-modal-header .btn-txt.n1 {
    left: 32px;
  }

  .ui-modal.ps-page .ui-modal-header .btn-txt.n2 {
    right: 18px;
  }

  .ui-modal.ps-page .ui-modal-header .n2 {
    right: 20px;
  }

  .ui-modal-header .btn-close,
  .ui-modal .btn-close {
    position: absolute;
    top: 40px;
    right: 40px;
    z-index: 101;
  }

  .ui-modal-wrap {
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

  .ui-modal-header {
    width: 100%;
    position: relative;
    border: 0;
    padding: 52px 40px 30px;
    max-height: 100%;
  }

  .ui-modal-header .ui-modal-tit {
    padding-right: 20px;
    font-size: 26px;
    line-height: 36px;
    text-align: left;
    font-weight: 700;
    background-color: #fff;
    width: 100%;
    height: 100%;
    color: #000;
    display: block;
  }

  .ui-modal-header .h5 {
    padding-right: 20px;
  }

  .ui-modal-header .ui-modal-progress {
    position: absolute;
    top: 32px;
    left: 40px;
  }

  .ui-modal-header .ui-modal-progress .tit-progress {
    font-size: 14px;
    color: #555;
  }

  .ui-modal-header .n2 {
    position: absolute;
    top: 20px;
    right: 32px;
    display: block;
    line-height: 22px;
    padding: 0;
  }

  .ui-modal-header .n1 {
    position: absolute;
    top: 22px;
    left: 32px;
    display: block;
    line-height: 22px;
    padding: 0;
  }

  .ui-modal-header .ui-modal-close.btn-txt {
    font-size: 14px;
    color: #999;
    font-weight: 400;
    border: 0;
  }

  .size-xl .ui-modal-cont {
    width: 800px;
  }

  .size-xl .ui-modal-header {
    width: 800px;
  }

  .size-l .ui-modal-cont {
    width: 690px;
  }

  .size-l .ui-modal-header {
    width: 690px;
  }

  .size-m .ui-modal-cont {
    width: 530px;
  }

  .size-m .ui-modal-header {
    width: 530px;
  }

  .size-s .ui-modal-cont {
    width: 250px;
  }

  .size-s .ui-modal-header {
    width: 250px;
  }

  .slider-wrap .slider-item {
    padding: 0 15px;
    outline: 0;
  }

  .ui-modal-cont {
    position: relative;
    top: 0;
    left: 0;
    width: 530px;
    height: auto;
    max-width: 846px;
    margin: 0;
    padding: 0;
    background-color: #fff;
  }

  .ui-modal-cont .modal-pd {
    padding: 0 40px;
  }

  .ui-modal-cont .modal-info {
    font-size: 18px;
  }

  .ui-modal-cont .center-phone {
    font-size: 32px;
    color: #000;
    font-weight: 700;
    line-height: 1;
  }

  .ui-modal-cont .center-time {
    font-size: 14px;
    color: #999;
    margin-top: 5px;
  }
  .txt-bold,
  .ui-modal-cont .txt-bold {
    font-weight: 700 !important;
  }

  .ui-modal-cont .center-box {
    position: relative;
    display: block;
    min-height: 140px;
    padding: 26px 30px;
    background: #f7f7f7;
  }

  .ui-modal-cont .center-box::after {
    content: "";
    position: absolute;
    display: block;
    top: 50%;
    right: 30px;
    width: 100px;
    height: 100px;
    border-radius: 100px;
    background: url("/static/images/content/ico_monitor.png") no-repeat #fff;
    margin-top: -50px;
  }

  .ui-modal-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 3;
    text-align: right;
    margin: 0;
    padding: 60px 40px 40px;
    background: #fff;
  }

  .ui-modal-footer .btn-txt {
    font-weight: 400;
    font-size: 14px;
    color: #999;
    border-bottom: 0;
    margin-right: 30px;
  }

  .ui-modal-cont .h2 {
    margin-top: 0;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
  }

  .ui-modal-footer .type-b {
    width: 34%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .ui-modal-footer .type-b + .btn-base-l {
    width: 66%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .ui-modal.page-insuinfo .wrap-inner {
    padding: 0;
  }

  /* 보안키패드 */
  .keypad-wrap {
    position: relative;
    border: 1px solid #ccc;
    background: #fff;
    padding: 15px 45px 20px 20px;
    margin-top: 15px;
    border-radius: 4px;
    width: 332px;
    text-align: center;
    overflow: hidden;
  }

  .keypad-wrap > button {
    width: 32px;
    height: 32px;
    background: #777;
    border: 1px solid #777;
    color: #fff;
    border-radius: 4px;
    margin: 5px 5px 0 0;
    font-size: 14px;
    font-weight: 700;
    vertical-align: top;
    float: left;
  }

  .keypad-wrap > button:disabled {
    background: #eee
    no-repeat 50% 50%;
    border-color: #eee;
  }

  .keypad-wrap .btn-keypad-confirm {
    position: absolute;
    bottom: 20px;
    right: 15px;
    width: 68px;
    background-color: #f05014;
    border-color: #f05014;
  }

  .keypad-wrap .btn-keypad-del {
    position: absolute;
    bottom: 57px;
    right: 15px;
    background: #eee url(/static/images/common/keypad_del.png) no-repeat
    50% 50%;
    border-color: #eee;
  }

  .keypad-head {
    width: 100%;
  }

  .keypad-head h1 {
    font-size: 16px;
    text-align: left;
    margin-bottom: 7px;
  }

  .keypad-head .btn-close {
    width: 20px;
    height: 20px;
    top: 17px;
    right: 17px;
    background: url(/static/images/common/btn_close.png) no-repeat 50% 50%;
  }

  /* 보험약관 layer */
  #iframeLayer.pdfIframe {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }

  .iframe-header {
    height: 60px;
    background: #323639;
    z-index: 3;
  }

  .iframe-header + iframe {
    background: #525659;
  }

  /* 나의계약조회 상세보기 modal 관련 */
  .page-insuinfo-tit {
    padding: 50px 40px 45px;
    min-height: 300px;
    background: #f7f7f7;
  }

  .page-insuinfo-tit .date {
    margin-top: 3px;
    font-size: 16px;
    color: #777;
  }

  .page-insuinfo-tit .h1 .txt-tag {
    position: relative;
    top: 13px;
    vertical-align: top;
  }

  .page-insuinfo-tit .info {
    margin-top: 30px;
  }

  .page-insuinfo-tit .info:after {
    content: "";
    display: block;
    clear: both;
  }

  .page-insuinfo-tit .info dl {
    float: left;
    min-width: 200px;
  }

  .page-insuinfo-tit .info dl.n1 {
    width: 230px;
  }

  .page-insuinfo-tit .info dl.n2 {
    width: 180px;
    min-width: auto;
  }

  .page-insuinfo-tit .info dl.n3 {
    width: auto;
    min-width: auto;
  }

  .page-insuinfo-tit .info dt {
    font-size: 14px;
    color: #777;
  }

  .page-insuinfo-tit .info dd {
    margin-top: 10px;
    font-size: 18px;
    color: #111;
    font-weight: 700;
  }

  .page-insuinfo-tit .info dd > span {
    display: block;
    font-size: 12px;
    color: #111;
    font-weight: 400;
  }

  .page-insuinfo-tit .btn-txt {
    font-size: 14px;
    color: #333;
    font-weight: 700;
    position: absolute;
    top: 100px;
    left: 601px;
  }

  .page-insuinfo-tit .btn-txt:hover,
  .page-insuinfo-tit .btn-txt:focus {
    color: #f05014;
  }

  .box-content.page-insureinfo {
    position: relative;
  }

  .ui-modal.page-insuinfo .ui-tab,
  .box-content.page-insureinfo .ui-tab {
    margin-top: -26px;
    padding: 0 40px 40px;
  }

  .ui-modal.page-insuinfo .ui-tab-pnl,
  .box-content.page-insureinfo .ui-tab-pnl {
    padding-top: 10px;
  }

  .graph-area,
  .course-area {
    border: 1px solid #eee;
    width: 100%;
    height: 200px;
  }

  .ui-floating-wrap {
    transform: translateZ(0);
  }

  .wrap-top {
    position: fixed;
    width: 100%;
    background: #fff;
    top: 96px;
    left: 0;
    padding: 53px 0 47px 0;
    z-index: 100;
    transition: padding 0.2s ease-out;
  }

  .type-floating.sp-top .wrap-content {
    padding-top: 225px;
  }

  .type-floating.sp-top .wrap-content.type-normal {
    padding-top: 0 !important;
  }

  .type-floating.sp-top .wrap-content.plan-double {
    padding-top: 205px;
  }

  .type-floating.sp-top.type-car .wrap-content {
    padding-top: 221px;
  }

  .type-floating.sp-top.type-small .wrap-content {
    padding-top: 183px;
  }

  .type-floating.sp-top.type-single .wrap-content {
    padding-top: 161px;
  }

  .type-floating.sp-top .wrap-content.plan-single {
    padding-top: 93px;
  }

  .scrolled .type-floating.sp-top .wrap-content {
    padding-top: 187px;
    transition: all 0.1s ease-in;
  }

  .type-floating.sp-top .wrap-content.type-car {
    padding-top: 312px;
  }

  .tooltip-side {
    transition: height 0.1s ease-in, right 0.3s ease-in;
    z-index: 2;
  }

  .wrap-top.plan-select {
    border-bottom: 1px solid #ccc;
  }

  .wrap-side {
    position: fixed;
    top: 0;
    right: -420px;
    width: 420px;
    height: 100%;
    padding: 0 0 0 28px;
    background: #fff;
    border: 1px solid #eee;
    transition: right 0.2s ease-out, padding 0.2s ease-out;
    z-index: 1;
  }

  .wrap-content.type-car {
    width: calc(100% - 390px);
  }

  .wrap-content.type-car .plan-select {
    height: 312px;
    overflow: initial;
  }

  .wrap-content.type-car .wrap-side {
    top: 372px;
    height: calc(100% - 372px);
  }

  .wrap-side .ui-acco.type-b .ui-acco-tit,
  .wrap-side .ui-acco.type-b .ui-acco-pnl {
    padding-left: 10px;
    padding-right: 10px;
  }

  .wrap-side .ui-datepicker .ui-datepicker-wrap {
    left: 0 !important;
    top: auto !important;
    bottom: 0;
    box-shadow: 0 5px 0 rgba(255, 255, 255, 1);
    transform: scale(1) translateY(0);
  }

  .ui-modal .ui-datepicker .ui-datepicker-wrap {
    left: 0 !important;
    top: 74px !important;
  }

  .type-car .wrap-top.plan-select {
    height: 242px;
  }

  .type-car .wrap-top.plan-select-desktop-calculation {
    height: 168px;
    border-bottom: 1px solid #ccc;
  }
  .scrolled .wrap-top.plan-select-desktop-calculation {
    height: 80px;
    border-bottom: 1px solid #ccc;
    padding-top: 0px;
    overflow: hidden;
    min-height: auto;
  }

  .scrolled .wrap-top.plan-select {
    height: 104px;
    border-bottom: 1px solid #ccc;
    padding-top: 0px;
    overflow: hidden;
    min-height: auto;
  }

  .wrap-side .ui-datepicker {
    height: auto;
  }

  .type-car .wrap-content .disp-scroll,
  .scrolled .plan-select-item,
  .scrolled .plan-select-item .item-dtl,
  .scrolled .plan-select-item.selected:before,
  .scrolled .type-car .wrap-content .plan-select-item .item-tit-sub > em,
  .scrolled .type-car .wrap-content .item-stit-wrap > .item-date,
  .scrolled .type-car .wrap-content .plan-select-item > .btn-wrap,
  .scrolled .type-car .wrap-content .disp-normal {
    display: none;
  }

  .scrolled .type-car .wrap-content .item-stit-wrap > .item-stit {
    margin-top: 13px;
  }

  .scrolled
  .type-car
  .wrap-content
  .item-stit-wrap
  > .item-stit
  > .item-stit-sub {
    display: inline-block;
    text-align: right;
  }

  .scrolled
  .type-car
  .wrap-content
  .item-stit-wrap
  > .item-stit
  > .item-stit-sub2 {
    font-size: 20px;
    font-weight: 400;
  }

  .scrolled .type-car .wrap-content .disp-scroll {
    display: block;
  }

  .scrolled .type-car .wrap-content .disp-scroll.item-stit-sub {
    margin-top: 8px;
  }

  .scrolled .type-car .wrap-content .disp-scroll > .item-stit {
    text-align: right;
  }
  //레저 plan-select-item 시작
  .type-leisureSwicth .plan-select-wrap > ul > li:last-child {
    float: right;
    padding-left: 30px;
  }
  //레저 plan-select-item 끝
  .scrolled .plan-select-item.selected,
  .scrolled .plan-double .plan-select-item.selected {
    display: block;
    border: 0;
    margin: 0;
    width: 100%;
    padding-right: 78px;
  }

  .scrolled .plan-select-item.selected button {
    width: 100%;
    overflow: hidden;
  }

  .scrolled .plan-select-item.selected button:focus {
    outline: none;
  }

  .scrolled .plan-select-item.selected button .item-tit {
    float: left;
    margin-top: 11px;
  }

  .scrolled .plan-select-item.selected button .item-stit-wrap {
    float: right;
    text-align: right;
  }

  .scrolled .plan-select-item .item-stit {
    margin-top: 6px;
  }

  .scrolled .item-tit span {
    display: none;
  }

  /* 태그 스타일 */
  .txt-tag {
    display: inline-block;
    min-height: 20px;
    margin: 0 3px;
    padding: 3px 16px;
    font-size: 13px;
    line-height: 1;
    color: #fff;
    background: #777;
    border-radius: 10px;
    font-weight: 400;
  }

  .txt-tag.type-a {
    background: #f05014;
  }

  .txt-tag.type-a.comp {
    background: var(--carrot-font-color3, #555);
  }

  .txt-tag.type-b {
    background: #00358e;
  }
  .txt-tag[class*="type-line"] {
    height: 26px;
    padding: 4px 14px 0;
    background: #fff;
    border-radius: 3px;
    border: 1px solid #eee;
    color: #c5c5c5;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
  }
  .txt-tag.type-line-a {
    border: 1px solid #f05014;
    color: #f05014;
  }

  .base-header .nav-login {
    padding-right: 66px;
  }

  /* 메인 layout */
  .type-main .base-header {
    top: -103px;
    opacity: 0;
    box-shadow: 5px 0 10px rgba(0, 0, 0, 0.08);
  }

  .type-main .base-header.gnb-view {
    top: 0;
    opacity: 1;
  }

  .type-main .base-header .logo {
    display: none;
  }

  .type-main .base-header .mLogo {
    display: block;
  }

  .type-main .base-header .nav-close {
    display: block;
    margin: 2px 0 0 -16px;
  }
  .type-main .base-header .nav-close .btn-close {
    width: 16px;
    height: 16px;
    border: 0;
    background: url("/static/images/main/typemain_nav_close.png")
    no-repeat;
  }

  .type-main .base-body {
    padding: 0;
  }

  .type-main .base-main {
    width: 100%;
    padding-left: 191px;
    min-height: 100%;
    height: 100%;
    background: #f2f2f2;
    padding-top: 85px;
  }

  .type-main .main-header {
    position: fixed;
    width: 100%;
    text-align: right;
    top: 0;
    right: 0;
    height: 85px;
    padding: 34px 30px 15px;
    z-index: 1;
    background: #f2f2f2;
  }

  .type-main .main-header::after {
    content: "";
    display: block;
    clear: both;
  }

  .type-main .main-header-btns {
    float: left;
    position: relative;
    width: calc(100% - 220px);
    margin-left: 142px;
  }

  .type-main .main-header-btns::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 2px;
    width: 100%;
    height: 1px;
    background: #f4a98e;
    z-index: -1;
  }

  .type-main .main-header-btns::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 7px;
    height: 7px;
    background: #f05014;
    border-radius: 7px;
  }

  .type-main .main-header .btn-txt {
    display: inline-block;
    height: 39px;
    min-width: 49px;
    padding-bottom: 6px;
    font-size: 14px;
    color: #111;
    font-weight: 600;
    margin: 0 20px;
    text-decoration: none;
    border: 0;
    vertical-align: middle;
  }

  .type-main .main-header .btn-txt.hue-p {
    border-bottom: 3px solid var(--carrot-primary-color, #f05014);
    letter-spacing: 0.5px;
    margin-right: 0;
  }
  .type-main .main-header .btn-txt.type-carrot {
    min-width: 70px;
    font-size: 12px;
    letter-spacing: normal;
  }
  .type-main .main-header .btn-txt.type-carrot span {
    font-size: 12px;
    letter-spacing: normal;
  }

  .type-main .main-header .btn-menu {
    float: right;
    position: relative;
    display: inline-block;
    width: 20px;
    height: 36px;
    z-index: 2;
    background: url("/static/images/common/btn_menu_d.png?20200428")
    center 10px no-repeat;
    margin-right: 10px;
  }

  .type-main .main-header .btn-menu::before,
  .type-main .main-header .btn-menu::after {
    display: none;
  }

  .type-main .main-header .btn-menu .hide {
    width: 0 !important;
    height: 0 !important;
    background: transparent !important;
    z-index: 0 !important;
    display: block;
    max-height: 0 !important;
    top: 0;
    left: 0;
    margin-top: 0;
    border-radius: 0;
  }

  .type-main .main-tit {
    position: absolute;
    top: 114px;
    left: 230px;
    font-size: 16px;
    font-weight: normal;
    color: #000;
    line-height: 1.6;
    letter-spacing: -1px;
    transition: all 0.2s ease-out;
  }

  .type-main .main-tit.down {
    top: 800px;
  }

  .type-main .main-tit::after {
    content: "";
    position: absolute;
    display: block;
    left: 0;
    bottom: -72px;
    width: 7px;
    height: 44px;
    background: url("/static/images/main/main_img_point.png")
    no-repeat;
    background-size: 100%;
  }

  /* 메인 왼쪽 side */
  .main-side {
    position: fixed;
    top: 0;
    left: 0;
    width: 191px;
    height: 100%;
    background: #f8f8f8;
    padding: 44px 27px 30px 24px;
    z-index: 2;
    box-shadow: 5px 0 10px rgba(0, 0, 0, 0.03);
  }

  .main-side .tag {
    position: absolute;
    top: 172px;
    left: -140px;
    font-size: 12px;
    color: #f05014;
    font-weight: 400;
    width: 340px;
    overflow: hidden;
    transform: rotate(-90deg);
  }

  .main-side .tag span {
    display: block;
    float: left;
    margin-right: 40px;
    white-space: nowrap;
  }

  .main-side .side-info {
    width: 143px;
    background: #fff;
    position: fixed;
    bottom: 30px;
    left: 35px;
  }

  .main-side .side-info .link {
    margin-bottom: 20px;
  }

  .main-side .side-info .link a,
  .main-side .side-info .link button {
    width: 100%;
    display: block;
    font-size: 23px;
    padding: 0 0 2px;
    text-align: left;
    border-bottom: 1px solid #ccc;
    text-decoration: none;
    color: #111;
    margin-top: 25px;
  }

  .main-side .helpdesk strong {
    display: block;
    font-size: 17px;
    font-weight: 600;
    color: #111;
  }

  .main-side .helpdesk p {
    color: #9fa0a0;
    font-size: 13px;
    margin-top: 5px;
  }

  .main-side .helpdesk .helpdesk-box {
    border: 1px solid #f05014;
    padding: 10px 15px;
    color: #f05014;
    font-size: 13px;
    font-weight: 500;
    margin-top: 20px;
  }

  /* desktop 메인 footer */
  .main-footer {
    position: absolute;
    width: 100%;
    height: 130px;
    font-size: 12px;
    font-weight: 200;
    color: #fff;
    background: #262626;
    z-index: 3;
    padding-top: 40px;
  }

  .main-footer:after {
    content: "";
    display: block;
    clear: both;
  }

  .main-footer-s1 {
    float: left;
    width: 220px;
    margin-left: 235px;
  }

  .main-footer-s1 strong {
    display: block;
    font-size: 18px;
    font-weight: 600;
    line-height: 1;
  }

  .main-footer-s2 {
    float: left;
    width: 400px;
  }

  .main-footer-s2 address {
    display: block;
    font-style: normal;
  }

  .main-footer-s2 address span {
    display: inline-block;
    margin-bottom: 5px;
  }

  .main-footer-s2 em {
    color: #fff;
    font-size: 9px;
    color: #717171;
    letter-spacing: 0;
  }

  .main-footer-s3 {
    float: left;
    width: 140px;
  }

  .main-footer-s3 button {
    display: block;
    color: #fff;
    font-size: 12px;
    padding-bottom: 7px;
    border: 0;
  }

  .main-footer-s4 {
    float: left;
    width: 270px;
  }

  .main-footer-s4 p {
    margin-bottom: 5px;
  }

  .main-footer-s4 .label {
    position: relative;
    display: inline-block;
    font-weight: 600;
    margin-right: 5px;
  }

  .main-footer-select {
    float: left;
    position: relative;
    width: 150px;
    height: 26px;
    border: 1px #777 solid;
    margin-top: -6px;
  }

  .main-footer-select button {
    position: relative;
    display: block;
    width: 100%;
    font-size: 12px;
    color: #fff;
    line-height: 24px;
    text-align: left;
    padding: 0 10px;
  }

  .main-footer-select > button::after {
    content: "";
    position: absolute;
    display: block;
    right: 10px;
    top: 9px;
    width: 0;
    height: 0;
    border-top: 6px #fff solid;
    border-left: 3px transparent solid;
    border-right: 3px transparent solid;
  }

  .main-footer-select > .select-layer {
    position: absolute;
    left: -1px;
    bottom: 100%;
    width: 150px;
    background: #fff;
    border: 1px #777 solid;
    z-index: 99;
  }

  .main-footer-select > .select-layer > button {
    color: #343434;
  }

  .main-footer-select > .select-layer > button:hover,
  .main-footer-select > .select-layer > button:focus {
    background: #e7e7e7;
  }

  .main-footer- .main-footer .btn-txt:focus,
  .main-footer .btn-txt:hover {
    color: #fff !important;
    border-color: #fff !important;
  }

  /* 상품별 메인 > 보장내용 팝업 텍스트 */
  .main-txt-wrap {
    font-size: 14px;
  }

  .main-txt-wrap .bul-dot {
    font-weight: 700;
    margin-top: 30px;
  }

  .main-txt-wrap p {
    margin-top: 10px;
  }

  .plan-select-wrap {
    width: 1000px;
    margin: 0 auto;
  }

  .plan-select-wrap ul::after {
    content: "";
    display: block;
    clear: both;
  }

  .plan-select-item {
    width: 320px;
    margin-left: 20px;
    float: left;
    position: relative;
  }
  .type-car .plan-select-item {
    width: 355px;
  }
  .plan-select-item:before {
    content: "";
    display: block;
    position: absolute;
    top: 7px;
    left: -20px;
    width: 1px;
    height: 100px;
    background: #eee;
  }

  .type-small .plan-select-item:before {
    height: 74px;
  }

  .plan-select-item:first-child:before {
    display: none;
  }

  .plan-select-item:first-child {
    margin-left: 0;
  }

  .plan-select-item:first-child button {
    border-left: 0;
  }

  .plan-select-item button {
    color: #ccc;
    letter-spacing: -0.05em;
    text-align: left;
  }

  .plan-select-item button + .btn-wrap > button {
    border: 1px #ccc solid;
    pointer-events: none;
  }

  .plan-select-item button:hover + .btn-wrap > button {
    border-color: #777;
  }

  .plan-select-item button:hover + .btn-wrap > button > span {
    color: #111;
  }

  .plan-select-item.selected button + .btn-wrap > button {
    border-color: #777;
    border-left-width: 1px;
    border-left-style: solid;
    pointer-events: all;
  }

  .plan-select-item.selected button .item-tit,
  .plan-select-item button:hover .item-tit,
  .plan-select-item button:focus .item-tit {
    color: #f05014;
  }

  .plan-select-item.selected button .item-stit,
  .plan-select-item button:hover .item-stit,
  .plan-select-item button:focus .item-stit {
    color: #111;
  }

  .plan-select-item.selected button .item-date,
  .plan-select-item button:hover .item-date,
  .plan-select-item button:focus .item-date {
    color: #777;
  }

  .plan-select-item .item-tit {
    font-size: 28px;
    display: block;
    font-weight: 700;
  }

  .plan-select-item .item-tit span {
    font-size: 22px;
  }

  .plan-select-item .item-tit span.type-s {
    font-size: 13px;
    margin: 12px 0 0 14px;
    vertical-align: top;
    background: #d7d7d7;
  }
  .plan-select-item button:hover .item-tit span.type-s {
    background: #686868;
  }

  .plan-select-item .item-stit span {
    font-size: 24px;
  }

  .plan-select-item .item-tit-sub {
    font-size: 22px;
  }

  .plan-select-item .item-stit {
    font-size: 28px;
    display: block;
    font-weight: 700;
    margin-top: 0;
  }

  .plan-select-item .item-stit-wrap.case-car .item-stit {
    line-height: 1.1;
    margin-top: 7px;
  }

  .plan-select-item .item-stit-wrap.case-car .item-stit > i {
    font-size: 20px;
    font-weight: 400;
  }

  .plan-select-item .item-stit.h5 {
    color: #ccc;
  }

  .plan-select-item .item-stit .sub-txt {
    position: relative;
  }

  .plan-select-item .item-stit .sub-txt > i {
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 120px;
    font-size: 14px;
    font-weight: 400;
  }

  .plan-select-item.selected .item-stit .sub-txt > i,
  .plan-select-item button:hover .item-stit .sub-txt > i,
  .plan-select-item button:focus .item-stit .sub-txt > i {
    color: #777;
  }

  .plan-select-item .item-stit-sub {
    font-size: 16px;
    font-weight: 400;
    color: #333;
  }

  .plan-select-item .item-date {
    font-size: 16px;
    display: block;
  }

  .plan-select-item .item-date b {
    font-weight: 700;
  }

  .plan-select-item.selected .item-date b,
  .plan-select-item button:hover .item-date b,
  .plan-select-item button:focus .item-date b {
    color: #111;
  }

  .plan-select-item .item-stit-wrap.case-car .txt-s {
    font-size: 14px;
  }

  .plan-select-item .bul-dot {
    padding-left: 9px;
    color: #ccc;
  }

  .plan-select-item .bul-dot:before {
    width: 3px;
    height: 3px;
    background: #ccc;
  }

  .plan-select-item .btn-txt-arrow {
    font-size: 16px;
    color: #111;
    font-weight: 700;
  }

  .plan-select-item .btn-txt-arrow::after {
    top: -1px;
  }

  .plan-select-item .btn-txt-arrow span {
    padding-left: 0;
  }

  .plan-select-item .btn-txt-arrow span::before {
    display: none;
  }

  .plan-select-item .ui-select {
    width: initial;
    width: 160px;
    margin-right: 10px;
    min-height: 40px;
  }

  .plan-select-item .ui-select::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background: #ccc;
    z-index: -1;
  }

  .plan-select-item .ui-select-btn {
    min-height: 40px;
    font-size: 24px;
    font-weight: 700;
    padding-left: 5px;
    padding-right: 40px;
    border-bottom: 0;
    pointer-events: none;
  }

  .plan-select-item .ui-select + .item-stit {
    display: inline-block;
  }

  .plan-select-item.selected .ui-select::after,
  .plan-select-item button:hover .ui-select::after,
  .plan-select-item button:focus .ui-select::after {
    background: #f05014;
  }

  .plan-select-item.selected .ui-select-btn,
  .plan-select-item button:hover .ui-select-btn,
  .plan-select-item button:focus .ui-select-btn {
    color: #111;
  }

  .plan-select-item.selected .ui-select-btn {
    pointer-events: all;
  }

  .plan-select-item > .btn-wrap button > span {
    color: #ccc;
  }

  .plan-select-item.selected > .btn-wrap button > span {
    color: #111;
  }

  // 단일 플랜일 경우
  .wrap-content.plan-single {
    padding-top: 104px;
  }

  .wrap-content.plan-single > .wrap-top {
    position: fixed;
    top: 96px;
    height: 104px;
    border-bottom: 1px solid #ccc;
    padding: 0;
  }

  .plan-single-wrap {
    width: 1000px;
    margin: 0 auto;
    padding-top: 11px;
  }

  .plan-single-wrap::after {
    content: "";
    display: block;
    clear: both;
  }

  .plan-single-wrap .item-tit {
    float: left;
    font-size: 28px;
    font-weight: 700;
    color: #f05014;
  }

  .plan-single-wrap .item-stit-wrap {
    float: right;
  }

  .plan-single-wrap .item-stit-wrap .item-stit {
    display: block;
    text-align: right;
    font-size: 24px;
    font-weight: 700;
    color: #111;
  }

  .plan-single-wrap .item-stit-wrap .item-date {
    display: block;
    margin-top: 5px;
    font-size: 16px;
    color: #777;
    text-align: right;
  }

  // 두개의 플랜일 경우
  .wrap-content.plan-double .plan-select-item {
    width: 490px;
  }

  .scrolled .plan-double .plan-select-item.selected strong {
    width: 490px;
  }

  .side-cont-wrap .side-cont-tit {
    position: relative;
    font-size: 14px;
  }

  .side-cont-tit .btn-txt-arrow {
    position: absolute;
    right: 0;
  }

  .side-cont-tit .btn-txt-arrow span {
    padding: 0;
  }

  .side-cont-tit .btn-txt-arrow span::before {
    display: none;
  }

  .side-cont-item {
    margin-top: 10px;
    padding-bottom: 25px;
    border-bottom: 1px #eee solid;
  }

  .side-cont-item > strong {
    font-size: 20px;
    font-weight: 700;
  }

  .side-cont-item > strong > b {
    color: #f05014;
    font-weight: 700;
  }

  /* tooltip */
  .tooltip-tit .tit,
  .tooltip-cont .tit-cont {
    font-size: 24px;
    font-weight: 700;
    word-break: break-all;
  }

  .tooltip-cont {
    margin-top: 35px;
  }

  .tooltip-cont .ui-tab .ui-tab-btns {
    padding: 0;
  }

  .tooltip-cont .agr-item dl {
    padding-right: 80px;
  }

  .modal-system .ui-modal-cont {
    text-align: center;
    padding: 20px;
    font-size: 16px;
  }

  .modal-system .btn-wrap {
    margin-top: 20px;
  }

  .modal-system .ui-modal-cont:before {
    content: "";
    display: inline-block;
    width: 50px;
    height: 50px;
    background: url(/netiveUI/resources/img/common/ico_modal.png) no-repeat -25px -25px;
    margin-bottom: 20px;
  }

  .modal-system .system-type-a:before {
    background-position: -25px -25px;
  }

  .modal-system .system-type-b:before {
    background-position: -125px -25px;
  }

  .modal-system .system-type-c:before {
    background-position: -225px -25px;
  }

  /* 약관 */
  .terms-wrap {
    position: relative;
    width: 100%;
    font-size: 15px;
    color: rgba(68, 68, 68, 0.99);
    letter-spacing: -0.075em;
    line-height: 1.6;
  }

  .terms-wrap h1 {
    font-size: 20px;
    font-weight: 700;
    color: #000;
    margin-bottom: 40px;
  }

  .terms-wrap h2 {
    font-size: 17px;
    font-weight: 700;
    color: #000;
    margin: 30px 0 15px;
  }

  .terms-wrap h3 {
    font-size: 15px;
    font-weight: 700;
    color: rgba(68, 68, 68, 0.99);
    margin: 30px 0 15px;
  }

  .terms-wrap p {
    font-size: 15px;
    margin: 15px 0;
  }

  .terms-wrap strong {
    color: #f05014;
    font-weight: 500;
  }

  .terms-wrap li {
    margin-bottom: 10px;
  }

  .terms-wrap li ol {
    margin-top: 10px;
  }

  .terms-wrap li ol > li {
    margin-top: 0;
  }

  .terms-wrap li ul {
    margin-top: 17px;
  }

  .terms-wrap > .fx-wrap:first-child,
  .ui-acco .terms-wrap h2:first-child,
  .ui-acco .terms-wrap h3:first-child,
  .ui-acco .terms-wrap h1 + .fx-wrap {
    margin-top: 0;
  }

  .ui-acco .terms-wrap .field-inlabel {
    padding-top: 0;
  }

  /*동의 mo다름  */
  .agr-item {
    padding: 14px 0 16px;
    border-top: 1px solid #eee;
  }

  .agr-item:first-child,
  .btn-base + .agr-item {
    padding: 10px 0 16px;
    border-top: 0;
  }

  .agr-item .tit {
    position: relative;
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
    color: #333;
    text-align: left;
  }

  .agr-item button.tit {
    padding-right: 25px;
  }

  .agr-item .tit .ico-arrow {
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -10px;
  }

  .agr-item dl {
    position: relative;
    padding-right: 132px;
  }

  .agr-item .agr-toggle {
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -13px;
  }

  .agr-item .no-toggle {
    padding-right: 0;
  }

  .agr-item .no-toggle dd {
    padding: 5px 0 12px;
  }

  .agr-item .tit.type-bold {
    font-weight: 700;
  }

  .agr-item .lb-check,
  .agr-item .lb-radio {
    padding-top: 0;
  }

  .agr-item .lb-check:before,
  .agr-item .lb-radio:before {
    top: 3px;
  }

  .agr-item-inner {
    margin-top: -4px;
    padding-bottom: 5px;
  }

  .agr-item-inner .tit {
    padding: 6px 0 6px 13px;
    color: #666;
  }

  .agr-item-inner .tit:before {
    content: "";
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: #666;
    position: absolute;
    left: 0;
    top: 18px;
  }

  .agr-wrap.type-b {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #eee;
  }

  .agr-wrap.type-b .agr-item {
    padding: 8px 0;
    border-top: 0;
  }

  .agr-wrap.type-b .ico-arrow {
    background-position: -82px -3px;
  }

  /* 동의*/
  .list-agree li {
    padding: 15px 0 18px;
    border-bottom: 1px solid #eee;
  }

  .list-agree li [class*="tit"] {
    position: relative;
    display: block;
    color: #333;
    font-size: 16px;
    line-height: 24px;
    word-break: keep-all;
  }

  .list-agree li [class*="tit"].type-num {
    position: relative;
    padding-left: 22px;
  }

  .list-agree li [class*="tit"].type-num .bul-num {
    margin-left: -22px;
    display: inline-block;
    width: 20px;
  }

  .list-agree li .txt-sub {
    margin-left: 23px;
    margin-top: 12px;
    color: #999;
    font-size: 14px;
    line-height: 24px;
    word-break: keep-all;
  }

  .list-agree li .txt-sub2 {
    margin-left: 40px;
    color: #999;
    font-size: 14px;
    line-height: 24px;
    word-break: keep-all;
  }

  .list-agree li .ch-group {
    margin-top: 6px;
  }

  .list-agree li.bdb-n {
    border-bottom: 0;
  }

  /* 정보 */
  .box-dlinfo {
    padding: 35px 25px 30px;
  }

  .dlinfo-item {
    margin-top: 20px;
  }

  .dlinfo-item:first-child {
    margin-top: 35px;
  }

  .dlinfo-item dt {
    min-width: 10px;
    font-size: 13px;
    color: rgba(68, 68, 68, 0.99);
    line-height: 20px;
  }

  .dlinfo-item dd {
    font-size: 15px;
    color: #010101;
    text-align: right;
    word-break: break-all;
  }

  .dlinfo-item.dlinfo-bold {
    margin-top: 30px;
  }

  .dlinfo-item.dlinfo-bold dt,
  .dlinfo-item.dlinfo-bold dd {
    color: #f05014;
    font-weight: 700;
  }

  .dlinfo-item.dlinfo-bold dt {
    line-height: 22px;
  }

  .dlinfo-item.dlinfo-bold dd {
    font-size: 20px;
  }

  /*가입완료*/
  .result-tit {
    margin: 15% 0 0;
    text-align: center;
  }

  .result-wrap {
    padding: 55px 25px;
    text-align: center;
  }

  .result-wrap h2 {
    font-size: 16px;
    font-weight: 700;
    color: #111;
  }

  .result-wrap p {
    margin-top: 15px;
    font-size: 16px;
  }

  .result-wrap .f-style {
    font-size: 23px;
    color: #f26421;
    font-weight: 700;
  }

  .result-wrap .btn-wrap {
    margin-top: 55px;
  }

  /* 아코디언 */
  .ui-acco,
  .ui-acco-wrap,
  .ui-acco-pnl {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .ui-acco {
    position: relative;
    margin: 0;
    list-style: none;
  }

  .ui-acco-wrap {
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    border-bottom: 1px solid #eee;
  }

  .ui-acco-wrap .ui-acco {
    border-bottom: 0;
  }

  .ui-acco-wrap .ui-acco-wrap {
    padding: 0;
  }

  .ui-acco-tit {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 100%;
    border-bottom: 0;
    margin: 0;
    padding: 0;
    background: #fff;
  }

  .ui-acco-tit h1 {
    position: relative;
    z-index: 0;
  }

  .ui-acco-btn {
    cursor: pointer;
    position: relative;
    top: 0;
    right: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    letter-spacing: 0;
    box-sizing: border-box;
    text-align: left;
    color: #000;
    padding: 20px 40px 20px 20px;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.3;
    letter-spacing: -0.03em;
  }

  .ui-acco-btn.selected {
    padding-bottom: 20px;
  }

  a.ui-acco-btn {
    display: inline-block;
    text-decoration: none;
  }

  a.ui-acco-btn .ui-acco-arrow::before {
    transform: rotate(-90deg);
  }

  .ui-d .ui-acco-pnl {
    display: block;
  }

  .ui-acco-pnl {
    display: none;
    position: relative;
    box-sizing: border-box;
    background: #f8f8f8;
    border-bottom: 0;
    padding: 16px 20px;
    margin: 0;
    font-size: 14px;
  }

  .ui-acco-pnl .bul-etc:first-child,
  .ui-acco-pnl .bul-etc li:first-child {
    margin-top: 0;
  }

  .ui-acco-pnl ol > li {
    margin-top: 25px;
  }

  .ui-acco-pnl ol,
  .ui-acco-pnl ul,
  .ui-acco-pnl li:first-child {
    margin-top: 0;
  }

  .ui-acco-pnl .box-base.top-line {
    border: 0;
    border-top: 1px solid #bbb;
  }

  .ui-modal .ui-acco.type-b {
    border-bottom: 0;
  }

  .ui-modal .ui-acco.type-fix .ui-acco-wrap {
    padding: 0;
    border: 0;
  }

  .ui-acco-arrow {
    font-size: 0;
    line-height: 0;
  }

  .ui-acco-arrow::before {
    content: "";
    display: block;
    position: absolute;
    top: 28px;
    right: 0;
    width: 10px;
    height: 6px;
    background: url("/static/images/common/ico_small.png")
    no-repeat -133px -10px;
    right: 20px;
  }

  .ui-acco-money {
    position: absolute;
    right: 50px;
  }

  button.selected .ui-acco-arrow:before {
    transform: rotate(180deg);
  }

  .ui-acco.type-b .ui-acco-btn {
    position: absolute;
    color: transparent;
    padding: 18px 35px 0 0;
    min-height: 60px;
    overflow: hidden;
    font-size: xx-small;
    line-height: 0;
  }

  .ui-acco.type-b {
    border: 0;
    border-top: 1px solid #bbb;
    padding: 0;
  }

  .ui-acco.type-b .ui-acco-wrap {
    border: 0;
    border-bottom: 1px solid #eee;
    padding: 0;
    min-height: 60px;
  }

  .ui-acco.type-b .ui-acco-tit {
    border: 0;
    padding: 18px 20px 0;
    min-height: 60px;
  }

  .ui-acco.type-b .ui-acco-txt {
    position: relative;
    display: inline-block;
    z-index: 2;
    text-decoration: none;
    color: #000;
  }

  .ui-acco.type-b .ui-acco-tit .lb-radio,
  .ui-acco.type-b .ui-acco-tit .lb-check {
    z-index: 2;
    padding-top: 0 !important;
    padding-left: 30px;
  }

  .ui-acco.type-b .ui-acco-tit .lb-radio:before,
  .ui-acco.type-b .ui-acco-tit .lb-check:before {
    margin-top: 0 !important;
    top: 1px;
  }

  .ui-acco.type-b .ui-acco-tit .lb-radio span,
  .ui-acco.type-b .ui-acco-tit .lb-check span {
    line-height: 21px;
    color: #333;
  }

  .ui-acco.type-b .ui-acco-tit .btn-tip {
    z-index: 10;
    top: 0;
    margin-top: 0;
  }

  .ui-acco.type-b .ui-acco-tit .tit-label {
    display: inline-block;
    vertical-align: top;
    margin-left: 30px;
  }

  .ui-acco.type-b .ui-acco-tit .lb-radio + .tit-label,
  .ui-acco.type-b .ui-acco-tit .lb-check + .tit-label {
    margin-top: -2px;
  }

  .ui-acco.type-b .ui-acco-tit .tit-label > strong {
    color: #f05014;
    font-weight: inherit;
  }

  .ui-acco.type-b .ui-acco-tit .lb-radio + .tit-label,
  .ui-acco.type-b .ui-acco-tit .lb-check + .tit-label {
    margin-left: 0;
  }

  .ui-acco.type-b .ui-acco-pnl {
    border: 0;
    padding: 20px;
    border-top: 1px solid #eee;
  }

  .ui-acco.type-b .ui-acco-pnl .chk-list li {
    margin-top: 20px;
    line-height: 1;
  }

  .ui-acco.type-b .ui-acco-pnl .chk-list li:first-child {
    margin-top: 0;
  }

  .ui-acco.type-b .ui-acco-pnl .chk-list li span {
    color: #333;
    line-height: 1;
  }

  .ui-acco.type-b .ui-acco-pnl .chk-list li .lb-check {
    padding-top: 0;
    line-height: 1;
  }

  .ui-acco.type-b .ui-acco-pnl .chk-list li .lb-check::before {
    top: 0;
  }

  .ui-acco.type-b .ui-acco-pnl > .terms-wrap,
  .ui-acco.type-terms .ui-acco-pnl > .terms-wrap {
    max-height: 260px;
    overflow: auto;
  }

  .ui-acco.type-b .ui-acco-pnl > .terms-wrap::-webkit-scrollbar,
  .ui-acco.type-terms .ui-acco-pnl > .terms-wrap::-webkit-scrollbar {
    width: 10px;
    height: 20px;
  }

  .ui-acco.type-b .ui-acco-pnl > .terms-wrap::-webkit-scrollbar-track,
  .ui-acco.type-terms .ui-acco-pnl > .terms-wrap::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
  }

  .ui-acco.type-b .ui-acco-pnl > .terms-wrap::-webkit-scrollbar-thumb,
  .ui-acco.type-terms .ui-acco-pnl > .terms-wrap::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #fff;
    border: 2px solid #ecebea;
  }

  .ui-acco.type-b .ui-acco-pnl > .terms-wrap::-webkit-scrollbar-button,
  .ui-acco.type-terms .ui-acco-pnl > .terms-wrap::-webkit-scrollbar-button {
    width: 20px;
    height: 10px;
  }

  .ui-acco.type-b .ui-acco-pnl > .terms-wrap > h1:first-child,
  .ui-acco.type-b .ui-acco-pnl > .ui-scrollbar .terms-wrap > h1:first-child,
  .ui-acco.type-terms .ui-acco-pnl > .terms-wrap > h1:first-child {
    display: none;
  }

  .ui-acco.type-b .ui-acco-pnl > .terms-wrap > h1:first-child + h2,
  .ui-acco.type-b
  .ui-acco-pnl
  > .ui-scrollbar
  .terms-wrap
  > h1:first-child
  + h2,
  .ui-acco.type-terms .ui-acco-pnl > .terms-wrap > h1:first-child + h2 {
    margin-top: 0;
  }

  .ui-acco.type-b .ui-acco-pnl > .terms-wrap > h1:first-child + p,
  .ui-acco.type-b .ui-acco-pnl > .ui-scrollbar .terms-wrap > h1:first-child + p,
  .ui-acco.type-terms .ui-acco-pnl > .terms-wrap > h1:first-child + p {
    margin: 0;
  }

  .ui-acco.type-b .ui-acco-pnl > .terms-wrap > p + p,
  .ui-acco.type-b .ui-acco-pnl > .ui-scrollbar .terms-wrap > p + p,
  .ui-acco.type-terms .ui-acco-pnl > .terms-wrap > p + p {
    margin-top: 15px;
  }

  .ui-acco.type-b .box-base .ui-acco-tit,
  .ui-acco.type-b .box-base .ui-acco-pnl {
    background: #f1f1f1;
  }

  .ui-acco.type-contract {
    border-top: 0;
    margin-top: 15px;
  }

  .ui-acco.type-contract .ui-acco-wrap {
    padding: 0;
    border: 0;
  }

  .ui-acco.type-contract .ui-acco-wrap:first-child .ui-acco-pnl {
    margin-top: 0;
  }

  .ui-acco.type-contract .ui-acco-pnl {
    margin-top: 10px;
    padding: 0;
  }

  .ui-acco.type-contract .ui-acco-pnl.list-none {
    display: table;
    width: 100%;
    height: 360px;
    background: #fff;
    border: 1px solid #ddd;
  }

  .ui-acco.type-contract .ui-acco-pnl.list-none p {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    font-size: 24px;
    color: #111;
  }

  .ui-acco.type-contract .ui-acco-sel {
    background: #fff;
    width: 100%;
    padding: 37px 40px 33px;
    outline: 0;
    border: 1px solid #ddd;
  }

  .ui-acco.type-contract .ui-acco-pnl.selected .ui-acco-sel,
  .ui-acco.type-contract .ui-acco-sel:focus,
  .ui-acco.type-contract .ui-acco-sel:active {
    border: 1px solid #ddd;
  }

  .ui-acco.type-contract .tbl-noline {
    margin-top: 0;
    padding: 0;
    border: 0;
  }

  .ui-acco.type-contract .tbl-noline th {
    padding: 9px 0;
  }

  .ui-acco.type-contract .tbl-noline td {
    padding: 7px 0;
  }

  .ui-acco .type-contract-tit {
    text-align: left;
    position: relative;
    padding: 0 0 15px;
  }

  .ui-acco .type-contract-tit h3 {
    font-size: 22px;
    color: #111;
  }

  .ui-acco .type-contract-tit p {
    margin-top: 5px;
    font-size: 14px;
    color: #777;
  }

  .ui-acco .type-contract-tit .btn-txt-arrow {
    position: absolute;
    right: 0;
    top: 6px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
    letter-spacing: -0.035em;
    text-decoration: none;
    line-height: 1;
  }

  .btn-wrap.type-contract {
    margin: 13px 0 50px;
    display: none;
  }

  .btn-wrap.type-contract.on {
    display: block;
  }

  .btn-wrap.type-contract .btn-base {
    border: 0;
    background: transparent
    url("/static/images/common/ico_small.png")
    no-repeat -175px 0;
    padding: 0;
    min-height: 0;
    min-width: 0;
    background-size: 300px;
    width: 25px;
    height: 25px;
  }

  .ui-acco.type-fix {
    margin-bottom: 0px;
    border-bottom: 0;
  }

  .ui-acco.type-fix .ui-acco-tit {
    border-radius: 0;
    background: #fff;
  }

  .ui-acco.type-fix .ui-acco-tit .ui-acco-btn {
    padding: 23px 32px;
  }

  .ui-acco.type-fix .ui-acco-tit .ui-acco-btn.selected {
    border-bottom: 1px solid #eee;
  }

  .ui-acco.type-fix .ui-acco-tit .btn-tip {
    position: absolute;
    top: 0;
    left: 0;
  }

  .ui-acco.type-fix .ui-acco-tit.type-pay .btn-tip {
    top: 50%;
    left: 108px;
    z-index: 2;
    margin-top: -8px;
  }

  .ui-acco.type-fix .ui-acco-tit.type-pay .txt-s5 {
    float: left;
    font-weight: 700;
    vertical-align: middle;
  }

  .ui-acco.type-fix .ui-acco-tit.type-pay .txt-price {
    float: right;
    padding-right: 25px;
  }

  .ui-acco.type-fix .ui-acco-pnl {
    margin-top: 0;
    padding: 0;
    max-height: 300px;
    overflow-y: auto;
  }

  .ui-acco.type-fix .ui-acco-pnl .box-base {
    padding: 25px 32px;
    border-top: 0;
  }

  .ui-acco.type-fix .ui-acco-arrow:before {
    right: 32px;
  }

  .ui-modal .ui-scrollbar-item {
    position: relative;
  }

  .ui-acco.type-lite {
    margin-top: 60px;
  }

  .ui-acco.type-lite .ui-acco-pnl {
    background: #fff;
    border-top: 1px solid #eee;
    padding: 15px 20px 17px;
  }

  .ui-acco.type-normal {
    border: 0;
  }

  .ui-acco.type-normal .ui-acco-wrap {
    border: 0;
    padding: 0;
    margin-top: 0;
  }

  .ui-acco.type-normal .ui-acco-wrap .ui-acco-pnl {
    margin-top: 20px;
    background: #fff;
    padding: 0;
  }

  .field-group {
    padding: 0 0 2px !important;
  }

  .field-group fieldset {
    display: block;
    width: 100%;
    border-top: 1px solid #eee;
    padding: 15px 0 18px;
  }

  .field-group fieldset legend {
    margin: 0 0 7px;
    padding: 0;
    float: left;
    width: 100%;
    color: #333;
  }

  .field-group fieldset:first-child {
    border: 0;
    margin: 0;
  }

  .field-group fieldset .lb-radio {
    margin-right: 75px;
  }

  .field-group fieldset .lb-radio:last-child {
    margin-right: 0;
  }

  .ui-acco.type-terms .ui-acco-pnl > .terms-wrap .ui-tab-btns {
    margin-top: 0;
  }

  .ui-acco.type-terms .ui-acco-pnl > .terms-wrap .tbl-base td:first-child {
    text-align: center;
  }

  .ui-acco.type-terms .ui-acco-btn {
    font-weight: 400;
  }

  /* $plugins.uiSelect */
  .ui-select {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    min-height: 51px;
  }

  .ui-select select {
    width: 100%;
  }

  .ui-select select.off {
    position: absolute;
    left: -9999px;
    width: 0;
    height: 0;
    border: 0;
    opacity: 0;
    z-index: -1;
    color: transparent;
    overflow: hidden;
    font-size: xx-small;
    line-height: 0;
  }

  .ui-select-btn {
    display: inline-block;
    position: relative;
    text-align: left;
    vertical-align: top;
    width: 100%;
    min-height: 51px;
    line-height: 1.2;
    padding: 7px 20px 7px 10px;
    background: transparent;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    border: 0;
    border-bottom: 1px solid #777;
    font-size: 22px;
    color: #111;
    cursor: pointer;
    outline: 0;
    box-sizing: border-box;
    text-overflow: ellipsis;
  }

  .ui-select-btn span {
    display: inline-block;
    width: 100%;
    text-align: left;
    word-break: break-all;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
  }

  .ui-select-btn[aria-expanded="true"],
  .ui-select-btn:focus,
  .ui-select-btn:active {
    border-bottom: 2px solid #f05014;
  }

  .ui-select-btn.opt-hidden {
    color: #ccc;
    font-weight: 300;
  }

  .ui-select-btn:disabled,
  .ui-select-btn[disabled] {
    border-color: #777 !important;
    color: #777 !important;
    opacity: 1;
    background-color: #f6f6f6;
    -webkit-text-fill-color: #777;
  }

  .ui-select-btn[disabled]::after {
    border-top: 7px #ccc solid;
  }

  .box-base .ui-select-btn {
    border-color: #dcdcdc;
  }

  .ui-select-wrap.on {
    display: block;
    width: 100%;
  }

  .ui-select-wrap.top {
    bottom: 50px;
  }

  .ui-select-wrap.bottom {
    top: 50px;
  }

  .ui-select-wrap {
    display: none;
    position: absolute;
    z-index: 100;
    left: 0;
    width: auto;
    border: 1px solid #ededed;
    background: #fff;
    overflow-y: auto;
    max-height: 300px;
  }

  .ui-select-wrap.ui-scrollbar {
    position: absolute;
    min-height: 300px;
  }

  .ui-select-opt {
    display: block;
    float: left;
    width: 100%;
    padding: 12px 10px 12px 40px;
    min-height: 56px;
    text-align: left;
    background: #fff;
    font-size: 22px;
    color: #111;
  }

  .ui-select-opt.selected {
    background: #f7f7f7
    url("/static/images/common/ico_select_opt.png")
    no-repeat 5px 50%;
  }

  .ui-select-opts {
    width: 100%;
  }

  .ui-select-opts:after {
    content: "";
    display: block;
    clear: both;
  }

  .ui-select-opts input:focus,
  .ui-select-opt:focus,
  .ui-select-opt:hover {
    font-weight: 700;
  }

  .ui-select-opts input:focus,
  .ui-select-dim,
  .ui-select-close,
  .ui-select-tit {
    display: none;
  }

  .ui-select .mCSB_inside > .mCSB_container {
    margin-right: 0;
  }

  .ui-modal .ui-select-wrap {
    top: initial !important;
  }

  .SelectBoxWrap .box-scroller.ui-scrollbar {
    height: 336px;
    padding: 0 !important;
    overflow: hidden;
  }

  .SelectBoxWrap .ui-scrollbar-item {
    width: 100% !important;
    padding: 0 !important;
  }

  .type-plan {
    position: relative;
    width: auto;
  }

  .type-plan::after {
    content: "";
    position: absolute;
    bottom: 6px;
    left: 0;
    width: 100%;
    height: 10px;
    background: #f05014;
    z-index: -1;
  }

  .type-plan .ui-select-btn,
  .type-plan select {
    height: 36px;
    border: 0;
    font-size: 32px;
    font-weight: 700;
    padding-left: 7px;
  }

  .type-plan .ui-select-btn {
    color: #111;
    padding-right: 45px;
  }

  .ui-select.type-tit {
    position: relative;
    width: auto;
    min-width: 180px;
  }

  .ui-select.type-tit .ui-select-btn {
    font-size: 24px;
    padding-right: 40px;
    border-width: 2px;
    border-color: #111;
  }

  .btn-plan {
    position: relative;
    display: inline-block;
  }

  .btn-plan::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 12px;
    width: 0;
    height: 0;
    border: 4px solid transparent;
    border-top: 5px solid #333;
  }

  .btn-plan::after {
    content: "";
    position: absolute;
    display: block;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 10px;
    background: #f05014;
    z-index: -1;
  }

  .btn-plan .btn-select {
    position: relative;
    display: block;
    width: 100%;
    height: 38px;
    font-size: 24px;
    color: #111;
    text-align: left;
    font-weight: 700;
    letter-spacing: -0.03em;
    padding: 3px 36px 7px 4px;
    -webkit-appearance: none;
  }

  /* ui-tab */
  .ui-tab {
    position: relative;
    margin: 0;
  }

  .w-n2 .ui-tab-btn {
    width: 50% !important;
  }

  .w-n3 .ui-tab-btn {
    width: 33.33% !important;
  }

  .w-n3 .ui-tab-btn:nth-child(2) {
    width: 33.34% !important;
  }

  .w-n4 .ui-tab-btn {
    width: 25% !important;
  }

  .ui-tab-btns {
    position: relative;
    display: block;
    width: 100%;
    z-index: 1;
    background: #fff;
  }

  .ui-tab-btn {
    position: relative;
    display: inline-block;
    width: auto;
    margin: 0 10px;
    padding: 5px;
    vertical-align: middle;
    z-index: 0;
    font-size: 18px;
    color: #999;
    border-bottom: 4px solid transparent;
  }

  .ui-tab-btn:first-child {
    margin: 0;
  }

  .ui-tab-btn.selected {
    z-index: 2;
  }

  .ui-tab-btn.selected:hover,
  .ui-tab-btn.selected:focus {
    border-bottom: 4px solid #f05014;
  }

  .ui-tab-btn.selected {
    color: #111;
    border-bottom: 4px solid #f05014;
    font-weight: 700;
  }

  .type-car .ui-tab-btn {
    letter-spacing: -0.8px;
    margin: 0 5px;
  }

  .type-car .ui-tab-btn:first-child {
    margin-left: 0;
  }

  .ui-tab.type-linebox {
    position: relative;
    margin: 0;
    /*z-index:0;*/
  }

  .ui-tab.type-linebox .ui-floating-wrap {
    border-bottom: none;
    background: none;
  }

  .ui-tab.type-linebox .ui-tab-btns {
    position: relative;
    z-index: 1;
    margin-left: 0;
    background: none;
    padding: 0 0 0 1px;
    border: 0;
  }

  .ui-tab.type-linebox .ui-tab-btn {
    position: relative;
    z-index: 0;
    margin: 0;
    font-size: 14px;
    color: #777;
    font-weight: 400;
    min-height: 52px;
    border-radius: 0;
    transition: box-shadow 0.1s ease-in, background-color 0.1s ease-in;
    border: 1px solid #ddd;
    background: #fff;
    margin-left: -1px;
    line-height: 1.2;
  }

  .ui-tab.type-linebox .row2 .ui-tab-btn {
    height: 64px;
  }

  .ui-tab.type-linebox .ui-tab-btn:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .ui-tab.type-linebox .ui-tab-btn:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .ui-tab.type-linebox .ui-tab-btn.selected {
    z-index: 2;
  }

  .ui-tab.type-linebox .ui-tab-btn.selected:hover i,
  .ui-tab.type-linebox .ui-tab-btn.selected:focus i {
    font-weight: 500;
  }

  .ui-tab.type-linebox .ui-tab-btn.selected:hover,
  .ui-tab.type-linebox .ui-tab-btn.selected:focus {
    box-shadow: 0 0 5px rgba(253, 71, 0, 0.7);
  }

  .ui-tab.type-linebox .ui-tab-btn.selected {
    background: #fff;
    color: #f05014;
    border-color: #f05014;
    font-weight: 500;
  }

  .ui-tab.type-linebox .ui-tab-btn.txt-s {
    font-size: 14px;
  }

  .ui-tab.type-linebox .ui-tab-btns.tabs2 .ui-tab-btn {
    width: calc(50% + 1px);
  }
  .ui-tab.type-linebox .ui-tab-btns.tabs1 .ui-tab-btn {
    width: 100%;
  }

  .ui-tab.type-linebox .ui-tab-btns.tabs3 .ui-tab-btn {
    width: calc(33.33% + 1px);
  }

  .ui-tab.type-linebox .ui-tab-btns.tabs4 .ui-tab-btn {
    width: calc(25% + 1px);
  }

  .ui-tab.type-linebox .ui-tab-btns.tabs5 .ui-tab-btn {
    width: calc(20% + 1px);
  }

  .ui-tab.type-linebox .ui-tab-btns.tabs6 .ui-tab-btn {
    width: calc(16.66% + 1px);
  }

  .ui-tab.type-linebox .ui-tab-btns.tabs7 .ui-tab-btn {
    width: calc(14.2% + 1px);
  }

  .ui-tab.type-sub .ui-tab-btns {
    position: relative;
    z-index: 1;
    padding-left: 1px;
  }

  .ui-tab.type-sub .ui-tab-btns:after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #ddd;
  }

  .ui-tab.type-sub .ui-tab-btns .ui-tab-btn {
    position: relative;
    width: 108px;
    z-index: 0;
    margin: 0;
    font-size: 14px;
    color: #333;
    min-height: 40px;
    border-radius: 0;
    border: 1px solid #ddd;
    background: #fff;
    margin-left: -1px;
    line-height: 1.2;
  }

  .ui-tab.type-sub .ui-tab-btns .ui-tab-btn.selected {
    z-index: 2;
    border: 0;
    background: #f05014;
    color: #fff;
    font-weight: 400;
    border-bottom: 1px solid #f05014;
  }

  .ui-tab-pnls {
    box-sizing: border-box;
    position: relative;
    width: 100%;
  }

  .ui-tab-pnl {
    box-sizing: border-box;
    top: 0;
    left: 0;
  }

  .ui-tab-pnl,
  .ui-tab-pnl[aria-hidden="true"] {
    visibility: hidden;
    position: absolute;
    opacity: 0;
    display: none;
  }

  .ui-tab-pnl.selected,
  .ui-tab-pnl[aria-hidden="false"] {
    visibility: visible;
    position: relative;
    opacity: 1;
    z-index: 1;
    display: block;
  }

  .ui-tab-pnl {
    width: 100%;
  }

  .ui-tab.eff-fade .ui-tab-pnl {
    @include vendorPrefix(transition, opacity 0.3s);
  }

  .ui-tab-pnl.type-brd {
    border-bottom: 1px #ddd solid;
    padding-bottom: 20px;
  }

  .tab-scroll .ui-tab-btns {
    overflow-x: hidden;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    width: 586px;
    margin: 0 auto;
  }

  .tab-scroll-wrap {
    white-space: nowrap;
    padding: 1px 1px 0;
    position: relative;
  }

  .tab-scroll-wrap .ui-tab-btn {
    min-width: 96px;
    width: auto;
    padding: 0 15px;
  }

  .tab-scroll .ui-tab-pnls {
    border: 0;
    margin-top: 0;
  }

  .tab-sort button {
    position: relative;
    font-size: 16px;
    color: #333;
    padding: 0 10px;
    font-weight: normal;
  }

  .tab-sort button:before {
    @include bar-border(12px, #ccc);
    top: 50%;
    left: 0px;
    margin-top: -5px;
    z-index: 1;
  }

  .tab-sort button:first-child {
    padding-left: 0;
  }

  .tab-sort button:first-child:before {
    display: none;
  }

  .tab-sort button.selected {
    color: #f05014;
  }

  .tab-scroll.col5 .ui-tab-btns .ui-tab-btn {
    width: calc(20% + 2px) !important;
  }

  .tab-scroll .btn-tabscroll {
    position: absolute;
    border: 1px solid #ddd !important;
    z-index: 1;
    width: 26px;
    height: 52px;
    top: 1px;
    right: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    background: #fff;
  }

  .tab-scroll .btn-tabscroll:after {
    content: "";
    display: block;
    width: 22px;
    height: 24px;
    opacity: 0.5;
    background: url("/static/images/common/ico_small.png")
    no-repeat 0 -25px;
  }

  .tab-scroll .btn-tabscroll.prev:after {
    background-position: -25px -25px;
  }

  .tab-scroll .btn-tabscroll:hover {
    border-color: #333333 !important;
    background-color: #fcfcfc;
  }

  .tab-scroll .btn-tabscroll:hover:after {
    opacity: 1;
  }

  .tab-scroll .btn-tabscroll:focus {
    border-color: #f05014;
    background-position: -26px -25px;
  }

  .tab-scroll-manual > .ui-tab-btns {
    overflow-x: auto;
  }

  .tab-scroll-manual > .ui-tab-btns::-webkit-scrollbar {
    width: 20px;
    height: 10px;
  }

  .tab-scroll-manual > .ui-tab-btns::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
  }

  .tab-scroll-manual > .ui-tab-btns::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #fff;
    border: 2px solid #ecebea;
  }

  .tab-scroll-manual > .ui-tab-btns::-webkit-scrollbar-button {
    width: 10px;
    height: 20px;
  }

  /* ui tooltip:z-index 200 ~ 299 */
  .ui-tooltip {
    display: none;
    position: absolute;
    z-index: 200;
    width: auto;
    max-width: 420px;
    min-width: 360px;
    font-size: 14px;
    border: 1px solid #e2e2e2;
    padding: 24px 30px 27px;
    background: #fff;
  }

  .ui-tooltip.ps-ct:after,
  .ui-tooltip.ps-cb:after,
  .ui-tooltip.ps-lt:after,
  .ui-tooltip.ps-lb:after,
  .ui-tooltip.ps-rt:after,
  .ui-tooltip.ps-rb:after {
    content: "";
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    background: #fff;
    border-top: 1px solid #e2e2e2;
    border-right: 1px solid #e2e2e2;
  }

  .ui-tooltip.ps-ct:after {
    left: 50%;
    bottom: -6px;
    transform: rotate(135deg);
    margin-left: -5px;
  }

  .ui-tooltip.ps-lt:after {
    left: 0;
    bottom: -6px;
    transform: rotate(135deg);
    margin-left: 10px;
  }

  .ui-tooltip.ps-rt:after {
    right: 0;
    bottom: -6px;
    transform: rotate(135deg);
    margin-right: 10px;
  }

  .ui-tooltip.ps-cb:after {
    left: 50%;
    top: -6px;
    transform: rotate(-45deg);
    margin-left: -5px;
  }

  .ui-tooltip.ps-lb:after {
    left: 0;
    top: -6px;
    transform: rotate(-45deg);
    margin-left: 10px;
  }

  .ui-tooltip.ps-rb:after {
    right: 0;
    top: -6px;
    transform: rotate(-45deg);
    margin-right: 10px;
  }

  .ui-tooltip-tit h1 {
    font-size: 18px;
    color: #111;
    margin-bottom: 12px;
  }

  .ui-tooltip-cont {
    position: relative;
    font-size: 14px;
    margin-top: 11px;
    line-height: initial;
    width: 100%;
    white-space: normal;
  }

  .ui-tooltip-cont > * {
    font-size: 14px;
    color: #333;
    font-weight: 400;
  }

  .ui-tooltip-cont li {
    color: #333;
    padding-left: 8px;
    margin-top: 12px;
    border: 0;
    width: 100%;
    word-break: keep-all;
  }

  .ui-tooltip-cont .bul-dot > li::before {
    content: "";
    top: 8px;
    width: 3px;
    height: 3px;
  }

  .ui-tooltip-cont .bul-hyphen li::before {
    content: "-";
    position: absolute;
    left: 0;
    top: 3px;
    font-weight: 400;
    line-height: 1.2;
  }

  .ui-tooltip-cont .bul-etc > li {
    padding-left: 20px;
  }

  .ui-tooltip-cont .bul-care {
    font-size: 14px;
  }

  .ui-tooltip-cont [class*="tbl-base"].type-s {
    margin: 10px 0;
    padding: 0;
  }

  .ui-tooltip-cont [class*="tbl-base"].type-s > table > thead th {
    height: auto;
    font-size: 13px;
    padding: 5px 10px;
  }

  .ui-tooltip-cont [class*="tbl-base"].type-s > table > tbody > tr > th,
  .ui-tooltip-cont [class*="tbl-base"].type-s > table > tbody > tr > td {
    height: auto;
    font-size: 14px;
    padding: 5px 10px;
  }

  /* 계약선택 아코디언 */
  .list-contract.type-acco .list-contract-item {
    padding: 35px 0 40px;
  }

  .list-contract.type-acco .list-contract-item:first-child {
    border-top: 0;
    padding-top: 10px;
  }

  .list-contract.type-acco .list-contract-item:last-child {
    padding-bottom: 50px;
  }

  .list-contract.type-acco .list-contract-tit {
    padding: 0 32px;
    border: 0;
  }

  .list-contract.type-acco .list-contract-tit h3 {
    margin-top: 0;
  }

  .wrap-inner-select {
    padding: 0;
    width: 610px;
    margin: 0 auto 15px;
  }

  /* list base */
  .list-base + .list-base {
    margin-top: 55px;
  }

  .list-base-item {
    padding: 24px 20px 26px;
    margin-top: 20px;
    border-top: 1px solid #bbb;
    border-bottom: 1px solid #eee;
  }

  .list-base-item.active .active {
    color: #f05014;
  }

  .list-base-item:first-child {
    margin-top: 0;
  }

  .list-base-head:after {
    content: "";
    display: block;
    clear: both;
  }

  .list-base-head {
    position: relative;
    height: 30px;
  }

  .list-base-head .tit {
    float: left;
    margin-top: 6px;
    font-size: 16px;
    color: #111;
    font-weight: 700;
    line-height: 1;
  }

  .list-base-head.type-b .tit {
    width: 50%;
    font-size: 16px;
  }

  .list-base-head .btn-wrap {
    float: right;
    margin: 0;
    width: auto;
  }

  .list-base-head .btn-base {
    min-width: 67px;
    min-height: 30px;
    color: #333;
  }

  .list-base-head .btn-base + .btn-base {
    margin-left: 10px;
  }

  .list-base-head .txt {
    margin: 5px 0 15px;
    font-size: 14px;
    color: #777;
  }

  .list-base-item .tbl-noline {
    margin-top: 18px;
  }

  .list-base-item .tbl-noline .tbl-item {
    margin-top: 15px;
  }

  .list-base-item .tbl-noline .tbl-item:first-child {
    margin-top: 0;
  }

  .list-base-item .tbl-noline .tbl-item .btn-del-circle.large {
    top: -1px;
    margin-left: 20px;
  }

  .list-base-item .tbl-noline thead th {
    padding: 0 0 20px !important;
    font-size: 16px;
    font-weight: 700;
    color: #333;
  }

  .list-base-item .tbl-noline .tbl-body {
    border-top: 1px solid #eee;
  }

  .list-base-item .tbl-noline .tbl-body-first tr:last-child th,
  .list-base-item .tbl-noline .tbl-body-first tr:last-child td {
    padding-bottom: 25px;
  }

  .list-base-item .tbl-noline .tbl-body tr:first-child th,
  .list-base-item .tbl-noline .tbl-body tr:first-child td {
    padding-top: 27px;
  }

  .list-base-item .total-price {
    margin-bottom: 5px;
  }

  .list-base-item hr {
    border-color: #eee;
    border-style: solid;
  }

  .list-base-item.active .active {
    color: #f05014;
  }

  .list-base-cont {
    margin: 30px 0 5px;
  }

  .list-base.type-pd .list-base-item {
    padding: 23px 20px 26px;
  }

  .list-base.type-data {
    margin-top: 10px;
  }

  .list-base.type-data .list-base-item {
    position: relative;
    border-bottom: 1px #eee solid;
    margin-top: 0;
  }

  .list-base.type-data .list-base-item + .list-base-item {
    border-top: 0;
  }

  .list-base.type-data .list-base-item.type-del .bul-etc {
    margin: 0 40px 0 0;
  }

  .list-base.type-data .list-base-item.type-del .btn-del-circle {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }

  .list-base.type-chk .list-base-item {
    padding-left: 50px;
  }

  .list-base.type-chk .list-base-head {
    height: auto;
  }

  .list-base.type-chk .list-base-head .tit {
    float: none;
  }

  .list-base.type-chk .field-inlabel {
    position: absolute;
    top: -10px;
    left: -30px;
    width: auto;
    padding: 0;
  }

  .list-base.type-chk .field-inlabel .lb-radio {
    padding: 0;
  }

  .tit-btn:after {
    content: "";
    display: block;
    clear: both;
  }

  .tit-btn [class*="h"] {
    float: left;
    padding-top: 5px;
  }

  .tit-btn .btn-wrap {
    float: right;
    width: auto;
  }

  .tit-btn .btn-wrap .btn-txt-arrow {
    font-weight: 700;
  }

  .list-base .tit-btn + .list-base-item {
    margin-top: 10px;
  }

  .list-base [class*="h"] + .list-base-item {
    margin-top: 15px;
  }

  .list-base.type-border .list-base-item {
    border-bottom: 1px #eee solid;
    padding: 15px 20px;
    margin-top: 0;
  }

  .list-base.type-border .list-base-item + .list-base-item {
    border-top: 0;
  }

  .list-base.type-border .list-base-head {
    position: relative;
    padding-right: 80px;
    height: auto;
    min-height: 30px;
  }

  .list-base.type-border .list-base-head.width-full {
    padding-right: 0;
  }

  .list-base.type-border .list-base-head .tit {
    display: inline;
    font-size: 16px;
    font-weight: 700;
  }

  .list-base.type-border .list-base-head strong {
    color: #f05014;
    font-weight: inherit;
  }

  .list-base.type-border .list-base-head .btn-wrap {
    position: absolute;
    top: 50%;
    right: 0;
    width: 80px;
    margin: 0;
    text-align: right;
    transform: translateY(-50%);
  }

  .list-base.type-border .list-base-head .btn-base {
    min-height: 30px;
  }

  .list-base.type-border .list-base-cont {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .list-base.type-border .list-base-cont .ico-special {
    position: relative;
    width: 25%;
    text-align: center;
    font-size: 14px;
    color: #333;
    padding-top: 60px;
  }

  .list-base.type-border .list-base-cont .ico-special::before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 50%;
    width: 48px;
    height: 48px;
    background-image: url("/static/images/content/ico_special.png");
    background-repeat: no-repeat;
    margin-left: -25px;
  }

  .list-base.type-border .list-base-cont .ico-special.ico1::before {
    background-position: -6px -6px;
  }

  .list-base.type-border .list-base-cont .ico-special.ico2::before {
    background-position: -66px -6px;
  }

  .list-base.type-border .list-base-cont .ico-special.ico3::before {
    background-position: -126px -6px;
  }

  .list-base.type-border .list-base-cont .ico-special.ico4::before {
    background-position: -186px -6px;
  }

  .list-base.type-border .list-base-cont .ico-special.ico5::before {
    background-position: -246px -6px;
  }

  .list-base.type-border .list-base-cont .ico-special.ico6::before {
    background-position: -306px -6px;
  }

  .list-base.type-border .list-base-cont .ico-special.ico1.on::before {
    background-position: -6px -66px;
  }

  .list-base.type-border .list-base-cont .ico-special.ico2.on::before {
    background-position: -66px -66px;
  }

  .list-base.type-border .list-base-cont .ico-special.ico3.on::before {
    background-position: -126px -66px;
  }

  .list-base.type-border .list-base-cont .ico-special.ico4.on::before {
    background-position: -186px -66px;
  }

  .list-base.type-border .list-base-cont .ico-special.ico5.on::before {
    background-position: -246px -66px;
  }

  .list-base.type-border .list-base-cont .ico-special.ico6.on::before {
    background-position: -306px -66px;
  }

  .list-base.type-border .list-base-cont .ico-special.on:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 25px;
    display: block;
    width: 13px;
    height: 10px;
    background: url("/static/images/common/ico_form@2x.png") -6px -33px
    no-repeat;
    background-size: 150px;
  }

  .list-base.type-border .list-base-cont .ico-special .sales {
    color: #999;
    margin-top: 5px;
  }

  .list-base.type-border .txt-check {
    color: #111;
    margin-top: 10px;
    padding-left: 22px;
  }

  .list-base.type-border .txt-check > span {
    color: #f05014;
  }

  .list-base.type-border .txt-check:before {
    content: "";
    display: inline-block;
    width: 13px;
    height: 10px;
    margin-right: 7px;
    margin-bottom: 1px;
    background: url("/static/images/common/ico_form@2x.png") -6px -33px
    no-repeat;
    background-size: 150px;
  }

  .list-base.type-border .btn-tip {
    margin-top: -3px;
  }

  /* list - yes or no 선택 */
  .list-selection {
    position: relative;
    margin-top: 35px;
  }

  .list-selection-item {
    position: relative;
    border-bottom: 1px #ddd solid;
    padding-bottom: 35px;
  }

  .list-selection-item + .list-selection-item {
    margin-top: 35px;
  }

  .list-selection.type-nobrd > .list-selection-item {
    border-bottom: 0;
  }

  .list-selection-head {
    position: relative;
  }

  .list-selection-head::after {
    content: "";
    display: block;
    clear: both;
  }

  .list-selection-head > .selection-label {
    float: left;
    max-width: 60%;
  }

  .list-selection-head > .selection-value {
    float: right;
    text-align: right;
  }

  .list-selection .lb-radio {
    padding-top: 0;
  }

  .list-selection .lb-radio::before {
    top: 3px;
  }

  .list-selection-info {
    color: #777;
    margin-top: 16px;
  }

  .list-selection-cont {
    position: relative;
    margin-top: 35px;
  }

  .list-selection-cont .bul-sdot > li {
    margin-top: 7px;
  }

  .list-selection-cont .bul-sdot > li:first-child {
    margin-top: 0;
  }

  .list-selection-cont .bul-sdot > li::before {
    background-color: #999;
  }

  .list-selection-cont .bul-hyphen {
    margin-top: 8px;
  }

  .list-selection-cont .bul-hyphen > li {
    font-size: 14px;
    color: #777;
  }

  .list-selection-cont .bul-star::before {
    color: #f05014;
  }

  .list-selection-cont .box-base {
    padding: 25px 30px;
    background: #f7f7f7;
    margin-top: 20px;
    border: 0;
    border-radius: 3px;
  }

  .list-selection-cont .box-base .bul-sdot li {
    font-size: 14px;
    color: #333;
  }

  .list-selection .file-wrap .file-item:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }

  /* 간편인증 */
  .certify-wrap {
    position: relative;
    border: 1px #ddd solid;
    margin-top: 40px;
    padding: 80px 0;
  }

  .certify-dot {
    position: relative;
    text-align: center;
  }

  .certify-dot span {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    background: #eee;
    border-radius: 16px;
    margin: 0 4px;
  }

  .certify-dot .active {
    background: #f05014;
  }

  .certify-tit {
    font-size: 20px;
    color: #111;
    text-align: center;
    margin-top: 0;
  }

  .certify-info {
    font-size: 14px;
    text-align: center;
    color: #777;
    margin-top: 15px;
  }

  .certify-num {
    position: relative;
    text-align: center;
    margin-top: 40px;
  }

  .certify-num .inp-num {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 26px;
    border: 1px #777 solid;
    margin: 0 5px;
  }

  .btn-wrap.type-certify {
    margin-top: 20px;
    text-align: left;
  }

  .btn-wrap.type-certify button {
    margin: 0;
  }

  .certi-img-wrap {
    position: relative;
    text-align: center;
    margin-top: 30px;
  }

  .certi-step .step-row {
    margin: 0;
  }

  .certi-step .step-row > li::after {
    display: none;
  }

  .certi-step .step-row dt {
    width: 190px;
  }

  .certi-step .step-row dd {
    width: calc(100% - 190px);
  }

  .certi-step .step-row dd > .certi-img-wrap {
    text-align: left;
    margin-top: 20px;
  }

  .certi-img-wrap.col-2 {
    text-align: left;
  }

  .certi-img-wrap.col-2 > img {
    display: block;
  }

  .certi-img-wrap.col-2::after {
    content: "";
    display: block;
    clear: both;
  }

  .certi-img-wrap.col-2 > p {
    float: left;
    width: 295px;
    font-size: 14px;
    color: #777;
    line-height: normal;
    margin-top: 15px;
    word-break: break-all;
  }

  .certi-img-wrap.col-2 > p + p {
    float: right;
    width: 295px;
  }

  .certi-tit::after {
    content: "";
    display: block;
    clear: both;
  }

  .certi-tit .h5 {
    float: left;
  }

  .certi-tit .btn-base-s {
    float: right;
  }

  .certify-num {
    position: relative;
    display: block;
    width: 290px;
    height: 40px;
    margin: 0 auto;
    margin-top: 30px;
    text-align: center;
    background: url("/static/images/content/certify_dot_pc.png") no-repeat;
  }

  .certify-num.dot0 {
    background-position-y: 0;
  }

  .certify-num.dot1 {
    background-position-y: -50px;
  }

  .certify-num.dot2 {
    background-position-y: -100px;
  }

  .certify-num.dot3 {
    background-position-y: -150px;
  }

  .certify-num.dot4 {
    background-position-y: -200px;
  }

  .certify-num.dot5 {
    background-position-y: -250px;
  }

  .certify-num.dot6 {
    background-position-y: -300px;
  }

  /* 보장내용 table */
  .tbl-base.type-cont th,
  .tbl-base.type-cont td {
    position: relative;
    font-size: 16px;
    background: none;
    padding-left: 20px;
    padding-top: 17px;
    padding-bottom: 17px;
    border-bottom: 1px solid #eee;
  }

  .tbl-base.type-cont td {
    text-align: right;
    font-weight: 700;
    color: #333;
    padding-right: 15px;
  }

  .tbl-base.type-cont td .money {
    display: inline-block;
    vertical-align: middle;
  }

  .tbl-base.type-cont .th-price {
    color: #777;
  }

  .tbl-base.type-cont .btn-tip {
    margin-top: -3px;
  }

  .tbl-base.type-cont .btn-arrow {
    vertical-align: middle;
    margin-left: 25px;
    margin-top: -5px;
  }

  .tbl-base.type-cont .btn-arrow-tbl {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    font-size: 16px;
    color: #333;
    font-weight: 700;
    text-align: right;
    padding-right: 45px;
    background: url("/static/images/common/ico_arrow_tbl.png")
    no-repeat;
    background-position: 285px center;
  }

  .tbl-base.type-cont tbody.type-special th,
  .tbl-base.type-cont tbody.type-special td {
    border-top: 0;
    border-bottom: 0;
  }

  .tbl-base.type-cont tbody.type-special td.type-txt {
    font-weight: 400;
    text-align: left;
    padding-top: 0;
  }

  .tbl-base.type-cont tbody.type-special dl::after {
    content: "";
    display: block;
    clear: both;
  }

  .tbl-base.type-cont tbody.type-special dt {
    float: left;
  }

  .tbl-base.type-cont tbody.type-special dd {
    float: right;
    padding-right: 45px;
  }

  .tbl-base.type-cont tbody tr.sel {
    background: #f7f7f7;
  }

  /* 증권발송 배너 */
  .bnr-wrap {
    position: relative;
    margin-top: 36px;
  }

  .bnr-wrap .bnr-tit {
    font-size: 15px;
    color: #111;
    font-weight: 500;
  }

  .bnr-wrap .bnr-info {
    margin-top: 16px;
  }

  /* 라디오선택 유형 디자인안나옴 */
  .type-lb-case .lb-radio,
  .type-lb-case .lb-check {
    width: 100%;
    padding-right: 60px;
    line-height: 24px;
  }

  .type-lb-case .lb-radio:before,
  .type-lb-case .lb-check:before {
    top: 0px;
    margin-right: 12px;
  }

  .type-lb-case .lb-radio em {
    display: block;
    font-size: 22px;
    line-height: 28px;
    margin-top: -3px;
    color: #111;
  }

  .type-lb-case .lb-radio i {
    display: block;
    margin-top: 5px;
    font-size: 15px;
  }

  .type-lb-case .lb-radio b {
    position: absolute;
    top: 10px;
    right: 0;
    width: 50px;
    font-size: 22px;
    color: #111;
    text-align: right;
  }

  .type-lb-case .lb-radio b:before {
    @include bar-border(16px, #ddd);
    top: 50%;
    margin-top: -8px;
  }

  .type-lb-case .lb-radio .inline {
    display: inline;
  }

  .type-lb-case .lb-radio .btn-tip {
    margin-top: -8px;
  }

  .lb-group {
    position: relative;
    border: 1px solid #ddd;
    padding: 40px;
  }

  .lb-group .lb-radio,
  .lb-group .lb-check {
    padding-top: 0;
  }

  .lb-group .btn-txt {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }

  .type-lb-cont {
    margin-top: 17px;
  }

  /* 금액선택 임시  MP02010600 */
  .type-lb-case2 .lb-radio {
    display: block;
    width: 50%;
  }

  .type-lb-case2 .lb-radio > span {
    width: 100%;
  }

  /* ui slider */
  .ui-slider {
    position: relative;
    width: 100%;
    padding: 50px 50px 0;
  }

  .ui-slider-wrap {
    position: relative;
    width: 100%;
    padding: 5px 0;
    z-index: 1;
  }

  .ui-slider-bg {
    position: relative;
    width: 100%;
    background-color: #eee;
    height: 10px;
    border-radius: 5px;
  }

  .ui-slider-bar {
    position: absolute;
    width: 0;
    height: 100%;
    top: 0;
    left: 50%;
    background: #ffc7a3;
    border-radius: 5px;
    z-index: 2;
    transition: all 0.2s;
  }

  [class^="ui-slider-btn-"] {
    position: absolute;
    top: 0;
    background: #f97232;
    border: 1px solid #f97232;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    z-index: 3;
    overflow: hidden;
    color: transparent;
    font-size: xx-small;
    transition: left 0.2s;
    margin-left: -10px;
  }

  [class^="ui-slider-btn-"]:focus,
  [class^="ui-slider-btn-"].on {
    z-index: 4;
  }

  [class^="ui-slider-btn-"] span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .ui-slider-divs {
    position: relative;
    z-index: 0;
    width: 100%;
    height: 30px;
  }

  .ui-slider-div {
    position: absolute;
    bottom: 0;
    padding: 0;
    z-index: 1;
    display: block;
    height: 30px;
  }

  .ui-slider-div em {
    display: block;
    text-align: center;
    width: 100%;
    margin-top: 10px;
    box-sizing: border-box;
    white-space: nowrap;
    font-size: 15px;
  }

  .ui-slider-div:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    width: 1px;
    height: 10px;
    border-left: 1px solid #ccc;
  }

  .ui-slider-tooltip {
    position: absolute;
    top: -30px;
    overflow: hidden;
    text-align: center;
    color: #f05014;
    padding: 5px 0;
    z-index: 1;
    white-space: nowrap;
    transition: all 0.2s;
    font-size: 15px;
  }

  /* ui slider type :reverse, vertical */
  .type-reverse .ui-slider-bar {
    left: auto;
  }

  .type-reverse [class^="ui-slider-btn-"] {
    margin-right: -10px;
    margin-left: 0;
  }

  .type-vertical {
    width: 100px;
    height: 300px;
    padding: 20px;
  }

  .type-vertical .ui-slider-wrap {
    width: 20px;
    height: 100%;
    padding: 0 5px;
  }

  .type-vertical .ui-slider-bg {
    width: 10px;
    height: 100%;
  }

  .type-vertical .ui-slider-bar {
    width: 10px;
    height: 0;
    top: auto;
    left: 0;
  }

  .type-vertical [class^="ui-slider-btn-"] {
    top: auto;
    left: 0;
    margin: -10px 0 0;
  }

  .type-vertical .ui-slider-divs {
    position: absolute;
    top: 0;
    left: 15px;
    width: auto;
    height: 100%;
  }

  .type-vertical .ui-slider-div {
    width: 100%;
    padding-left: 15px;
    bottom: auto;
  }

  .type-vertical .ui-slider-div:after {
    top: 50%;
    left: 0;
    width: 10px;
    height: 1px;
    border-top: 1px solid #ccc;
    border-left: 0;
  }

  .type-vertical .ui-slider-div em {
    text-align: left;
    top: 5px;
  }

  .type-vertical .ui-slider-tooltip {
    left: auto;
    right: 20px;
  }

  .type-vertical.type-reverse .ui-slider-btn-s,
  .type-vertical.type-reverse .ui-slider-btn-e {
    margin: 0 0 -10px;
  }

  .type-vertical.type-reverse .ui-slider-tooltip {
    top: auto;
  }

  .ui-slider-btns {
    position: absolute;
    left: 0;
    width: 100%;
    margin-top: -2px;
  }

  .ui-slider-btns button {
    position: absolute;
    width: 24px;
    height: 24px;
    background: url("/static/images/common/ico_small.png")
    no-repeat;
  }

  .ui-slider-btns .ui-slider-down {
    background-position: -144px -1px;
    transform: rotate(180deg);
  }

  .ui-slider-btns .ui-slider-up {
    background-position: -144px -1px;
  }

  /* slide bar */
  .mileage-slider {
    padding: 40px 30px 30px;
  }

  .mileage-slider .ui-slider-down {
    top: 0;
    left: 0;
  }

  .mileage-slider .ui-slider-up {
    top: 0;
    right: 0;
  }

  .mileage-slider .ui-slider-div em {
    width: 70px;
    color: #777;
    font-size: 12px;
    margin-left: -35px;
    text-align: center;
  }

  .mileage-slider .ui-slider-div:first-child em {
    margin-left: 0;
    text-align: left;
  }

  .mileage-slider .ui-slider-div:last-child em {
    margin-left: -70px;
    text-align: right;
  }

  .ui-slidepage {
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;
  }

  .ui-slidepage-wrap.ontran {
    transition: left 0.2s cubic-bezier(0.87, 0.06, 0.82, 0.58);
  }

  .ui-slidepage-wrap {
    position: relative;
    width: 100%;
    left: 0;
  }

  .ui-slidepage-item {
    position: absolute;
    display: none;
    width: 100%;
    top: 0;
  }

  .ui-slidepage-item.on {
    display: block;
  }

  /* 보상처리절차안내 */
  .step-process-img {
    position: relative;
    width: 100%;
    margin-top: 15px;
  }

  .step-process-img .step-basic {
    text-align: center;
  }

  .step-process-img .step-item {
    width: 96px;
  }

  .step-process-img .item-circle::after {
    left: -40px;
    width: 32px;
  }

  .step-process-img .step-item .txt {
    color: #777;
  }

  .ico-security {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 24px;
    height: 24px;
    vertical-align: middle;
    background: url("/static/images/common/ico_small.png")
    no-repeat -226px -25px;
  }

  .ico-security i {
    @include txt-hide();
  }

  .srh-wrap .ui-select {
    width: 190px;
  }

  .srh-wrap .type-srh {
    display: inline-block;
    width: calc(100% - 200px);
    margin-left: 10px;
  }

  // 총 보험료 type-b
  .total-price.type-b > div {
    overflow: hidden;
    margin-top: 5px;
  }

  .total-price.type-b > div:first-child {
    margin-top: 0;
  }

  .total-price.type-b .tit {
    float: left;
    font-size: 16px;
  }

  .total-price.type-b .txt-price {
    float: right;
    font-size: 16px;
    color: #333;
    font-weight: 400;
  }

  .total-price.type-b .txt-price b {
    margin-right: 0;
    font-size: 16px;
    color: #111;
    font-weight: 700;
    vertical-align: inherit;
  }

  /* 게시판 */
  .tbl-board {
    margin-top: 15px;
  }

  .tbl-board table {
    table-layout: fixed;
    width: 100%;
    border-top: 1px solid #bbb;
    text-align: center;
  }

  .tbl-board thead th {
    padding: 18px 10px;
    font-size: 16px;
    font-weight: 700;
    border-bottom: 1px solid #eee;
    position: relative;
  }

  .tbl-board thead th:before {
    @include bar-border($height: 14px, $color: #eee);
    top: 50%;
    left: 0;
    margin-top: -7px;
  }

  .tbl-board thead th:first-child:before {
    display: none;
  }

  .tbl-board tbody td {
    padding: 18px 10px;
    font-size: 16px;
    border-bottom: 1px solid #eee;
  }

  .tbl-board .tit {
    position: relative;
    padding: 0 10px;
    display: block;
    @include ellipse-one(100%);
    text-decoration: none;
    color: #333;
    text-align: initial;
    cursor: pointer;
  }

  .tbl-board tbody .fix td,
  .tbl-board tbody .fix .tit {
    color: #111;
  }

  .tbl-board .no-data td {
    color: #777;
    height: 160px;
  }

  .tbl-board .no-data td b {
    color: #111;
    font-weight: 700;
  }

  .tbl-board.type-notice .tit {
    padding-left: 44px;
    @include ellipse-one(328px);
  }

  .tbl-board.type-notice .tit .ico-security {
    left: 10px;
  }

  .tbl-board .txt-tag {
    margin: 0;
    min-height: 20px;
    font-size: 14px;
  }

  .tbl-board.type-notice .txt-tag {
    margin: 0 10px;
  }

  /* paging */
  .paging-wrap {
    margin-top: 40px;
    text-align: center;
  }

  .paging-wrap button {
    display: inline-block;
    padding-right: 2px;
    margin: 0 15px;
    font-size: 16px;
    color: #999;
    vertical-align: top;
  }

  .paging-wrap button.on {
    color: #111;
    font-weight: 700;
    border-bottom: 1px solid #111;
  }

  .paging-wrap [class*="btn-page"] {
    width: 30px;
    height: 30px;
    margin: 0 5px;
    border: 1px solid #eee;
    background: url("/static/images/common/ico_small.png") -250px
    1px;
  }

  .paging-wrap [class*="btn-page"] span {
    @include txt-hide();
  }

  .paging-wrap .btn-page-first {
    background-position: -249px -24px;
  }

  .paging-wrap .btn-page-prev {
    background-position: -249px 1px;
    margin-right: 25px;
  }

  .paging-wrap .btn-page-next {
    background-position: -274px 1px;
    margin-left: 25px;
  }

  .paging-wrap .btn-page-last {
    background-position: -274px -24px;
  }

  .paging-wrap + .btn-wrap-fixed {
    margin-top: 80px;
  }

  .paging-direction {
    margin-top: 20px;
    text-align: center;
  }

  .paging-direction button {
    width: 38px;
    height: 38px;
    margin: 0 10px;
    border: 1px solid #ddd;
    border-radius: 100%;
  }

  .paging-direction button:before {
    content: "";
    display: block;
    width: 25px;
    height: 25px;
    margin: 0 auto;
    background: url("/static/images/common/ico_small.png") -250px
    0;
  }

  .paging-direction button:disabled:before,
  .paging-direction button[disabled]:before,
  .paging-direction button.disabled:before {
    opacity: 0.4;
  }

  .paging-direction button span {
    @include txt-hide();
  }

  .paging-direction .btn-page-first:before {
    background-position: -251px -25px;
  }

  .paging-direction .btn-page-prev:before {
    background-position: -251px 0;
  }

  .paging-direction .btn-page-next:before {
    background-position: -273px 0;
  }

  .paging-direction .btn-page-last:before {
    background-position: -273px -25px;
  }

  /* 자주하는질문 */
  .tag-wrap {
    margin-top: 16px;
  }

  .tag-wrap button {
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
    border-radius: 18px;
    padding: 7.5px 12px;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -1px;
    color: #808080;
  }

  .tag-wrap button:hover {
    border: 1px solid #dd5414;
    color: #f05014;
  }

  .tag-wrap button:not(:first-child) {
    margin-left: 8px;
  }

  .ui-acco.type-faq {
    border-top: 0;
  }

  .ui-acco.type-faq .total {
    padding: 35px 0 15px;
    text-align: right;
  }

  .ui-acco.type-faq .total + .ui-acco-wrap {
    border-top: 1px solid #bbb;
  }

  .ui-acco.type-faq .ui-acco-btn {
    position: relative;
    padding: 19px 50px 19px 45px;
    font-size: 16px;
    color: #111;
  }

  .ui-acco.type-faq .simbol {
    position: absolute;
    top: 16px;
    left: 20px;
    font-size: 18px;
    font-weight: 700;
  }

  .ui-acco.type-faq .ui-acco-pnl {
    position: relative;
    padding: 15px 45px;
    font-size: 16px;
    color: #333;
    line-height: 24px;
    background: #f7f7f7;
  }

  .ui-acco.type-faq .no-data {
    padding: 75px 0;
    color: #777;
    text-align: center;
  }

  /* 상세 페이지 */
  .view-wrap {
    margin-top: 80px;
    padding-bottom: 40px;
    border-bottom: 1px solid #eee;
  }

  .view-wrap .view-tit {
    padding-bottom: 35px;
    border-bottom: 1px solid #bbb;
  }

  .view-wrap .view-tit .h1 {
    word-break: break-all;
  }

  .view-wrap .view-tit .date {
    margin-top: 7px;
  }

  .view-wrap .event-date {
    position: relative;
    padding-right: 122px;
    margin-top: 10px;
  }

  .view-wrap .event-date > span {
    display: inline-block;
    width: 50%;
    font-size: 14px;
    color: #777;
  }

  .view-wrap .event-date > span b {
    margin-right: 6px;
    font-size: 16px;
    color: #111;
    font-weight: 700;
  }

  .view-wrap .event-date .btn-sns-wrap {
    position: absolute;
    top: -2px;
    right: 10px;
  }

  .view-wrap .view-cont {
    padding: 40px 20px 0;
  }

  .view-wrap .btn-down-wrap {
    padding: 2px 20px 20px;
    border-bottom: 1px solid #eee;
    text-align: right;
  }

  .view-wrap .btn-down-wrap .btn-ico {
    margin-top: 15px;
    max-width: 50%;
    @include ellipse-one($wid: 100%);
    text-align: right;
  }

  .view-wrap .edit-wrap {
    font-size: 16px;
    color: #333;
    line-height: 24px;
    word-break: break-all;
  }

  .view-wrap .edit-wrap img {
    max-width: 600px;
  }

  .view-wrap .edit-wrap pre {
    font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', 'Apple SD Gothic Neo', 'Malgun Gothic', '맑은 고딕', sans-serif;
    white-space: pre-wrap;
  }

  .view-wrap .edit-wrap .ql-align-center {
    text-align: center;
  }

  .view-wrap.type-consult {
    padding-bottom: 20px;
  }

  .view-wrap.type-consult .view-cont {
    padding: 0;
  }

  .view-wrap.type-consult .tit {
    font-size: 16px;
    color: #111;
    font-weight: 700;
  }

  .view-wrap.type-consult .tit i {
    margin-right: 10px;
    font-size: 18px;
    margin-left: -25px;
  }

  .view-wrap.type-consult .question {
    padding: 15px 45px 20px;
  }

  .view-wrap.type-consult .edit-wrap {
    padding: 15px 45px;
    border-top: 1px solid #eee;
  }

  .view-wrap.type-consult .answer {
    padding: 15px 45px;
    background: #f7f7f7;
  }

  .view-wrap.type-consult .answer .tit i {
    margin-left: -20px;
  }

  .view-wrap.type-consult .answer .edit-wrap {
    padding: 10px 0 0;
    border-top: 0;
  }

  .view-wrap + .btn-wrap-fixed {
    margin-top: 80px;
  }

  .btn-sns-wrap .btn-sns {
    position: relative;
    width: 24px;
    height: 24px;
    background: url(/static/images/content/ico_sns.png) no-repeat;
    text-indent: -9999px;
    margin-left: 6px;
  }

  .btn-sns-wrap .btn-sns:first-child {
    margin-left: 0;
  }

  .btn-sns-wrap .btn-sns.kakaotalk {
    background-position: 0 0;
  }

  .btn-sns-wrap .btn-sns.facebook {
    background-position: -34px 0;
  }

  .btn-sns-wrap .btn-sns.instagram {
    background-position: -68px 0;
  }

  /* 이벤트 결과*/
  .result-event {
    margin-top: 60px;
    padding: 35px 0;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
  }

  .result-event b {
    font-weight: 700;
    color: #f05014;
  }

  /* 이전글/다음글 */
  .view-wrap + .view-paging {
    border-top: 0;
  }

  .view-paging {
    border-top: 1px solid #eee;
  }

  .view-paging dl {
    padding: 18px 0;
    border-bottom: 1px solid #eee;
  }

  .view-paging dl:after {
    content: "";
    display: block;
    clear: both;
  }

  .view-paging dt {
    float: left;
    width: 134px;
    font-weight: 700;
    padding-left: 20px;
  }

  .view-paging dd {
    float: left;
    @include ellipse-one($wid: calc(100% - 200px));
    padding-right: 10px;
  }

  .view-paging dd a {
    color: #333;
    text-decoration: none;
  }

  .view-paging + .btn-wrap-fixed {
    margin-top: 80px;
  }

  /* 썸네일 리스트 */
  .thumb-list {
    margin-top: 15px;
    padding-top: 40px;
    border-top: 1px solid #bbb;
    border-bottom: 1px solid #eee;
  }

  .thumb-list:after {
    content: "";
    display: block;
    clear: both;
  }

  .thumb-list li {
    float: left;
    width: 295px;
    margin-left: 20px;
    margin-bottom: 40px;
  }

  .thumb-list li:nth-child(2n-1) {
    margin-left: 0;
  }

  .thumb-list a,
  .thumb-list button {
    display: block;
    width: 100%;
    color: #111;
    text-decoration: none;
  }

  .thumb-list .thumb {
    position: relative;
    display: block;
    width: 295px;
    height: 196px;
    border: 1px #eee solid;
  }
  .thumb-list .thumb > .play-dim {
    position: absolute;
    width: 293px;
    height: 194px;
    background: #262626;
    opacity: 0.5;
  }
  .thumb-list .thumb > .btn-play {
    position: absolute;
    background-image: url("/static/images/icon/movie-play.png");
    background-size: 100%;
    width: 35px;
    height: 35px;
    top: 40%;
    left: 45%;
    opacity: 0.8;
  }

  .thumb-list .thumb > img {
    width: 100%;
  }

  .thumb-list.type-list01 {
    .thumb {
      height: 146px;
      .img_center_thumb {
        display: block;
        position: absolute;
        top: 50%;
        left: 0%;
        width: 100%;
        transform: translate(0, -50%);
        text-align: center;
        > img {
          max-width: 100%;
        }
      }
    }
    .tit {
      margin-top: 20px;
    }
    .tit .txt-tag {
      width: 60px;
      margin: 0 9px 0 0;
      padding: 4px 0px 0;
      vertical-align: top;
    }
    .result {
      .tit .txt-tag.type-line {
        color: #00358e;
        background: #fff;
        border-color: #00358e;
      }
    }
    .cont {
      padding: 7px 0 0;
    }
  }

  .thumb-list .tit {
    display: block;
    padding: 0 0 5px;
    margin-top: 15px;
    font-size: 16px;
    font-weight: 700;
    @include ellipse-one(100%);
  }

  .thumb-list .cont {
    padding: 10px 0 0;
    line-height: 1;
  }

  .thumb-list .cont .txt-tag {
    margin: 0 10px 0 0;
    vertical-align: top;
    min-height: 20px;
    padding: 3px 17px;
  }

  .thumb-list .cont .date {
    font-size: 14px;
    color: #777;
    line-height: 20px;
    vertical-align: top;
  }

  .thumb-list .ing .cont .date {
    color: #f05014;
  }

  .thumb-list .end .layer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    line-height: 196px;
    vertical-align: middle;
    text-align: center;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
    font-weight: 700;
  }
  .thumb-list.type-list01 .ing .cont .date {
    color: #777;
  }
  .thumb-list.type-list01 .end .layer {
    line-height: 146px;
  }

  .thumb-list .btn-wrap {
    margin: 15px 0 0;
    text-align: right;
  }

  .thumb-list .btn-txt-arrow {
    font-size: 14px;
  }

  .thumb-list .no-data {
    width: 100%;
    text-align: center;
    padding: 75px 0;
    margin: 0;
    color: #999;
    border-top: 1px solid #eee;
  }

  .thumb-list .no-data:first-child {
    border-top: 0;
    padding-top: 35px;
  }

  /* pop - 동영상*/
  .player-box iframe {
    width: 100%;
    height: 405px;
  }

  .movie-cont {
    padding: 30px 0 5px;
  }

  .movie-caption {
    height: 46px;
    overflow-y: auto;
    margin: 10px 0 0;
    font-size: 16px;
    line-height: 25px;
  }

  /* 주소검색 */
  .srch-result.type-address {
    height: 240px;
    overflow: auto;
  }

  .srch-result.type-address .result-item {
    border-bottom: 1px #eee solid;
    cursor: pointer;
  }

  .srch-result.type-address .result-item:hover {
    background: #f6f6f6;
  }

  .srch-result.type-address .result-item > dl {
    position: relative;
    display: table;
    width: 100%;
  }

  .srch-result.type-address .result-item dt {
    display: table-cell;
    width: 90px;
    text-align: center;
    vertical-align: middle;
  }

  .srch-result.type-address .result-item dd {
    display: table-cell;
    width: calc(100% - 90px);
    padding: 16px 20px;
  }

  .srch-result.type-address .addr-ico {
    color: #777;
  }

  /* 공통 - 결제 - 계좌이체 */
  .txt-cm {
    font-size: 14px;
    color: #333;
  }

  .bank-list {
    margin-top: 15px;
    padding: 1px 0 0 1px;
  }

  .bank-list:after {
    content: "";
    display: block;
    clear: both;
  }

  .bank-list li {
    float: left;
    width: calc(20% + 1px);
    height: 120px;
    margin: -1px 0 0 -1px;
  }

  .bank-list li button {
    position: relative;
    display: block;
    width: 100%;
    height: 120px;
    border: 1px solid #ddd;
    font-size: 14px;
    text-align: center;
    z-index: 0;
  }

  .bank-list li button:hover {
    border-color: #f05014;
    color: #f05014;
    z-index: 1;
  }

  .bank-list li button span {
    display: block;
    word-wrap: break-word;
    width: 100%;
    height: 100%;
    padding: 20px 0 0;
    line-height: 19px;
  }

  .bank-list li button span img {
    display: block;
    margin: 0 auto;
    width: 56px;
    height: 56px;
  }

  .bank-list li.no-data {
    background: url(/static/images/content/logo_pic2.png) 50% 50% no-repeat;
    border: 1px solid #ddd;
  }

  .selected-bank {
    margin: 50px 0 15px;
  }

  .selected-bank:after {
    content: "";
    display: block;
    clear: both;
  }

  .selected-bank .bank {
    float: left;
    font-size: 22px;
  }

  .selected-bank .bank img {
    margin-right: 14px;
    border: 1px solid #ddd;
    vertical-align: middle;
  }

  .selected-bank .bank span i {
    vertical-align: middle;
  }

  .selected-bank .btn-base {
    float: right;
    margin: 7px 0 0;
  }

  /* 공통 - 전자서명 - 공인인증서 */
  .box-base.type-certify {
    padding: 35px 40px;
    text-align: center;
  }

  .box-base.type-certify .h4 {
    font-weight: 400;
  }

  .box-base.type-certify .h4:before {
    content: "";
    display: block;
    width: 88px;
    height: 88px;
    margin: 5px auto 15px;
    background: url(/static/images/content/ico_illu.png) -6px 3px;
  }

  /* 가족 정보 등록 */
  .info-register-wrap {
    padding-bottom: 55px;
  }

  .info-register-wrap li:first-child {
    margin-top: 25px;
  }

  .info-register-wrap li {
    margin-top: 21px;
  }

  .info-register .item-family {
    display: inline-block;
    width: 105px;
    vertical-align: top;
  }

  .info-register .item-gender {
    display: inline-block;
    max-width: calc(100% - 140px);
    margin-right: 10px;
    color: #777;
    line-height: 26px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: top;
  }

  .info-register .btn-del-circle{
    margin-top: 4px;
    vertical-align: top;
  }

  /* 필수사항 */
  .important-chk {
    margin-top: 45px;
  }

  .important-chk + .important-chk {
    margin-top: 50px;
  }

  .fx-wrap + .important-chk {
    margin-top: 50px;
  }

  .important-chk .tit {
    overflow: hidden;
  }

  .important-chk .tit h2 {
    float: left;
    color: #111;
  }

  .important-chk .tit h2 > strong {
    color: #f05014;
    font-weight: 700;
  }

  .important-chk .tit button {
    float: right;
  }

  .important-chk .tit button > span {
    font-weight: 700;
  }

  // 확인사항
  .important-chk.type-a {
    margin-top: 50px;
  }

  .important-chk.type-a .tit h2 {
    margin-top: 5px;
  }

  .important-chk.type-a .important-chk-list li + li {
    margin-top: 8px;
  }

  // 총 보험료
  .total-price {
    background-color: #f7f7f7;
    margin: 25px 0 7px;
    overflow: hidden;
    padding: 16px 30px 15px;
    border-radius: 4px;
  }

  .total-price .tit {
    color: #333;
    font-size: 20px;
    font-weight: normal;
  }

  .total-price .txt-price {
    float: right;
    font-size: 20px;
    color: #f05014;
    font-weight: bold;
  }

  //테이블 bg-fff, bullet box
  .tbl-base.type-bg > tbody > tr > th {
    background: #fff;
  }

  .bullet-box {
    background-color: #f7f7f7;
    border: 0;
    border-radius: 4px;
    margin: 10px 0 0 -20px;
    width: calc(100% + 20px);
    padding: 20px;
  }

  // 담보가입내역
  .ui-acco.type-details-list .ui-acco-pnl {
    background-color: #f7f7f7;
    padding: 20px;
  }

  //보험료확인 테이블 type-tbl-line
  .type-tbl-line dl {
    border-top: 1px solid #777;
    border-bottom: 1px solid #eee;
    padding: 25px 20px 25px;
    margin-top: 20px;
  }

  .type-tbl-line dl + dl {
    border-top: 0;
    margin: 0;
  }

  .type-tbl-line dt {
    font-weight: 700;
  }

  .type-tbl-line .tbl-noline tbody + tbody > tr:first-child > * {
    padding-top: 13px !important;
  }

  //보험료확인 테이블 type-tbl-line ex)행사보험 PO06005300
  .tbl-noline.type-tbody-a tbody + tbody > tr:first-child > * {
    padding-top: 13px !important;
  }

  .tbl-noline.type-tbody-a th {
    padding: 0;
  }

  .tbl-noline.type-tbody-a td {
    text-align: right;
    color: #333;
    padding: 0;
  }

  .list-agree li .txt-sub li {
    border-bottom: 0;
    padding: 2px 15px;
  }

  .list-agree li .bul-dot.txt-sub li ul li {
    padding-top: 0;
  }

  // SideModl 버튼3개  btn-wrap col3 ex)행사보험 PO06005301
  .btn-wrap.col3 .btn-chips-step {
    width: calc(33.3% - 7px);
    margin: 0 10px 10px 0;
    padding: 0;
  }

  .btn-wrap.col3 .btn-chips-step:nth-child(3n) {
    margin-right: 0;
  }

  .btn-wrap.col3 {
    font-size: 0;
    text-align: left;
  }

  .btn-wrap.col3 .btn-base-r {
    width: calc(33.3% - 7px);
    margin: 0 10px 0 0;
    padding: 0;
  }

  .btn-wrap.col3 .btn-base-r:nth-child(3) {
    margin-right: 0;
  }

  .fx-wrap + .btn-wrap.col3 {
    margin-top: 20px;
  }

  /*나의계약*/
  /* text simple banner  */
  .txt-bn {
    margin-top: 20px;
    padding: 25px 30px;
    background: #f7f7f7;
  }

  .txt-bn .tit {
    display: block;
    font-size: 15px;
    font-weight: 500;
    color: #111;
    line-height: 22px;
  }

  .txt-bn .btn-txt-arrow {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 700;
  }

  .txt-bn + .btn-wrap-fixed {
    margin-top: 80px;
  }

  .sub-main-list .box-base .bul-etc {
    font-size: 15px;
  }

  .sub-main-list .box-base .bul-etc > li {
    margin-top: 10px;
  }

  .txt-toggle {
    margin-top: 15px;
  }

  .txt-toggle:after {
    content: "";
    display: block;
    clear: both;
  }

  .txt-toggle .txt {
    float: left;
    font-size: 14px;
    font-weight: 400;
  }

  .txt-toggle.on .txt {
    font-weight: 700;
  }

  .txt-toggle .btn {
    float: right;
    font-size: 14px;
    color: #777;
  }

  .txt-toggle .btn .ico-arrow3 {
    margin-left: 18px;
  }

  .pdl-other {
    padding-left: 190px;
  }

  /* 나의계약 - 부속품 아코디언 */
  .acco-box .ui-acco.type-normal .ui-acco-wrap {
    margin-top: 20px;
    border: 1px solid #ddd;
    padding: 30px 40px 35px;
  }

  .acco-box .ui-acco.type-normal .ui-acco-pnl {
    margin-top: 15px;
  }

  .acco-box .ui-acco-tit {
    font-size: 18px;
    color: #111;
    font-weight: 700;
  }

  .acco-box .ui-acco-tit .lb-check span {
    font-size: 18px;
  }

  .acco-box .ui-acco-tit .lb-check + .btn-tip {
    margin-top: 0;
    top: 3px;
  }

  .acco-box .box-price {
    padding: 20px 25px;
    background: #f7f7f7;
    border-radius: 4px;
    margin-top: 20px;
  }

  .acco-box .box-price > div {
    line-height: inherit;
    font-size: 16px;
  }

  .acco-box .box-price .txt-price > b {
    display: inline-block;
    font-size: 16px;
    margin-top: 4px;
  }

  .acco-box .box-base {
    border: 0;
    padding: 0 0 0 30px;
  }

  .acco-box .box-base::after {
    content: "";
    display: block;
    clear: both;
  }

  .acco-box .box-base > .fx-wrap {
    float: left;
    margin-top: 0;
    width: calc(100% - 220px);
  }

  .acco-box .box-base > .box-price {
    float: right;
    width: 200px;
    margin-top: 15px;
  }

  .acco-box .ui-acco-pnl > .fx-wrap {
    padding-left: 30px;
    margin-top: 0;
  }

  /* 나의계약 - 금융주소한번에 */
  .list-base.type-service,
  .list-base.type-service ul {
    overflow: hidden;
  }

  .list-base.type-service .list-base-item {
    width: calc(50% - 10px);
    height: 386px;
    padding: 25px 20px 20px;
    margin: 0;
    text-align: center;
  }

  .list-base.type-service .list-base-item + .list-base-item:before {
    content: "";
    display: block;
    position: absolute;
    top: 56%;
    left: -36px;
    width: 50px;
    height: 20px;
    background: url(/static/images/content/ico_service.png) -40px 0 no-repeat;
  }

  .list-base.type-service ul {
    margin-top: 15px;
  }

  .list-base.type-service ul li {
    float: left;
    width: 50%;
    padding: 9px 0;
    font-size: 14px;
    color: #777;
  }

  .list-base.type-service .h6.ico-chk {
    display: inline-block;
    position: relative;
    color: #f05014;
  }

  .list-base.type-service .h6.ico-chk:before {
    content: "";
    display: block;
    position: absolute;
    top: 10px;
    left: -30px;
    width: 26px;
    height: 20px;
    background: url(/static/images/content/ico_service.png) 0 0 no-repeat;
  }

  .list-base.type-service .txt {
    font-size: 20px;
    font-weight: 700;
    margin-top: 138px;
  }

  /* 나의계약 - 금융회사 선택 */
  .slc-company > div {
    width: calc(50% - 10px);
  }

  .slc-company .box-base {
    padding: 0;
    height: 282px;
  }

  .slc-company .box-base .btn {
    position: relative;
    height: 56px;
    width: 100%;
    text-align: left;
    padding: 0 50px;
    font-size: 22px;
    color: #111;
    line-height: 1;
  }

  .slc-company .box-base .btn.on {
    font-weight: 700;
    color: #f05014;
  }

  .slc-company .box-base .btn.on span:before {
    content: "";
    display: block;
    width: 18px;
    height: 13px;
    position: absolute;
    top: 22px;
    left: 23px;
    background: url("/static/images/common/ico_form.png") -29px -31px
    no-repeat;
  }

  .selected-company {
    margin-top: 50px;
    min-height: 160px;
  }

  .selected-company .company-list li {
    display: inline-block;
    margin-top: 10px;
    margin-right: 12px;
    padding: 9px 14px 9px 26px;
    font-size: 20px;
    color: #111;
    border: 1px solid #ddd;
    border-radius: 25px;
  }

  .selected-company .company-list li .btn-del-circle {
    margin-left: 20px;
    vertical-align: top;
  }

  .selected-company .company-list li .btn-del-circle span {
    top: 15px;
    width: 20px;
    height: 20px;
    background: #ddd;
  }

  .selected-company .company-list li .btn-del-circle span:before {
    height: 2px;
  }

  .selected-company .company-list li .btn-del-circle span:after {
    width: 2px;
  }

  .serial-number {
    margin-top: 33px;
  }

  .serial-number li {
    margin-top: 10px;
  }

  // list-base type-a
  .list-base.type-a .list-base-item {
    margin-top: 16px;
    padding: 36px 40px 37px;
    border: 1px solid #eee;
  }

  .list-base.type-a .list-base-item li {
    font-size: 14px;
    color: #333;
  }

  .list-base.type-a .list-base-item li p {
    font-size: 16px;
    margin-bottom: 22px;
  }

  .list-base.type-a .list-base-item li .item-wrap span {
    display: inline-block;
    text-align: center;
    margin-top: 4px;
  }

  .list-base.type-a li {
    margin-top: 32px;
  }

  .list-base.type-a li:first-child {
    margin-top: 0;
  }

  .list-base.type-a .item-wrap:before {
    content: "";
    display: block;
    width: 100%;
    height: 8px;
  }

  .list-base.type-a .item-wrap.n1::before {
    background: url(/static/images/content/insgrap_1.png) no-repeat;
    background-size: 100%;
  }

  .list-base.type-a .item-wrap.n1 span,
  .list-base.type-a .item-wrap.n4 span {
    width: calc(50% - 5px);
    padding-left: 5px;
  }

  .list-base.type-a .item-wrap.n2 span {
    width: calc(62% - 5px);
    padding-left: 5px;
  }

  .list-base.type-a .item-wrap.n2 span + span {
    width: calc(38% - 5px);
    padding-left: 0;
  }

  .list-base.type-a .item-wrap.n2::before {
    background: url(/static/images/content/insgrap_2.png) no-repeat;
    background-size: 100%;
  }

  .list-base.type-a .item-wrap.n3 {
    text-align: center;
  }

  .list-base.type-a .item-wrap.n3::before {
    background: url(/static/images/content/insgrap_3.png) no-repeat;
    background-size: 100%;
  }

  .list-base.type-a .item-wrap.n4::before {
    background: url(/static/images/content/insgrap_4.png) no-repeat;
    background-size: 100%;
  }

  /* 탑라인 없음 */
  .list-base.type-b .list-base-item {
    border-top: 0;
    overflow: hidden;
    margin-top: 0;
  }
  .list-base.type-b .list-base-item:last-child {
    border-bottom: 0;
  }
  .list-base.type-b .list-base-item > div {
    width: 50%;
    float: left;
  }

  /* total-price type-a 가입 완료 PO02005800 */
  .total-price.type-a {
    padding: 16px 30px 18px;
  }

  .total-price.type-a div + div {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #ddd;
  }

  .btn-wrap-fixed.col2 {
    overflow: hidden;
    border-radius: 4px;
  }

  .btn-wrap-fixed.col2 .btn-base-l {
    width: calc(100% - 305px);
    border-radius: 0;
  }

  .item-wrap-sw {
    margin-bottom: 25px;
  }

  .item-wrap-sw + .item-wrap-sw {
    margin-bottom: 0;
    padding-top: 25px;
    border-top: 1px solid #ddd;
  }

  .item-wrap-sw span {
    display: inline-block;
    font-size: 14px;
    vertical-align: top;
    width: 156px;
  }

  .item-wrap-sw span + span {
    font-size: 16px;
    width: calc(100% - 156px);
  }

  /* 일반보험 - 보험료 확인 내에서 사용 */
  .box-col-2.type-a dl {
    width: 250px;
  }

  .box-col-2.type-a dl + dl {
    width: calc(100% - 250px);
  }

  .box-col-2.type-a .btn-tip {
    position: absolute;
    right: -30px;
    top: 5px;
  }

  .box-col-2::after {
    display: block;
    clear: both;
    content: "";
  }

  .box-col-2 {
    position: relative;
    margin-top: 10px;
    border: 1px solid #ddd;
  }

  .box-col-2 dl {
    float: left;
    width: 50%;
    padding: 26px 30px;
  }

  .box-col-2 dl + dl {
    float: right;
    border-left: 1px solid #ddd;
  }

  .box-col-2 dl dt {
    font-size: 14px;
    color: #777;
  }

  .box-col-2 dl strong {
    color: #333;
  }

  .box-col-2 dl dd strong {
    font-size: 20px;
  }

  .box-col-2 dl dd ul {
    font-size: 14px;
  }

  .box-col-2 dl dd li::after {
    display: block;
    clear: both;
    content: "";
  }

  .box-col-2 dl dd li span {
    float: left;
    padding-top: 3px;
  }

  .box-col-2.type-a dl dd li span {
    display: inline-block;
    max-width: 175px;
    word-break: break-all;
  }

  .box-col-2.type-a dl dd li strong {
    display: inline-block;
    max-width: 70px;
  }

  .box-col-2 dl dd li strong {
    float: right;
  }

  .box-txt-sub {
    color: #777;
    font-size: 14px;
    margin-top: 14px;
  }

  .box-txt-sub b {
    color: #333;
    font-size: 16px;
    padding-left: 16px;
  }

  .ico-plus {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -10px 0 0 -10px;
  }

  .ico-minus {
    position: absolute;
    top: 50%;
    left: 40%;
    margin: -10px 0 0 -2px;
  }

  /* 990운전자보험 */

  .mgl-two {
    margin-left: 2px;
  }

  .double-select {
    display: inline-block;
    margin-right: 20px;
  }

  .double-select + .double-select {
    margin-right: 0;
  }

  .double-select h2 {
    display: block;
    float: left;
    margin-top: 18px;
  }

  .double-select h2 .btn-tip {
    margin-left: 10px;
  }

  .double-select .ui-select {
    display: block;
    float: right;
    width: 114px;
    margin-left: 20px;
  }

  .double-select + .btn-base {
    padding: 0 30px;
    margin: 13px 0 0 18px;
  }

  .double-select .fixed {
    float: left;
    position: relative;
    display: block;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    padding-left: 10px;
    margin-top: 18px;
  }

  /* 예상보험료 */
  .expect-info {
    position: relative;
    border-top: 1px #eee solid;
    padding-top: 30px;
    line-height: 1.3;
  }

  .expect-info > b {
    font-size: 20px;
    font-weight: 700;
  }

  .expect-info > strong {
    font-size: 20px;
    color: #f05014;
    font-weight: 700;
  }

  /* 운전자 정보 */
  .select-driver {
    margin-top: 15px;
  }

  .driver-info {
    position: relative;
    border-top: 1px #eee solid;
  }

  .driver-info .txt-w6 {
    color: #333;
  }

  /* 자동차정보 */
  .srh-tag {
    margin-top: -10px;
  }

  .srh-tag .btn-chips-step {
    margin: 10px 10px 0 0;
  }

  .srh-step {
    margin-top: 40px;
  }

  .srh-step .step {
    display: block;
    font-size: 14px;
    color: #777;
  }

  .srh-step .step + .fx-wrap {
    margin-top: 0;
  }

  /* 검색으로 찾기 */
  .srh-result {
    position: relative;
    max-height: 305px;
    overflow: auto;
  }

  .srh-result::-webkit-scrollbar {
    width: 10px;
    height: 20px;
  }

  .srh-result::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
  }

  .srh-result::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #fff;
    border: 2px solid #ecebea;
  }

  .srh-result::-webkit-scrollbar-button {
    width: 20px;
    height: 10px;
  }

  .srh-result.no-result {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 305px;
    border-bottom: 1px #eee solid;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    -ms-flex-direction: column;
    -ms-flex-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .srh-result .result-list > li {
    min-height: 61px;
    border-bottom: 1px solid #eee;
  }

  .srh-result .result-list > li button {
    display: block;
    width: 100%;
    text-align: left;
    font-size: 20px;
    color: #111;
    padding: 16px 20px;
    background: #fff;
  }

  .srh-result .result-list > li button:hover {
    background: #fcfcfc;
  }

  .srh-result b {
    font-weight: 700;
  }

  .srh-result .result-list > li.no-result {
    height: initial;
    padding: 55px 20px;
    border-bottom: 1px solid #eee;
    text-align: center;
  }

  .box-dialog .srh-result {
    max-height: 183px;
  }
  /* 2020-01-21 검색으로 찾기 신규 */
  .result-list a {
    text-decoration: none;
    color: inherit;
  }
  .srh-result .result-list__cont {
    font-size: 15px;
    min-height: 61px;
    padding: 6px 10px 10px;
  }
  .result-cont-row:after {
    clear: both;
    display: block;
    content: "";
  }
  .result-cont-row {
    padding-top: 4px;
  }
  .result-cont-row .result-col {
    float: left;
  }
  .result-cont-row__right {
    float: right;
  }
  .result-cont-row__left {
    float: left;
  }
  .srh-result .result-color {
    color: #4275b7 !important;
  }
  .srh-result .result-color02 {
    color: #f05014 !important;
  }
  .result-flex-row {
    display: flex;
    flex-wrap: wrap;
  }
  .result-cont-row.result-flex-row:after {
    content: normal;
  }
  .result-flex-col {
    display: block;
    width: 68px;
  }
  .result-flex-last-col {
    margin-left: auto;
    text-align: right;
    width: 100px;
    overflow: hidden;
  }
  .result-eli-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .result-flex-col.result-long-text {
    width: 290px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .result-cont-detail {
    display: inline-block;
    overflow: hidden;
    max-width: 480px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  /* 박스형 선택 아코디언 full */
  .ui-acco.type-box {
    border: 0;
  }

  .ui-acco.type-box > .ui-acco-wrap {
    padding: 37px 40px;
    border: 1px solid #ddd;
  }

  .ui-acco.type-box > .ui-acco-wrap + .ui-acco-wrap {
    margin-top: 10px;
  }

  .ui-acco.type-box > .ui-acco-wrap > .ui-acco-pnl {
    padding: 0;
    margin-top: 35px;
    background: none;
  }

  .ui-acco.type-box.type-openacco > .ui-acco-wrap > .ui-acco-pnl {
    display: block;
  }

  .ui-acco.type-box > .ui-acco-wrap .lb-radio,
  .ui-acco.type-box > .ui-acco-wrap .lb-check {
    padding-top: 0;
  }

  .ui-acco.type-box > .ui-acco-wrap .lb-radio::before,
  .ui-acco.type-box > .ui-acco-wrap .lb-check::before {
    top: 2px;
  }

  .ui-acco.type-box > .ui-acco-wrap > .ui-acco-tit .tit {
    font-size: 22px;
  }

  .ui-acco.type-box > .ui-acco-wrap > .ui-acco-tit .lb-radio span,
  .ui-acco.type-box > .ui-acco-wrap > .ui-acco-tit .lb-check span {
    font-size: 22px;
  }

  .ui-acco.type-box > .ui-acco-wrap > .ui-acco-tit .lb-radio em {
    display: inline-block;
    color: #777;
    margin-top: 5px;
  }

  .ui-acco.type-box > .ui-acco-wrap .lb-radio span > .txt-sub {
    font-size: 14px;
    color: #777;
    margin-left: 6px;
  }

  .ui-acco.type-box > .ui-acco-wrap .lb-radio + .btn-tip,
  .ui-acco.type-box > .ui-acco-wrap .lb-check + .btn-tip {
    margin-top: -5px;
  }

  .ui-acco.type-box > .ui-acco-wrap .lb-check + .ui-tooltip-btn {
    margin-top: -2px;
  }

  .ui-acco.type-box .fx-wrap {
    margin-top: 0;
  }

  .ui-acco.type-box .box-price {
    padding: 20px 25px;
    background: #f7f7f7;
    border-radius: 4px;
    margin-top: 20px;
  }

  .ui-acco.type-box .box-price > div {
    font-size: 20px;
    line-height: inherit;
  }

  .ui-acco.type-box .box-price .txt-price > b {
    display: inline-block;
    font-size: 22px;
    margin-top: 4px;
  }

  .ui-acco.type-box .bul-dot .btn-tip {
    margin-top: -2px;
  }

  .ui-acco.type-box > .ui-acco-wrap .ui-acco-wrap + .ui-acco-wrap {
    margin-top: 20px;
  }

  .ui-acco.type-box > .ui-acco-wrap > .ui-acco-pnl .lb-check > span {
    font-size: 18px;
    font-weight: 700;
    line-height: inherit;
  }

  .ui-acco.type-box > .ui-acco-wrap > .ui-acco-pnl .box-base {
    border: 0;
    padding: 0;
    padding-left: 30px;
  }

  .ui-acco.type-box > .ui-acco-wrap > .ui-acco-pnl .box-base::after {
    content: "";
    display: block;
    clear: both;
  }

  .ui-acco.type-box > .ui-acco-wrap > .ui-acco-pnl .box-base > .fx-wrap {
    float: left;
    width: calc(100% - 220px);
    padding-left: 0;
  }

  .ui-acco.type-box > .ui-acco-wrap > .ui-acco-pnl .box-base > .box-price {
    float: right;
    width: 200px;
    margin-top: 15px;
  }

  .ui-acco.type-box
  > .ui-acco-wrap
  > .ui-acco-pnl
  .box-base
  > .box-price
  .fl-item-l,
  .ui-acco.type-box
  > .ui-acco-wrap
  > .ui-acco-pnl
  .box-base
  > .box-price
  .fl-item-r,
  .ui-acco.type-box > .ui-acco-wrap > .ui-acco-pnl .box-base > .box-price b {
    font-size: 16px;
  }

  .ui-acco.type-accident > .ui-acco-wrap {
    padding: 0;
  }

  .ui-acco.type-accident > .ui-acco-wrap > .ui-acco-tit {
    padding: 37px 40px;
  }

  .ui-acco.type-accident > .ui-acco-wrap > .ui-acco-pnl {
    background: #f7f7f7;
    margin-top: 0;
  }

  .ui-acco.type-accident > .ui-acco-wrap > .ui-acco-pnl .fx-wrap {
    padding: 37px 40px;
  }

  .ui-acco.type-accident > .ui-acco-wrap > .ui-acco-pnl .fx-item + .fx-item {
    margin-top: 25px;
  }

  .ui-acco.type-accident > .ui-acco-wrap > .ui-acco-pnl .lb-radio > span {
    font-size: 18px;
  }

  .ui-acco.type-accident > .ui-acco-wrap > .ui-acco-pnl .lb-radio > em {
    color: #777;
  }

  /* 자동차보험 보험료 확인 */
  .plan-btn {
    color: #ccc;
    letter-spacing: -0.05em;
    cursor: pointer;
  }

  .plan-select-item .plan-btn:hover .item-tit,
  .plan-select-item .plan-btn:focus .item-tit,
  .plan-select-item.selected .plan-btn .item-tit {
    color: #f05014;
  }

  .plan-select-item .plan-btn:hover .item-stit,
  .plan-select-item .plan-btn:focus .item-stit,
  .plan-select-item.selected .plan-btn .item-stit {
    color: #111;
  }

  .plan-select-item .plan-btn:hover .item-date,
  .plan-select-item .plan-btn:focus .item-date,
  .plan-select-item.selected .plan-btn .item-date {
    color: #777;
  }

  .plan-select-item .plan-btn:hover .item-date b,
  .plan-select-item .plan-btn:focus .item-date b,
  .plan-select-item.selected .item-date b {
    color: #111;
  }

  .plan-select-item .plan-btn + .btn-wrap > button {
    border-color: #ccc;
    pointer-events: none;
  }

  .plan-select-item .plan-btn:hover + .btn-wrap > button {
    border-color: #777;
  }

  .plan-select-item .plan-btn:hover + .btn-wrap > button > span {
    color: #111;
  }

  .plan-select-item.selected .plan-btn + .btn-wrap > button {
    border-color: #777;
    pointer-events: all;
  }

  .plan-select-item.selected .ui-select::after,
  .plan-select-item .plan-btn:hover .ui-select::after,
  .plan-select-item .plan-btn:focus .ui-select::after {
    background: #f05014;
  }

  .plan-select-item.selected .ui-select-btn,
  .plan-select-item .plan-btn:hover .ui-select-btn,
  .plan-select-item .plan-btn:focus .ui-select-btn {
    color: #111;
  }

  .scrolled .plan-select-item.selected .plan-btn .item-tit {
    float: left;
    margin-top: 11px;
  }

  .scrolled .plan-select-item.selected .plan-btn .item-stit-wrap {
    float: right;
  }

  .scrolled .type-floating .wrap-content.plan2 > .plan-select {
    height: 130px;
  }

  .scrolled .type-floating .wrap-content.plan3 > .plan-select {
    height: 150px;
  }

  .btn-base.type-select {
    position: relative;
    width: 100%;
    min-height: 51px;
    text-align: left;
    padding: 7px 20px 7px 10px;
    background: transparent;
    border-radius: 0;
    border: 0;
    border-bottom: 1px #777 solid;
    font-size: 22px;
    color: #111;
  }

  .btn-base.type-select::after {
    content: "";
    position: absolute;
    display: block;
    top: 50%;
    right: 10px;
    width: 0;
    height: 0;
    border: 5px transparent solid;
    border-top: 7px #333 solid;
    margin-top: -3px;
  }

  /* 청약내용 확인 */
  .box-base.type-conf {
    padding: 0;
  }

  .box-base.type-conf .box-base-head {
    position: relative;
    border-bottom: 1px #ddd solid;
    margin: 33px 40px 0;
    padding-bottom: 25px;
  }

  .box-base.type-conf .box-base-head::after {
    content: "";
    display: block;
    clear: both;
  }

  .box-base.type-conf .box-base-head .tit {
    float: left;
    font-size: 22px;
    font-weight: 400;
  }

  .box-base.type-conf .box-base-head .date {
    float: right;
    font-size: 22px;
  }

  .box-base.type-conf .box-base-item {
    padding: 25px 40px 40px;
  }

  .box-base.type-conf .box-base-cont {
    position: relative;
    background: #f7f7f7;
    padding: 37px 40px;
  }

  .box-base.type-conf .box-base-cont > ul > li {
    padding-bottom: 16px;
  }

  .box-base.type-conf .box-base-cont > ul > li:last-child {
    padding-bottom: 0;
  }

  .box-base.type-conf .box-base-cont .lb-check {
    padding-top: 0;
  }

  .box-base.type-conf .box-base-cont .lb-check::before {
    top: 3px;
  }

  .box-base.type-conf .box-base-cont .bul-etc > li {
    font-size: 14px;
    margin-top: 0;
  }

  /* 동일증권 관리 */
  .box-base.type-conf .fx-wrap {
    margin-top: 0;
  }

  .box-base.type-conf .fx-item {
    padding: 37px 40px;
  }

  .box-base.type-conf .field-inlabel > .lb-radio {
    margin: 0;
    padding: 0;
    padding-left: 30px;
    min-height: auto;
  }

  .box-base.type-conf .field-inlabel > .lb-radio::before {
    top: 3px;
  }

  .box-base.type-conf .field-inlabel > .lb-radio i {
    color: #777;
    font-size: 16px;
  }

  .box-base.type-conf .type-car {
    position: relative;
    padding: 36px 40px;
    background: #f7f7f7;
    border-bottom: 1px #ddd solid;
  }

  .box-base.type-conf .type-car:last-child {
    border-bottom: 0;
  }

  .box-base.type-conf .type-car .field-inlabel,
  .box-base.type-conf .type-car .lb-radio {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
  }

  .box-base.type-conf .type-car .lb-radio {
    z-index: 1;
  }

  .box-base.type-conf .type-car .lb-radio::before {
    position: absolute;
    top: 58px;
    left: 40px;
  }

  .box-base.type-conf .type-car .type-car-cont::after {
    content: "";
    display: block;
    clear: both;
  }

  .box-base.type-conf .type-car .type-car-cont .ico-car {
    float: left;
    position: relative;
    display: block;
    width: 120px;
    font-size: 18px;
    text-align: center;
    padding-left: 32px;
    color: #111;
  }

  .box-base.type-conf .type-car .type-car-cont .ico-car::before {
    content: "";
    position: relative;
    display: block;
    width: 48px;
    height: 48px;
    background: url("/static/images/content/ico_car@2x.png") no-repeat;
    background-size: 96px;
    margin: 0 auto;
    margin-top: -2px;
  }

  .box-base.type-conf .type-car .type-car-cont .multi::before {
    background-position: -48px 0;
  }

  .box-base.type-conf .type-car .type-car-cont + .type-car-cont {
    margin-top: 30px;
  }

  .box-base.type-conf .type-car .graph-bar {
    float: right;
    position: relative;
    display: block;
    width: calc(100% - 178px);
    height: 10px;
    border-radius: 10px;
    margin: 20px 12px 0;
  }

  .box-base.type-conf .type-car .graph-bar::after {
    content: "";
    display: block;
    clear: both;
  }

  .box-base.type-conf .type-car .graph-progress {
    float: right;
    position: relative;
    display: block;
    width: 100%;
    height: 10px;
    border-radius: 10px;
    background: #ddd;
  }

  .box-base.type-conf .type-car .graph-progress > span {
    position: relative;
    display: block;
    width: 24px;
    height: 24px;
    margin-top: -7px;
  }

  .box-base.type-conf .type-car .graph-progress > span::before {
    content: "";
    position: absolute;
    display: block;
    width: 24px;
    height: 24px;
    background: #bbb;
    border-radius: 24px;
  }

  .box-base.type-conf .type-car .graph-progress > span.graph-start {
    float: left;
    margin-left: -12px;
  }

  .box-base.type-conf .type-car .graph-progress > span.graph-end {
    float: right;
    margin-right: -12px;
  }

  .box-base.type-conf .type-car .graph-progress > span.graph-end::before {
    right: 0;
  }

  .box-base.type-conf .type-car .graph-progress > span > em {
    position: absolute;
    display: block;
    top: 25px;
    width: 80px;
    font-size: 12px;
    color: #777;
  }

  .box-base.type-conf .type-car .graph-progress > .graph-start > em {
    left: 0;
    text-align: left;
  }

  .box-base.type-conf .type-car .graph-progress > .graph-end > em {
    right: 0;
    text-align: right;
  }

  .box-base.type-conf .type-car .type-car-cont.equal .graph-progress {
    background: #ffc7a3;
  }

  .box-base.type-conf
  .type-car
  .type-car-cont.equal
  .graph-progress
  > span::before {
    background: #f05014;
  }

  .box-base.type-conf .type-car .type-car-cont .additional {
    position: relative;
    font-size: 14px;
    color: #111;
    text-align: right;
    margin: 0 0 6px 184px;
  }

  .box-base.type-conf .type-car .type-car-cont .additional + .graph-bar {
    margin-top: 10px;
  }

  .box-base.type-conf .type-car .type-car-cont .additional .badge {
    display: inline-block;
    font-size: 14px;
    color: #fff;
    line-height: 20px;
    padding: 0 15px;
    background: #777;
    border-radius: 20px;
    margin-right: 8px;
  }

  .box-base.type-conf .type-car .type-car-cont .additional em {
    color: #f26421;
  }

  /* 블랙박스 사진샘플 */
  .imgs-wrap {
    float: left;
    width: calc(50% - 10px);
    margin-left: 20px;
  }

  .imgs-wrap:nth-child(2n + 1) {
    margin-left: 0;
  }

  .imgs-wrap .img-box {
    position: relative;
    height: 170px;
    margin-top: 5px;
    background: #fff;
  }

  /* 자동차보험 사진등록 */
  .list-regfile .h5 {
    color: #333;
  }

  .list-regfile-head::after {
    content: "";
    display: block;
    clear: both;
  }

  .list-regfile-head .regfile-label {
    float: left;
  }

  .list-regfile-head .regfile-value {
    float: right;
    text-align: right;
  }

  .list-regfile-head .lb-radio {
    padding-top: 0;
  }

  .list-regfile-head .lb-radio::before {
    top: 2px;
  }

  .list-regfile-item + .list-regfile-item {
    margin-top: 40px;
  }

  .list-regfile-item .regfile-info {
    font-size: 14px;
    color: #777;
    margin-top: 5px;
  }

  .list-regfile-item .box-base.type-file::after {
    content: "";
    display: block;
    clear: both;
  }

  .list-regfile-item .box-base.type-file > div {
    float: left;
    position: relative;
    width: 265px;
  }

  .list-regfile-item .box-base.type-file > div + div {
    margin-left: 20px;
  }

  .list-regfile-item .box-base.type-file img {
    width: 265px;
    /*height:170px;*/
  }

  .list-regfile-item .box-base.type-file .add-label {
    word-break: keep-all;
  }

  .list-regfile-item .box-base.type-file .btn-del-circle {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .list-regfile-item .box-base.type-file .doc-file {
    width: 100%;
    background: #fff;
    padding: 16px 20px 20px;
    margin: 0;
  }

  .list-regfile-item .box-base.type-file .doc-file + .doc-file {
    margin-top: 10px;
  }

  .list-regfile-item .box-base.type-file .doc-file .btn-base-s {
    display: block;
    margin: 0 auto;
  }

  .list-regfile-item .box-base.type-file .doc-file .btn-del-circle {
    top: 2px;
    right: 0;
  }

  .list-regfile-item .box-base.type-file .doc-file .bul-care {
    margin-top: 0;
  }

  .list-regfile-item .box-base.type-file .doc-file .doc-wrap {
    border-top: 1px #eee solid;
    padding-top: 10px;
  }

  .list-regfile-item .box-base.type-file .doc-file .doc-wrap-pic {
    text-align: center;
  }

  .list-regfile-item
  .box-base.type-file
  .doc-file
  .doc-wrap-pic
  .btn-del-circle {
    top: 9px;
    right: 9px;
  }

  .list-regfile-item .box-base.type-file > .box-base {
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
  }

  .list-regfile-item .box-base.type-file > .box-base .inp-base {
    border-color: #777;
  }

  .list-regfile-item .box-base.type-file > .box-base .inp-base:focus {
    border-color: #f05014;
  }

  /* 자동차보험 E-Call 안심서비스 */
  .ecall-wrap {
    position: relative;
    display: table;
    width: 100%;
    border: 1px #ddd solid;
    padding: 30px 64px;
    margin-top: 15px;
  }

  .ecall-wrap .ecall-ico {
    position: relative;
    display: table-cell;
    width: 120px;
    height: 120px;
  }

  .ecall-wrap .ecall-ico span {
    position: relative;
    display: block;
    width: 120px;
    height: 120px;
    background: url("/static/images/content/ico_ecall.png") no-repeat;
  }

  .ecall-wrap .ecall-ico .ico-car {
    background-position: 0 0;
  }

  .ecall-wrap .ecall-ico .ico-symbol {
    background-position: -120px 0;
  }

  .ecall-wrap .ecall-cont {
    position: relative;
    display: table-cell;
    width: calc(100% - 240px);
    height: 120px;
    vertical-align: middle;
  }

  .ecall-wrap .ecall-cont > p {
    position: relative;
    display: block;
    width: 100%;
    font-size: 14px;
    text-align: center;
    color: #111;
  }

  .ecall-wrap .ecall-cont > p.txt {
    padding-bottom: 12px;
    margin-bottom: 14px;
  }

  .ecall-wrap .ecall-cont > p.txt::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 25px;
    width: calc(100% - 50px);
    height: 2px;
    background: #ddd;
  }

  .ecall-wrap .ecall-cont > p.txt::after {
    content: "";
    position: absolute;
    bottom: -7px;
    right: 16px;
    width: 0;
    height: 0;
    border: 8px transparent solid;
    border-left: 8px #ddd solid;
  }

  .ecall-wrap .ecall-cont > p.txt2 {
    padding-top: 12px;
    margin-top: 14px;
  }

  .ecall-wrap .ecall-cont > p.txt2::before {
    content: "";
    position: absolute;
    top: 0;
    left: 25px;
    width: calc(100% - 50px);
    height: 2px;
    background: #ddd;
  }

  .ecall-wrap .ecall-cont > p.txt2::after {
    content: "";
    position: absolute;
    top: -7px;
    left: 16px;
    width: 0;
    height: 0;
    border: 8px transparent solid;
    border-right: 8px #ddd solid;
  }

  .ecall-wrap .ecall-cont .ico-phone {
    position: relative;
    display: inline-block;
    width: 14px;
    height: 20px;
    background: url("/static/images/content/ico_phone.png") no-repeat;
    background-size: 100%;
    vertical-align: middle;
    margin-top: -2px;
    margin-right: 7px;
  }

  /* 나의계약 조회 */
  .caution-wrap,
  .total-price {
    position: relative;
  }

  .caution-wrap::after {
    content: "";
    display: block;
    clear: both;
  }

  .caution-wrap .item-l,
  .total-price .item-l {
    float: left;
    line-height: 1.3;
  }

  .total-price .item-l em {
    font-size: 14px;
    color: #777;
  }

  .caution-wrap .item-r,
  .total-price .item-r {
    float: right;
    text-align: right;
  }

  .total-price .item-r .type-toggle {
    margin-top: 10px;
  }

  .caution-wrap .item-r .btn-txt-arrow {
    position: relative;
    top: initial;
    right: initial;
    margin: 0;
    font-weight: 400;
  }

  .caution-wrap .item-r .btn-txt-arrow:hover {
    font-weight: 700;
  }

  .pic-wrap {
    position: relative;
  }

  .pic-wrap img {
    width: 100%;
  }

  /* 나의계약 > 스마트on > 펫 선택 20200427*/
  .box-base.swich-pet {
    overflow: hidden;
    padding: 20px 40px;
  }
  .swich-pet .photo-wrap {
    float: left;
  }
  .swich-pet .pet-photo {
    position: relative;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background: #fff;
    border: 1px solid #ddd;
  }
  .swich-pet .pet-photo img {
    width: 100%;
    border-radius: 50%;
  }
  .swich-pet .pet-photo .btn-register {
    position: absolute;
    display: block;
    font-size: 0;
    width: 35px;
    height: 35px;
    left: -1px;
    bottom: -1px;
    border-radius: 50%;
    background: #fff url(/static/images/content/ico_pet_photo_register.png)
    no-repeat 50% 50%;
    background-size: 15px auto;
    border: 1px solid #e5e5e5;
  }
  .swich-pet .pet-select-wrap {
    position: relative;
    float: left;
    width: calc(100% - 130px);
    padding-right: 30px;
  }
  .swich-pet .lb-check {
    width: 100%;
    padding: 0;
  }
  .swich-pet .lb-check:before {
    position: absolute;
    border-radius: 50%;
    background: #dcdcdc
    url("/static/images/common/ico_form.png")
    no-repeat -3px -3px;
    background-size: 150px;
    border-color: #dcdcdc;
    right: -30px;
    left: auto;
    top: 50%;
    transform: translate(0, -50%);
    margin: 0;
    width: 22px;
    height: 22px;
  }
  .swich-pet input:checked + .lb-check:before {
    background-position: -3px -3px;
  }
  .swich-pet .pet-info {
    padding-left: 60px;
  }
  .swich-pet .pet-info span {
    position: relative;
    display: block;
    font-size: 16px;
    padding: 2px 0 0 40px;
  }
  .swich-pet .pet-info span em {
    position: absolute;
    left: 0;
    top: 2px;
    font-weight: bold;
    display: inline-block;
    width: 40px;
  }
  .swich-pet .pet-name {
    display: block;
    font-size: 20px !important;
    font-weight: bold;
    color: #111;
    padding: 5px 0 15px 0 !important;
  }
  .swich-pet input:focus + .lb-check.pet-info > span.pet-name {
    text-decoration: underline;
  }
  .swich-pet input:focus + .lb-check.pet-info > span {
    text-decoration: none;
  }

  // 리스트
  .list-base-head.sub-txt {
    height: 40px;
  }

  .list-base-head.sub-txt .tit {
    float: none;
  }

  .list-base-head.sub-txt .txt {
    display: block;
    font-size: 14px;
    color: #777;
    margin-bottom: 15px;
  }

  .list-base-item .no-data {
    display: table;
    width: 100%;
    height: 178px;
  }

  .list-base-item .no-data p {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    color: #111;
  }

  .list-base-item .no-data b {
    font-weight: 700;
  }

  .line-t-n {
    border-top: none;
  }

  .txt-small {
    font-size: 14px !important;
    color: #777;
  }

  .txt .txt-small {
    display: block;
  }

  .bul-care .btn-txt.txt-w6 {
    font-size: 14px;
  }

  .bul-dot.spacing * {
    letter-spacing: -0.05em;
  }

  .tooltip-cont .tit-area > * {
    vertical-align: middle;
  }

  .tbl-base.type-s .no-data td {
    color: #777;
    height: 160px;
    text-align: center;
    vertical-align: middle;
  }

  .tbl-base.type-s .no-data td b {
    color: #111;
    font-weight: 700;
  }

  .tooltip-side .list-bank,
  .tooltip-side .list-bank .field-inlabel,
  .tooltip-side .list-bank fieldset {
    margin: 0;
    padding: 0;
  }

  .tooltip-side .list-bank {
    margin-top: -9px;
  }

  .tooltip-side .line-tit {
    margin: 40px 0 30px;
    padding-top: 35px;
    border-top: 1px solid #ddd;
  }

  .tooltip-side .lb-radio,
  .tooltip-side .lb-check {
    margin-right: 40px;
  }

  .tooltip-side .box-txt-g {
    background: #f7f7f7;
    padding: 15px 20px;
    margin-top: 30px;
  }

  .tooltip-side .box-txt-g label span {
    color: #777;
  }

  .tit-area.line-tit {
    overflow: hidden;
    margin-bottom: 27px;
  }

  .tit-area.line-tit .tit-cont {
    float: left;
    margin-top: 5px;
  }

  .tit-area.line-tit .btn-base {
    float: right;
  }

  .box-sort .tab-sort {
    vertical-align: bottom;
  }

  .box-sort .ui-select {
    width: 240px;
  }

  .tit-select .ui-select {
    width: 150px;
  }

  .tit-select .ui-select + .txt {
    display: inline-block;
    vertical-align: bottom;
    margin-left: 10px;
  }

  .box-inquiry .fx-item {
    width: 230px;
  }

  .box-inquiry .box-btn {
    width: calc(100% - 500px);
    margin-left: 20px;
    vertical-align: bottom;
  }

  .ui-acco.type-small .ui-acco-pnl * {
    font-size: 14px;
  }

  /* 보상서비스 - 메인 */
  .sub-main .base-body {
    max-width: 100%;
  }

  .sub-main .base-body .wrap-inner {
    width: 100%;
  }

  .sub-main-intro {
    background: #f7f7f7;
  }

  .sub-main-intro .tit-wrap,
  .sub-main-list {
    width: 610px;
    margin: 0 auto;
  }

  .sub-main-intro .tit-wrap {
    padding: 50px 0 130px;
    background: #f7f7f7;
    z-index: 0;
  }

  .sub-main-intro.min .tit-wrap {
    padding-bottom: 84px;
  }

  .sub-main-intro .h1 {
    line-height: 1;
  }

  .sub-main-intro .txt {
    color: #555;
    margin-top: 25px;
  }

  .sub-main-intro .btn-txt-arrow span,
  .sub-main-intro .btn-txt-arrow::after {
    vertical-align: middle;
  }

  .sub-main-intro .btn-txt-arrow span {
    padding-left: 0;
    font-size: 16px;
    color: #333;
  }

  .sub-main-intro .btn-wrap {
    margin: 13px 0 50px;
  }

  .sub-main-list .box-list-wrap {
    margin-top: -84px;
  }

  .sub-main-list .box-list-wrap {
    margin-bottom: 40px;
  }

  /* 보상서비스 - 사고접수 */
  .h4.tit-mgt-m {
    margin-top: 60px;
    color: #111;
  }

  .box-tit-check {
    position: relative;
  }

  .box-check {
    position: absolute;
    right: 0;
  }

  .box-check-thumb label {
    position: relative;
    margin-right: 0;
    margin-left: 19px;
  }

  .box-check-thumb input:first-child + label {
    margin-left: 0;
  }

  .box-check-thumb label span {
    text-decoration: none !important;
  }

  .box-check-thumb label span::after {
    display: block;
    width: 136px;
    height: 98px;
    margin: 20px 0 0 -30px;
    border: 1px solid #777;
    border-radius: 4px;
    content: "";
  }

  .box-check-thumb label span::before {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    display: block;
    width: 120px;
    height: 60px;
    margin: 0 auto;
    background: url(/static/images/content/ico_accident.png) no-repeat;
    content: "";
  }

  .box-check-thumb label.label2 span::before {
    background-position: -120px 0;
  }

  .box-check-thumb label.label3 span::before {
    background-position: 0 -60px;
  }

  .box-check-thumb label.label4 span::before {
    background-position: -120px -60px;
  }

  .box-check-thumb input:checked + .lb-radio span::after,
  .box-check-thumb .lb-radio.checked span::after {
    border-color: #f05014;
  }

  .txt-check-result {
    display: block;
    margin: 13px 0 5px;
    color: #777;
  }

  .txt-bn .btn-txt-arrow.txt-small {
    font-weight: 400;
    font-size: 14px;
    color: #111;
  }

  /* 사고처리 결과 조회 */
  .tbl-noline.tbl-accident {
    border-top: 1px #bbb solid;
    border-bottom: 1px solid #ddd;
    padding: 22px 20px 20px;
  }

  .tbl-noline.tbl-accident th,
  .tbl-noline.tbl-accident td {
    padding: 10px 0;
  }

  /* 보상서비스 - 증빙서류 */
  .type-plan.box-btn-sel button {
    border: none;
  }

  .type-plan.box-btn-sel .ui-select-btn {
    display: none;
  }

  .type-plan.box-btn-sel .btn-select {
    width: 100%;
    font-size: 24px;
    font-weight: 700;
    color: #111;
  }

  /* 마이페이지 - 금융거래 10계명 */
  .bul-etc.type-b li + li {
    margin-top: 30px;
  }

  .bul-etc.type-b .bul-ico,
  .bul-etc.type-b .tit {
    font-size: 18px;
  }

  /* 소비자 포털 */
  .list-ico {
    overflow: hidden;
  }

  .list-ico li {
    width: calc(33.3% - 15px);
    float: left;
    text-align: center;
  }

  .list-ico.circle li {
    width: calc(25% - 15px);
    float: left;
    text-align: center;
  }

  .list-ico li + li {
    margin-left: 20px;
  }

  .list-ico .btn-txt {
    font-size: 14px;
    position: relative;
    border-color: transparent;
  }

  .list-ico .btn-txt:hover {
    border-color: #f05014;
  }

  .list-ico .btn-txt::before,
  .list-ico .btn-txt::after {
    content: "";
    display: block;
    margin: 0 auto;
  }

  .box-radius {
    border: 1px solid #ddd;
    border-radius: 7px;
    padding: 40px 0;
    text-align: center;
    position: relative;
  }

  .list-group {
    width: 568px;
    margin: 0 auto;
    position: relative;
  }

  .list-group::after {
    display: block;
    position: absolute;
    top: 48px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 1px;
    height: 170px;
    background: #ddd;
    content: "";
    z-index: -1;
  }

  .list-group::before {
    display: block;
    position: absolute;
    top: 218px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 300px;
    height: 1px;
    background: #ddd;
    content: "";
    z-index: -1;
  }

  .list-group .bx {
    display: inline-block;
    border-radius: 3px;
    width: 269px;
    height: 48px;
    line-height: 48px;
    background: #777;
    color: #fff;
    margin-bottom: 30px;
  }

  .list-group .bx.first {
    background: #333;
  }

  .list-group .bx.second {
    margin-bottom: 15px;
  }

  .list-group .last-group > div {
    width: calc(50% - 15px);
  }

  .list-group dl {
    position: relative;
    border: 1px solid #ddd;
    margin-top: 30px;
    border-radius: 3px;
  }

  .list-group dl::after {
    display: block;
    width: 1px;
    height: 31px;
    position: absolute;
    top: -31px;
    left: 0;
    right: 0;
    margin: 0 auto;
    background: #ddd;
    content: "";
  }

  .list-group dt {
    background: #f7f7f7;
    border-bottom: 1px solid #ddd;
    height: 46px;
    padding-top: 11px;
    box-sizing: border-box;
  }

  .list-group dd {
    padding: 25px 0;
  }

  .tit-diagram {
    font-size: 24px;
    font-weight: 700;
    position: absolute;
    top: 45%;
    left: 0;
    right: 0;
    margin: auto;
    line-height: 30px;
  }

  .tit-diagram span {
    display: block;
    font-size: 20px;
    font-weight: 400;
  }

  .list-diagram {
    width: 407px;
    height: 400px;
    margin: 55px auto;
    position: relative;
    background: url(/static/images/content/bg_diagram.png) no-repeat;
  }

  .list-diagram li {
    width: 125px;
    height: 125px;
    border-radius: 50%;
    background: #f7f7f7;
    position: absolute;
  }

  .list-diagram .item1,
  .list-diagram .item4 {
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .list-diagram .item1 {
    top: -55px;
  }

  .list-diagram .item4 {
    bottom: -55px;
  }

  .list-diagram .item2,
  .list-diagram .item3 {
    right: -25px;
  }

  .list-diagram .item5,
  .list-diagram .item6 {
    left: -25px;
  }

  .list-diagram .item2,
  .list-diagram .item6 {
    top: 42px;
  }

  .list-diagram .item3,
  .list-diagram .item5 {
    bottom: 42px;
  }

  .list-diagram li > div {
    position: relative;
    width: 125px;
    height: 125px;
    padding-top: 40px;
    text-align: center;
  }

  .list-diagram li * {
    font-weight: 700;
    font-size: 14px;
  }

  .list-diagram .tit {
    display: block;
    font-size: 18px;
    color: #f05014;
  }

  .list-diagram .sub-tit {
    color: #bbb;
  }

  .list-diagram .txt {
    color: #777;
    position: absolute;
  }

  .list-diagram .txt span {
    display: block;
    position: relative;
    padding-left: 50px;
  }

  .list-diagram .txt span::before,
  .list-diagram .txt span::after {
    content: "";
    display: block;
    background: #999;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto 0;
  }

  .list-diagram .txt span::before {
    width: 45px;
    height: 1px;
  }

  .list-diagram .txt span::after {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }

  .list-diagram .right span {
    padding-left: 0;
    padding-right: 50px;
  }

  .list-diagram .right span::before,
  .list-diagram .right span::after {
    right: 0;
    left: auto;
  }

  .list-diagram .item1 .txt {
    bottom: 38px;
    left: 150px;
  }

  .list-diagram .item2 .txt {
    bottom: -40px;
    left: 92px;
  }

  .list-diagram .item3 .txt {
    bottom: -28px;
    left: -17px;
  }

  .list-diagram .item4 .txt {
    bottom: 68px;
    right: 146px;
  }

  .list-diagram .item5 .txt {
    width: 115px;
    bottom: auto;
    top: -47px;
    right: 91px;
  }

  .list-diagram .item6 .txt {
    bottom: auto;
    top: -31px;
    right: -19px;
  }

  /* 소비자보호사례 */
  .tbl-board.type-a th::before {
    display: none;
  }

  .box-radius.gray {
    background: #f7f7f7;
    border: none;
  }

  .box-radius .step-basic.step6 {
    border: none;
    margin: 0;
    padding: 0;
  }

  .box-radius .step-basic.step6 .item-circle {
    width: 90px;
    height: 90px;
    font-size: 22px;
    color: #555;
    line-height: 90px;
  }

  .box-radius .step-basic.step6 .item-circle::after {
    left: -56px;
  }

  .box-radius .step-basic.step6 .txt {
    color: #555;
  }

  .list-circle {
    overflow: hidden;
  }

  .list-circle li {
    position: relative;
    width: 153px;
    height: 153px;
    padding-top: 67px;
    border-radius: 50%;
    background: #fff;
    font-weight: 700;
    margin: 0 20px;
    float: left;
    line-height: 20px;
  }

  .list-circle li::after {
    display: block;
    width: 18px;
    height: 15px;
    background: url(/static/images/content/ico_check.png) no-repeat;
    content: "";
    position: absolute;
    top: 35px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  /* 금융정보 */
  .box-gray {
    background: #f7f7f7;
    padding: 40px 0;
  }

  .box-gray.busi {
    padding: 38px 40px;
    margin: 40px -40px 0;
  }

  .box-gray.busi .lb-check {
    padding-top: 0;
  }

  .box-gray.busi .lb-check::before {
    top: 2px;
  }

  .list-ico.circle li:nth-child(5) {
    margin-left: 0;
  }

  .list-ico.circle li:nth-child(-n + 4) {
    margin-bottom: 60px;
  }

  .list-ico.circle .btn-txt {
    font-weight: 700;
  }

  .list-ico.circle .btn-txt span {
    margin-top: 17px;
  }

  .list-ico.circle .btn-txt::before {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background: #eee;
  }

  .list-ico.circle .btn-txt::after {
    background: url(/static/images/content/ico_consumer.png) no-repeat;
    width: 60px;
    height: 60px;
    position: absolute;
    top: 15px;
    left: 0;
    right: 0;
  }

  .list-ico.circle .item2 .btn-txt::after {
    background-position: -60px 0;
  }

  .list-ico.circle .item3 .btn-txt::after {
    background-position: -120px 0;
  }

  .list-ico.circle .item4 .btn-txt::after {
    background-position: -180px 0;
  }

  .list-ico.circle .item5 .btn-txt::after {
    background-position: -240px 0;
  }

  .list-ico.circle .item6 .btn-txt::after {
    background-position: 0 -60px;
  }

  .list-ico.circle .item7 .btn-txt::after {
    background-position: -60px -60px;
  }

  .list-ico.circle .item8 .btn-txt::after {
    background-position: -120px -60px;
  }

  .ui-acco.type-ellp .ui-acco-tit .ellp {
    width: 95%;
  }

  .ui-acco.type-ellp .no-data {
    width: 100%;
    border-bottom: 1px solid #eee;
    display: table;
    height: 120px;
  }

  .ui-acco.type-ellp .no-data > * {
    display: table-cell;
    color: #777;
    vertical-align: middle;
    text-align: center;
  }

  .box-diagram {
    width: 100%;
    height: 460px;
    margin-top: 40px;
    background: url(/static/images/content/img_diagram_1.gif) no-repeat 50%
    102px;
  }

  .box-diagram .in {
    @include txt-hide();
  }

  /* 소비자보호사례 */
  .list-base-item.type-ico {
    padding: 50px 0 32px;
  }

  .list-ico.big .btn-txt::before {
    width: 77px;
    height: 67px;
    background: url(/static/images/content/ico_consumer.png) no-repeat 0 -120px;
    margin-bottom: 25px;
  }

  .list-ico.big .item2 .btn-txt::before {
    background-position: -120px -120px;
    width: 90px;
  }

  .list-ico.big .item3 .btn-txt::before {
    background-position: 0 -236px;
    width: 54px;
  }

  .list-ico.big .item4 .btn-txt::before {
    background-position: -60px -236px;
    width: 69px;
  }

  .btn-wrap.btn-ps-a {
    position: relative;
    margin: 0;
  }

  .btn-wrap.btn-ps-a .btn-base {
    position: absolute;
    top: -50px;
    right: 0;
  }

  .tbl-board.type-a .last-th {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
  }

  .step-basic.type-big {
    border-radius: 7px;
  }

  .step-basic.type-big .item-circle {
    width: 90px;
    height: 90px;
    font-size: 24px;
    font-weight: 700;
    line-height: 90px;
    color: #555;
  }

  .step-basic.type-big .item-circle::after {
    width: 33px;
    left: -75px;
  }

  .step-basic.type-big .txt {
    margin-top: 16px;
    color: #555;
  }

  .tbl-board.txt-spac td {
    letter-spacing: -0.05em;
  }

  /* 민원처리 */
  .box-col .fl-item-l {
    position: relative;
    width: calc(50% - 13px);
    min-height: 297px;
    padding: 40px 10px 40px 30px;
    border: 1px solid #ddd;
  }

  .box-col .fl-item-l + .fl-item-l {
    margin-left: 20px;
  }

  .box-col .tit-sub {
    margin-top: 25px;
  }

  .box-col .box-foot {
    position: absolute;
    bottom: 40px;
    left: 30px;
  }

  .box-col .box-foot .btn-base {
    padding: 0 15px;
  }

  .box-col .box-foot .btn-base + .btn-base {
    margin-left: 10px;
  }

  /* 기업보험 */
  .tit-wrap .h1 + .txt-busi {
    font-size: 20px;
    color: #555;
    line-height: 28px;
    margin-top: 3px;
  }

  .fx-item.box-g6-3 {
    margin-top: 8px;
  }

  .fx-item.box-g6-3 .g6-3 {
    padding: 0;
    vertical-align: middle;
  }

  .state-busi > * {
    display: inline-block;
    position: relative;
  }

  .state-busi .state {
    font-weight: 700;
  }

  .state-busi .type-a {
    color: #f05014;
  }

  .state-busi .type-b {
    color: #00358e;
  }

  .state-busi .number {
    margin-left: 10px;
    padding-left: 10px;
  }

  .state-busi .number::before {
    @include bar-border($color: #ccc);
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto 0;
  }

  .total-price .tit {
    display: inline-block;
  }

  .total-price {
    overflow: visible !important;
  }

  .total-price:after {
    content: "";
    display: block;
    clear: both;
  }

  /* 보험 상품 공시 */
  .insure-info-wrap {
    position: relative;
  }

  .insure-info-wrap::after {
    content: "";
    display: block;
    clear: both;
  }

  .insure-info-wrap > .insure-info-item {
    float: left;
    position: relative;
    width: calc(25% - 15px);
  }

  .insure-info-wrap > .insure-info-item + .insure-info-item {
    margin-left: 20px;
  }

  .insure-info-item > .ico-insure {
    position: relative;
    display: block;
    width: 100px;
    height: 100px;
    text-align: center;
    font-size: 14px;
    color: #999;
    background: #f7f7f7;
    border-radius: 100px;
  }

  .insure-info-item > p {
    font-size: 14px;
    color: #777;
  }

  /* 코딩 가이드 및 퍼블 파일에서 사용 */
  .box-map-accident {
    width: 610px;
    height: 480px;
    background: #ddd;
  }

  .ico-plus {
    display: block;
    width: 20px;
    height: 20px;
    border: 1px solid #f26826;
    border-radius: 10px;
    background: #fff
    url("/static/images/common/ico_plusminus.png")
    no-repeat -1px -1px;
  }

  .ico-minus {
    display: block;
    width: 20px;
    height: 20px;
    border: 1px solid #f26826;
    border-radius: 10px;
    background: #fff
    url("/static/images/common/ico_plusminus.png")
    no-repeat -21px -1px;
  }

  /* 채용공고 */
  .emp-wrap {
    width: 100%;
    overflow: hidden;
  }

  .emp-tit {
    float: left;
    font-size: 32px;
    font-weight: 600;
  }

  .emp-cont {
    float: right;
    width: 580px;
  }

  .type-b .emp-cont {
    float: left;
    width: 100%;
  }

  .emp-cont p {
    font-size: 15px;
    line-height: 2;
    margin: 0;
  }

  .emp-cont h3 {
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 10px;
  }

  .emp-cont .bul-star {
    font-size: 13px;
    margin: 15px 0;
  }

  .emp-wrap + .emp-wrap {
    margin-top: 120px;
  }

  .list-well strong {
    font-weight: 600;
    margin-bottom: 5px;
  }

  .list-well li {
    margin-bottom: 20px;
  }

  .list-well .bul-hyphen li:before,
  .list-well .bul-hyphen dd:before,
  .list-well p.bul-hyphen:before,
  .list-well dt.bul-hyphen::before {
    top: 7px;
  }

  .list-well p {
    margin-left: 15px;
  }

  /* 가입정보입력 라디오박스 */
  .type-lb-insu .lb-radio {
    display: block;
    width: 100%;
    margin-top: 20px;
    padding: 37px 70px 34px;
    border: 1px solid #ddd;
  }

  .type-lb-insu .lb-radio:before {
    top: 38px;
    left: 40px;
  }

  .type-lb-insu .lb-radio strong {
    display: block;
    margin-bottom: 20px;
    font-size: 22px;
  }

  .type-lb-insu .lb-radio span em {
    display: inline-block;
    width: 98px;
    font-size: 14px;
    color: #777;
    margin-right: 10px;
  }

  .type-lb-insu .lb-radio span i {
    color: #333;
  }

  .type-lb-insu .lb-radio strong > i {
    color: #f05014;
    margin-left: 7px;
  }

  .type-lb-insu .lb-radio:hover {
    background-color: #fbfbfb;
  }

  .type-lb-insu fieldset {
    margin-top: 0;
  }

  /* tmap width */
  .box-map {
    width: 100% !important;
  }

  /* 보험상품별 css */

  // 상품소개 화면 이미지
  .intro-insu.type-overseas .intro-left .intro-insu-item .insu-cont::after {
    background-image: url("/static/images/main/main_a5_v2_d.png");
    background-position: 284px 100%;
  }

  // 상품소개 화면 이미지
  .intro-insu.type-global .intro-left .intro-insu-item .insu-cont::before {
    content: "";
    position: absolute;
    display: block;
    right: 15px;
    top: -31px;
    width: 155px;
    height: 101px;
    background: url("/static/images/main/main_a6_v2_d.png") no-repeat;
    z-index: 2;
  }

  // 상품소개 화면 이미지
  .intro-insu.type-overseasSwitch
  .intro-left
  .intro-insu-item
  .insu-cont::after {
    background-image: url("/static/images/main/insumain_img_switchoversea.png");
    background-position: 212px 100%;
  }

  // 우리말서비스
  .tel-service {
    .service-text {
      &.bul-dot.hue-p:before {
        background: #f05014;
      }
    }
  }
  .btn-ico-tel {
    display: inline-block;
    height: 40px;
    width: 100%;
    padding: 11px 8px 0;
    border: 1px solid #000;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    background: #fff;
    vertical-align: middle;
    color: #000;
    line-height: 1;
    border-radius: 4px;
    letter-spacing: -0.03em;
    &:before {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 8px;
      margin-top: -1px;
      background: url("/static/images/common/ico_tel.png")
      0 0 no-repeat;
      background-size: 100%;
      vertical-align: middle;
    }
  }

  // 상품소개 화면 이미지
  .intro-insu.type-pet .intro-left .intro-insu-item .insu-cont::after {
    background-image: url("/static/images/main/insumain_img_pet.png");
    background-position: 284px 100%;
  }

  // 상품소개 화면 이미지
  .intro-insu.type-leisure .intro-left .intro-insu-item .insu-cont::after {
    background-image: url("/static/images/main/insumain_img_leisure.png");
    background-position: 250px 89%;
  }

  // 상품소개 화면 이미지
  .intro-insu.type-driver .intro-left .intro-insu-item .insu-cont::after {
    background-image: url("/static/images/main/main_a10_v2_d.png");
    background-position: 266px 100%;
  }

  // 상품소개 화면 이미지
  .intro-insu.type-domestic .intro-left .intro-insu-item .insu-cont::after {
    background-image: url("/static/images/main/main_a9_v2_d.png");
    background-position: 224px 100%;
  }

  // 상품소개 화면 이미지
  .intro-insu.type-multi .intro-left .intro-insu-item .insu-cont::after {
    background-image: url("/static/images/main/main_a7_v2_d.png");
    background-position: 330px 100%;
  }

  // 상품소개 화면 오른쪽 영역
  .type-multi .intro-right .fx-wrap.type-col .label {
    width: 114px;
  }

  .type-multi .intro-right .type-col > .type-term {
    float: left;
    width: calc(100% - 74px);
  }

  .type-multi .intro-right .type-col > .type-term .fx-item.short {
    top: 13px;
  }

  .type-multi .intro-right .type-col .inp-datepicker [class*="inp-"] {
    padding-right: 50px !important;
  }

  .type-multi .intro-right .type-col .field-inlabel [class*="inp-"]:disabled {
    color: #fff !important;
    border-color: #cacaca !important;
    background-color: transparent !important;
    -webkit-text-fill-color: initial !important;
  }

  .type-multi .intro-right .type-col .inp-datepicker .ui-datepicker-btn > i {
    background-position: 0 -75px;
  }

  .type-multi .intro-right .btn-base.w-full {
    width: 185px !important;
    border-radius: 40px;
  }

  // 상품소개 화면 이미지
  .intro-insu.type-event .intro-left .intro-insu-item .insu-cont::after {
    background-image: url("/static/images/main/insumain_img_event.png");
    background-position: 159px 100%;
  }

  // 상품소개 화면 오른쪽 영역
  .type-event .intro-right .type-col.join .number {
    width: 186px;
  }

  .type-event .intro-right .type-col.join .text {
    width: 164px;
  }

  /* 퍼마일 특약 정보 모달 , 예상 보험료 모달 관련 */
  .box-base .btn-wrap.btn-wrap--insurance {
    margin-top: 12px;
  }
  .btn-wrap--insurance .btn-base {
    width: 49%;
  }
  // 퍼마일 스텝3번 수정 팝업 수정
  #Step3_findCarInfo_POP > div {
    position: relative;
  }
  #Step3_findCarInfo_POP > div > .box-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 75%;
  }
  #Step3_findCarInfo_POP .step3_findCarInfo_pop {
    height: 100%;
  }
  .step3_findCarInfo_pop > .box-header {
    height: 142px;
  }
  .step3_findCarInfo_pop .box-content.box-scroller {
    max-height: calc(100% - 260px);
    overflow-y: auto !important;
  }
  .step3_findCarInfo_pop .ui-tab-pnls .gmjhEE .box-wrap .box-scroller {
  }
  .step3_findCarInfo_pop .box-footer {
    padding: 30px 40px 30px !important;
    height: 118px;
  }
  // 퍼마일  스크롤팝업 하이트 수정
  .permaileInfo-pop.box-scroller {
    max-height: 440px;
  }
  .permile-month > li {
    margin-top: 25px;
  }
  .permile-month > li:first-child {
    margin-top: 20px;
  }
  .permile-month__img {
    display: inline-block;
    width: 23%;
    padding: 0 5px;
    text-align: center;
    vertical-align: middle;
  }
  .permile-month__tex {
    display: inline-block;
    width: 76%;
    padding-left: 5px;
    vertical-align: middle;
    font-size: 17px;
    color: #000;
  }
  .permile-month__tex strong {
    font-weight: 700;
  }
  .box-content__img {
    margin-top: 40px;
    text-align: center;
  }

  // 예상 보험료 모달
  .price-info-item {
    position: relative;
    padding-bottom: 40px;
  }
  .price-info-item--bg:before {
    content: "";
    position: absolute;
    top: 60px;
    bottom: 0;
    left: 30px;
    width: 1px;
    background: #ddd;
  }
  .price-info-item--bg:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 27px;
    width: 7px;
    height: 9px;
    background: url(/static/images/content/bg_price_info_list.png) 0 0 no-repeat;
  }
  .price-info-item .bul-care,
  .price-info-item .bul-dot,
  .price-info-item .box-base {
    margin-left: 76px;
    width: auto;
  }
  .tit-wrap-type2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .tit-wrap-type2 .ico-tit {
    overflow: hidden;
    position: relative;
    display: inline-block;
    width: 60px;
    height: 60px;
    background: #ed5b16;
    border-radius: 100%;
  }
  .tit-wrap-type2 .ico-tit > span {
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translateY(-50%);
    width: 100%;
    max-height: 32px;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    padding: 0 8px;
    text-align: center;
    word-break: break-all;
    line-height: 16px;
  }
  .tit-wrap-type2 .h1-type2 {
    display: inline-block;
    font-size: 22px;
    color: #111;
    font-weight: 500;
  }
  .tit-wrap-type2 .h1-type2:after {
    clear: both;
    content: "";
    display: block;
  }
  .tit-wrap-type2 .ico-tit + .h1-type2 {
    width: calc(100% - 78px);
    padding-top: 14px;
  }
  .tit-wrap-type2 .h1-type2 > strong {
    display: block;
    font-weight: bold;
    float: left;
  }
  .h1-type2 .h1-type__price {
    float: right;
  }
  .h1-type__price {
    font-size: 19px;
    display: block;
    margin-top: -6px;
  }
  .h1-type__price i {
    font-weight: bold;
    font-size: 27px;
    color: #ed5b16;
  }
  .tit-wrap-type2 + .box-base {
    margin-top: 36px;
  }
  .price-info-item .box-base {
    padding-top: 27px;
    padding-bottom: 26px;
  }
  .box-base + .tit-wrap-type2 {
    margin-top: 50px;
  }
  .box-base + p.bul-care {
    margin-top: 14px;
  }
  .info-lst {
    font-size: 16px;
  }
  .info-lst > li {
    margin-top: 20px;
  }
  .info-lst > li:first-child {
    margin-top: 0;
  }
  .box-base .info-lst {
    color: #555;
  }
  .info-lst__tit {
    font-size: 16px;
    font-weight: 700;
    color: #111;
  }
  .info-lst__tit strong {
    color: #ed5b16;
    font-weight: 700;
  }
  .info-lst__tit span {
    display: inline-block;
    vertical-align: top;
  }
  .info-lst__tit span em {
    color: #555;
    font-size: 18px;
    font-weight: normal;
  }
  .info-lst__tit + p {
    margin-top: 9px;
  }

  .tit-wrap-type3 {
    margin-top: 50px;
    padding: 22px 0 20px;
    border-top: 1px solid #ddd;
  }
  .tit-wrap-type3 + .box-base {
    margin-top: 0;
  }
  .tit-wrap-type3 .h1-type3 {
    font-size: 20px;
    font-weight: bold;
    color: #111;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .h1-type3 > strong {
    padding-top: 2px;
    font-weight: bold;
  }
  .item-stit .item-stit__price {
    color: #f05014;
    font-weight: 700;
  }

  //테이블 내 상세 박스
  .table-detail-box {
    border-top: 1px dashed #ddd;
    margin-top: 12px;
    padding-top: 10px;
    margin-bottom: 10px;
  }
  .table-detail-box > p {
    margin-top: 6px;
    display: flex;
    flex-wrap: wrap;
  }

  .table-detail-box__tit {
    display: inline-block;
    padding-right: 2px;
    font-weight: 500;
    color: #111;
    max-width: 88px;
    min-width: 34px;
  }
  .table-detail-box__txt {
    padding-left: 4px;
  }
  // 상품내용 팝업 신규
  .txt-bold {
    font-weight: 700;
  }
  .hue-p {
    color: var(--carrot-primary-color, #f05014);
  }
  .ui-acco-pnl.ui-acco-pnl--goodsinfo {
    background-color: #fff;
  }
  .ui-acco-pnl .intro-desc {
    margin-top: 15px;
    padding: 16px 0 8px;
    font-weight: normal;
  }
  .word-break {
    word-break: break-all;
  }
  .intro-desc strong {
    font-weight: 700;
  }
  .ui-acco-pnl .intro-desc > li {
    margin-top: 8px;
  }
  .intro-desc > li:first-child {
    margin-top: 0;
  }
  .intro-title .intro-title__desc-lst strong {
    font-weight: 700;
  }
  .ui-acco-pnl .reminder-box {
    margin-top: 30px;
  }
  .reminder-box {
    margin-top: 30px;
    border: 1px solid #c9c9c9;
    padding: 20px 20px;
  }
  .reminder-box__img {
    width: 148px;
    float: right;
  }
  .reminder-box__img img {
    width: 100%;
  }
  .reminder-box__txt {
    font-size: 15px;
  }
  .reminder-box__tit {
    font-size: 18px;
    display: block;
    margin-bottom: 4px;
  }
  .reminder-box__txt strong {
    font-weight: 700;
    color: #000;
  }
  .img_premium_refer {
    margin-top: 30px;
  }
  .img_premium_refer img {
    width: 100%;
  }
  .recommend-lst {
    border-top: 1px solid #d8d8d8;
    padding-top: 20px;
  }
  .recommend-lst > li {
    margin-top: 15px;
  }
  .recommend-lst__left,
  .recommend-lst__right {
    display: inline-block;
    vertical-align: middle;
    position: relative;
  }
  .recommend-lst__left {
    width: 100px;
  }
  .recommend-lst__left:after {
    position: absolute;
    top: 50%;
    left: 60px;
    transform: translateY(-50%);
    width: 36px;
    height: 1px;
    border-bottom: 1px dashed #f05014;
    content: "";
  }
  .recommend-lst__right {
    width: calc(100% - 105px);
    padding-left: 6px;
    color: #000;
  }
  .recommend-lst__tit {
    position: relative;
    overflow: hidden;
    display: flex;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    background: #f05014;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
  }

  .recommend-lst__tit span {
    font-size: 16px;
    color: #fff;
    width: 100%;
  }
  .table-right-desc {
    margin-top: 10px;
    padding-bottom: 6px;
    text-align: right;
    font-size: 14px;
  }
  .table-right-desc + .tbl-base {
    margin-top: 0;
  }
  //퍼마일 보험료 예시 하단 테이블
  .tbl-base.type-a.tbl-base--car-premium table tbody tr th {
    font-size: 15px;
    background: #f4f4f4;
    border-color: #ddd;
  }
  th.th-bg-gray,
  td.td-bg-gray {
    background: #f4f4f4 !important;
  }

  .tbl-base.type-a.tbl-base--car-premium table tbody tr td {
    font-size: 13px;
    vertical-align: middle;
    border-color: #ddd;
  }
  strong.hue-p {
    font-weight: 700;
  }
  //퍼마일 스텝3번페이지 관련 수정
  .calcula-mr-minusbox {
    margin-right: -26px;
  }
  .field-inlabel__notice-step5-push {
    margin-top: 4px;
    font-size: 14px;
  }

  // k-car 제휴 페이지 (full-page)
  .wrap-content.full {
    height: calc(100vh - 80px);
    position: relative;
  }
  .wrap-content.full {
    .wrap-scroll {
      position: absolute;
      bottom: 6vh;
      left: 50%;
      height: 80px;
      transform: translateX(-50%);
      margin: 0 auto;
    }
    .wrap-scroll span {
      display: block;
      height: 34px;
      line-height: 34px;
      padding: 0 18px;
      background: #f05014;
      font-size: 15px;
      color: #fff;
      border-radius: 30px;
    }
    .wrap-scroll span::after {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      border-top: 5px solid #f05014;
      border-right: 5px solid #f05014;
      transform: rotate(135deg);
      margin: 15px auto 35px;
    }
  }

  .flex-row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    align-items: baseline;
  }
  .flex-row + .flex-row {
    margin-top: 14px;
  }
  .flex-col-12 {
    width: 100%;
  }
  .flex-col-11 {
    width: calc(100% / 12 * 11);
  }
  .flex-col-10 {
    width: calc(100% / 12 * 10);
  }
  .flex-col-9 {
    width: calc(100% / 12 * 9);
  }
  .flex-col-8 {
    width: calc(100% / 12 * 8);
  }
  .flex-col-7 {
    width: calc(100% / 12 * 7);
  }
  .flex-col-6 {
    width: calc(100% / 12 * 6);
  }
  .flex-col-5 {
    width: calc(100% / 12 * 5);
  }
  .flex-col-4 {
    width: calc(100% / 12 * 4);
  }
  .flex-col-3 {
    width: calc(100% / 12 * 3);
  }
  .flex-col-2 {
    width: calc(100% / 12 * 2);
  }

  .flex-col-1 {
    width: calc(100% / 12);
  }
  .flex-row .lb-check {
    padding-top: 0;
  }

  // 보험계약 해지안내
  .cancel-guide-cont {
    .cancel-sub-color01 {
      color: #bdbdbd;
    }
    .cancel-step-row.certi-step .step-row {
      li {
        &:before {
          left: 60px;
        }
        dt {
          width: 120px;
        }
        dd {
          width: calc(100% - 120px);
        }
      }
    }
    .tbl-board.tbl-cancel-info {
      th,
      td {
        vertical-align: top;
        padding: 13px 0;
      }
    }
  }

  /* 자동차보험 보상가이드 시작 */
  .list-contract-item {
    position: relative;
    padding: 28px 0;
    border-top: 6px solid #eee;
    font-size: 16px;
    color: #777;
    font-weight: 400;
  }
  .btn-base.btn-app {
    width: 112px;
  }
  .txt-r .btn-app {
    margin-left: 8px;
  }
  .btn-base.btn-base-full {
    width: 100%;
  }
  .bg-accident-research {
    background: url("/static/images/content/bg_accident_research.png") 97%
    center no-repeat;
    background-size: 16%;
  }
  .img-trafficAccident {
    vertical-align: top;
    margin-left: 18px;
    width: 35px;
  }
  .a-tel {
    padding-right: 6px;
  }
  .car-guide-cont__bottom-img {
    margin-top: 40px;
    margin-bottom: 120px;
    text-align: center;
  }
  /* sos 팝업 테이블*/
  .sos-pop {
    max-height: 560px !important;
    overflow-y: auto;
  }
  #sosInfoPop .box-dialog {
    width: 691px;
  }
  .iGoblC .box-dialog .box-content.sos-pop {
    padding-bottom: 40px;
  }
  .tbl-type2 table {
    border-top: 1px solid #eee;
    border-right: 1px solid #eee;
  }
  .tbl-type2 th,
  .tbl-type2 td {
    border-left: 1px solid #eee;
    border-bottom: 1px solid #eee;
    padding: 12px 8px !important;
    vertical-align: middle;
  }
  .guarantee-table table td {
    color: #111;
  }
  td.tdRadioBg {
    background: #fdfaf6;
  }

  .tbl-type2 th {
    background: #f4f4f473;
  }
  .tbl-type2 td {
    font-size: 13px;
  }
  .tbl-type2 tbody tr th:first-child {
    padding-right: 0;
  }
  .tbl-type2.sos-table tbody th,
  .tbl-type2.sos-table tbody td {
    font-size: 12px;
    overflow-wrap: break-word;
  }
  .tbl-type2.sos-table tbody th {
    font-size: 13px;
  }
  .tbl-type2.sos-table tbody td {
    color: #555;
  }

  //tip-box
  .tip-box {
    font-size: 14px;
  }
  .tip-box__tit {
    font-weight: bold;
    color: #f05014;
  }
} // .ui-d
