// * ui-tab -> carrot-tab 으로 변경중
.carrot-tab-container {
  margin: auto;
}

.carrot-tab-container ul {
  list-style: none;
  width: 100%;
  display: flex;
}

.carrot-tab-container ul li {
  background: #fff;
  transition: all 0.5s;
  padding: 0;
  border: 1px solid #c4c4c4;
  border-right: none;
  color: #c4c4c4;
  letter-spacing: -1px;
  width: 500px;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: normal;
  min-width: 40px;
  min-height: 40px;
}

.carrot-tab-container ul li:first-of-type {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.carrot-tab-container ul li:last-of-type {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-right: 1px solid #c4c4c4;
}

.carrot-tab-container li.active {
  background: #f05014;
  border: 1px solid #f05014;
  color: #fff;
}

.carrot-tab-container li button {
  width: 100%;
  height:100%;
  color: #000;
}

.carrot-tab-container li.active button {
  background: #f05014;
  border: 1px solid #f05014;
  color: #fff;
}