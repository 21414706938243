/* Date Picker */
.ui-drop-dim {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
}

/* ui slide */
.ui-slide {
  position: relative;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ui-slide-wrap {
  position: relative;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-transition: height 0.3s;
  -moz-transition: height 0.3s;
  -o-transition: height 0.3s;
  transition: height 0.3s;
}
.ui-slide-itemwrap {
  position: relative;
  top: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
}
.ui-slide.load .ui-slide-item {
  opacity: 1;
  display: block;
}
.ui-slide-item {
  display: none;
  position: absolute;
  top: 0;
  opacity: 0;
  z-index: 0;
  cursor: move;
  transition: opacity 0.6s ease-in;
}
.ui-slide-item[aria-hidden="false"] {
  display: block;
  z-index: 5;
}
.ui-slide-tit {
  display: none;
  position: absolute;
  z-index: 10;
}
.ui-slide-dotwrap {
  position: absolute;
  left: 0;
  z-index: 10;
  width: 100%;
  text-align: center;
}
.ui-slide-navwrap button {
  position: absolute;
  top: 50%;
  z-index: 10;
}
.ui-slide-navwrap .ui-slide-next {
  right: 0;
}
.ui-slide-navwrap .ui-slide-prev {
  left: 0;
}
.ui-slide-dot[aria-selected="true"] {
  font-weight: 700;
}
.ui-slide-gauge {
  position: absolute;
  width: 100%;
  height: 5px;
  background-color: #ccc;
}
.ui-slide-gaugebar {
  position: absolute;
  top: 0;
  width: 0;
  height: 100%;
  background-color: red;
}
.ui-slide-multi .ui-slide-itemwrap {
  width: auto;
  overflow: hidden;
  cursor: move;
}
.ui-slide-multi .ui-slide-item {
  float: left;
  position: relative;
  left: 0 !important;
}
.ui-slide-wrap,
.ui-slide-itemwrap,
.ui-slide-item {
  width: 100%;
}
.ui-slide .btn-wrap-fixed {
  bottom: -60px;
}
.ui-slide .ui-slide-item.on .btn-wrap-fixed {
  bottom: 0;
}

/* ui position */
.ui-floating-wrap {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
.ui-fixed-bottom .ui-floating-wrap {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
}
.ui-fixed-top .ui-floating-wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

/* ui-menu */
.ui-menu a,
.ui-menu button {
  color: #222;
  text-decoration: none;
}
.ui-menu .dep-1-btn {
  font-size: 16px;
}
.ui-menu .dep-1-btn.selected {
  color: #000;
  font-weight: 700;
}
.ui-menu .dep-2-wrap {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px 0;
}
.ui-menu .dep-2-btn.selected {
  color: #000;
  font-weight: 700;
}
.ui-menu .dep-3-wrap {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px 0;
}
.ui-menu .dep-3-btn.selected {
  color: #000;
  font-weight: 700;
}

/* dropdowns:z-index 100 ~ 199 */
.ui-drop {
  position: relative;
  display: inline-block;
}
.ui-drop-pnl.on {
  transform: scale(1);
}
.ui-drop-pnl.ui-datepicker-wrap {
  transform: scale(1);
}
.ui-drop-pnl {
  position: absolute;
  z-index: 100;
  background: transparent;
}
.ui-drop-pnl,
.ui-drop-pnl[aria-hidden="true"] {
  display: none;
}
.ui-drop-box {
  background: #fff;
  border: 2px solid #222;
  padding: 10px;
  border-radius: 10px;
}
.ui-drop-pnl .btn-close {
  position: absolute;
  top: 6px;
  right: 6px;
}
.ui-drop-pnl .btn-close:before,
.ui-drop-pnl .btn-close:after {
  background: #222;
}
.drop-backdrop {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  background: rgba(0, 0, 0, 0.2);
}
.drop-backdrop.on {
  display: block;
}

@media print {
  body.print-ing > * {
    display: none;
  }
  body.print-ing > .base-print {
    display: block;
  }
}

.ui-scrollbar-barwrap.type-y {
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  z-index: 100;
}
.type-y .ui-scrollbar-bar {
  position: absolute;
  top: 0;
  right: 2px;
  width: 6px;
  margin: 2px 0;
  height: 20px;
  background: #fff;
  opacity: 1;
  border-radius: 2px;
}
.ui-scrollbar:hover .ui-scrollbar-bar {
  opacity: 1;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.ui-scrollbar {
  position: relative;
  overflow: auto;
}
.ui-scrollbar-item {
  position: absolute; /* background-attachment: fixed; */
}
.ie .ui-bgscroll {
  transition: background-position 0.3s;
}
.ui-scrollbar-barwrap.type-x {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background: rgba(0, 0, 0, 0.1);
  z-index: 100;
}
.type-x .ui-scrollbar-bar {
  position: absolute;
  top: 2px;
  left: 0;
  width: 20px;
  margin: 0 2px;
  height: 6px;
  background: #fff;
  opacity: 1;
  border-radius: 2px;
}

.video-js .vjs-big-play-button {
  display: none;
}
