@charset "UTF-8";

.hidden,
.hide {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}


/* base layout */

.base-layer {
  position: relative;
  z-index: 20;
}

.base-wrap {
  width: 100%;
  height: 100%;
  min-height: 100dvh;
  background-color: #fff;
  z-index: 0;
}

.base-wrap.toss-layout {
  padding-bottom: 80px;
}

.base-aside,
.base-main {
  display: inline-block;
}

/* ico_bullet */
.bul-hyphen,
.bul-dot,
.bul-sdot,
.bul-etc {
  margin: 10px 0;
}

.bul-etc.txt-s .bul-ico {
  font-size: 13px;
}

.bul-sdot li,
.bul-sdot > dd,
.bul-dot li,
.bul-dot > dd,
.bul-hyphen li,
.bul-hyphen > dd,
dt.bul-hyphen,
p.bul-sdot,
a.bul-sdot,
span.bul-sdot,
p.bul-dot,
a.bul-dot,
span.bul-dot,
p.bul-hyphen,
p.bul-star {
  position: relative;
  margin-top: 8px;
  padding-left: 12px;
  line-height: 1.5;
  display: block;
}
p.bul-bill {
  position: relative;
  margin-top: 8px;
  padding-left: 20px;
  line-height: 1.5;
  display: block;
}

.bul-care > li,
div.bul-care,
em.bul-care,
p.bul-care {
  position: relative;
  margin-top: 5px;
  padding-left: 20px;
  line-height: 1.5;
  display: block;
  font-size: 13px;
  color: #999;
}

.bul-care > li {
  margin-top: 8px;
}

.bul-etc > dd,
p.bul-etc {
  position: relative;
  margin-top: 5px;
  padding-left: 20px;
  line-height: 1.5;
  display: block;
}

.bul-sdot li li:first-child,
.bul-sdot li:first-child,
.bul-dot li li:first-child,
.bul-dot li:first-child,
.bul-hyphen li li:first-child,
.bul-hyphen li:first-child {
  margin-top: 0;
}

.bul-sdot > li:before,
.bul-sdot > dd:before,
p.bul-sdot:before,
a.bul-sdot:before,
span.bul-sdot:before {
  content: "";
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background: #555;
  position: absolute;
  left: 0;
  top: 8px;
}

.bul-dot > li:before,
.bul-dot > dd:before,
p.bul-dot:before,
a.bul-dot:before,
span.bul-dot:before {
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #555;
  position: absolute;
  left: 0;
  top: 8px;
}

.bul-dot > li.emphasis__carrot,
li .emphasis__carrot {
  color: var(--carrot-primary-color, #f05014);
  &:before {
    background: var(--carrot-primary-color, #f05014);
  }
}

.bul-hyphen li:before,
.bul-hyphen dd:before,
p.bul-hyphen:before,
dt.bul-hyphen::before {
  content: "-";
  position: absolute;
  left: 0;
  top: 1px;
  font-weight: 400;
  line-height: 1.2;
}

p.bul-star:before {
  content: "*";
  position: absolute;
  left: 0;
  top: 6px;
  font-weight: 400;
  line-height: 1.2;
}

p.bul-bill:before {
  content: "※";
  position: absolute;
  left: 0;
  top: 2px;
  font-weight: 400;
  line-height: 1.2;
}

.bul-care > li:before,
div.bul-care:before,
em.bul-care:before,
p.bul-care:before {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  font-weight: 400;
  line-height: 1.2;
  display: block;
  width: 15px;
  height: 15px;
  background: url("/static/images/common/ico_small@2x.png")
  no-repeat -30px -4px;
  background-size: 300px;
}

.bul-care.hue-p:before {
  background-position: -105px -79px !important;
}

a > .bul-care.hue-p {
  display: inline-block;
  padding-right: 19px;
}

a > .bul-care.hue-p:after {
  content: "";
  position: absolute;
  right: 0;
  top: 2px;
  font-weight: 400;
  line-height: 1.2;
  display: block;
  width: 15px;
  height: 15px;
  background: url("/static/images/common/ico_small@2x.png")
  no-repeat -130px -79px;
  background-size: 300px;
}

.bul-hyphen ul,
.bul-sdot ul,
.bul-dot ul,
.bul-etc ul,
.bul-etc ol {
  margin: 5px 0 0;
}

.bul-sdot dd li,
.bul-sdot li li,
.bul-dot dd li,
.bul-dot li li,
.bul-hyphen dd li,
.bul-hyphen li li {
  margin: 5px 0 0;
  padding-left: 10px;
}

.bul-sdot dt,
.bul-dot dt,
.bul-hyphen dt {
  color: #000;
  font-weight: 500;
}

.bul-sdot ul li:before {
  content: "-";
  position: absolute;
  left: 0;
  top: 0;
  font-weight: 400;
  line-height: 1.2;
  background: none;
}

.bul-dot ul li:before {
  content: "-";
  position: absolute;
  left: 0;
  top: 0;
  font-weight: 400;
  line-height: 1.2;
  background: none;
}

.bul-hyphen ul li:before {
  content: "·";
  position: absolute;
  left: 0;
  top: 2px;
  font-weight: 900;
  line-height: 1.2;
}

.bul-etc > li {
  position: relative;
  margin-top: 5px;
  padding-left: 20px;
  line-height: 1.5;
  display: block;
}

.bul-ico {
  position: relative;
  top: 0;
  margin: 0 0 0 -20px;
  width: 20px;
  display: inline-block;
}

.bul-wrap.line {
  padding-top: 8px;
  border-top: 1px solid #eee;
}

.bul-dot .btn-tip {
  top: -1px;
}

.bul-care .btn-txt-arrow {
  margin: 6px 0 8px;
}

/* paragraph */
.paragraph-a {
  position: relative;
  width: 100%;
  margin-top: 20px;
}

.paragraph-a h3 {
  margin-top: 30px;
}

.paragraph-a p {
  margin-top: 15px;
}

.paragraph-a > *:first-child {
  margin-top: 0;
}

/* color */
.hue-p *,
.hue-p {
  color: #f05014 !important;
}

.hue-p2 *,
.hue-p2 {
  color: #00358e !important;
}

.hue-m *,
.hue-m {
  color: #111 !important;
}

.hue-s *,
.hue-s {
  color: #555 !important;
}

.hue-s2 *,
.hue-s2 {
  color: #777 !important;
}

.h1 .hue-p,
.h4 .hue-p,
.h4.hue-p {
  color: var(--carrot-primary-color, #f05014) !important;
}

/* text:type */
[class*="txt-mark"] {
  display: inline-block;
  margin: 0 5px;
  padding: 10px 0 0;
}

/* text:size */
.fs-s {
  font-size: 12px !important;
}

/* text:size */
.fs-m {
  font-size: 14px !important;
}

/* text:weight */

.txt-w6 {
  font-weight: 700;
}

/* text:align */
.txt-c {
  text-align: center !important;
}

.txt-c th,
.txt-c td {
  text-align: center !important;
}

.txt-l {
  text-align: left !important;
}

.txt-l th,
.txt-l td {
  text-align: left;
}

.txt-r {
  text-align: right !important;
}

.txt-r th,
.txt-r td {
  text-align: right;
}


/* bass text :margin & padding */
.mg-n {
  margin: 0 !important;
}

.mg-xxxs {
  margin: 5px !important;
}

.mg-xxs {
  margin: 10px !important;
}

.mg-xs {
  margin: 15px !important;
}

.mg-s {
  margin: 20px !important;
}

.mgt-sxxx {
  margin-top: -5px !important;
}

.mgt-n {
  margin-top: 0 !important;
}

.mgt-xxxs {
  margin-top: 5px !important;
}

.mgt-xxs {
  margin-top: 10px !important;
}

.mgt-xs {
  margin-top: 15px !important;
}

.mgt-s {
  margin-top: 20px !important;
}

.mgt-ms {
  margin-top: 25px !important;
}

.mgt-m {
  margin-top: 30px !important;
}

.mgt-ml {
  margin-top: 35px !important;
}

.mgt-l {
  margin-top: 40px !important;
}

.mgt-xl {
  margin-top: 45px !important;
}

.mgt-xxl {
  margin-top: 50px !important;
}

.mgt-xxxl {
  margin-top: 55px !important;
}

.mgt-ll {
  margin-top: 60px !important;
}

.mgb-n {
  margin-bottom: 0 !important;
}

.mgb-xxxs {
  margin-bottom: 5px !important;
}

.mgb-xxs {
  margin-bottom: 10px !important;
}

.mgb-xs {
  margin-bottom: 15px !important;
}

.mgb-s {
  margin-bottom: 20px !important;
}

.mgb-ms {
  margin-bottom: 25px !important;
}

.mgb-m {
  margin-bottom: 30px !important;
}

.mgb-ml {
  margin-bottom: 35px !important;
}

.mgb-l {
  margin-bottom: 40px !important;
}

.mgb-xl {
  margin-bottom: 45px !important;
}

.mgb-xxl {
  margin-bottom: 50px !important;
}

.mgb-xxxl {
  margin-bottom: 55px !important;
}

.mgb-ll {
  margin-bottom: 60px !important;
}

.mgl-n {
  margin-left: 0 !important;
}

.mgl-xxxs {
  margin-left: 5px !important;
}

.mgl-xxs {
  margin-left: 10px !important;
}

.mgl-xs {
  margin-left: 15px !important;
}

.mgl-s {
  margin-left: 20px !important;
}

.mgl-l {
  margin-left: 40px !important;
}

.mgr-n {
  margin-right: 0 !important;
}

.mgr-xxxs {
  margin-right: 5px !important;
}

.mgr-xxs {
  margin-right: 10px !important;
}

.mgr-xs {
  margin-right: 15px !important;
}

.mgr-s {
  margin-right: 20px !important;
}

.mgr-m {
  margin-right: 30px !important;
}

.mgr-l {
  margin-right: 40px !important;
}

.mgr-xxxl {
  margin-right: 55px !important;
}

.pd-n {
  padding: 0 !important;
}

.pd-xxxs {
  padding: 5px !important;
}

.pd-xxs {
  padding: 10px !important;
}

.pd-xs {
  padding: 15px !important;
}

.pd-s {
  padding: 20px !important;
}

.pd-ms {
  padding: 25px !important;
}

.pd-m {
  padding: 30px !important;
}

.pdt-n {
  padding-top: 0 !important;
}

.pdt-xxxs {
  padding-top: 5px !important;
}

.pdt-xxs {
  padding-top: 10px !important;
}

.pdt-xs {
  padding-top: 15px !important;
}

.pdt-s {
  padding-top: 20px !important;
}

.pdt-ms {
  padding-top: 25px !important;
}

.pdt-m {
  padding-top: 30px !important;
}

.pdt-ml {
  padding-top: 35px !important;
}

.pdt-l {
  padding-top: 40px !important;
}

.pdt-xxl {
  padding-top: 50px !important;
}
.pdb-n {
  padding-bottom: 0 !important;
}

.pdb-xxxs {
  padding-bottom: 5px !important;
}

.pdb-xxs {
  padding-bottom: 10px !important;
}

.pdb-xs {
  padding-bottom: 15px !important;
}

.pdb-s {
  padding-bottom: 20px !important;
}

.pdb-ms {
  padding-bottom: 25px !important;
}

.pdb-m {
  padding-bottom: 30px !important;
}

.pdb-ml {
  padding-bottom: 35px !important;
}

.pdb-l {
  padding-bottom: 40px !important;
}

.pdb-xl {
  padding-bottom: 45px !important;
}

.pdb-xxl {
  padding-bottom: 50px !important;
}

.pdb-xxxl {
  padding-bottom: 55px !important;
}

.pdb-ll {
  padding-bottom: 60px !important;
}

.pdl-n {
  padding-left: 0 !important;
}

.pdl-xxxs {
  padding-left: 5px !important;
}

.pdl-xxs {
  padding-left: 10px !important;
}

.pdl-xs {
  padding-left: 15px !important;
}

.pdl-s {
  padding-left: 20px !important;
}

.pdl-ms {
  padding-left: 25px !important;
}

.pdl-m {
  padding-left: 30px !important;
}
.pdl-l {
  padding-left: 40px !important;
}

.pdl-xxl {
  padding-left: 50px !important;
}

.pdl-xxxl {
  padding-left: 55px !important;
}

.pdl-ll {
  padding-left: 60px !important;
}

.pdr-n {
  padding-right: 0 !important;
}

.pdr-xxxs {
  padding-right: 5px !important;
}

.pdr-xxs {
  padding-right: 10px !important;
}

.pdr-xs {
  padding-right: 15px !important;
}

.pdr-s {
  padding-right: 20px !important;
}

.pdr-ms {
  padding-right: 25px !important;
}

.pdr-m {
  padding-right: 30px !important;
}

.pdr-ml {
  padding-right: 35px !important;
}

.pdr-l {
  padding-right: 40px !important;
}

.pdr-ll {
  padding-right: 60px !important;
}
.w-m {
  width: 25% !important;
}

.w-half {
  width: 50% !important;
}

.w-full {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

/* table:col */

.h-100vh {
  height: 100vh !important;
}


/* base menu */
/* .ui-menu li {list-style:none;} */

/* layout */
.in-wrap {
  position: relative;
  font-size: 0;
  margin: 0;
}

.in-wrap > *,
.in-item {
  font-size: 14px;
  display: inline-block;
  vertical-align: top;
}

.fx-wrap {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-lines: single;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.fl-wrap:after {
  content: "";
  display: block;
  clear: both;
}

.fl-item-l {
  display: block;
  float: left;
  position: relative;
}

.fl-item-r {
  display: block;
  float: right;
  position: relative;
}

.fx-dir-row {
  -webkit-box-orient: horizontal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.fx-dir-col {
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;
}

.fx-align-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.fx-align-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.fx-align-justify {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.fx-valign-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.fx-item {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex-grow: 1;
}

.col-2 .fx-item {
  -webkit-box-flex: 0;
  -ms-flex: 0;
  flex-grow: 0;
  min-width: calc(50% - 12px);
  max-width: calc(50% - 12px);
}

.col-2 .fx-item-txt {
  -webkit-box-flex: 0;
  -ms-flex: 0;
  flex-grow: 0;
  min-width: 24px;
  max-width: 24px;
  padding-top: 31px;
  text-align: center;
  color: #010101;
}

.col-2 .fx-item + .fx-item {
  margin-left: 23px;
}

.col-4 .fx-item {
  -webkit-box-flex: 0;
  -ms-flex: 0;
  flex-grow: 0;
  min-width: calc(25% - 8px);
  max-width: calc(25% - 8px);
}

.fx-item-0 {
  -webkit-box-flex: 0;
  -ms-flex: 0;
  flex-grow: 0;
}

.fx-item-1 {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex-grow: 1;
}

.fx-item-2 {
  -webkit-box-flex: 2;
  -ms-flex: 2;
  flex-grow: 2;
}

.fx-item-3 {
  -webkit-box-flex: 3;
  -ms-flex: 3;
  flex-grow: 3;
}

.fx-item-4 {
  -webkit-box-flex: 4;
  -ms-flex: 4;
  flex-grow: 4;
}

.fx-item-5 {
  -webkit-box-flex: 5;
  -ms-flex: 5;
  flex-grow: 5;
}

.fx-item-6 {
  -webkit-box-flex: 6;
  -ms-flex: 6;
  flex-grow: 6;
}

.fx-item-7 {
  -webkit-box-flex: 7;
  -ms-flex: 7;
  flex-grow: 7;
}

.fx-item-8 {
  -webkit-box-flex: 8;
  -ms-flex: 8;
  flex-grow: 8;
}

.fx-item-9 {
  -webkit-box-flex: 9;
  -ms-flex: 9;
  flex-grow: 9;
}

.fx-item-10 {
  -webkit-box-flex: 10;
  -ms-flex: 10;
  flex-grow: 10;
}

.fx-item-11 {
  -webkit-box-flex: 11;
  -ms-flex: 11;
  flex-grow: 11;
}

.fx-item-12 {
  -webkit-box-flex: 12;
  -ms-flex: 12;
  flex-grow: 12;
}

/* base form:radio & checkbox */
.lb-radio,
.lb-check {
  position: relative;
  display: inline-flex;
  vertical-align: top;
  align-items: flex-start;
  min-height: 20px;
  padding: 12px 0 0;
  margin: 0;
  cursor: pointer;
  line-height: 1.5;
}

.fx-item .lb-radio,
.fx-item .lb-check {
  min-height: 40px;
}

.lb-radio:before,
.lb-check:before {
  content: "";
  display: block;
  flex: 0;
  min-width: 23px;
  min-height: 23px;
  margin-right: 7px;
  background-color: #fff;
  box-sizing: border-box;
  border: 1px solid #d3d3d3;
  transition: box-shadow 0.5s, border 0.3s;
}

.lb-radio:before {
  border-radius: 50%;
}

.lb-check:before {
  border-radius: 3px;
  margin-top: 1px;
}

fieldset .lb-radio,
fieldset .lb-check {
  margin-right: 20px;
}

fieldset .lb-radio:last-child,
fieldset .lb-check:last-child {
  margin-right: 0;
}

input:checked + .lb-radio:before,
input:checked + .lb-check:before,
.lb-radio.checked:before,
.lb-check.checked:before {
  border: 1px solid #f05014;
  background: #f05014
  url("/static/images/common/ico_form@2x.png")
  no-repeat -2px -2px;
  background-size: 150px;
}

.lb-radio.activated:before,
.lb-check.activated:before,
.lb-radio:hover:before,
.lb-check:hover:before,
.lb-radio:focus:before,
.lb-check:focus:before {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.lb-radio.checked.activated:before,
.lb-check.checked.activated:before,
.lb-radio.checked:hover:before,
.lb-check.checked:hover:before,
.lb-radio.checked:focus:before,
.lb-check.checked:focus:before {
  box-shadow: 0 0 5px rgba(253, 71, 0, 0.7);
}

.lb-radio.checked,
.lb-check.checked {
  color: #000;
}

input:disabled + .lb-radio,
input:disabled + .lb-check,
.lb-radio.disabled,
.lb-check.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.lb-radio.disabled.checked,
.lb-check.disabled.checked {
  opacity: 1;
}

.lb-radio span,
.lb-check span {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  font-size: 16px;
  line-height: 24px;
  border-bottom: 1px solid transparent;
}

.lb-check.btn-txt:before {
  display: none;
}

.lb-tit {
  display: inline-block;
  height: 40px;
  margin-right: 10px;
  padding-top: 10px;
  vertical-align: top;
  cursor: pointer;
}

.lb-radio.type-toggle,
.lb-check.type-toggle {
  position: relative;
  display: inline-flex;
  vertical-align: top;
  align-items: center;
  min-width: 40px;
  min-height: 22px;
  font-size: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  line-height: 1;
  background: #ccc;
  border: 0;
  border-radius: 11px;
  transition: background-color 0.1s ease-in;
}

.lb-radio.type-toggle:before,
.lb-check.type-toggle:before {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 2px;
  min-width: 18px;
  min-height: 18px;
  border-radius: 50%;
  margin: 0;
  box-sizing: border-box;
  background: #fff;
  border: 0;
  transition: left 0.1s ease-in;
}

input:checked + .lb-radio.type-toggle:before,
input:checked + .lb-check.type-toggle:before,
.lb-radio.type-toggle.selected:before,
.lb-check.type-toggle.selected:before,
.lb-radio.type-toggle.checked:before,
.lb-check.type-toggle.checked:before {
  left: calc(100% - 20px);
  background-color: #fff;
  background-image: none;
  border: 0;
}

input:checked + .lb-radio.type-toggle,
input:checked + .lb-check.type-toggle,
.lb-radio.type-toggle.selected,
.lb-check.type-toggle.selected,
.lb-radio.type-toggle.checked,
.lb-check.type-toggle.checked {
  background: #f05014;
  border: 0;
}

.lb-radio.type-toggle.activated,
.lb-check.type-toggle.activated,
.lb-radio.type-toggle:hover,
.lb-check.type-toggle:hover,
.lb-radio.type-toggle:focus,
.lb-check.type-toggle:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.lb-radio.type-toggle.checked.activated,
.lb-check.type-toggle.checked.activated,
.lb-radio.type-toggle.checked:hover,
.lb-check.type-toggle.checked:hover,
.lb-radio.type-toggle.checked:focus,
.lb-check.type-toggle.checked:focus {
  box-shadow: 0 0 5px rgba(253, 71, 0, 0.7);
}

.lb-radio.type-toggle span,
.lb-check.type-toggle span {
  font-size: 0;
  line-height: 0;
}

.btn-toggle {
  position: relative;
  display: inline-block;
  margin-right: 10px;
}

.btn-toggle.type-tip .lb-check {
  width: 100%;
}

.btn-toggle .lb-check:before {
  display: none;
}

.btn-toggle .lb-check {
  background: #f7f7f7;
  color: #999;
  height: 50px;
  border-radius: 25px;
  border: 0;
  padding: 14px 18px 0;
  font-size: 15px;
  letter-spacing: -1px;
}

.btn-toggle :checked + .lb-check,
.btn-toggle .lb-check.checked {
  background: #f05014;
  color: #fff;
}

.btn-toggle .btn-tip {
  position: absolute !important;
  top: 50%;
  right: 15px;
  margin-top: -8px;
}


.field-dl {
  position: relative;
  font-size: 15px;
  width: 100%;
}

.field-dl:after {
  content: "";
  display: block;
  clear: both;
}

.field-dt {
  float: left;
  font-weight: 700;
  color: #222;
}

.field-dt-tit {
  min-height: 0;
  display: inline-block;
  padding-top: 3px;
}

.field-dd {
  float: right;
}

/* base form:input text */
textarea,
input {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

.inp-error {
  border-color: #f05014 !important;
}

textarea {
  max-height: 186px;
  margin-top: 7px;
}

/* Removes the clear button from date inputs */
input[type="date"]::-webkit-clear-button {
  display: none;
}

/* Removes the spin button */
input[type="date"]::-webkit-inner-spin-button {
  display: none;
}

/* Always display the drop down caret */
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}

input[type="date"] {
  appearance: none;
  -webkit-appearance: none;
}

/* table:base */
[class*="tbl-base"] {
  margin: 10px 0;
  width: 100%;
  position: relative;
  border-top: 1px solid #777;
  border-bottom: 1px solid #ddd;
  table-layout: fixed;
}

[class*="tbl-base"] > thead th {
  background: #f4f4f4;
  border-bottom: 1px solid #ddd;
  color: #010101;
  padding: 13px 10px;
  font-size: 14px;
  height: 40px;
  font-weight: 400;
  word-break: break-all;
}

[class*="tbl-base"] > tbody > tr > th,
[class*="tbl-base"] > tbody > tr > td {
  background: #fff;
  border-top: 1px solid #ddd;
  color: rgba(68, 68, 68, 0.99);
  height: 40px;
  padding: 13px 10px;
  font-weight: 400;
  font-size: 14px;
  word-break: break-all;
  line-height: 1.5;
  text-align: left;
}

[class*="tbl-base"] > tbody:first-child > tr:first-child > th,
[class*="tbl-base"] > tbody:first-child > tr:first-child > td {
  border-top: 0;
}

[class*="tbl-base"] > thead > tr > th:first-child,
[class*="tbl-base"] > tbody > tr > th:first-child,
[class*="tbl-base"] > tbody > tr > td:first-child {
  border-left: 0;
}

[class*="tbl-base"] > tbody > tr > th {
  background: #f4f4f4;
}

[class*="tbl-base"].type-tbody-w > tbody > tr > th {
  background: #fff;
}

tbody.line-t {
  border-top: 1px solid #c5c5c5;
}

td .placeholder {
  margin-top: 5px;
}

/* table:base small*/
[class*="tbl-base"].type-s {
  margin: 23px 0 0;
}

[class*="tbl-base"].type-s > thead th {
  padding: 15px 3px;
  color: #555;
}

[class*="tbl-base"].type-s > tbody > tr > th,
[class*="tbl-base"].type-s > tbody > tr > td {
  padding: 10px 3px;
  text-align: center;
  color: #555;
}

/* table:base vertical */
.tbl-base-v > tbody > th {
  background: #f1f1f1;
  border-bottom: 1px solid #e4e4e4;
  color: #000;
  padding: 5px 15px;
  font-weight: 500;
  font-size: 12px;
  height: 40px;
}

.carrot-loading-wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3000;
  width: 100%;
  height: 100%;
  opacity: 1;
  display: none;
}
.carrot-loading-item {
  position: absolute;
  width: 45px;
  height: 23px;
  top: calc(100vh / 2 - 11.5px);
  left: calc(100vw / 2 - 22.5px);

  @media screen and (min-width: 1024px) {
    width: 48px;
    height: 24px;
    top: calc(100vh / 2 - 12px);
    left: calc(100vw / 2 - 24px);
  }
}

/* error page */
// desktop
@media screen and (min-width: 1024px) {
  .box-err {
    position: relative;
    width: 100%;
    margin-top: 60px;
    padding: 60px 40px 40px;
    border: 1px solid #ddd;
  }

  .box-err:before {
    content: "";
    display: block;
    position: absolute;
    top: -40px;
    left: 50%;
    margin-left: -40px;
    width: 80px;
    height: 80px;
    background: url("/static/images/content/ico_illu.png") -100px
    0;
  }

  .box-err.ico2:before {
    background-position: -180px 0;
  }

  .err-info {
    padding-bottom: 25px;
    text-align: center;
    border-bottom: 1px solid #eee;
  }

  .err-info .tit {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }

  .err-info .txt1 {
    margin-top: 20px;
    font-size: 18px;
  }

  .err-info .txt1 b {
    font-weight: 700;
  }

  .err-reason {
    margin-top: 25px;
    font-size: 16px;
    color: #777;
  }

  .err-reason li {
    margin-top: 10px;
  }

  .err-reason li strong {
    margin-right: 10px;
    color: #111;
  }

  .box-err .bul-care {
    margin-top: 27px;
  }
}

// 갱신용 CLASS. 나중에 슬라이더 꼭 빼버리기.
.ui-d,
.ui-m {
  .renew-slider {
    & .ui-slider-divs {
      height: 8px;

      & .ui-slider-div {
        bottom: 22px !important;
        @media screen and (min-width: 600px) {
          bottom: 38px !important;
        }
        height: 8px;

        &.n1 {
          em {
            margin-left: -35px;
          }
        }

        &.n2 {
          em {
            margin-left: -70px;
          }
          @media screen and (min-width: 600px) {
            margin-left: -10px;
          }
        }

        em {
          margin: 0;
          letter-spacing: -0.5px !important;
          color: #d9d9d9 !important;
          font-weight: 400 !important;
          line-height: 8px !important;
          font-size: 8px !important;
          @media screen and (min-width: 600px) {
            line-height: 16px !important;
            font-size: 16px !important;
          }
        }
      }
    }

    & .ui-slider-btn-s {
      text-indent: 0;
      overflow: unset;
    }

    & .ui-slider-bar {
      background-color: #f05014;
    }

    & .ui-slider-wrap {
      padding: 0;
    }

    & .ui-slider-bg {
      height: 4px;
    }

    @media screen and (min-width: 600px) {
      .ui-slider-btns {
        .ui-slider-down,
        .ui-slider-up {
          display: none;
        }
      }

      & .mileage-slider {
        padding: 0;
      }

      &.box-base {
        border: none;
        padding: 75px 0 33px;
      }
    }

    & .mileage-slider--car [class^="ui-slider-btn-"] {
      top: -11px;
      width: 26px;
      height: 26px;
      background: #fff none;
      border-radius: 50%;
      box-shadow: 2px 0px 4px rgba(119, 119, 119, 0.5);
      border: none;
    }

    & .ui-slider-tooltip {
      display: none;
    }

    & .ui-slider-txt {
      opacity: 0;
    }
  }
}

// mobile
@media screen and (max-width: 1023px) {
  .ui-d.error {
    .base-wrap {
      min-width: initial !important;
    }

    .wrap-inner {
      width: 100% !important;
      padding: 0 30px !important;
    }

    .base-header {
      height: 60px !important;
    }

    .base-header .btn-menu,
    .base-header .menu-wrap {
      display: none;
    }

    .base-header-wrap {
      height: 60px !important;
      text-align: center;
    }

    .base-header-wrap .logo,
    .base-header-wrap .nav-main {
      display: none !important;
    }

    .base-body {
      padding-top: 60px !important;
    }

    .err-info {
      padding-top: 35px;
    }

    .err-info .tit {
      font-weight: 700;
      font-size: 24px;
      color: #111;
    }

    .err-info .txt1 {
      font-size: 16px;
      margin-top: 35px;
    }

    .err-info .txt1 > b {
      font-weight: 700;
    }

    .err-info .txt2 {
      font-size: 14px;
      margin-top: 13px;
      color: #555;
    }

    .err-info + .bul-care {
      margin-top: 35px;
    }

    .err-info + .bul-care > li {
      font-size: 13px;
      color: #6e6e6e;
      padding-left: 20px;
    }

    .err-info + .bul-care > li::before {
      top: 2px;
      width: 15px;
      height: 15px;
      background: url("/static/images/common/ico_small@2x.png")
      no-repeat -30px -4px;
      background-size: 300px;
    }

    .btn-wrap-fixed {
      position: fixed !important;
      bottom: 0 !important;
      left: 0 !important;
      z-index: 20;
    }

    .btn-wrap-fixed .btn-base-l {
      font-size: 18px;
    }

    .base-footer {
      display: none;
    }
  }
}
