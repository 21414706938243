/**************************
 * 제휴 해외여행보험 UI CUSTOM
 ***************************/

// color set change
.PA00062, .PA00299, .PA00301 {
  --carrot-primary-color: #233de8;
  --carrot-primary-color1: #233de8;
  --carrot-primary-color2: #233de8;
  --carrot-primary-color3: #233de8;
  --carrot-primary-color4: #233de8;
  --carrot-primary-color5: #233de8;
  --carrot-primary-color6: #233de8;
  --carrot-primary-color7: #233de8;
  --carrot-primary-color8: #233de8;
  --carrot-primary-color9: #233de8;

  --carrot-secondary-color1: #233de8;
  --carrot-secondary-color2: #233de8;
  --carrot-secondary-color3: #233de8;
  --carrot-secondary-color4: #233de8;
  --carrot-secondary-color5: #233de8;
  --carrot-secondary-color6: #233de8;
  --carrot-secondary-color7: #233de8;
  --carrot-secondary-color8: #233de8;
  --carrot-secondary-color9: #233de8;

  &.ui-m {
    .keypad-wrap button:disabled {
      background: none;
      background-size: 30px;
    }

    .hue-p *,
    .hue-p {
      color: var(--carrot-primary-color) !important;
    }

    // long label check
    .all-check .lb-check {
      border: 1px solid var(--carrot-primary-color);
      color: var(--carrot-primary-color);
    }

    .ui-modal-progress {
      background: var(--carrot-primary-color);
    }

    .ui-confirmed-msg {
      color: var(--carrot-primary-color);
      padding-left: 4px;
      &:before {
        background: none;
      }
    }
    
    li.active button {
      background: var(--carrot-primary-color);
      border: 1px solid var(--carrot-primary-color);
    }

    // btn-tip
    .circle-symbol {
      color: var(--carrot-primary-color);
      border: 1px solid var(--carrot-primary-color);
      .circle-symbol-character {
        color: var(--carrot-primary-color);
      }
    }
    .menu {
      display: none;
    }
    .back {
      display: none;
    }
    .shortcut {
      display: none;
    }
    
    //sol 해외여행보험만 적용
    .PA00062 .header {
      display: none;
    }
    .PA00062 .logo {
      display: none;
    }
    .title {
      //display: none;
    }
  }
}
