/**************************
 * IBK 자동차보험 UI CUSTOM
 ***************************/

/*

MAIN : #4C9dF8
SUB
- #333
- #666
- #757575

POINT
- #1D74E8
- #FF6F61
- #32D3AB
- #FFA061

*/

// color set change
.PA00226 {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', 'Apple SD Gothic Neo', 'Malgun Gothic', '맑은 고딕', sans-serif;

  --carrot-primary-color: #4c9df8;
  --carrot-primary-color1: #333333;
  --carrot-primary-color2: #666666;
  --carrot-primary-color3: #757575;
  --carrot-primary-color4: #757575;
  --carrot-primary-color5: #757575;
  --carrot-primary-color6: #757575;
  --carrot-primary-color7: #757575;
  --carrot-primary-color8: #757575;
  --carrot-primary-color9: #757575;
  --carrot-primary-color10: #4c9df8;

   //내가 수정한거
  --ibk-point-color: #1D74E8;
  --carrot-error-color: #FF6F61;
  --carrot-input-font-color: #333333;
  --carrot-border-color4: #d9d9d9;
  --carrot-slider-color: #4c9df8;
  --carrot-secondary-color: #1D74E8;

  &.ui-m {
    // 키패드 logo 삭제 (톤 안맞아서 걍 내가 삭제)
    .keypad-wrap button:disabled {
      background: none;
      background-size: 30px;
    }

    // 슬라이더
    [class^="ui-slider-btn-"] {
      background:var(--carrot-secondary-color);
    }

    .btn-wrap-fixed {
      .btn-base-l {
        min-height: 55px;
        height: 55px;
      }
    }

    // 야매. styled-components class 바꿔서 하는건데, 좋은지는 사실 잘 모르겠음.
    [class^="BottomNextButtonstyled__BottomNextButtonBlock"] {
      height: 55px;
    }
    .btn-close, .ui-modal-header .n2.btn-close {
      color: var(--carrot-primary-color);
      top: 14px;
      right: 12px;
      padding: 6px 12px;
      background: none;
      text-indent: 0;
      width: auto;
      height: auto;
    }
  }
}
