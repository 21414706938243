/************************************

현대자동차 퍼아워

TO-DO
1) 좌우에 margin 30px을 주니 주민등록번호 뒷자리 입력칸이 급격하게 작아짐.
   키패드 주민등록번호 뒷 자리가 더 길도록 변경해야 함.
2) 폰트 사이즈 정확하게 다 맞는지에 대해 확인 필요
  (각 class 별로 important가 산재되어 있어서 하나씩 확인해야 할 듯)

REF
1) Font Size : 21 / 16 / 15 / 14 / 13 / 11
2) SK Planet 1REM = 16px
- 0.375 : 6 / 1.9375 : 31 / 0.875 : 14 / 1.25 : 20
************************************/

// color set change
.PA00029 {
  --carrot-primary-color: #002c5f;
  --carrot-primary-color1: #002c5f;
  --carrot-primary-color2: #002c5f;
  --carrot-primary-color3: #002c5f;
  --carrot-primary-color4: #002c5f;
  --carrot-primary-color5: #002c5f;
  --carrot-primary-color6: #002c5f;
  --carrot-primary-color7: #002c5f;
  --carrot-primary-color8: #002c5f;
  --carrot-primary-color9: #002c5f;
  --carrot-secondary-color: #00aad2;
  --carrot-secondary-color1: #00aad2;
  --carrot-secondary-color2: #00aad2;
  --carrot-secondary-color3: #00aad2;
  --carrot-secondary-color4: #00aad2;
  --carrot-secondary-color5: #00aad2;
  --carrot-secondary-color6: #00aad2;
  --carrot-secondary-color7: #00aad2;
  --carrot-secondary-color8: #00aad2;
  --carrot-secondary-color9: #00aad2;
}

$hue-afccd: PA00029; // 제휴코드
/* 글자 색 */
$hue-m: #222;
$hue-s: #464646;
$hue-s2: #707070;
$hue-s3: #999;
$hue-s4: #bbb;
/* 포인트 색 */
$hue-p0: #002c5f;
$hue-p1: #00aad2;
$hue-p2: #82bcfb;
$hue-p3: #e7f6ff;
$hue-p4: #ff3e00;
$hue-check: #d3d3d3;
/* LINE */
$hue-line: #f6f6f7;
$hue-line2: #f2f2f2;
$hue-line3: #ededed;
$hue-line4: #d2d2d2;
/* BG */
$hue-bg: #fff;
$hue-bg1: #f6f6f7;
$hue-bg2: #f2f2f2;
$hue-bg3: #f1f6ff;

/*==================================================*/
/*==================================================*/
/*============= 진행화면에만 적용 =============*/
html.PA00029 {
  /* 자주 적용하는 내용에 대한 변수화 */
  $sk-shadow: 1px 0 0 0 $hue-line3, 0 1px 0 0 $hue-line3, 1px 1px 0 0 $hue-line3,
  1px 0 0 0 $hue-line3 inset, 0 1px 0 0 $hue-line3 inset;
  @mixin sk-label {
    padding: 10px 0 0 10px;
    color: $hue-s3;
  }
  /* BUTTON CSS(DEAFULT : disabled) */
  @mixin sk-check($isEnabled: 1) {
    border-radius: 6px;
    width: 100%;
    max-width: 328px;
    height: 40px;
    min-height: 40px;
    font-size: 15px;
    font-weight: normal;
    text-align: center;
    padding: 8px 20px;
    line-height: 1.6;
    @if $isEnabled == 1 {
      color: $hue-bg; // #FFF
      border: 0;
      background: $hue-p0;
    } @else {
      color: $hue-s3;
      border: 1px solid $hue-line4;
      background: $hue-bg;
    }
  }
  body {
    font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', 'Apple SD Gothic Neo', 'Malgun Gothic', '맑은 고딕', sans-serif;
    color: $hue-s;
  }
  button {
    font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', 'Apple SD Gothic Neo', 'Malgun Gothic', '맑은 고딕', sans-serif;
    color: $hue-s2;
  }
  input,
  textarea,
  select {
    font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', 'Apple SD Gothic Neo', 'Malgun Gothic', '맑은 고딕', sans-serif;
  }
  font-size: 15px;
  .base-wrap {
    .base-header {
      .logo {
        font-size: 20px;
      }
    }
    .base-body {
      .base-main {
        padding: 0 25px;
        .wrap-inner {
          padding: 0;
          .tit-wrap {
            padding-top: 24px;
            .type-plan {
            }
            // 보험료 계산 드롭박스 아래 선
            .type-plan::after {
              background: $hue-p0;
              height: 3px;
            }
          }
        }
      }
    }
  }
  .h1 {
    font-size: 21px !important;
    text-align: center;
  }
  .h2 {
    font-size: 16px !important;
    text-align: center;
  }
  .h3 {
    font-size: 16px !important;
    text-align: center;
  }
  .h4 {
    font-size: 16px !important;
    text-align: center;
  }
  .h5 {
    font-size: 15px !important;
    text-align: center;
  }
  .h6 {
    font-size: 14px !important;
    text-align: center;
  }
  .h7 {
    font-size: 13px !important;
    text-align: center;
  }
  .h8 {
    font-size: 11px !important;
    text-align: center;
  }
  /*============= IFRAME =============*/
  #iframeLayer {
    .iframe-footer {
      .btn-base-l {
        max-width: 100%;
        border: 0;
        border-radius: 0;
      }
    }
  }
  /*============= WRAP =============*/
  .sk-wrap {
    margin-top: 10px;
  }
  /*============= 본문 =============*/
  .ui-m {
    .box-base {
      &.type-line {
        border: 1px solid $hue-line4;
      }
      &.type-expect .expect-info p > strong {
        color: $hue-p0;
      }
    }
    .box-head.type-b .txt-tag.type-b {
      border: 1px solid $hue-p2;
      border-radius: 30px;
      background-color: $hue-bg;
      height: 30px;
      min-width: 50px;
      padding: 8px 13px;
      text-align: center;
      font: 12px bold;
      color: $hue-p0;
    }
    .box-link {
      margin-bottom: 6px;
    }
    .check-list-wrap {
      padding: 14px 25px 40px;
    }
    .ico-arrow {
      display: inline-block;
      position: relative;
      top: 0;
      width: 18px;
      height: 18px;
      overflow: hidden;
      color: transparent;
      font-size: xx-small;
      box-sizing: content-box;
      margin-left: 0;
      vertical-align: top;
      background: url("/static/images/common/ico_small@2x.png")
      no-repeat -83px -3px;
      background-size: 300px;
    }
    .lb-radio::before {
      margin-right: 10px;
      min-height: 20px;
      min-width: 20px;
      border-radius: 20px;
    }
    .lb-check span {
      font-size: 16px;
      color: $hue-s2;
    }
    .list-agree > li > [class*="tit"] {
      color: $hue-m;
      em {
        color: $hue-p0;
      }
    }
    .list-contract-item {
      padding: 0 0 20px;
      .btn-base.focused,
      .btn-base:hover,
      .btn-base:focus {
        border-color: $hue-p0;
      }
    }
    .list-dlsel.type-agree {
      .list-dlsel-item [class*="tit"] {
        color: $hue-s;
        font-size: 15px;
      }
    }
    // 아이템별 wrap 양식
    .fx-wrap {
      .btn-all {
        color: $hue-s3;
      }
      .btn-all.selected {
        p {
          color: $hue-m;
        }
        .h3::after {
          background: url("/static/images/common/ico_form@2x.png") -112.5px -62.5px
          no-repeat;
          background-size: 300px;
        }
      }
    }
    .btn-base,
    .btn-base-m {
      min-height: 30px;
    }
    // 하단 fixed 버튼
    .btn-wrap-fixed {
      .btn-base-l {
        padding: 0;
        background-color: $hue-p0;
        height: 54px;
        border-radius: 0px;
        border-width: 0px;
        max-width: 100%;
        width: 100%;
        color: $hue-bg2; // white
      }
      .btn-base-l[disabled] {
        background-color: $hue-bg2;
        color: $hue-s4;
      }
    }
    // 버튼 전체 양식
    [class*="btn-base"] {
      font-size: 12px;
      font-weight: normal;
      color: $hue-s;
      border: 1px solid $hue-line4;
      border-radius: 2em;
    }
    // 긴 버튼
    .btn-base-l {
      @include sk-check(1);
    }
    // 전체 버튼
    .btn-wrap.type-full button {
      height: 40px;
      max-width: 328px;
      font-size: 15px;
      color: $hue-bg;
      text-align: center;
      background-color: $hue-p0;
      border-radius: 6px;
    }
    // 박스 아래 안내 / 경고 문구
    .bul-care > li,
    div.bul-care,
    em.bul-care,
    p.bul-care {
      margin-top: 16px;
    }
    .tit-wrap h1 {
      font-size: 21px !important;
      strong {
        color: $hue-p0;
        font-weight: 700;
      }
    }
    .txt-price b {
      color: $hue-p0 !important;
    }
    .type-plan .btn-select,
    .type-plan select {
      font-size: 21px;
    }
    .mileage-slider__price span {
      color: $hue-p0;
    }
  }
  /*============= 본문 END =============*/
  .type-system.box-dialog .ui-modal-cont .box-system-inner {
    text-align: center;
    padding: 40px 0px 28px;
  }
  /******************** STEP HANDLING ********************/
  // box-shadow를 통해 item별로 border를 만든다. 해당 border가 필요한 화면에서만 import.
  .modalLayer .fx-wrap:not(.radio-wrap),
  .pushCstmrInfo .fx-wrap,
  .findCarInfo :not(.ui-tab-btns).fx-wrap,
  .findCarInfo .srh-step .field-inlabel.type-large.type-srh,
  .pushInsInfo .fx-wrap {
    box-shadow: $sk-shadow;
    .fx-item label,
    label[for="eCallPhoneNumber"],
    label[for="carCompanyAndName"] {
      @include sk-label;
    }
    .fx-item {
      .field-inlabel-label {
        @include sk-label;
        label {
          padding: 0;
        }
      }
    }
    .ui-select,
    select {
      border-bottom: 0;
    }
  }
  .btn-base-b:focus {
    color: $hue-p4;
  }
  .bul-care {
    color: $hue-s3 !important;
  }
  .fx-wrap {
    .btn-all.selected {
      .h3 {
        color: $hue-p1;
        &::after {
          background: (
              "/static/images/common/ico_form@2x.png"
            ) -209.5px -12.5px no-repeat;
          background-size: 300px;
        }
      }
    }
  }
  .step-wrap {
    background: $hue-bg;
    width: 139px;
    height: 3px;
    display: inline-block;
    overflow: hidden;
    left: calc((100% - 139px) / 2);
    span {
      float: left;
      display: block;
      margin-right: 5px;
      width: 13px;
      height: 3px;
      text-indent: -1000em;
      overflow: hidden;
      background: #ededed;
      border-radius: 1px;
      &.pre,
      &.current {
        background: $hue-p0;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  .step-bar {
    background: $hue-p0;
  }
  .hue-p *,
  .hue-p {
    color: $hue-p1 !important;
  }
  select:hover,
  select:focus {
    border-color: $hue-p1;
  }
  input + .lb-check::before {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    margin-right: 10px;
  }
  input:checked + .lb-radio:before,
  input:checked + .lb-check:before,
  .lb-radio.checked:before,
  .lb-check.checked:before {
    border: 1px solid $hue-p1;
    background: $hue-p1
    url("/static/images/common/ico_form@2x.png")
    no-repeat -3px -3px;
  }
  [class*="btn-chips"] [checked] + .lb-check,
  .ui-m [class*="btn-chips"] :checked + .lb-check,
  .ui-m [class*="btn-chips"].selected {
    background-color: $hue-p1;
  }
  .box-head.type-b .txt-tag.type-b {
    background: $hue-p1;
  }
  .box-err {
    text-align: center;
  }
  .btn-base.focused,
  .btn-base.checked,
  .btn-base-m.checked,
  .btn-base:hover,
  .btn-base:focus {
    border-color: $hue-p1;
    color: $hue-p1;
    font-weight: bold;
  }
  .terms-wrap strong {
    color: $hue-p1;
  }
  // datepicker
  .tbl-datepicker {
    button:hover,
    button.selected {
      color: $hue-bg;
      background: $hue-p1;
    }
  }

  .tbl-datepicker button.today,
  .tbl-datepicker button.today + em,
  .tbl-datepicker button.selected-start + em,
  .tbl-datepicker button.selected-end + em {
    color: $hue-p1;
  }
  // 결제 - 계좌이체
  .chk-account .btn-base.focused:hover,
  .chk-account .btn-base.focused:focus {
    border-color: $hue-p1;
    background: inherit;
    color: $hue-p1;
  }
  // 가상키패드
  .keypad-wrap {
    .confirm {
      background-color: $hue-p0;
    }
    button:disabled {
      background: #e5e5e5 no-repeat 50% 50%;
    }
  }

  // ui-error-msg
  .ui-error-msg {
    // display:none !important;
    color: $hue-p4;
  }

  /* in label style */
  // 박스가 생기면서 해당 부분이 문제가 됨.
  .inp-base {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    border: 0;
    font-size: 16px;
    color: $hue-m;
    height: 38px;
    padding: 14px 0 0 0;
    outline: 0;
    background: transparent;
  }
  .focus,
  [class*="inp-"]:focus {
    border-color: $hue-p0;
    border-width: 2px;
    padding-top: 8px;
  }
  [class*="inp-"].init-height {
    height: initial;
    min-height: 38px;
    border-color: $hue-line2;
  }
  .box-base .focus,
  .box-base [class*="inp-"] {
    border-color: $hue-s2;
  }

  .field-inlabel {
    [class*="inp-"].ui-inpcancel,
    &.type-large [class*="inp-"].ui-inpcancel,
    .inp-base[name^="detailAddress"] {
      // LABEL이 Absolute이기에, 박스 사이즈가 작아지는 경우에 label과 아래 글자가 붙어버린다.
      // 여기에 padding을 주는 경우 select가 커버가 안되서 여기를 0주고 box padding을 늘린다.
      padding: 0px 7px 0px 0px;
    }
    [class*="inp-"] + .ui-inpcancel + .tel-password + ::placeholder,
    :-ms-input-placeholder {
      color: $hue-s3;
    }
    input.ui-inpcancel:focus,
    [class*="inp-"]:focus {
      border-color: $hue-p0 !important;
      border-width: 2px !important;
      padding-top: 0;
    }
  }
  // 체크 - input
  input:checked + .lb-radio:before,
  input:checked + .lb-check:before,
  .lb-radio.checked:before,
  .lb-check.checked:before {
    border: 1px solid $hue-p0;
    background: $hue-p0
    url("/static/images/common/ico_form@2x.png")
    no-repeat -3px -3px;
    background-size: 150px;
  }
  // 체크 - 버튼
  .btn-check {
    color: $hue-p0;
  }
  .btn-txt-arrow {
    color: $hue-p0;
  }
  .afc-wrap-#{$hue-afccd} + .btn-wrap.mg-n.txt-l {
    padding-left: 11px;
  }
  // 체크 - 라벨
  .lb-check {
    &.btn-txt {
      color: $hue-p0;
    }
    &.btn-txt:before {
      background: (
          "/static/images/common/ico_form@2x.png"
        ) -56px -25px no-repeat;
      background-size: 150px;
    }
  }
  .lb-check.btn-txt.checked:before,
  input:checked + .lb-check.btn-txt:before {
    background: (
        "/static/images/common/ico_form@2x.png"
      ) -56px -25px no-repeat;
    background-size: 150px;
  }
  .btn-toggle :checked + .lb-check,
  .btn-toggle .lb-check.checked {
    background: $hue-p0;
  }
  // 레이블들 글자색 변경으로 인해 라디오 레이블까지 글자색이 변경됨. 이에 대해 원복한다.
  .modalLayer {
    .fx-wrap:not(.radio-wrap) {
      .fx-item {
        label.lb-check {
          span {
            color: $hue-m;
          }
        }
      }
    }
  }
  // sub-title text
  .txt-s2 {
    font-size: 21px !important;
    color: $hue-m;
  }
  // 주민등록번호 input field
  .type-resreg {
    .inp-base {
      width: calc(35% - 10px);
      &[name*="Jumin1"],
      &[name*="Jumin2"] {
        padding: 0;
      }
    }
    .type-security {
      width: calc(65% - 16px);
      .inp-base.tel-password {
        width: calc(100% - 50px);
      }
      .btn-security {
        margin: 0 10px;
      }
    }
    .in-item {
      padding-top: 2px;
      margin: 8px 0;
    }
  }
  [class^="ui-slider-btn-"] {
    background: $hue-p0;
  }
  #uiTabCalculation {
    display: none;
  }
  // 아코디언 전체 class
  .ui-acco-wrap {
    [class*="inp-"] {
      border-bottom: 1px solid $hue-s4;
    }
  }
  .ui-slider-tooltip {
    color: $hue-p0;
  }
  .ui-slider-bar {
    background: $hue-p2;
  }
  /***** DIALOG *****/
  // page :전체 / bottom :아래 (ex :달력) / center :가운데 팝업
  #modalLayer .page {
    background-color: $hue-m;
    .box-dialog {
      .box-header {
        text-align: center;
        .box-title {
          font-size: 20px;
        }
      }
      .box-content {
        .btn-wrap.type-full button {
          max-width: 328px;
        }
        .ui-modal-progress {
          background-color: $hue-p0;
          .tit-process {
            color: $hue-bg;
          }
        }
        .wrap-inner#uiTermsContent {
          padding: 0 1.5rem;
        }
      }
    }
  }

  #modalLayer .center {
    border: 1px solid $hue-p0;
    .ui-modal-wrap {
      border-radius: 6px;
    }
    .box-dialog {
      z-index: 998;
      border-radius: 6px;
      margin: 0 30px;
      // text-align:center;
      .box-header {
        padding: 28px 15px 12px 15px;
        .box-title {
          text-align: center;
        }
      }
      .box-content {
        border-radius: 6px;
        padding: 0 15px;
      }
      .box-footer {
        padding: 0 0 20px;
        text-align: center;
        justify-content: center;
        .btn-cancel {
          height: 40px;
          min-width: 126px;
          border: 1px solid $hue-line4;
          background-color: $hue-bg;
          color: $hue-s3;
          border-radius: 30px;
          margin-right: 8px;
          padding: 8px 20px;
          text-align: center;
          font: 15px bold;
        }
        .btn-check {
          height: 40px;
          min-width: 126px;
          background-color: $hue-p0;
          color: $hue-bg; // white
          border-radius: 30px;
          padding: 8px 20px;
          text-align: center;
          font: 15px bold;
          &.ui-fctab-e.act-close::before,
          &.ui-modal-close::before {
            all: unset;
          }
          &.ui-modal-close {
            margin: 28px auto 0px auto;
          }
        }
      }
    }
    .background {
      opacity: 0;
    }
  }
  /*==================================================*/
  /*==================================================*/
  /*============= 전체 적용(MODAL + 진행화면) =============*/
  &.ui-m {
    // 자녀할인 특약에 대해서 달력이 보이지 않는 부분에 대해 수정
    body.dropdownOpened {
      -webkit-overflow-scrolling: auto;
    }
    .tab-scroll::after {
      right: -22px;
    }
    .fx-wrap.fx-dir-col {
      box-shadow: none;
    }
    // 기계좌 있을 시 선택하는 경우 radio로만 구현이 되어있기에 박스를 없애고 간격만 맞춰줌.
    .fx-wrap.radio-wrap {
      box-shadow: none;
      .fx-item {
        .field-inlabel {
          padding: 0 !important;
          .field-inlabel-label {
            position: relative;
          }
          .btn-tip {
            margin-top: 10px;
          }
        }
      }
    }
    [class*="btn-chips"] [checked] + .lb-check,
    [class*="btn-chips"] :checked + .lb-check,
    [class*="btn-chips"].selected {
      background-color: $hue-p0;
    }
    .txt-check {
      color: $hue-p4 !important;
    }
    .box-dialog {
      .fx-wrap.afc-wrap-#{$hue-afccd} {
        box-shadow: none;
      }
    }
    .pushInsInfo {
      .fx-wrap {
        .fx-item .field-inlabel input[readonly] {
          padding-top: 0px;
        }
        &.afc-wrap-#{$hue-afccd} {
          legend {
            @include sk-label;
          }
          .fx-item {
            .field-inlabel {
              box-shadow: $sk-shadow;
              [role="radiogroup"] {
                .lb-radio {
                  padding-left: 0px;
                  span {
                    color: $hue-m;
                  }
                }
              }
            }
          }
          margin-bottom: 16px;
        }
        &.radio-wrap {
          padding: 0 11px;
          [role="radiogroup"] {
            .field-inlabel {
              legend {
                position: relative;
              }
              .lb-radio {
                padding-left: 0px;
              }
            }
            .bul-care {
              margin-top: 0;
              margin-bottom: 15px;
            }
          }
        }
      }
    }
    // .ui-select select {
    //   direction: rtl;
    // }
    @media (min-width: 320px) {
      .type-term .fx-item {
        width: calc(45% - 15px);
      }
      .type-term .fx-item.short {
        width: 30px;
      }
      .type-term .fx-item:first-child {
        width: calc(55% - 15px);
      }
    }
    /*============= LABEL =============*/
    // 전체 체크하는 label. 버튼과 동일한 기능을 하기 때문에 버튼과 같은 모양으로 만든다.
    .all-check {
      text-align: center;
      .lb-check {
        @include sk-check(1);
      }
    }
    .lb-radio:before,
    .lb-check:before {
      min-width: 20px;
      min-height: 20px;
      margin-right: 10px;
    }
    .field-inlabel {
      padding: 30px 11px 8px !important;
      &.no-label {
        padding-top: 8px !important;
        margin-top: 0px;
      }
      &.pdt-n {
        padding-top: 0px;
        box-shadow: none;
      }
      // 금액은 오른쪽으로
      .inp-base.ui-inpcancel.moneyInput {
        text-align: right;
        padding-right: 40px;
      }
    }

    /*============= BUTTON =============*/
    .type-full {
      .btn-base:disabled {
        @include sk-check(0);
      }
      .btn-base {
        @include sk-check(1);
      }
    }
    .btn-base-l {
      @include sk-check(1);
      &:disabled,
      &.disabled,
      &[disabled] {
        background-color: $hue-bg2;
      }
    }
    .btn-wrap-fixed {
      text-align: center;
    }
    button.focused .ico-check:before,
    button.checked .ico-check:before,
    button:hover .ico-check:before,
    button:focus .ico-check:before,
    .btn-base.type-check.checked .ico-check::before {
      background-position: -6px -8px;
    }

    .ui-tab-btn.selected,
    .ui-tab-btn.selected:hover,
    .ui-tab-btn.selected:focus,
    .ui-tab.type-linebox .ui-tab-btn.selected {
      color: $hue-p0;
      border: 1px solid $hue-p2;
      border-bottom: 2px solid $hue-p0;
      i {
        font-weight: 700;
      }
    }
    .ui-tab.type-linebox .ui-tab-btn.selected:last-child {
      border-right: 2px solid $hue-p0;
    }
    .ui-tab.type-linebox .ui-tab-btn {
      border: 1px solid $hue-line;
      color: $hue-s4;
    }
    // 일반적으로 fx-wrap안에는 입력 필드만 있는데, 이 class는 안에 버튼도 있음.
    // 해당 부분에 대해 따로 수정 진행 : 누가 운전할 지 선택하는 화면
    .page-driver-inp {
      .fx-wrap {
        box-shadow: none;
        .fx-item {
          .field-inlabel {
            box-shadow: $sk-shadow;
            margin-bottom: 28px;
          }
          .btn-base {
            @include sk-check(1);
            margin: 0 auto;
          }
        }
      }
    }
    // 아코디언 타입은 날짜에 필드가 있기 때문에 padding설정이 기본 설정을 따라가야함. 아니면 전체 외형을 다시 다 잡아야 됨.
    .ui-acco-pnl .field-inlabel {
      padding: 17px 0px 19px !important;
    }
    .wrap-inner {
      padding: 0 22px;
      .type-daytime .fx-item {
        text-align: right;
        width: calc(65%);
        flex-grow: 0;
      }
      .type-daytime .fx-item:first-child {
        text-align: left;
        width: calc(35%);
      }
      fieldset .lb-radio,
      fieldset .lb-check {
        margin-right: 1rem;
      }
    }
    .bottomMargin {
      height: 230px;
      display: block;
    }
    // 자동차보험 마일리지 슬라이더
    .mileage-slider--car [class^="ui-slider-btn-"] {
      background: $hue-p0;
    }
  }
}

/* 20200511 추가*/
html.PA00029.case-a {
  .base-wrap {
    .base-header {
      .logo {
        font-size: 14px;
      }
      .btn-menu {
        display: none;
      }
    }
    .base-body {
      padding-top: 82px;
      .base-main {
        padding: 0;
        .wrap-inner {
          padding: 0 31px;
        }
      }
    }
    .box-head {
      h3.tit {
        font-size: 18px;
        font-weight: 700;
        color: #000;
        float: none;
        margin-bottom: 0;
      }
      .txt {
        color: #707070;
        margin-top: 0;
        font-size: 14px;
      }
    }
  }
  .field-inlabel {
    padding: 17px 0 19px !important;
  }
  #modalLayer .page {
    background-color: $hue-m;
    .box-dialog {
      .box-header {
        text-align: center;
        .box-title {
          font-size: 14px;
        }
      }
      .field-inlabel {
        padding: 30px 11px 8px !important;
      }
    }
  }
  .h1 {
    font-size: 21px !important;
    text-align: left;
  }
  .h4 {
    font-size: 16px !important;
    text-align: left;
  }
}
