// * lb-radio -> carrot-radio 로 변경중

.carrot-raido-container {
  display: flex;
  flex-wrap: wrap;
}

.carrot-radio-item {
  margin-right: 16px;
  margin-bottom: 10px;
  color: #fff;
  border-radius: 5px;
}

.carrot-radio {
  height: 0;
  width: 0;
}

.carrot-radio + label {
  position: relative;
  display: flex;
  font-size: 20px;
  line-height: 20px;
  margin: 0;
  align-items: center;
  transition: color 250ms cubic-bezier(0.4, 0, 0.23, 1);
  align-items: flex-start;
}

.carrot-radio + label > .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  width: 1em;
  height: 1em;
  min-width: 1em;
  border-radius: 50%;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 50%;
  cursor: pointer;
  transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
}

.carrot-radio + label > .text {
  vertical-align: center;
  color: #777;
  font-size: 14px;
  line-height: 20px;
}

.carrot-radio + label:hover > .icon,
.carrot-radio:focus + label > .icon {
  background: #fff;
}

.carrot-radio:checked + label > .icon {
  border: 0.5em solid #f05014;
  animation: shrink-bounce 200ms cubic-bezier(0.4, 0, 0.23, 1);
}

.carrot-radio:checked + label > .icon:before {
  content: "";
  position: absolute;
  top: 0.45em;
  left: 0.25em;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  transform: rotate(45deg);
  transform-origin: 0% 100%;
  animation: radio-check 125ms 250ms cubic-bezier(0.4, 0, 0.23, 1) forwards;
}

@keyframes shrink-bounce {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes radio-check {
  0% {
    width: 0;
    height: 0;
    border-color: #fff;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  33% {
    width: 0.25em;
    height: 0;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  100% {
    width: 0.25em;
    height: 0.375em;
    border-color: #fff;
    transform: translate3d(0, -0.375em, 0) rotate(45deg);
  }
}

.ui-m {
  .carrot-radio + label > .text {
    vertical-align: center;
    color: var(--carrot-font-color4, #777);
    font-size: 14px;
    line-height: 20px;
  }
}
